import {v4 as uuidv4} from "uuid";
import {Item} from "../item.model";

import {IMetaData, MetaData} from "../../data/files/meta-data.model";
import {EncounterDto} from "../../dto/encounter/encounterDto";

export interface IFileItem extends Item {
  image?: string;
  name: string;
  path: string;
  metaData: IMetaData;
  finishedLoading: boolean;
  encounterId: string | undefined;
  encounter?: EncounterDto;
  populationId?: string;
  file?: Blob;
  marked?: boolean;


}

export class FileItem implements IFileItem {
  id: string;
  image?: string;
  name: string;
  path: string;
  metaData: IMetaData;
  finishedLoading = false;
  encounterId: string | undefined;
  file?: Blob;
  constructor(path: string, metaData?: IMetaData, encounterId?: string, image?: string) {
    this.image = image;
    this.path = path;
    this.name = path.split("/").pop()!;
    this.id = uuidv4().toString();
    this.metaData = metaData || new MetaData();
    this.metaData.fileItemId = this.id;

    this.encounterId = encounterId;
  }

}
