import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { EncounterDto } from "../../../../../../../models/dto/encounter/encounterDto";
import { OrganizationDto } from "../../../../../../../models/dto/user/organization/organizationDto";
import { EncountersService } from "../../../../../../../services/encounters/encounters.service";
import { ErrorHandlerService } from "../../../../../../../services/error/error-handler.service";
import { ILoggingService } from "../../../../../../../services/logging/logging.service.interface";
import { UserOrganizationService } from "../../../../../../../services/user/organization/user-organization.service";

@Component({
  selector: 'app-update-organizations',
  templateUrl: './update-organizations.component.html',
  styleUrls: ['./update-organizations.component.scss']
})
export class UpdateOrganizationsComponent implements OnInit, OnDestroy {
  @Input() encounter?: EncounterDto
  @Output() newOrganizations: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  public userOrganizations: Array<OrganizationDto> = new Array<OrganizationDto>();
  public selectableOrganizations: Array<OrganizationDto> = new Array<OrganizationDto>();
  public inProgress = false;
  selectedOrganizations: UntypedFormControl = new UntypedFormControl();
  private subs: Array<Subscription> = new Array<Subscription>();
  constructor(
    private orgService: UserOrganizationService,
    private errorHandler: ErrorHandlerService,
    private encounterService: EncountersService,
    private log: ILoggingService
    ) {
  }

  ngOnDestroy() {
    for (let a of this.subs) {
      a.unsubscribe();
    }
  }

  updateOrganizations() {
    this.inProgress = true;
    this.encounterService
      .addOrganizationsToEncounter(this.encounter!.id!, this.selectedOrganizations.value)
      .subscribe({
        next: value => {
          this.log.info(`Organizations added. This process may take some time to update all affected items.`)
          this.inProgress = false;
        },
        error: value => {
          this.errorHandler.handleRequestError("Adding Organizations", value)
          this.inProgress = false;
        }
      })
  }

  ngOnInit() {
    if (!this.encounter?.user?.id) {
      return;
    }


    this.subs.push(
      this.orgService.getOrganizationsForUser(this.encounter.user.id).subscribe({
        next: (value: Array<OrganizationDto>) =>{
          this.userOrganizations = value;
          const encounterOrganizationIds = this.encounter?.organizations?.map(a => a.id) ?? [];
          this.selectableOrganizations = this.userOrganizations.filter(a => encounterOrganizationIds.indexOf(a.id) == -1);
        },
        error: (value: HttpErrorResponse) => this.errorHandler.handleRequestError("Fetching user organizations", value)
      })
    )
  }
}
