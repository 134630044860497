<div class="profile-container">
  <div class="profile" *ngIf="organizationProfile !== undefined">

    <!-- Info and Population -->
    <div class="information-panel">
      <div class="profile-header panel">
        <div class="profile-logo" *ngIf="organizationProfile">
          <app-organization-profile-picture [organizationProfile]="organizationProfile" [administrationAccess]="administrationAccess"></app-organization-profile-picture>
        </div>
        <div class="organization-identifiers">
          <div class="organization_name id-field">{{organizationProfile.organization_Name}}</div>
          <div class="organization_url  id-field" *ngIf="organizationProfile?.organization_Url">
            <a [href]="organizationProfile.organization_Url" target="_blank">{{organizationProfile.organization_Url}}</a>
          </div>
          <button mat-stroked-button color="accent" class="profile-edit-button" *ngIf="administrationAccess" (click)="toggleEditOrganization()">Edit Organization</button>
          <button mat-stroked-button color="accent" class="profile-join-button" *ngIf="!isMember" (click)="joinOrganization()">Join Organization</button>
          <button mat-stroked-button color="accent" class="profile-leave-button" *ngIf="isMember" (click)="leaveOrganization()">Leave Organization</button>
          <!-- *ngIf="!foreign"   *ngIf="profileEditActive" -->
          <div class="organization-edit-container" *ngIf="organizationEditActive">
            <form [formGroup]="OrganizationProfileUpdateForm">
              <mat-form-field>
                <mat-label>Organization Name</mat-label>
                <input matInput placeholder="Organization Name" formControlName="name" [value]="organizationProfile.organization_Name">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Organization Url</mat-label>
                <input matInput placeholder="Organization Url" formControlName="url" [value]="organizationProfile.organization_Url">
              </mat-form-field>
              <button mat-stroked-button color="accent" (click)="updateOrganizationProfileInformation(OrganizationProfileUpdateForm.value)">Save</button>
            </form>
          </div>
          <mat-divider></mat-divider>
          <div class="summary">
            <!--            <div class="summary-line">-->
            <!--              <span class="summary-category">Admins: </span><span class="count summary-value">{{allOrganizationAdmins}}</span>-->
            <!--            </div>-->
            <div class="summary-line">
              <span class="summary-category">Members: </span><span class="count summary-value">{{allOrganizationMembers}}</span>
            </div>
            <div class="organization-encounters summary-line">
              <span class="summary-category">Encounters: </span><span class="count summary-value">{{allOrganizationEncounters}}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="organization-sidebar sidebar-line">
            <h3>Active Populations</h3>
            <div class="organization-population-list" *ngFor="let population of OrganizationPopulationStatistics">
              <app-organization-profile-population-preview [population]="population"></app-organization-profile-population-preview>
            </div>
          </div>
        </div>
      </div>
      <div class="organization-summary panel">
        <div class="panel-content">
          <div class="panel-section">
            <h1><span class="summary-category">Members: </span></h1>
            <div class="organization-list summary-line">
              <div class="organization-member-list" *ngFor="let members of OrganizationMemberList">
                <app-organization-profile-member-preview [member]="members"></app-organization-profile-member-preview>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>
          <div class="panel-section">
            <app-encounter-timeline [organizationId]="organizationProfile!.organization_Id!"></app-encounter-timeline>
<!--            <h1><span class="summary-category">Encounters: </span></h1>-->
<!--            <div class="organization-list summary-line">-->
<!--              <div class="organization-encounters-list" *ngFor="let encounters of OrganizationEncounters">-->
<!--                <app-organization-profile-encounter-preview [encounter]="encounters"></app-organization-profile-encounter-preview>-->
<!--              </div>-->
<!--            </div>-->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
