import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContributorDto} from "../../../../../../models/dto/population/contributorDto";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationDto} from "../../../../../../models/dto/population/populationDto";
import {PopulationService} from "../../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";

@Component({
  selector: 'app-population-contributor-edit',
  templateUrl: './population-contributor-edit.component.html',
  styleUrls: ['./population-contributor-edit.component.scss']
})
export class PopulationContributorEditComponent implements OnInit {
  @Input() contributor: ContributorDto | undefined;
  @Input() contributors: Array<ContributorDto> | undefined;
  @Input() population: PopulationDto | undefined;
  @Input() editing: boolean = false;
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();
  addingContributor = false;
  constructor(
    private populationService: PopulationService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.contributor === undefined) {
      this.contributor = {
        populationId: this.population?.id
      }
    }
  }

  cancelAdd() {
    this.addingContributor = false;
    this.contributor = undefined;
    this.cancelled.emit();
  }



  confirmContributor() {
    if (this.population && this.population.id && this.contributor) {
      if (this.editing) {
        this.populationService.updateContributor(this.population.id, this.contributor)
          .subscribe({
            next: (value: ContributorDto) => {
              this.log.info(`Updated ${value.name}`);
              this.contributor = undefined;
              this.addingContributor = false;
              this.cancelAdd();

            },
            error: (value: HttpErrorResponse) => {
              this.log.error(`Could not update ${this.contributor?.name} : ${value.error}`);
              this.cancelAdd();
            }
          })
      } else {
        this.populationService.addContributor(this.population.id, this.contributor)
          .subscribe({
            next: (value: ContributorDto) => {
              this.log.info(`Added ${value.name}`);
              this.cancelAdd();

            },
            error: (value: HttpErrorResponse) => {
              this.log.error(`Could not add ${this.contributor?.name} : ${value.error}`);
              this.cancelAdd();
            }
          })
      }

    }
  }

  addIcon(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then( res => {
      this.contributor!.icon = res as string;
    })
  }

  addAlternateIcon(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then( res => {
      this.contributor!.footerIcon = res as string;
    })
  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
