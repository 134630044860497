import {Component, Inject, OnInit} from '@angular/core';
import {NewUserInvitation} from "../../populations/membership-invitation/membership-invitation.component";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export class OrganizationUserAddData {
  newUsers?: Array<UserProfileDto>;
  currentMembers?: Array<UserProfileDto>;
  allUsersObs?: Observable<Array<UserProfileDto>>;
}

@Component({
  selector: 'app-organization-user-add-dialog',
  templateUrl: './organization-user-add-dialog.component.html',
  styleUrls: ['./organization-user-add-dialog.component.scss']
})
export class OrganizationUserAddDialogComponent implements OnInit {

  constructor(    public dialogRef: MatDialogRef<OrganizationUserAddDialogComponent>,
                  @Inject(MAT_DIALOG_DATA) public data: OrganizationUserAddData) { }

  public memberIds: Array<string> | undefined;

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.memberIds = this.data.currentMembers?.map(a => a.email!);
  }

  submit() {
    const addedUsers = this.memberIds?.filter(a => this.data.currentMembers?.map(e => e.email).indexOf(a) == -1);
    this.dialogRef.close(addedUsers);
  }

  filterUsers(users: Array<UserProfileDto>) {
    this.data.currentMembers?.map(a => a.email)
    return users.filter(a => this.data.currentMembers?.map(a => a.email).indexOf(a.email) == -1)
  }
}
