<div class="content-container">
  <div class="header">
    <h1>Applications for Population Creation</h1>
<!--    <div class="menu">-->
<!--      <button mat-button [matMenuTriggerFor]="menu">Sort By</button>-->
<!--      <mat-menu #menu="matMenu">-->
<!--        <button mat-menu-item (click)="sortFactor = 'status'">Status</button>-->
<!--        <button mat-menu-item (click)="sortFactor = 'date'">Change Date</button>-->
<!--      </mat-menu>-->
<!--    </div>-->

  </div>

  <mat-divider></mat-divider>
  <div class="population-applications" *ngIf="appObs | async as applications; else loading">
    <app-population-creation-application *ngFor="let application of sorted(applications)" [application]="application" (applicationUpdated)="refetch()"></app-population-creation-application>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [color]="'accent'" [diameter]="25" [mode]="'indeterminate'"></mat-progress-spinner>
</ng-template>
