import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {PopulationDto} from "../../models/dto/population/populationDto";
import {HttpClient} from "@angular/common/http";
import {ILoggingService} from "../logging/logging.service.interface";
import {UserContentService} from "../user/content/user-content.service";
import {PopulationRoleSet} from "./population.service";
import {PopulationFeedEntryDto} from "../../models/dto/population/populationFeedEntryDto";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PopulationFeedService {



  constructor(
    private httpClient: HttpClient,
    private log: ILoggingService,
    private userContentService: UserContentService) {
  }

  public getFeed(populationId: string): Observable<Array<PopulationFeedEntryDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.population.feed}/${populationId}`;
    return this.httpClient.get<Array<PopulationFeedEntryDto>>(url);
  }

  public createFeedEntry(populationId: string, encounterId: string): Observable<PopulationFeedEntryDto> {

    const url = `${environment.server.baseUrl}${environment.server.api.population.feed}`;
    return this.httpClient.post<PopulationFeedEntryDto>(url, {populationId: populationId, encounterId: encounterId});
  }


  public getDummyFeed(count: number): Array<PopulationFeedEntryDto> {
    const authors = ["Jared Towers", "Mark Malleson", "Gary Sutton", "Tasli Shaw"];
    const locations = ["Alert bay", "Victoria", "Blackfish Sound", "Beaver Cove", "San Juan"];

    const l: Array<PopulationFeedEntryDto> = [];
    for (let i = 0; i < count; i++) {
      l.push({
        populationId: "asdf",
        encounterId: "asdfsadfa",
        location: locations[Math.floor(Math.random()*locations.length)],
        photoCount: Math.floor(Math.random() * 200),
        userName: authors[Math.floor(Math.random()*authors.length)],
        dateTime: new Date(new Date(2022, 0, 1).getTime() + Math.random() * (new Date().getTime() - new Date(2022, 0, 1).getTime()))
      })
    }
    return l
  }


}
