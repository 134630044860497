import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {AnimalProfileDto} from "../../../../models/dto/animal/animalProfileDto";
import {AnimalService} from "../../../../services/animal/animal.service";
import {ExtractedImage} from "../../../../models/dto/inference/verificationTaskDto";
import {mergeMap, Observable, of, Subscription} from "rxjs";
import {ImageService} from "../../../../services/utilities/image.service";
import {AnimalRelationshipDto} from "../../../../models/dto/animal/animalRelationshipDto";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {Location} from "../../../../models/location/location.model";
import {IFileItem} from "../../../../models/items/files/file-item.model";
import {AnnotatedImage} from "../../../../models/items/files/images/annotated-images/annotated-image.model";
import {RelationshipUpdateDialogComponent} from "./relationshipUpdateDialog/relationship-update-dialog.component";
import {ResponseDto} from "../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {DateService} from "../../../../services/utilities/date.service";
import {ResponsiveDesignService} from "../../../../services/design/responsive-design.service";
import {MatDialog} from "@angular/material/dialog";
import { PopulationDto } from '../../../../models/dto/population/populationDto';
import { PopulationService } from '../../../../services/population/population.service';
import { WorkspaceDto } from '../../../../models/dto/workspace/workspaceDto';
import { WorkspaceService } from '../../../../services/workspace/workspace.service';

@Component({
  selector: 'orca-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy, OnChanges {
  public profile: AnimalProfileDto | undefined;
  public imageData: Observable<Array<IFileItem>> | undefined;
  public id!: string;
  public encounterImageMap: Map<string, Observable<Array<IFileItem>>> = new Map<string, Observable<Array<IFileItem>>>();
  public locations: Array<Location> | undefined;
  public originalLocations: Array<Location> | undefined;
  public finishedLoading = false;
  public filterYear: number | undefined;

  profileImagesLoaded: boolean | undefined;
  notesLoaded: boolean | undefined;
  sightingsLoaded: boolean | undefined;
  relationshipsLoaded: boolean | undefined;
  profileDetailsLoaded: boolean | undefined;
  isMobile = false;
  isTablet = false;
  loading = true;

  private subs: Array<Subscription> = [];
  public years?: Array<number>;
  public selectedYear?: number | string;

  public nextProf: AnimalProfileDto | undefined;
  public prevProf: AnimalProfileDto | undefined;
  public population: PopulationDto | undefined;

  constructor(
    private route: ActivatedRoute,
    private animalService: AnimalService,
    public imageService: ImageService,
    private router: Router,
    private encounterService: EncountersService,
    private dialog: MatDialog,
    private log: ILoggingService,
    private dateService: DateService,
    private responsiveService: ResponsiveDesignService,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService
    ) { }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit(): void {

    this.responsiveService.mobile.subscribe(res => {
      this.isMobile = res;
    });
    this.responsiveService.tablet.subscribe(res => {
      this.isTablet = res;
    });
    /*this.populationService.population.subscribe(res => {
      this.population = res;
    })
    this.workspaceService.workspace.subscribe(res => {
      this.population = res.settings!.population;
    })*/
    /*this.workspaceService.getPopulationWorkspace().subscribe(res => {
  this.population = res.settings?.population;
}) not working

this.populationService.getPopulationById(this.profile!.populationId!).subscribe(res => {
      this.population = res;
      if (this.population != undefined) {

        this.loadpreviousAnimal();
        this.loadnextAnimal();
      }
      else {
        console.log("Failure, no populationID")
      }
    });


*/

    //this.workspaceService.getPopulationWorkspace().subscribe

    this.profileImagesLoaded = false;
    this.notesLoaded = false;
    this.sightingsLoaded = false;
    this.relationshipsLoaded = false;
    this.profileDetailsLoaded = false;
    this.route.params.subscribe(res => {
      this.id = res["id"];
      this.loading = false;
      const sub = this.animalService.getAnimalProfile(this.id).subscribe( {
        next: (res: AnimalProfileDto) => {
          this.profile = res;
          this.finishedLoading = true;
          this.locations = new Array<Location>();
          if (this.profile.encounters) {
            for (let encounter of this.profile.encounters) {
              this.encounterImageMap.set(encounter.id!,
                this.encounterService.getExtractedImagesForAnimalInEncounter(encounter.id!, this.id)
                  .pipe(mergeMap((items: Array<ExtractedImage>) => {
                    return this.mapExtractedImagesToFileItems(items, encounter.id!)
                  }))
              );
              if (encounter.location) {
                this.locations.push(encounter.location)
              }

            }
          }
          this.originalLocations = this.locations;
          this.profile.originalEncounters = res.encounters;

          this.populationService.getPopulationById(this.profile!.populationId!).subscribe(res => {
            this.population = res;
            if (this.population != undefined) {

              // this.loadpreviousAnimal();
              // this.loadnextAnimal();
              this.loading = false;
            }
            else {
            }
          });

        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Error loading profile: ${error.message}.`, true)
          this.finishedLoading = true;
        }
      });
      this.subs.push(sub);
      this.imageData = this.animalService.getExtractedAnimalImages(this.id).pipe(mergeMap( (items: Array<ExtractedImage>) => {
        return this.mapExtractedImagesToFileItems(items, "")
      }));
    });


  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public mapExtractedImagesToFileItems(items: Array<ExtractedImage>, encounterId: string): Observable<Array<IFileItem>> {
    const fileItems: Array<IFileItem> = [];
    for (let item of items) {
      const fileItem: AnnotatedImage = {
        image: this.imageService.prefixImg(item.image!),
        id: item.id,
        encounterId: encounterId,
        name: "",
        path: "",
        finishedLoading: true,
        annotations: [],
        width: item.width ?? 512,
        height: item.height ?? 512,
        metaData: {
          id: "",
          thumbnail: this.imageService.prefixImg(item.image!),
          width: item.width ?? 512,
          height: item.height ?? 512,
        }
      }
      fileItems.push(fileItem);
    }
    return of(fileItems);

  }

  formatDate(date: any) {
    const dt = new Date(date);
    const dts = `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`
    return dts;
  }

  toggleRelationship(relationship: AnimalRelationshipDto) {
    if (this.profile?.relationships === undefined) {
      return;
    }
    for (let r of this.profile?.relationships) {
      r.active = false;
    }
    relationship.active = true;

  }
  visitRelation(relationship: AnimalRelationshipDto) {
    let relatedAnimal = relationship.animalAId;
    if (relationship.animalAId == this.id) {
      relatedAnimal = relationship.animalBId;
    }
    this.router.navigate(['/animals', relatedAnimal]);
  }

  getRelatedName(relationshipDto: AnimalRelationshipDto) {
    if (relationshipDto.animalAId == this.id) {
      return relationshipDto.animalBName
    }
    return relationshipDto.animalAName;
  }

  getRelationshipType(relationship: AnimalRelationshipDto) {
    return relationship.relationshipType ? relationship.relationshipType : "Relationship Unknown";
  }

  updateRelationship(relationship: AnimalRelationshipDto) {
    const dialogRef = this.dialog.open(RelationshipUpdateDialogComponent,  {
      data: relationship
    });

    dialogRef.afterClosed().subscribe((result: AnimalRelationshipDto) => {
      this.animalService.updateAnimalRelationship(result).subscribe({
        next: (value: ResponseDto) => {
          this.log.info(`Relationship between ${relationship.animalAName} and ${relationship.animalBName} set to ${result.relationshipType}`, true)
        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Relationship was not updated: ${error.message}`, true);
        }
      })
    });
  }

  filterContent() {
    if (this.filterYear !== undefined) {
      this.locations = this.originalLocations?.filter(l => new Date(l.dateTime!).getUTCFullYear() == this.filterYear);
      this.profile!.encounters = JSON.parse(JSON.stringify(this.profile?.originalEncounters!.filter(l => new Date(l.dateTime!).getUTCFullYear() == this.filterYear)));
      this.profile = JSON.parse(JSON.stringify(this.profile));
    } else {
      this.ngOnInit();
    }
  }

  updateContent(year: number | undefined) {
    this.filterYear = year;
    this.filterContent();
  }

  selectYear() {
    if (this.selectedYear == "all" || this.selectedYear === undefined) {
      this.updateContent(undefined);
      this.filterYear = undefined;
    } else {
      this.updateContent(this.selectedYear as number);
      this.filterYear = this.selectedYear as number;
    }
  }

  loadpreviousAnimal() {
    this.loading = true;
    this.animalService.getPreviousAnimal(this.id!, this.population!.id!, "all").subscribe({
      next: (value: AnimalProfileDto) => {
        this.prevProf = value;
      },
      error: (err: HttpErrorResponse) => {

      }
    });
  }
  loadnextAnimal() {
    this.loading = true;
    this.animalService.getNextAnimal(this.id!, this.population!.id!, "all").subscribe({
      next: (value: AnimalProfileDto) => {
        this.nextProf = value;
      },
      error: (err: HttpErrorResponse) => {

      }
    });
  }

  public nextProfile() {
    this.router.navigate([`/individuals/${this.nextProf!.id}`]);
  }

  public previousProfile() {
    this.router.navigate([`/individuals/${this.prevProf!.id}`]);
  }
}
