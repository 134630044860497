import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  PopulationCreationApplicationDto, PopulationCreationApplicationUpdate
} from "../../../../../../models/dto/population/populationCreationApplicationDto";
import {UserProfileDto} from "../../../../../../models/dto/user/userProfileDto";
import {PopulationManagementService} from "../../../../../../services/population/population-management.service";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../../../services/error/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-population-creation-application',
  templateUrl: './population-creation-application.component.html',
  styleUrls: ['./population-creation-application.component.scss']
})
export class PopulationCreationApplicationComponent implements OnInit {
  @Input() application: PopulationCreationApplicationDto | undefined;
  @Output() applicationUpdated: EventEmitter<any> = new EventEmitter<any>();
  commenting: any = false;
  update: PopulationCreationApplicationUpdate | undefined;
  processing = false;
  constructor(
    private populationManagementService: PopulationManagementService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService

  ) { }

  ngOnInit(): void {
    if (this.application) {
      this.update = {comments: "", applicationId: this.application!.id!}
    }
  }

  getMailLink(authorityA: UserProfileDto) {
    return `mailto:${authorityA.email}`
  }

  acknowledge() {
    this.update!.status = "IN REVIEW";
    this.putUpdate();

  }

  approve() {
    this.commenting = true;
    this.update!.status = "ACCEPTED";
  }

  reject() {
    this.commenting = true;
    this.update!.status = "REJECTED";
  }

  putUpdate() {
    this.processing = true;
    this.populationManagementService
      .updateApplication(this.update!)
      .subscribe({
        next: () => {
          this.log.info("Successfully acknowledged ");
          this.applicationUpdated.emit();
          this.processing = false;
        }, error: (err: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Updating Application", err);
          this.processing = false;
        }
      })
  }

  reopen() {
    this.acknowledge();
  }
}
