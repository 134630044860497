import {Injectable} from "@angular/core";
import * as L from 'leaflet';
import {IMetaData} from "../../models/data/files/meta-data.model";
import {DateService} from "../utilities/date.service";
import {EncounterDto} from "../../models/dto/encounter/encounterDto";

@Injectable({
  providedIn: "root"
})
export class MapService {

  constructor(public dateService: DateService) {}

  public getTileLayer(): L.TileLayer {
    return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
      maxZoom: 20});
    // return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}', {
    //   attribution: 'Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri',
    //   maxZoom: 13
    // });
  }

  public getMarkerContentFromMetaData(metaData: IMetaData, individualId: string | undefined): string {
    let markerContent = "";

    if (individualId) {
      markerContent = `${markerContent}${individualId} </br>`
    }

    if (metaData.location && metaData.location.name) {
      markerContent = `${markerContent}${metaData.location.name} </br>`;
    }
    if (metaData.creationDateTime) {
      if (typeof(metaData.creationDateTime) == "string") {
        metaData.creationDateTime = new Date(metaData.creationDateTime);
      }
      markerContent = `${markerContent}Creation Date: ${this.dateService.formatDate(metaData.creationDateTime)} </br>`
    }
    if (metaData.photographerName) {
      markerContent = `${markerContent}Photographer: ${metaData.photographerName} </br>`
    }
    return markerContent;
  }

  public getMarkerContentFromEncounter(encounter: EncounterDto): string {
    let markerContent = "";
    if (encounter.dateTime) {
      markerContent = `${markerContent}${this.dateService.formatDateFromAny(encounter.dateTime,  false)} </br>`
    }
    if (encounter.location) {
      markerContent = `${markerContent}${encounter.location.name}</br>`
    }
    if (encounter.user) {
      markerContent = `${markerContent}${encounter.user.firstName} ${encounter.user.lastName}</br>`
    }


    return markerContent
  }

  public getRandomHexColor(): string {
    const colorArray = ["2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D"]
    let color = ""
    for (let i = 0; i < 6; i++) {
      const component = colorArray[Math.floor(Math.random()*colorArray.length)]
      color += component;
    }
    return color;
  }

  public createSvgIcon(hexColor: string): L.DivIcon {
    return L.divIcon({
      html: `
        <svg
         width="32"
         height="32"
         viewBox="0 0 12.7 12.7"
         version="1.1"
         id="svg5"
         inkscape:version="1.1.2 (1:1.1+202202050950+0a00cf5339)"
         sodipodi:docname="orca-map-icon.svg"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:svg="http://www.w3.org/2000/svg">
        <sodipodi:namedview
           id="namedview7"
           pagecolor="#ffffff"
           bordercolor="#666666"
           borderopacity="1.0"
           inkscape:pageshadow="2"
           inkscape:pageopacity="0.0"
           inkscape:pagecheckerboard="0"
           inkscape:document-units="mm"
           showgrid="false"
           units="px"
           inkscape:zoom="16"
           inkscape:cx="-11.1875"
           inkscape:cy="15.96875"
           inkscape:window-width="2560"
           inkscape:window-height="1375"
           inkscape:window-x="1440"
           inkscape:window-y="740"
           inkscape:window-maximized="1"
           inkscape:current-layer="layer2" />
        <defs
           id="defs2" />
        <g
           inkscape:groupmode="layer"
           id="layer2"
           inkscape:label="Background"
           style="display:inline">
          <ellipse
             style="fill:#${hexColor};stroke:#feffff;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"
             id="path859"
             cx="6.3525782"
             cy="6.3363199"
             rx="6.328464"
             ry="6.3423772" />
        </g>
        <g
           inkscape:groupmode="layer"
           id="layer3"
           inkscape:label="OrcaPath"
           style="display:inline">
          <path
             id="path1111"
             style="display:inline;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.245669;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="M 21.503906,4.2460938 20.333984,4.3574219 19.617188,4.84375 18.259766,5.8789062 16.257812,7.6953125 14.847656,9.21875 l -1.154297,1.759766 -0.86914,1.576172 -0.908203,1.636718 -1.126954,2.529297 -0.113281,1.15625 -0.316406,3.005859 -0.5683594,3.056641 -0.421875,3.513672 0.3085938,4.052734 0.1191406,4.13086 -0.2578125,4.111328 22.0996095,0.119141 -0.240234,-0.601563 -0.771485,-1.375 -0.390625,-0.898437 -0.558594,-1.03125 -0.539062,-0.919922 -1.007813,-2.09375 h 0.472657 l 0.769531,0.167968 0.9375,0.05664 0.851562,-0.167969 0.640625,-0.654297 0.113282,-1.199218 -0.84961,-1.007813 -2.117187,-1.558594 -1.271485,-0.878906 -1.253906,-0.419922 -3.101562,-1.216797 -1.121094,-0.386718 h -0.583984 l -0.472657,0.861328 0.03906,1.96289 -1.337891,-0.521484 -0.703125,-1.310547 -0.9375,-0.728516 -1.03125,-1.041015 -0.71289,-1.615235 -0.636719,-1.873046 0.130859,-1.679688 0.244141,-1.529297 0.701587,-1.738058 0.710522,-1.806864 1.208984,-1.935547 1.394532,-2.705078 L 22.8125,6.0625 22.707031,5.2636719 22.238281,4.4863281 Z m -7.429687,11.9921872 0.105469,0.21875 -0.05469,1.707031 0.119141,2.601563 -0.146485,1.394531 -0.394531,1.69336 -0.162109,1.818359 -0.490235,1.863281 -1.126953,0.248047 -0.451172,-1.869141 -0.173828,-1.466796 v -1.054688 l 0.277344,-1.039062 0.04883,-1.746094 0.111328,-1.371094 0.423828,-1.113281 0.640625,-1.054688 z"
             transform="scale(0.26458333)"
             sodipodi:nodetypes="ccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc" />
          <path
             style="display:inline;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.065;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="m 6.0356575,1.6041355 0.1862772,0.2199779 0.092658,0.2529641 0.1394108,0.3322475 0.1514946,0.4951343 0.096301,0.3826417 0.1391867,0.4539483 0.116122,0.4900668 0.1260231,0.3529555 0.1166056,0.4160194 0.1235334,0.3421724 0.074459,0.3972298 0.1680327,0.624676 0.1597051,0.4482639 0.068944,0.097695 0.1528797,0.2471883 0.1593488,0.3435263 0.2251829,0.3817652 0.1126232,0.3592659 0.062186,0.4220494 0.1593331,0.4412099 0.2966107,0.6582709 0.2341477,0.5202129 0.1144699,0.189753 H 9.4322732 L 8.5977501,8.6236827 8.5456727,8.2715491 l 0.104584,-0.072887 0.7195616,0.1258085 0.5858068,0.021312 0.6570179,-0.1002186 0.47429,-0.1185788 0.529,-0.2613599 0.0548,-0.2974692 L 11.558884,7.4003399 10.80115,7.1511252 9.6332179,6.8592014 9.3215782,6.805922 8.8174204,6.8167117 8.458134,6.8085705 8.0774581,6.7632942 7.862094,6.7081428 7.7573663,6.5583693 7.627831,6.0103069 7.569596,5.5012036 7.3869896,4.844534 7.183584,4.2192127 6.936106,3.3632568 6.6996608,2.7567043 6.4890829,2.1013705 6.2761478,1.658988 6.0693733,1.443938 Z"
             id="path1341"
             sodipodi:nodetypes="ccccccccccccccccccccccccccccccccccccccccccccccccccccc" />
        </g>
      </svg>`,
      className: "svg-icon",
      iconSize: [32, 32],
      iconAnchor: [16, 16],
    });
  }
}
