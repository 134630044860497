<div class="profile-notes-container" *ngIf="profile">
  <div class="profile-details">
    <div class="profile-section-head">
      <h2>Notes</h2>
      <button mat-icon-button *ngIf="canUserChange" (click)="toggleEdit()"><mat-icon>edit</mat-icon></button>
    </div>
    <div class="notes" *ngIf="profile.notes && !editing">
      {{profile.notes}}
    </div>
    <div class="notes-editing" *ngIf="editing">
      <mat-form-field>
        <mat-label>Create some Notes for {{profile.identifier}}</mat-label>
        <textarea matInput placeholder="Typically seen with..." [(ngModel)]="profile.notes" (focusout)="finishEditing()"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
