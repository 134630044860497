import {UserProfileDto} from "./userProfileDto";

export class UserCreationInvitationDto {
  user: UserProfileDto;
  populationId: string;
  message: string;
  role: string;

  constructor(user: UserProfileDto, message: string, populationId: string, role: string) {
    this.user = user;
    this.populationId = populationId;
    this.message = message;
    this.role = role;
  }
}
