import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

export interface ResizeResult {
  image: string;
  height: number;
  width: number
}
@Injectable({
  providedIn: "root"
})
export class ImageScalingService {
  constructor(@Inject(DOCUMENT) public document: Document)  {}

  public resizeFileFromForm(content: ArrayBuffer, maxWidth=256, maxHeight=256): Promise<ResizeResult> {
    const canvas = this.document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    return new Promise(resolve => {
      const blob = new Blob( [ content ], { type: "image/jpeg" } );
      const blobUrl = URL.createObjectURL(blob);
      const img = new Image();
      img.src = blobUrl;
      img.addEventListener("load", () => {

        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height)

        const width = img.width * ratio + 0.5 | 0;
        const height = img.height * ratio + 0.5 | 0;


        canvas.width = width
        canvas.height = height

        ctx!.drawImage(img, 0, 0, width, height)
        resolve({
          image: canvas.toDataURL("image/jpeg"),
          width: width,
          height: height
        })
      })
    })






  }
}
