import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnimalService} from "../../../../../services/animal/animal.service";
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationService} from "../../../../../services/population/population.service";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";

@Component({
  selector: 'app-profile-notes-management',
  templateUrl: './profile-notes-management.component.html',
  styleUrls: ['./profile-notes-management.component.scss']
})
export class ProfileNotesManagementComponent implements OnInit {
  @Input() profile: AnimalProfileDto | undefined;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  population: PopulationDto | undefined;
  canUserChange: boolean = false;
  public editing = false;
  constructor(
    private animalService: AnimalService,
    private log: ILoggingService,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe( res => {
      this.population = res.settings?.population;
      const roles = res.settings?.populationRoles;
      this.canUserChange = (roles?.expert || roles?.administrator || roles?.professional) ?? false;
    })
    // this.populationService.population.subscribe(res => {
    //   this.population = res;
    // });
    // this.populationService.populationRoles.subscribe(res => {
    //   this.canUserChange = (res !== undefined && (res.expert || res.professional || res.administrator))!;
    // });
    this.loaded.emit(true);
  }

  finishEditing() {
    this.editing = !this.editing;
    this.submitChanges();
  }

  submitChanges() {
    if (this.profile !== undefined) {

      this.animalService
        .updateAnimalDetails(this.profile)
        .subscribe({
          next: (value: AnimalProfileDto) => {
            this.log.info(`Updated profile for ${this.profile?.identifier}`, true)
          },
          error: (value: HttpErrorResponse) => {
            this.log.info(`Could not update profile: ${value.message}`);
          }
        })

    }
  }

  toggleEdit() {
    if (!this.canUserChange) return;
    this.editing = !this.editing;
  }
}
