import {Component, Inject, OnInit} from "@angular/core";
import {
  SelectMapDialogComponent,
} from "../../../fin-print/select-map/select-map-dialog/select-map-dialog.component";
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {UntypedFormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {PopulationService} from "../../../../services/population/population.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {PopulationCreationApplicationDto} from "../../../../models/dto/population/populationCreationApplicationDto";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";




@Component({
  selector: 'global-new-population-dialog',
  templateUrl: './new-population-dialog.component.html',
  styleUrls: ['./new-population-dialog.component.scss']
})
export class NewPopulationDialogComponent implements OnInit {
  public populationControl = new UntypedFormControl(undefined);
  public filteredIdentifiers: Observable<Array<string>> | undefined;
  public populations: Array<PopulationDto> | undefined;
  popLat: number | undefined;
  popLng: number | undefined;
  public joining = true;

  createSelected = false;
  errors: Array<string> = new Array<string>();
  constructor(
    public dialogRef: MatDialogRef<NewPopulationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopulationCreationApplicationDto,
    private dialog: MatDialog,
    private populationService: PopulationService,
    private log: ILoggingService
  ) {
  }

  public ngOnInit() {
    this.populationService.getUserNonPopulations().subscribe( res => {
      this.populations = res;
      this.filteredIdentifiers = this.populationControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.populations!.filter(population => population.displayName!.toLowerCase().includes(filterValue)).map(a => a.displayName!);
  }

  public joinPopulation() {
    if (this.populationControl.value !== undefined) {
      this.populationService.joinPopulation(this.populationControl.value).subscribe({
        next: (value: PopulationDto) => {
          this.log.info(`Joined ${value.speciesName} -- ${value.displayName}`, true);
          this.dialogRef.close(value);
          this.populationService.select(value);
        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Could not join ${this.populationControl.value.displayName}: ${error.error}`, true);
        }
      })
    }
  }

  public applicationValid() {
    let valid = true;
    if (!this.data.location || !this.validAuthorities() || !this.data.speciesName || !this.data.displayName) {
      valid = false;
    }
    return valid;
  }

  private validAuthorities() {
    if (!this.data.authorityA || !this.data.authorityB) {
      return false;
    } else if (!this.validAuthority(this.data.authorityA) || !this.validAuthority(this.data.authorityB)) {
      return false;
    }

    return true;
  }

  private validAuthority(auth: UserProfileDto) {
    return auth.firstName && auth.lastName && auth.email;
  }

  viewMap() {
    const dialogRef = this.dialog.open(SelectMapDialogComponent, {
      width: '1200px',
      data: {
        allowDrag: false,
        location: {
          latitude: 55,
          longitude: -128
        },
        singleSelect: true,
        allowReselect: true
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      this.popLat = res.latitude;
      this.popLng = res.longitude;
      this.data.location!.latitude = res.latitude;
      this.data.location!.longitude = res.longitude;
    })

  }
}
