import {Injectable} from "@angular/core";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {IFileStorageService} from "./file-storage.service.interface";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: 'root'
})
export class LocalFileStorageService implements IFileStorageService {

  constructor(public log: ILoggingService) {}
  storeFile(item: IFileItem): void {
    localStorage.setItem(item.name, JSON.stringify(item));
  }
  getFileByName(fileName: string): IFileItem | null {
    const storedItem: string | null = localStorage.getItem(fileName);
    if (storedItem) {
      return JSON.parse(storedItem)
    }
    return null;
  }

  update(item: IFileItem): void {
    localStorage.setItem(item.name, JSON.stringify(item));
  }

  get(item: IFileItem): IFileItem {
    const storedItem = localStorage.getItem(item.name);
    if (storedItem) {
      return JSON.parse(storedItem);
    }
    return item;
  }
}
