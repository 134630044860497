import {Component, Input, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../models/dto/encounter/encounterDto";
import {DateService} from "../../../../../services/utilities/date.service";
import {ImageService} from "../../../../../services/utilities/image.service";
import {STATUS} from "../../../../../models/dto/status/statusChangeDto";
import {LicenseService} from "../../../../../services/license/license.service";
import {AnimalService} from "../../../../../services/animal/animal.service";
import { EncounterPageRequestDto } from '../../../../../models/dto/encounter/encounterPageRequestDto';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-encounter-preview',
  templateUrl: './encounter-preview.component.html',
  styleUrls: ['./encounter-preview.component.scss'],
})
export class EncounterPreviewComponent implements OnInit {
  @Input() encounter: EncounterDto | undefined;
  @Input() request: EncounterPageRequestDto | undefined;
  constructor(
    private dateService: DateService,
    private imageService: ImageService,
    private licenseService: LicenseService,
    private animalService: AnimalService,
    private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
  }

  public licenseText = ""

  formatTime(dateTime: string | Date | undefined) {
    return this.dateService.formatDateFromAny(dateTime, false);
  }
  formatAnimals() {
    if (this.encounter?.preview?.animals) {
      const identifiers = [];
      for (let a of this.encounter.preview.animals) {
        const identifier = this.animalService.getDisplayIdentifier(a);
        identifiers.push(identifier);
      }
      return identifiers.join(", ")
    }
    return undefined;
  }

  getRouterLink(encounter: EncounterDto) {
    return `/encounters/${encounter.id}`;
  }

  route() {

    this.cookieService.set('EncounterRequest', JSON.stringify(this.request));
    this.router.navigate([`/encounters/${this.encounter!.id}`]);
  }

  maybePrefix(thumbnail: string | undefined | null) {
    if (thumbnail === undefined || thumbnail === null) {
      return "";
    }
    return this.imageService.maybePrefix(thumbnail);
  }

  getStatusClass(encounter: EncounterDto) {
    if (encounter.status?.status !== undefined) {
      return encounter.status.status.replace(" ", "")
    }
    return STATUS.NEW
  }

  getStatusTooltip(encounter: EncounterDto) {
    if (encounter.status?.status !== undefined) {
      return STATUS.invert(encounter.status.status);
    }
    return STATUS.invert(STATUS.NEW)
  }

  getLicenseLogos() {
    if (this.encounter && this.encounter.license) {
      return this.licenseService.getLogosForLicense(this.encounter?.license, true)
    }
    return [];
  }

  removeLicenseText() {
    this.licenseText = "";
  }


  setLicenseText(logo: any) {
    this.licenseText = logo.text;
  }

  getCompleteTooltip(encounter: EncounterDto) {
    switch(encounter.completeStatus) {
      case "complete":
        return "Encounter contains images of each individual present"
      case "incomplete":
        return "Encounter does not contain images of each individual present"
      default:
        return "It is unknown if the encounter contains images of each individual present"
    }
  }
}
