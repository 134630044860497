<div class="selection-content" *ngIf="request">
  <div class="selection-options-container">
    <div class="selection-content-header">
      <h3>Search</h3>
    </div>
    <div class="selection-section">
      <div class="selection-section-header">
        <h4>Data Sources</h4>
        <button mat-icon-button class="reset-button" (click)="resetDataSources()"><mat-icon>restart_alt</mat-icon></button>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="request.dataSources!.publicData"
        >
          All Public Data
        </mat-checkbox>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="request.dataSources!.organizationalData"
        >
          My Organizations
        </mat-checkbox>
      </div>
<!--x-->
    </div>
    <mat-divider></mat-divider>
    <app-encounter-complete-status-selection [workspace]="workspace" (chosenStatus)="selectedCompletionStatus=$event" [request]="request"></app-encounter-complete-status-selection>
    <mat-divider></mat-divider>
    <app-behavior-selection [population]="population" (behaviorSelected)="behaviors = $event" [request]="request"></app-behavior-selection>
    <app-encounter-status-selection [workspace]="workspace" (chosenStatus)="selectedStatus=$event" [request]="request"></app-encounter-status-selection>
    <app-individual-selection [animals]="animals" [workspace]="workspace" [population]="population" (chosenAnimals)="selectedAnimals=$event" [request]="request"></app-individual-selection>
    <mat-divider></mat-divider>
    <div class="selection-section">
    <app-photographer-selection [workspace]="workspace" [population]="population" (chosenPhotographers)="selectedPhotographers = $event" [request]="request"></app-photographer-selection>

    </div>
    <mat-divider></mat-divider>
    <app-location-selection [workspace]="workspace" [population]="population" [locations]="locations" (chosenLocations)="selectedLocations = $event" [request]="request"></app-location-selection>
    <mat-divider></mat-divider>
    <div class="selection-section">
      <div class="selection-section-header">
        <h4>Date Range</h4>
        <button (click)="resetDate()" class="reset-button" mat-icon-button><mat-icon>restart_alt</mat-icon></button>
      </div>
      <mat-form-field  color="accent"  class="full-width">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input  [formGroup]="range" [rangePicker]="picker">
          <input formControlName="start" matStartDate placeholder="Start date">
          <input formControlName="end" matEndDate placeholder="End date">
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <div class="button-row">
    <button mat-stroked-button color="accent" (click)="submit()"><div class="activityButton"><span>Search</span><span><mat-progress-spinner *ngIf="searchStarted && !searchFinished" [diameter]="25" mode="indeterminate"></mat-progress-spinner></span></div></button>
    <button mat-stroked-button color="accent" (click)="reset()">Reset</button>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25"></mat-progress-spinner>
</ng-template>

<app-loading-overlay [criteria]="searching" [title]="'Searching...'"></app-loading-overlay>
