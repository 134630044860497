<div class="member-preview-container" *ngIf="member" [routerLink]="getRouterLink(member)">
  <div class="default" *ngIf="!userImage && member">
    <svg width="200" height="200" [data-jdenticon-value]="getJdenticon(member)"></svg>
  </div>
  <div class="member-profile-image" *ngIf="userImage && member">
    <img [src]="userImage.image" [alt]="member.organization_Member.firstName">
  </div>
  <div class="member-overlay shader">
    <div class="member-title">
      <h3><span *ngIf="member">{{member.organization_Member.firstName}} {{member.organization_Member.lastName}}</span></h3>
    </div>
    <h4><span *ngIf="member">{{member.organization_Member.username}}</span></h4> <!--member.email-->
    <div class="member-description">
<!--      <h4><span class="summary-category">Role: </span><span class="count summary-value">{{member.organization_Member_role}}</span></h4>-->
      <h4><span class="summary-category">Encounters: </span><span class="count summary-value">{{member.organization_Member_Encounters!.length}}</span></h4>
      <h4><span class="summary-category">Images: </span><span class="count summary-value">{{member.organization_Member_Images}}</span></h4>
      <h4><span class="summary-category">Detections: </span><span class="count summary-value">{{member.organization_Member_Detections}}</span></h4>
    </div>

  </div>
</div>
