<h1 mat-dialog-title>Current Relationship Information</h1>
<div mat-dialog-content class="dialog-content-column">
  <div class="dialog-content-column">
    <mat-form-field *ngIf="relationships !== undefined">
      <mat-label>Known Relationship Type</mat-label>
      <mat-select (selectionChange)="updateType($event)">
        <mat-option *ngFor="let relationshipType of relationships" [(ngModel)]="data.relationshipType" [value]="relationshipType.type" ngDefaultControl>{{relationshipType.type}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Enter a new Relationship Type</mat-label>
      <input matInput [(ngModel)]="data.relationshipType" name="relationshipType" ngDefaultControl/>
    </mat-form-field>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">Cancel</button>
  <button mat-stroked-button [mat-dialog-close]="data" cdkFocusInitial>Confirm</button>
</div>
