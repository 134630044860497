<div mat-dialog-content class="content">
  <div class="loading-block" *ngIf="data.item === undefined || data.item.image == undefined" mat-dialog-content>
    <mat-progress-spinner mode="indeterminate" [diameter]="25" [strokeWidth]="2"></mat-progress-spinner>
    <span class="loading-message">
    Loading Annotation Tool...
  </span>
  </div>
  <global-annotate *ngIf="data.item && data.item.image != undefined"
                   [image]="data.item"
                   [src]="data.item.image!"
                   [user]="data.user"
                   [tab]="data.tab!"
                   [animals]="data.animals!"
                   [encounter]="data.encounter"
                   [showMetaData]="data.showMetaData"
                   [showAnnotations]="data.showAnnotations"
                   [allowAnnotation]="data.allowAnnotation"
                   [suggestions]="data.suggestions"
                   (locationUpdated)="updateLocation($event)"
                   (annotationRemoved)="removeAnnotation($event)"
                   (annotationConfirmed)="confirmAnnotation($event)"
  ></global-annotate>
  <app-area-loading [criteria]="!saving" title="Saving..."></app-area-loading>

</div>

<div mat-dialog-actions class="button-row">
  <div class="action-buttons">
    <button mat-raised-button [mat-dialog-close]="data" color="primary">Close</button>
  </div>

  <div class="direction-buttons">
    <button mat-stroked-button (click)="previousImage()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-stroked-button (click)="nextImage()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

</div>
