<div class="profile-relationship-container" *ngIf="profile">
  <div class="profile-details">
    <div class="profile-section-head">
      <h2>Relationships</h2>
    </div>
    <div class="profile-section-content">
      <div class="profile-section-content-header">
        <h3>Total Co-Occurrences</h3>
      </div>
      <div class="profile-section-content y-scrollable h-max-300">
        <div class="profile-section-entry editable-line clickable" *ngFor="let coOc of totalCoOccurrence"  [routerLink]="getRouterLink(coOc)">
          <div class="profile-section-entry-key">
            <h4>{{coOc.animal.identifier}}</h4>
          </div>
          <div class="profile-section-entry-value">
            <h4>{{coOc.coOccurrence}}</h4>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="profile-section-content" *ngIf="coOccurrenceMap !== undefined && currentYear !== undefined">
      <div class="profile-section-content-header">
        <h3>Co-Occurrences By Year</h3>
      </div>
      <div class="profile-section-content y-scrollable h-max-300" *ngIf="!editingCoOccurrenceYear">
        <div class="profile-section-entry sub-header">
          <div class="profile-section-entry-key clickable editable-line" (click)="editingCoOccurrenceYear = true" >
            <h3 class="hil">{{currentYear}}</h3>
          </div>
          <div class="profile-section-entry-value"></div>
        </div>
          <div class="profile-section-entry editable-line clickable" *ngFor="let coOc of getSortedOccurrences(currentYear)" [routerLink]="getRouterLink(coOc)">
            <div class="profile-section-entry-key">
              <h4>{{coOc.animal.identifier}}</h4>
            </div>
            <div class="profile-section-entry-value">
              <h4>{{coOc.coOccurrence}}</h4>
            </div>
          </div>
        </div>
      <div class="profile-section-content" *ngIf="editingCoOccurrenceYear && coOccurrenceMap !== undefined && currentYear !== undefined">
        <mat-form-field>
          <mat-label>Year for CoOccurrences</mat-label>
          <mat-select [(ngModel)]="currentYear" (ngModelChange)="editingCoOccurrenceYear = false"
                      (selectionChange)="editingCoOccurrenceYear = false"
                      (closed)="editingCoOccurrenceYear = false">
            <mat-option [value]="k" *ngFor="let k of years">{{k}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>

<ng-template #loading>
  ...
</ng-template>
