<div class="content-container">
  <div class="content-header">
    <h2>Members</h2>
    <button mat-stroked-button color="accent" (click)="addMembers()"><mat-icon>add</mat-icon> Add Members</button>
  </div>
  <div class="content">
    <mat-accordion *ngIf="membersObs | async as members; else loading">
      <mat-expansion-panel *ngFor="let member of members" class="member" (afterExpand)="loadMember(member)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{member.firstName}} {{member.lastName}}
          </mat-panel-title>
          <mat-panel-description>
            {{member.email}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="member-info">
          <h3 [routerLink]="getMemberLink(member)" class="clickable">Profile Page</h3>
        </div>
        <div class="member-changes">
          <button mat-stroked-button color="accent" *ngIf="isUserOrganizationAdmin(member)" (click)="demoteUser(member)">Demote From Administrator</button>
          <button mat-stroked-button color="accent" *ngIf="!isUserOrganizationAdmin(member)" (click)="promoteUser(member)">Promote To Administrator</button>
          <button mat-stroked-button color="warn" (click)="removeUserFromOrganization(member)">Remove {{member.firstName}} {{member.lastName}} from {{organization!.name}}</button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<!--<h2>My Organizations</h2>-->
<!--<div class="organization-creation">-->
<!--  <button mat-stroked-button color="primary" (click)="toggleOrganizationCreation()">Create an Organization</button>-->

<!--</div>-->

<!--<div class="organizations">-->
<!--  <mat-accordion *ngIf="organizations | async as list; else loading">-->
<!--    <mat-expansion-panel *ngFor="let organization of list" class="organization" (afterExpand)="loadOrganization(organization)">-->
<!--      <mat-expansion-panel-header>-->
<!--        <mat-panel-title>-->
<!--          {{organization.name}}-->
<!--        </mat-panel-title>-->
<!--        <mat-panel-description>-->
<!--          {{organization.members.length}} Members-->
<!--        </mat-panel-description>-->
<!--      </mat-expansion-panel-header>-->
<!--      <h3>Members</h3>-->
<!--      <mat-action-list>-->
<!--        <mat-list-item *ngFor="let member of organization.members" (click)="activateUser(member, organization)">-->
<!--          <div class="member-management">-->
<!--            <div class="member-information">-->
<!--              {{member.firstName}} {{member.lastName}} - {{member.email}}-->
<!--            </div>-->
<!--            <div class="member-changes" *ngIf="member.active && organization.active && isUserOrganizationAdmin(user, organization)">-->
<!--              <button mat-stroked-button color="primary" *ngIf="isUserOrganizationAdmin(member, organization)" (click)="demoteUser(member, organization)">Demote From Administrator</button>-->
<!--              <button mat-stroked-button color="primary" *ngIf="!isUserOrganizationAdmin(member, organization)" (click)="promoteUser(member, organization)">Promote To Administrator</button>-->
<!--              <button mat-stroked-button color="warn" (click)="removeUserFromOrganization(member, organization)">Remove {{member.firstName}} {{member.lastName}} from {{organization.name}}</button>-->
<!--            </div>-->
<!--          </div>-->

<!--        </mat-list-item>-->
<!--      </mat-action-list>-->
<!--      <mat-divider></mat-divider>-->
<!--      <div class="organization-buttons">-->
<!--        <button mat-stroked-button color="primary" (click)=toggleUserAddActive(organization) *ngIf="isUserOrganizationAdmin(user, organization)">Add Users to {{organization.name}}</button>-->
<!--        <div class="danger-buttons">-->
<!--          <button mat-stroked-button color="warn" (click)="leaveOrganization(organization)">Resign from {{organization.name}}</button>-->
<!--          <button mat-stroked-button color="warn" (click)="deleteOrganization(organization)" *ngIf="isUserOrganizationAdmin(user, organization)">Delete {{organization.name}}</button>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div class="organization-user-add" *ngIf="organization.userAddActive">-->
<!--        <mat-form-field *ngIf="possibleOrganizationMembers.has(organization.id)">-->
<!--          <mat-label>New Members</mat-label>-->
<!--          <mat-select [formControl]="newOrganizationMembers" multiple>-->
<!--            <mat-option *ngFor="let user of possibleOrganizationMembers.get(organization.id) | async" [value]="user.email">{{user.firstName}} {{user.lastName}}</mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--        <button mat-stroked-button color="primary" (click)="addUsersToOrganization(organization)">Add Users To Organization</button>-->
<!--      </div>-->

<!--    </mat-expansion-panel>-->
<!--  </mat-accordion>-->
<!--  -->
<!--</div>-->
<ng-template #loading>
<!--  <app-area-loading [criteria]="(organizations | async)?.length == 0" [diameter]="50"></app-area-loading>-->
</ng-template>
