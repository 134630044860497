import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {UserManagementService} from "../../../../services/user/management/user-management.service";
import {
  NewSubmissionUserDialogComponent,
  NewSubmissionUserDialogData
} from "../dialogs/newSubmissionUserDialog/new-submission-user-dialog.component";
import {Observable} from "rxjs";
import {UserDto} from "../../../../models/dto/response/user/management/userDto";
import {MatSelectChange} from "@angular/material/select";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-submission-user',
  templateUrl: './submission-user.component.html',
  styleUrls: ['./submission-user.component.scss']
})
export class SubmissionUserComponent implements OnInit {
  @Input() submissionUser!: UserProfileDto | undefined;
  @Output() submissionUserUpdated: EventEmitter<UserProfileDto> = new EventEmitter<UserProfileDto>();
  public usersObs: Observable<Array<UserDto>> | undefined;
  public originalUser: UserProfileDto | undefined;
  public selectUser: UserProfileDto | undefined;
  public users: Array<UserDto> | undefined;

  private newSubmissionUser: NewSubmissionUserDialogData | undefined;
  constructor(
    private userManagementService: UserManagementService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.originalUser = this.submissionUser;
    this.usersObs = this.userManagementService.getUserDtos();
  }

  _handleDialog(users: Array<UserDto>) {
    if (this.newSubmissionUser === undefined) {
      this.newSubmissionUser = {
        firstName: "",
        lastName: "",
        email: "",
        users: users
      }
    }
    const dialogRef = this.dialog.open(NewSubmissionUserDialogComponent, {
      data: this.newSubmissionUser,
      disableClose: true
    });
    dialogRef
      .afterClosed()
      .subscribe( (data: any) => {
        if (data && data.firstName && data.lastName && data.email) {
          this.newSubmissionUser = data;
          this.submissionUser = data;
          this.report(this.submissionUser!);
        }

      });
  }

  createNewSubmissionUser() {
    this.usersObs?.subscribe( res => {
      this._handleDialog(res);
    });


  }

  report(user: UserProfileDto) {
    this.submissionUserUpdated.emit(user);
  }

  updateSubmissionUser($event: MatSelectChange) {
    this.submissionUser = $event.value;
    this.report(this.submissionUser!);

  }

  resetSubmissionUser() {
    this.submissionUser = this.originalUser;
    this.newSubmissionUser = undefined;
    this.selectUser = undefined;
    this.report(this.originalUser!);
  }

  public reset() {
    this.resetSubmissionUser();
  }

}
