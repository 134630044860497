import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {InvitationDto} from "../../models/dto/invitations/invitationDto";
import {Observable} from "rxjs";
import {InvitationValidationResponseDto} from "../../models/dto/invitations/invitationValidationResponseDto";
import {InvitationRedemptionCompletionDto} from "../../models/dto/invitations/invitationRedemptionCompletionDto";
import {ResponseDto} from "../../models/dto/response/responseDto";

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(private http: HttpClient) { }

  public getInvitations(): Observable<Array<InvitationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.invitation.invitation}`;
    return this.http.get<Array<InvitationDto>>(url);
  }

  public validateKey(invitationKey: string):  Observable<InvitationValidationResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.invitation.redeem}/${invitationKey}`;
    return this.http.get<InvitationValidationResponseDto>(url);

  }

  public completeRedemption(invitationKey: string, user: InvitationRedemptionCompletionDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.invitation.redeem}/${invitationKey}`;
    return this.http.post<ResponseDto>(url, user);
  }

  public createInvitation(invitationDto: InvitationDto): Observable<InvitationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.invitation.invitation}`;
    return this.http.post<InvitationDto>(url, invitationDto);
  }

  public deleteInvitation(invitationId: string): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.invitation.invitation}/${invitationId}`;
    return this.http.delete<ResponseDto>(url);
  }


  public updateInvitation(invitationId: string, dto: InvitationDto): Observable<InvitationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.invitation.invitation}/${invitationId}`;
    return this.http.put<InvitationDto>(url, dto);
  }


}
