import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as L from "leaflet";
import {MapService} from "../../../services/map/map.service";
import { Location } from "../../../models/location/location.model";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import 'leaflet-arrowheads';
import {LatLngTuple} from "leaflet";
import 'leaflet.markercluster';
import {EncounterDto} from "../../../models/dto/encounter/encounterDto";
// @ts-ignore
import * as Gradient from "javascript-color-gradient";
import {DateService} from "../../../services/utilities/date.service";


@Component({
  selector: 'events-map',
  templateUrl: './events-map.component.html',
  styleUrls: ['./events-map.component.scss']
})
export class EventsMapComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() singleSelect = false;
  @Input() selectedLocation: Location | undefined;
  @Input() locations: Array<Location> | undefined;
  @Input() encounters: Array<EncounterDto> | undefined;
  @Input() allowDrag: boolean = false;
  @Input() allowReselect: boolean = true;
  @Input() initialZoom: number = 7;
  @Input() maxZoom: number = 30;
  @Input() minZoom: number = 1;
  @Input() defaultLatitude = 39.8282;
  @Input() defaultLongitude = -98.5795;
  @Input() width: number | undefined;
  @Input() height: number | undefined;

  @Input() heatmap: boolean = true;

  @Output() positionSelected: EventEmitter<{lat: number, lng: number}> = new EventEmitter<{lat: number; lng: number}>();
  @Output() positionRemoved: EventEmitter<{lat: number, lng: number}> = new EventEmitter<{lat: number; lng: number}>();

  public map: any;
  public marker: any;


  private lastLatLng: {lat: number, lng: number} | undefined;
  public gradient: any;
  public gradientDisplay: Array<{date: string, color: string}> = new Array<{date: string, color: string}>();


  constructor(
    public mapService: MapService,
    private log: ILoggingService,
    private dateService: DateService
  ) { }

  ngOnInit(): void {



  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    this.locations = undefined;
    if (this.map !== undefined) {
      this.map.off();
      this.map.remove();
      this.map = undefined;
    }
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    // if (this.map === undefined) {
    //   if (this.selectedLocation !== undefined &&
    //     this.selectedLocation.latitude !== undefined &&
    //     this.selectedLocation.longitude !== undefined) {
    //     this.initMap(this.selectedLocation.latitude!, this.selectedLocation.longitude!);
    //   } else {
    //     this.initMap(this.defaultLatitude, this.defaultLongitude);
    //   }
    // }

    if (this.locations === undefined && this.encounters !== undefined) {
      const locs = new Array<Location>();
      for (let encounter of this.encounters) {
        if (encounter.location === undefined) continue;
        if (encounter.location.mapName === undefined) {
          encounter.location.mapName = `${this.dateService.formatDateFromAny(encounter.dateTime, false)} <br/>
                ${encounter.location.name} <br />
                ${encounter.user?.firstName} ${encounter.user?.lastName} <br/>`

          if (encounter.preview) {
            encounter.location.mapName += `${encounter.preview.imageCount} image(s) <br />
            ${encounter.preview.detectionCount} detections <br />`;
            if (encounter.preview.confirmedAnimalCount !== undefined && encounter.preview!.confirmedAnimalCount! > 0) {
              encounter.location.mapName += `${encounter.preview.animals?.map( a => a.identifier)?.join(', ')} <br />`
            }
          }
          encounter.location.mapName += `<a href="/encounters/${encounter.id}">View Encounter</a>`

          encounter.location.dateTime = encounter.dateTime;
        }
        locs.push(encounter.location!)
      }
      this.locations = locs;
    }
    if (this.locations === undefined || this.locations.length === 0) return;
    const initialLocation = this.locations[0];
    this.initMap(initialLocation.latitude!, initialLocation.longitude!);
  }

  private initMap(latitude: number, longitude: number): void {
    const layers = this.getLayers();
    if (this.map) return;
    this.map = L.map(`events-map`, {
      center: [ latitude,  longitude ],
      zoom: this.initialZoom,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      layers: layers[0]
    });


    this.gradient = new Gradient()
      .setColorGradient("FFFFFF", "FFFFFF")


    // if (this.heatmap) {
    //   this.gradient = new Gradient()
    //     .setColorGradient("FF2323", "2181AD")
    //     .setMidpoint(this.locations!.length)
    // }

    const markers = L.markerClusterGroup();
    let colorIdx = 1;
    for (let location of this.locations!) {
      const marker = L.marker(
        [location.latitude!, location.longitude!],
        {
          draggable: false,
          icon: this.mapService.createSvgIcon(this.gradient.getColor(colorIdx).replace("#", ""))
        },
      )
      marker.bindPopup(
        location.mapName!,
        {closeButton: false, offset: [0, -16]}
      )
      colorIdx += 1;

      markers.addLayer(marker);
    }
    this.map.addLayer(markers);
    if (this.locations && this.locations?.length > 1){
      this.map.fitBounds(markers.getBounds());
    }



    if (this.locations !== undefined) {
      // const locs = this.locations.reverse();
      // const coords: Array<LatLngTuple> = [];
      // for(let loc of locs) {
      //   coords.push([loc.latitude!, loc.longitude!])
      // }
      // // const myVector = L.polyline(coords).arrowheads({size: '5px', frequency: '30px', color: 'black'});
      // // myVector.addTo(this.map);
    }

    if (!this.allowReselect) {
      return;
    }


  }



  private getLayers() {
    const tileLayer = this.mapService.getTileLayer();
    const layers: any = [tileLayer];
    const base = {
      "World": tileLayer
    }


    return [layers, base];
  }

}
