import { Component, OnInit } from '@angular/core';
import {PopulationManagementService} from "../../../../../services/population/population-management.service";
import {PopulationCreationApplicationDto} from "../../../../../models/dto/population/populationCreationApplicationDto";
import {Observable} from "rxjs";

@Component({
  selector: 'app-population-creation-management',
  templateUrl: './population-creation-management.component.html',
  styleUrls: ['./population-creation-management.component.scss']
})
export class PopulationCreationManagementComponent implements OnInit {
  appObs: Observable<Array<PopulationCreationApplicationDto>> | undefined;
  sortFactor: string | undefined;
  constructor(
    private populationManagementService: PopulationManagementService
  ) { }

  ngOnInit(): void {
    this.appObs = this.populationManagementService.getApplications() as Observable<Array<PopulationCreationApplicationDto>>;
  }

  refetch() {
    this.ngOnInit();
  }

  sorted(applications: Array<PopulationCreationApplicationDto>) {
    if (this.sortFactor === undefined) {
      return applications;
    }
    if (this.sortFactor === "date") {
      return applications.sort((a, b) => b.status!.updateDate?.getTime()! - a.status!.updateDate?.getTime()!)
    }
    if (this.sortFactor === "status") {
      return applications.sort((a, b) => a.status!.status!.localeCompare(b.status!.status!))
    }
    return applications;
  }
}
