import {environment} from "../../../../environments/environment";
import {ElectronFileStorageService} from "../../../../services/storage/file/file-storage.service.electron";
import {WebFileStorageService} from "../../../../services/storage/file/file-storgae.service.web";
import {LocalFileStorageService} from "../../../../services/storage/file/file-storage.service.local";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";

export let fileStorageFactory = (log: ILoggingService) => {
  if (environment.production && environment.electron) {
    return new ElectronFileStorageService(log)
  } else if (environment.production && !environment.electron) {
    return new WebFileStorageService(log)
  } else {
    return new LocalFileStorageService(log)
  }
}
