export interface Exportable {
  displayName: string;
  target: string;
  selected: boolean;
  timeCriteria?: Exportable;
  exportCriteria?: Exportable;
  timeSelection?: Array<Exportable>;
  exportSelection?: Array<Exportable>;
}


export class FileMetaDataByFrame implements Exportable {
  displayName = "File meta Data by Image";
  target = "FileMetaDataByFrame";
  selected = false;
  timeSelection = [
    {
      displayName: "Creation Date",
      target: "CreationDateTime",
      selected: true
    },
    {
      displayName: "Upload Date",
      target: "UploadDateTime",
      selected: false
    },
    {
      displayName: "Insert Date",
      target: "InsertionDateTime",
      selected: false
    },
  ];
  exportSelection = [
    {
      displayName: "Export from my Data",
      target: "ExportMyData",
      selected: true
    },
    {
      displayName: "Export from all Data",
      target: "ExportAggregateData",
      selected: false
    }
  ];
}

export class FileMetaDataByEncounter implements Exportable {
  displayName = "File meta Data by Encounter";
  target = "FileMetaDataByEncounter";
  selected = false;
  timeSelection = [
    {
      displayName: "Encounter Date",
      target: "DateTime",
      selected: true
    },
    {
      displayName: "Upload Date",
      target: "InsertDateTime",
      selected: false
    },
  ];
  exportSelection = [
    {
      displayName: "Export from my Data",
      target: "ExportMyData",
      selected: true
    },
    {
      displayName: "Export from all Data",
      target: "ExportAggregateData",
      selected: false
    }
  ];
}

export class AnimalRelationships implements Exportable {
  displayName = "Animal Relationships";
  target = "AnimalRelationships";
  selected = false;
  timeSelection = [
    {
      displayName: "Database Insertion Date",
      target: "InsertionDateTime",
      selected: true
    },
  ];
  exportSelection = [
    {
      displayName: "Export from my Data",
      target: "ExportMyData",
      selected: true
    },
    {
      displayName: "Export from all Data",
      target: "ExportAggregateData",
      selected: false
    }
  ];
}
