import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { min } from 'rxjs';
import { EncounterDto } from '../../../../models/dto/encounter/encounterDto';
import { organizationMemberStatisticsDto } from '../../../../models/dto/user/organization/organizationMemberStatisticsDto';
import { organizationPopulationStatisticsDto } from '../../../../models/dto/user/organization/organizationPopulationStatisticsDto';
import { OrganizationProfileDto } from '../../../../models/dto/user/organization/organizationProfileDto';
import { organizationProfileInformationUpdateDto } from '../../../../models/dto/user/organization/organizationProfileInformationUpdateDto';
import { UserProfileDto } from '../../../../models/dto/user/userProfileDto';
import { ErrorHandlerService } from '../../../../services/error/error-handler.service';
import { ILoggingService } from '../../../../services/logging/logging.service.interface';
import { UserOrganizationService } from '../../../../services/user/organization/user-organization.service';
import {A} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss']
})
export class OrganizationProfileComponent implements OnInit {
  private id: string = "";
  private visitor: string | undefined;
  public organizationProfile?: OrganizationProfileDto;
  public organizationEditActive: boolean = false;
  public nameEditActive: boolean = false;
  public logoEditActive: boolean = false;
  public UrlEditActive: boolean = false;
  public OrganizationProfileUpdateForm!: FormGroup;
  public OrganizationUpdateName!: FormGroup;
  public OrganizationUpdateLogo!: FormGroup;
  public OrganizationUpdateURL!: FormGroup;
  public administrationAccess = false;
  public isMember = false;
  public OrganizationMemberList: Array<organizationMemberStatisticsDto> = [];

  public OrganizationEncounters: Array<EncounterDto> = [];
  public OrganizationPopulationStatistics: Array<organizationPopulationStatisticsDto> = [];


  public allOrganizationAdmins = 0;
  public allOrganizationMembers = 0;
  public allOrganizationEncounters = 0;
  //public allOrganizationPopulations = "";
  private admins_array: Array<organizationMemberStatisticsDto> = [];
  private members_array: Array<organizationMemberStatisticsDto> = [];
  private pops = 0;
  public organizationHasContribution = false;
  constructor(
    private route: ActivatedRoute,
    private organizationservice: UserOrganizationService,
    private errorService: ErrorHandlerService,
    private log: ILoggingService
  ) {
  }

  ngOnInit(): void {
    this.OrganizationMemberList = new Array<organizationMemberStatisticsDto>();
    this.admins_array = new Array<organizationMemberStatisticsDto>();
    this.members_array = new Array<organizationMemberStatisticsDto>();
    this.route.params.subscribe(res => {
      this.id = res["organizationid"];
      this.organizationservice.isUserMember(this.id).subscribe({
        next: (value: boolean) => { this.isMember = value; },
        error: (err: any) => { this.isMember = false; }
      })
      this.organizationservice.isUserAdministrator(this.id).subscribe({
        next: (value: boolean) => { this.administrationAccess = value; },
        error: (err: any) => { this.administrationAccess = false; }
      })
      this.organizationservice.getOrganizationProfile(this.id).subscribe({
        next: (value: OrganizationProfileDto) => {
          this.organizationProfile = value;
          this.allOrganizationAdmins = value.organization_Administrators.length;
          this.allOrganizationMembers = value.organization_Members.length + value.organization_Administrators.length;
          this.allOrganizationEncounters = value.organization_Encounters.length;


          for (var _i = 0; _i < value.organization_Administrators.length; _i++) {
            const _admin: organizationMemberStatisticsDto = {
              organization_Member: value.organization_Administrators[_i],
              organization_Member_role: "admin",
              organization_Member_Encounters: [],
              organization_Member_Images: 0,
              organization_Member_Detections: 0
            }
            this.admins_array?.push(_admin);
          }

          for (var _i = 0; _i < value.organization_Members.length; _i++) {
            const _member: organizationMemberStatisticsDto = {
              organization_Member: value.organization_Members[_i],
              organization_Member_role: "member",
              organization_Member_Encounters: [],
              organization_Member_Images: 0,
              organization_Member_Detections: 0

            }
            this.members_array?.push(_member);
          }

          this.OrganizationEncounters = value.organization_Encounters;
          for (var _i = 0; _i < value.organization_Populations.length; _i++) {
            const populationStatistics: organizationPopulationStatisticsDto = {
              organization_Population: value.organization_Populations[_i],
              organization_Population_Encounters: [],
              organization_Population_Images: 0,
              organization_Population_Detections: 0
            }
            this.OrganizationPopulationStatistics?.push(populationStatistics)
          }
          //Fill Statistics
          for (var _i = 0; _i < value.organization_Encounters.length; _i++) {
            for (var _j = 0; _j < this.OrganizationPopulationStatistics.length; _j++) {
              if (value.organization_Encounters[_i].populationId == this.OrganizationPopulationStatistics[_j].organization_Population?.id) {
                this.OrganizationPopulationStatistics[_j].organization_Population_Encounters!.push(value.organization_Encounters[_i]);
                this.OrganizationPopulationStatistics[_j].organization_Population_Images! += value.organization_Encounters[_i].preview!.imageCount!;
                this.OrganizationPopulationStatistics[_j].organization_Population_Detections! += value.organization_Encounters[_i].preview!.detectionCount!;
                break;
              }
            }
            for (var _j = 0; _j < this.admins_array.length; _j++) {
              if (value.organization_Encounters[_i].user?.id == this.admins_array[_j].organization_Member.id) {
                this.admins_array[_j].organization_Member_Encounters!.push(value.organization_Encounters[_i]);
                this.admins_array[_j].organization_Member_Images! += value.organization_Encounters[_i].preview!.imageCount!;
                this.admins_array[_j].organization_Member_Detections! += value.organization_Encounters[_i].preview!.detectionCount!;
                break;
              }
            }
            for (var _j = 0; _j < this.members_array.length; _j++) {
              if (value.organization_Encounters[_i].user?.id == this.members_array[_j].organization_Member.id) {
                this.members_array[_j].organization_Member_Encounters!.push(value.organization_Encounters[_i]);
                this.members_array[_j].organization_Member_Images! += value.organization_Encounters[_i].preview!.imageCount!;
                this.members_array[_j].organization_Member_Detections! += value.organization_Encounters[_i].preview!.detectionCount!;
                break;
              }
            }
          }
          this.OrganizationMemberList = this.admins_array.concat(...this.members_array);


          this.OrganizationProfileUpdateForm = new FormGroup({
            name: new FormControl(value.organization_Name),
            url: new FormControl(value.organization_Url),
          });

        },
        error: (err: any) => { }
      });

    });

  }

  public toggleEditOrganization() {
    this.organizationEditActive = !this.organizationEditActive;
  }
  public toggleNameEdit() {
    this.nameEditActive = !this.nameEditActive;
  }

  public updateOrganizationProfileInformation = (registerFormValue: any) => {
    this.OrganizationMemberList = new Array<organizationMemberStatisticsDto>();
    const formValues = { ...registerFormValue };
    const organizationProfile: organizationProfileInformationUpdateDto = {
      Id: this.id,
      Name: formValues.name,
      Url: formValues.url,
    };
    this.organizationservice.setOrganizationProfileInformation(organizationProfile).subscribe({
      next: () => {
        this.log.info(`Organization Profile updated`)
        this.ngOnInit();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleRequestError("Organization profile update", err);
      }
    })
    this.toggleEditOrganization();
  }


  public toggleLogoEdit() {
    this.logoEditActive = !this.logoEditActive;
  }
  public toggleURLEdit() {
    this.UrlEditActive = !this.UrlEditActive;
  }

  public leaveOrganization() {
    this.organizationservice.leaveOrganization(this.id).subscribe({
      next: () => {
        this.isMember = false;
        this.ngOnInit();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleRequestError("Failed to leave: ", err);
      }
    })
  }

  public joinOrganization() {
    this.organizationservice.joinOrganization(this.id).subscribe({
      next: () => {
        this.isMember = true;
        this.ngOnInit();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleRequestError("failed to join: ", err);
      }
    })
  }

}
