import {Component, Input, OnInit} from '@angular/core';
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {AnimalDto} from "../../../../../models/dto/animal/animalDto";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {merge} from "rxjs";
import {Router} from "@angular/router";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";

@Component({
  selector: 'app-profile-merge',
  templateUrl: './profile-merge.component.html',
  styleUrls: ['./profile-merge.component.scss']
})
export class ProfileMergeComponent implements OnInit {
  @Input() profile: AnimalProfileDto | undefined;
  public animals: Array<AnimalDto> | undefined;
  public merging: boolean = false;
  public canEdit = false;
  public workspace: WorkspaceDto | undefined;
  public population: PopulationDto | undefined;

  public mergeTarget: AnimalDto | undefined;
  constructor(
    public animalService: AnimalService,
    private workspaceService: WorkspaceService,
    private router: Router,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.workspaceService.workspace
      .subscribe(res => {
        this.workspace = res;
        if (this.workspace.settings && this.workspace.settings.population && this.workspace.settings.populationRoles) {
          this.canEdit = this.workspace.settings.populationRoles.professional! || this.workspace.settings.populationRoles.administrator!;
          this.population = this.workspace.settings.population;
        }
      });
  }

  startMerge() {
    if (this.animals === undefined && this.workspace && this.population) {
      this.animalService.getPopulationAnimals(this.population?.id!).subscribe(res => {
        this.animals = res;
      })
    }
    this.merging = true;
  }

  merge() {
    const dto = {
      mergeFrom: this.profile?.id,
      mergeTo: this.mergeTarget?.id
    }
    this.animalService
      .mergeAnimalProfiles(dto)
      .subscribe({
        next: (value: AnimalDto) => {
          this.log.info(`Successfully moved data for ${this.profile!.identifier} to ${this.mergeTarget?.identifier}`)
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([`individuals/${value.id}`])
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Merging Profiles", value);
        }
    })
  }
}
