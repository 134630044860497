import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DateService} from "../../../../../../../services/utilities/date.service";
import {EncounterDeletionDialogData} from "../delete-encounter-dialog/delete-encounter-dialog.component";
import {FileItem, IFileItem} from "../../../../../../../models/items/files/file-item.model";

export interface BulkActionData {
  items: Array<IFileItem>;
  action: string;
  title: string;
  selection: string;
  markedImages: Array<FileItem>
}
@Component({
  selector: 'app-bulk-item-action-confirmation',
  templateUrl: './bulk-item-action-confirmation.component.html',
  styleUrls: ['./bulk-item-action-confirmation.component.scss']
})
export class BulkItemActionConfirmationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BulkItemActionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkActionData,
    private dateService: DateService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.data.markedImages = this.data.items.filter( a => a.marked == true);
  }
}
