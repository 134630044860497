import {BehaviorSubject, Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {IFileStorageService} from "../../storage/file/file-storage.service.interface";
import {ImageMetaDataService} from "./meta/image-meta-data.service";
import {LocationService} from "../../location/location.service";
import {ILoggingService} from "../../logging/logging.service.interface";
import {HttpClient} from "@angular/common/http";
import {FileItemGetAllResponse} from "../../../models/response/file-item/file-item.get.all.response.model";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {IMetaData} from "../../../models/data/files/meta-data.model";

@Injectable({
  providedIn: 'root'
})
export class IImageService {
  images = new BehaviorSubject<Array<IFileItem>>([]);
  directory = new BehaviorSubject<string[]>([]);
  dataDirectories: Map<string, BehaviorSubject<Array<IFileItem>>> = new Map<string, BehaviorSubject<Array<IFileItem>>>();

  constructor(
    public log: ILoggingService,
    public fileStorageService: IFileStorageService,
    public imageMetaDataService: ImageMetaDataService,
    public locationService: LocationService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer) {}
  getImages(path: string): void {}
  fetchImages(): Observable<IFileItem[]> | undefined{return undefined;}
  fetchImageObservables(): Array<Observable<IFileItem>> | undefined {
    return undefined
  }

  fetchImage(fileItem: IFileItem): Observable<any> {return of({})}
  fetchImageFromId(fileItemId: string): Observable<any> {return of({})}
  fetchThumbnailFromId(fileItemId: string): Observable<any> {return of({})}

  getFileItems(): Observable<FileItemGetAllResponse> { return of({response: []})}
  getEncounterGalleryByPopulation(populationId: string): Observable<FileItemGetAllResponse> {return of({response: []})}

  addDataDirectory(path: string): void {}

  navigateDirectory(path: string): void {}

  arrayBufferToImageSrc(buffer: ArrayBuffer): SafeUrl | undefined  { return ; }
  saveNewImage(fileItem: IFileItem, content: ArrayBuffer): Observable<any> {return of({})}
  uploadFile(file: File, populationId: string, encounterId: string): Observable<any> {return of({})}
  overWriteImage(fileItem: IFileItem, content: ArrayBuffer): Observable<any> {return of({})}
  initializeImage(bufferContent: ArrayBuffer, fileItem: IFileItem): Observable<IFileItem> {return of(fileItem)}
  deleteImage(itemId: string): Observable<any> {return of({})}
  updateMetaData(meta: IMetaData): Observable<any> {return of({})}
}
