import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ImageAnnotation} from "../../models/annotation/image/annotation.model";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {ResponseDto} from "../../models/dto/response/responseDto";

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  constructor(private http: HttpClient) { }

  public createAnnotation(a: ImageAnnotation): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.base}`;
    return this.http.post<ResponseDto>(url, a);
  }
  public deleteAnnotation(a: ImageAnnotation): Observable<ResponseDto>  {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.base}/${a.id}`;
    return this.http.delete<ResponseDto>(url);
  }
  public updateAnnotation(a: ImageAnnotation): Observable<ResponseDto>  {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.base}`;
    return this.http.patch<ResponseDto>(url, a);
  }

  public confirmAnnotation(a: ImageAnnotation): Observable<ResponseDto>  {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.base}`;
    return this.http.put<ResponseDto>(url, a);
  }

}
