export const api= {
  heartbeat:{
    root: "/api/live"
  },
  populationManagement: {
    base: "/api/populationmanagement"
  },
  access: {
    root: "/api/access"
  },
  animal: {
    getAnimals: "/api/animal",
    getAnimalsComplete: "/api/animal/complete",
    getAnimalProfile: "/api/animal/profile",
    getNextAnimal: "/api/animal/next",
    getPreviousAnimal: "/api/animal/previous",
    profileImage: "/api/animal/profile/image",
    getAnimalProfileImages: "/api/animal/profile/images",
    getMainImage: "/api/animal/profile/image/main",
    getMainImages: "/api/animal/profile/image/mains",
    getMainSidedImage: "/api/animal/profile/images",
    getExtractedImageById: "/api/animal/image/extracted",
    getExtractedAnimalImages: "/api/animal/images/extracted",
    getAnimalImages: "/api/animal/images",
    blob: "/api/animal/image/blob",
    getEncounterAnimalImages: "/api/encounter/extracted",
    updateRelationship: "/api/animal/relationships/update",
    getRelationshipTypes: "/api/animal/relationships/types",
    uploadCoOccurrenceFile: "/api/animal/coOccurrenceFile",
    getTopCoOccurrences: "/api/animal/cooccurrences",
    updatePopulation: "/api/animal/updatePopulation"
  },
  behavior: {
    base: "/api/behavior",
    encounter: "/api/behavior/encounter",
    population: "/api/behavior/population"
  },
  population: {
    getPopulations: "/api/population",
    getUserPopulations: "/api/population/user",
    getPopulationUsers: "/api/population",
    getPopulationRoles: "/api/population/roles",
    getUserNonPopulations: "/api/population/user/foreign",
    getUserRightedPopulations: "/api/population/user/rights",
    confirmUser: "/api/population/confirm",
    addUser: "/api/population/add",
    inviteUser: "/api/population/members/invite",
    getUserPopulationDomains: "/api/population/user/domains",
    createPopulation: "/api/population",
    joinPopulation: "/api/population",
    userAssignableRoles: "/api/population/roles/assignable",
    isAdministrator: "/api/population/administrator",
    isProfessional: "/api/population/professional",
    updateUserRole: "/api/population/roles/update",
    settings: "/api/population/settings",
    prey: "/api/population/prey",
    feed: "/api/population/feed",
    contributors: "/api/population/contributors",
    photographers: "/api/population/photographers",
    statistics: "/api/population/statistics"
  },
  file: {
    saveNew: "/api/file/save/new",
    overwriteNew: "/api/file/save/overwrite",
    thumbnail: "/api/file/thumbnail",
    metaData: "/api/file/meta",
    upload: "/api/file/newUpload",
    save: "/api/file/upload",
    delete: "/api/file",
    getAll: "/api/file",
    getAllByEncounterPopulation: "/api/file/encounter/population",
    requeue: "/api/file/requeue",
    requeueAll: "/api/file/requeue/all"
  },
  annotation: {
    base: "/api/annotation",
    annotationById: "/api/annotation",
    confirm: "/api/annotation/confirm",
    create: "/api/annotation/create",
    file: "/api/annotation/file",
    update: "/api/annotation/update",
    delete: "/api/annotation"
  },
  profile: {
    main: "/api/profile/profile",
    encounters: "/api/profile/encounters",
    content: "/api/profile/content",
    statistics: "/api/profile/statistics",
    image: "/api/profile/image"
  },
  invitation: {
    invitation: "/api/invitation",
    redeem: "/api/invitation/redeem"
  },
  data: {
    submission: {
      autotag: "/api/inference/fin-print-pipeline-auto",
      enqueue: "/api/inference/fin-print/queue"
    }
  },
  export: {
    exportData: "/api/export",
    exportDownload: "/api/export/download",
    exportGetReview: "/api/export/getReview",
    exportSendReview: "/api/export/sendReview"
  },
  inference: {
    verification: {
      getVerifications: "/api/inference/validate",
      confirmDetection: "/api/inference/validate"
    }
  },
  authentication: {
    login: "/api/authentication/login",
    update: "/api/authentication/update",
    register: "/api/authentication/registration",
    claims: "/api/authentication/claims",
    create: "/api/authentication/create",
    forgotPassword: "/api/authentication/forgotPassword",
    resetPassword: "/api/authentication/resetPassword",
    userManualPasswordReset: "/api/authentication/manualuserpasswordreset",
    adminUserPasswordChange: "/api/authentication/adminpasswordreset"
  },
  userManagement: {
    users: "/api/userManagement/users",
    userDtos: "/api/userManagement/userDtos",
    roles: "/api/userManagement/roles",
    updateRoles: "/api/userManagement/roles/update",
    delete: "/api/userManagement/user",
    statistics: "/api/userManagement/user/statistics"
  },
  location: {
    get: "/api/location",
    add: "/api/location",
    update: "/api/location"
  },
  encounter: {
    add: "/api/encounter",
    update: "/api/encounter",
    prev: "/api/encounter/prev",
    license: "/api/encounter/license",
    next: "/api/encounter/next",
    delete: "/api/encounter/delete",
    all: "/api/encounter",
    encounterUser: "/api/encounter/user/all",
    user: "/api/encounter/encounters",
    userBare: "/api/encounter/encounters/bare",
    encounterByFileItemId: "/api/encounter/fileItems",
    fileItems: "/api/encounter/items",
    page: "/api/encounter/page",
    preview: "/api/encounter/preview",
    suggestions: "/api/encounter/suggestions",
    yearlySightings: "/api/encounter/yearlySightings",
    encounterOrganization: "/api/encounter/organization",
    removePredictions: "/api/encounter/predictions/remove",
    association: "/api/encounter/association",
    changepopulation: "/api/encounter/changepopulation"
  },
  organization: {
    getForUser: "/api/organization",
    deleteOrganization: "/api/organization",
    createOrganization: "/api/organization",
    getProfile: "/api/organization/profile",
    setProfileBanner: "/api/organization/profile/Banner",
    updateOrganizationInformation: "/api/organization/profile",
    update: "/api/organization/profile/update",
    leaveOrganization: "/api/organization/resign",
    joinOrganization: "/api/organization/join",
    isUserMember: "/api/organization/member/verify",
    isUserAdministrator: "/api/organization/administrator/verify",
    addMember: "/api/organization/member/add",
    removeMember: "/api/organization/member/remove",
    promoteMember: "/api/organization/administrator/promote",
    demoteMember: "/api/organization/administrator/demote",
    getOrganizationMembers: "/api/organization/member",
    getOrganizationAdministrators: "/api/organization/administrator",
    getAllOrganizationPreviews: "/api/organization/preview"
  },
  workspace: {
    get: "/api/workspace"
  },
  notifications: {
    all: "/api/notification"
  },
  comment: {
    getCommentSection: "/api/Comment/getCommentSection",
    //newCommentSection: "/api/Comment/newCommentSection",
    addComment: "/api/Comment/addComment",
    updateComment: "/api/Comment/updateComment",
    deleteComment: "/api/Comment/deleteComment",
    checkUser: "/api/Comment/checkUser"
  }
}
