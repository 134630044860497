import {Component, Input, OnInit} from '@angular/core';
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {UserManagementService} from "../../../../services/user/management/user-management.service";
import {UserProfileService} from "../../../../services/user/profile/user-profile.service";
import {UserImageDto} from "../../../../models/dto/user/userImageDto";
import {ImageService} from "../../../../services/utilities/image.service";
import {ErrorHandlerService} from "../../../../services/error/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {
  UserProfileImageUpdateDialogComponent
} from "./user-profile-image-update-dialog/user-profile-image-update-dialog.component";
import {UuidService} from "../../../../services/utilities/uuid.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import * as http from "http";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss']
})
export class UserProfileImageComponent implements OnInit {
  @Input() user: UserProfileDto | undefined;
  @Input() foreign: boolean = true;
  public userImage: UserImageDto | undefined | null;

  constructor(
    private profileService: UserProfileService,
    public imageService: ImageService,
    private errorService: ErrorHandlerService,
    private dialog: MatDialog,
    private uuidService: UuidService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.user === undefined) return;
    this.profileService.getProfilePicture(this.user).subscribe({
      next:(value: UserImageDto | null | undefined) => {
        if (value && value.image) {
          value.image = this.imageService.maybePrefix(value.image);
        }
        this.userImage = value;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleRequestError("Fetching User Profile Image", err);
      }
    })
  }

  public getJdenticon(user: UserProfileDto) {
    return `${user.firstName} ${user.lastName} ${user.email}`;
  }

  openPhotoUpdateDialog() {
      const ref = this.dialog.open(UserProfileImageUpdateDialogComponent, {
        data: {
          userImage: {
            id: this.uuidService.generate(),
            userId: this.user?.id,
            image: ""
          }
        }
      })
    ref.afterClosed().subscribe( res => {
      if (res) {
        this.profileService.setProfilePicture(res).subscribe({
          next: () => {
            this.log.info(`Profile image updated`)
            this.ngOnInit();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.handleRequestError("Updating profile image", err);
          }
        })
      }
    })
  }
}
