import {Component, Inject, Input} from '@angular/core';
import {EncounterDto} from "../../../../../../../models/dto/encounter/encounterDto";
import {map, Observable, startWith} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {EncountersService} from "../../../../../../../services/encounters/encounters.service";
import {Router} from "@angular/router";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../../../../services/error/error-handler.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {FormControl} from "@angular/forms";


export interface EncounterMergeDialogData {
  population: PopulationDto
  encounter: EncounterDto
}

@Component({
  selector: 'app-encounter-merge-dialog',
  templateUrl: './encounter-merge-dialog.component.html',
  styleUrls: ['./encounter-merge-dialog.component.scss']
})
export class EncounterMergeDialogComponent {

  encounterControl = new FormControl<string | EncounterDto>('');
  filteredEncounters!: Observable<EncounterDto[]>;

  public mergeTargets: Array<EncounterDto> | undefined;
  public merging: boolean = false;
  public canEdit = false;
  public mergeTarget: EncounterDto | undefined;

  constructor(
    public encounterService: EncountersService,
    private router: Router,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService,
    public dialogRef: MatDialogRef<EncounterMergeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EncounterMergeDialogData,
  ) { }

  ngOnInit(): void {
    this.startMerge();
  }

  displayFn(encounter: EncounterDto): string {
    this.mergeTarget = encounter;
    return encounter ? `${new Date(encounter.dateTime!).toLocaleDateString()} ${encounter.location?.name} ${encounter.user?.firstName} ${encounter.user?.lastName}` : '';
  }

  private _filter(name: string): EncounterDto[] {
    const filterValue = name.toLowerCase();

    return this.mergeTargets!.filter(option => this.encounterService.getEncounterName(option).toLowerCase().includes(filterValue));
  }

  startMerge() {
    if (this.mergeTargets === undefined && this.data.population) {
      this.encounterService.getBasicEncounters(this.data.population?.id!).subscribe(res => {
        // @ts-ignore
        this.mergeTargets = res.filter(a => a.id !== this.encounter?.id);
        this.filteredEncounters = this.encounterControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : this.encounterService.getEncounterName(value!);
            return name ? this._filter(name as string) : this.mergeTargets!.slice();
          }),
        );
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  merge() {
    this.merging = true;
    const dto = {
      mergeFrom: this.data.encounter?.id,
      mergeTo: this.mergeTarget?.id
    }
    this.encounterService
      .mergeEncounters(dto)
      .subscribe({
        next: (value: EncounterDto) => {
          this.log.info(`Successfully moved data for ${this.encounterService.getEncounterName(this.data.encounter!)} to ${this.encounterService.getEncounterName(this.mergeTarget!)}`)
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([`encounters/${value.id}`])
          this.merging = false;
          this.dialogRef.close();
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Merging Profiles", value);
          this.merging = false;
          this.dialogRef.close();
        }
      })
  }

  setTarget(event: MatAutocompleteSelectedEvent) {
    this.mergeTarget = event.option.value;
  }
}
