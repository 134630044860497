import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";
import {PopulationService} from "../../../../services/population/population.service";
import {PopulationDomain} from "../../../../models/dto/population/populationDomain";
import {InvitationDto, InvitationPrivilegeDto} from "../../../../models/dto/invitations/invitationDto";
import {PopulationRoleDto} from "../../../../models/dto/population/populationRoleDto";
import {InvitationService} from "../../../../services/invitations/invitation.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../services/error/error-handler.service";


export interface InvitationCreationData {
  limit: 1;
  expirationDate?: Date;
  link?: string;
  privileges?: Array<InvitationPrivilegeDto>

}

export interface PopulationRoleAssignment {
  populationId: string;
  populationRole: PopulationRoleDto;
  assignableRoles: Array<PopulationRoleDto>;
}

@Component({
  selector: 'app-new-invitation',
  templateUrl: './new-invitation.component.html',
  styleUrls: ['./new-invitation.component.scss']
})
export class NewInvitationComponent implements OnInit {
  public domains: Array<PopulationDomain> = new Array<PopulationDomain>();
  constructor(
    public dialogRef: MatDialogRef<NewInvitationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvitationCreationData,
    public workspaceService: WorkspaceService,
    public populationService: PopulationService,
    private invitationService: InvitationService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService
  ) {}
  public selectedPopulationRoleAssignment?: PopulationRoleAssignment;
  public loading = false;
  onNoClick(): void {
    this.dialogRef.close(this.data.link);
  }

  submit() {
    this.loading = true;
    const dto: InvitationDto = {
      expirationDate: this.data.expirationDate,
      limit: this.data.limit,
      privileges: this.data.privileges
    };
    this.invitationService.createInvitation(dto).subscribe({
      next: (value: InvitationDto) => {
        this.data.link = value.link!
        this.log.info("Invitation successfully created")
        this.loading = false;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Creating Invitation", value);
        this.loading = false;
      }
    })
  }

  ngOnInit(): void {
    this.populationService.getUserPopulationDomains().subscribe(res => {
      this.domains = res;
    });
  }

  addUser(id: string | undefined) {
    const population = this.domains.find(a => a.population.id == id);
    if (population === undefined) {
      return;
    }
    const lowestRole = population.assignableRoles.find(a => a.name?.toLowerCase() === "novice");
    if (lowestRole === undefined) {
      return;
    }
    this.selectedPopulationRoleAssignment = {
      populationId: id!,
      populationRole: lowestRole,
      assignableRoles: population.assignableRoles
    };
  }

  confirmAssignment() {
    const population = this.domains.find(a => a.population.id == this.selectedPopulationRoleAssignment!.populationId);
    if (population === undefined) return;
    population.assignedRole = this.selectedPopulationRoleAssignment!.populationRole;
    if (this.data.privileges === undefined) {
      this.data.privileges = new Array<InvitationPrivilegeDto>();
    }
    this.data.privileges!.push({
      roleId: population.assignedRole.id!,
      roleName: population.assignedRole.name!,
      population: population.population
    });
    this.selectedPopulationRoleAssignment = undefined;

  }

  removeUser(id: string | undefined) {
    const population = this.domains.find(a => a.population.id == id);
    if (population === undefined) return;
    population!.assignedRole = undefined;
    const idx = this.data.privileges?.findIndex(a => a.population = population.population);
    if (idx !== undefined && idx >= 0) {
      this.data.privileges?.splice(idx, 1)
    }
  }

  finish() {
    this.dialogRef.close(this.data.link);
  }
}
