import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {OrganizationDto} from "../../../models/dto/user/organization/organizationDto";
import {ResponseDto} from "../../../models/dto/response/responseDto";
import {CreateOrganizationResponseDto} from "../../../models/dto/user/organization/createOrganizationDto";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {
  OrganizationManagementDto,
  OrganizationManagementResponseDto
} from "../../../models/dto/user/organization/organizationManagementDto";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {OrganizationCreationDto} from "../../../models/dto/organization/organizationCreationDto";
import { OrganizationProfileDto } from '../../../models/dto/user/organization/organizationProfileDto';
import { organizationProfileImageDto } from '../../../models/dto/user/organization/organizationProfileImageDto';
import { organizationProfileInformationUpdateDto } from '../../../models/dto/user/organization/organizationProfileInformationUpdateDto';
import { AuthenticationUpdateResponseDto } from '../../../models/dto/response/user/authenticationUpdateResponseDto';
import { organizationUpdateDto } from '../../../models/dto/organization/organizationUpdateDto';
import { organizationPreviewDto } from '../../../models/dto/user/organization/organizationPreviewDto';

@Injectable({
  providedIn: 'root'
})
export class UserOrganizationService {

  constructor(private httpClient: HttpClient) { }

  getOrganizationsForUser(userId: string) : Observable<Array<OrganizationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getForUser}/user/${userId}`;
    return this.httpClient.get<Array<OrganizationDto>>(url);
  }
  getUserOrganizations(): Observable<Array<OrganizationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getForUser}`;
    return this.httpClient.get<Array<OrganizationDto>>(url);
  }

  deleteOrganization(organizationId: string): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.deleteOrganization}/${organizationId}`;
    return this.httpClient.delete<ResponseDto>(url);
  }

  createNewOrganization(request: OrganizationCreationDto): Observable<OrganizationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.createOrganization}`;
    return this.httpClient.post<OrganizationDto>(url, request);
  }

  leaveOrganization(organizationId: string): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.leaveOrganization}/${organizationId}`;
    return this.httpClient.get<ResponseDto>(url);
  }

  joinOrganization(organizationId: string): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.joinOrganization}/${organizationId}`;
    return this.httpClient.get<ResponseDto>(url);
  }

  isUserMember(id: string,): Observable<boolean> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.isUserMember}/${id}`;
    return this.httpClient.get<boolean>(url);
  }

  isUserAdministrator(id: string): Observable<boolean> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.isUserAdministrator}/${id}`;
    return this.httpClient.get<boolean>(url);
  }

  addUserToOrganization(request: OrganizationManagementDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.addMember}`;
    return this.httpClient.put<ResponseDto>(url, request);
  }

  removeUserFromOrganization(request: OrganizationManagementDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.removeMember}`;
    return this.httpClient.put<ResponseDto>(url, request);
  }

  promoteUserToAdministrator(request: OrganizationManagementDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.promoteMember}`;
    return this.httpClient.put<ResponseDto>(url, request);
  }

  demoteUserFromAdministrator(request: OrganizationManagementDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.demoteMember}`;
    return this.httpClient.put<ResponseDto>(url, request);
  }

  getMembersForOrganization(organizationId: string): Observable<Array<UserProfileDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getOrganizationMembers}/${organizationId}`;
    return this.httpClient.get<Array<UserProfileDto>>(url);
  }

  getAdministratorsForOrganization(organizationId: string): Observable<Array<UserProfileDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getOrganizationAdministrators}/${organizationId}`;
    return this.httpClient.get<Array<UserProfileDto>>(url);
  }

  getOrganization(organizationId: string): Observable<OrganizationDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.createOrganization}/${organizationId}`;
    return this.httpClient.get<OrganizationDto>(url);
  }

  getOrganizationProfile(organizationId: string): Observable<OrganizationProfileDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getProfile}/${organizationId}`;
    return this.httpClient.get<OrganizationProfileDto>(url);
  }

  setOrganizationProfileInformation(body: organizationUpdateDto): Observable<AuthenticationUpdateResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.updateOrganizationInformation}`;
    return this.httpClient.post<any>(url, body);
  }

  getOrganizationProfileBanner(organizationId: string): Observable<organizationProfileImageDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getProfile}/${organizationId}`;
    return this.httpClient.get<organizationProfileImageDto>(url);
  }

  setOrganizationProfileBanner(organizationImage: organizationProfileImageDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.setProfileBanner}`;
    return this.httpClient.post<any>(url, organizationImage);
  }
  updateUser(body: organizationProfileInformationUpdateDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.update}`;
    return this.httpClient.post<any>(url, body);
  }
  getAllOrganizationPreviews(): Observable<Array<organizationPreviewDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.organization.getAllOrganizationPreviews}`;
    return this.httpClient.get<Array<organizationPreviewDto>>(url);
  }

}
