import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PopulationDto } from '../../../../models/dto/population/populationDto';

@Component({
  selector: 'app-submission-population',
  templateUrl: './submission-population.component.html',
  styleUrls: ['./submission-population.component.scss']
})
export class SubmissionPopulationComponent {
  @Input() selectedPopulation!: UntypedFormControl;
  @Input() populations: Array<PopulationDto> | undefined;
  @Output() populationUpdate: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.populations !== undefined && this.populations.length > 0) {
      this.selectedPopulation.setValue(this.populations);
    }
  }

  announceUpdates() {
    this.populationUpdate.emit(this.selectedPopulation.value);
  } 

}
