<div mat-dialog-title>
  <h1>Delete Encounter</h1>
</div>
<div mat-dialog-content>
  <p>This cannot be undone and may take some time.</p>
  <p>You will be informed via email after the deletion process has finished.</p>
  <mat-form-field color="accent" class="full-width">
    <mat-label>Deletion Message to Encounter Owner</mat-label>
    <textarea matInput [(ngModel)]="data.message"></textarea>
  </mat-form-field>
  <div class="key-matching-container">
    <p>Please enter </p>
    <p style="font-family:'Lucida Console', monospace !important">{{deletionVerificationKey}}</p>
    <p> to delete the encounter.</p>
  </div>
  <mat-form-field class="full-width" color="accent">
    <mat-label>Encounter Deletion Key</mat-label>
    <input matInput [(ngModel)]="keyInput" cdkFocusInitial (keyup)="keysMatch()" onpaste="return false">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data" [disabled]="!keyMatch">Ok</button>
  <button mat-button (click)="onNoClick()">Close</button>
</div>
