import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from "@angular/common/http";
import {EncounterCreationRequestDto} from "../../models/dto/encounter/encounterCreationRequestDto";
import {EncounterCreationResponseDto} from "../../models/dto/encounter/encounterCreationResponseDto";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Encounter} from "../../models/encounters/encounter.model";
import {IFileItem} from "../../models/items/files/file-item.model";
import {ExtractedImage} from "../../models/dto/inference/verificationTaskDto";
import {EncounterPageRequestDto} from "../../models/dto/encounter/encounterPageRequestDto";
import {EncounterPageResponseDto} from "../../models/dto/encounter/encounterPageResponseDto";
import {UserProfileDto} from "../../models/dto/user/userProfileDto";
import {EncounterPreviewDto} from "../../models/dto/encounter/encounterPreviewDto";
import {AnimalDto} from "../../models/dto/animal/animalDto";
import {EncounterDto} from "../../models/dto/encounter/encounterDto";
import {DataLimitationLicenseDto} from "../../models/dto/encounter/DataLimitationLicenseDto";
import {EncounterDeletionDto} from "../../models/dto/encounter/encounterDeletionDto";
import {AnimalMergeDto} from "../../models/dto/animal/animalMergeDto";
import {DateService} from "../utilities/date.service";
import {ResponseDto} from "../../models/dto/response/responseDto";
import {OrganizationDto} from "../../models/dto/user/organization/organizationDto";

@Injectable({
  providedIn: 'root'
})
export class EncountersService {

  constructor(
    public httpClient: HttpClient,
    private dateService: DateService
  ) { }

  public getEncounterName(dto: EncounterDto): string {
    return `${this.dateService.formatDateFromAny(dto.dateTime, false, true, true, true, false)} ${dto.location?.name} ${dto.user?.firstName} ${dto.user?.lastName}`;
  }


  public createEncounter(dto: EncounterCreationRequestDto): Observable<EncounterCreationResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.add}`;
    return this.httpClient.post<EncounterCreationResponseDto>(url, dto);
  }

  public getEncounter(id: string): Observable<EncounterDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.add}/${id}`;
    return this.httpClient.get<EncounterDto>(url);
  }

  public getLicenses(): Observable<Array<DataLimitationLicenseDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.getAll}/licenses`;
    return this.httpClient.get<Array<DataLimitationLicenseDto>>(url);
  }

  public updateEncounter(dto: EncounterCreationRequestDto): Observable<EncounterCreationResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.update}/${dto.id}`;
    return this.httpClient.put<EncounterCreationResponseDto>(url, dto);
  }

  public getEncountersForUser(userId: string, pageIdx: number = 0, pageSize: number = 30, sort: string, sortDirection: string): Observable<EncounterPageResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.page}/user/${userId}/${pageIdx}/${pageSize}/${sort}/${sortDirection}`;
    return this.httpClient.get<EncounterPageResponseDto>(url);
  }

  public getEncountersForAnimal(animalId: string, pageIdx: number = 0, pageSize: number = 30, sort: string, sortDirection: string): Observable<EncounterPageResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.page}/animal/${animalId}/${pageIdx}/${pageSize}/${sort}/${sortDirection}`;
    return this.httpClient.get<EncounterPageResponseDto>(url);
  }

  public getEncountersForOrganization(organizationId: string, pageIdx: number = 0, pageSize: number = 30, sort: string, sortDirection: string): Observable<EncounterPageResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.page}/org/${organizationId}/${pageIdx}/${pageSize}/${sort}/${sortDirection}`;
    return this.httpClient.get<EncounterPageResponseDto>(url);
  }

  public deleteEncounter(dto: EncounterDeletionDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.delete}/${dto.encounterId!}`;
    return this.httpClient.post(url, dto);
  }
  public getAllEncounters(): Observable<Array<Encounter>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.all}`;
    return this.httpClient.get<Array<Encounter>>(url);
  }

  public getEncounterPage(encounterPageRequest: EncounterPageRequestDto): Observable<EncounterPageResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.page}`
    return this.httpClient.post<EncounterPageResponseDto>(url, encounterPageRequest);
  }

  public getUserEncounters(): Observable<Array<Encounter>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.user}`;
    return this.httpClient.get<Array<Encounter>>(url);
  }

  public getUserEncounterContainers(): Observable<Array<Encounter>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.userBare}`;
    return this.httpClient.get<Array<Encounter>>(url);
  }

  public getEncounterPreview(encounterId: string): Observable<EncounterPreviewDto> {
    const url =`${environment.server.baseUrl}${environment.server.api.encounter.preview}/${encounterId}`;
    return this.httpClient.get<EncounterPreviewDto>(url);
  }

  public getUserForEncounter(encounterId: string): Observable<UserProfileDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.encounterUser}/${encounterId}`;
    return this.httpClient.get<UserProfileDto>(url);
  }

  public getAnimalSuggestionsForEncounter(encounterId: string): Observable<Array<AnimalDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.suggestions}/${encounterId}`;
    return this.httpClient.get<Array<AnimalDto>>(url);
  }

  public getFileItemsForEncounter(encounterId: string): Observable<Array<IFileItem>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.fileItems}/${encounterId}`;
    return this.httpClient.get<Array<IFileItem>>(url);
  }

  public getExtractedImagesForAnimalInEncounter(encounterId: string, animalId: string): Observable<Array<ExtractedImage>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getEncounterAnimalImages}/${encounterId}/${animalId}`;
    return this.httpClient.get<Array<ExtractedImage>>(url);
  }

  public requeueUnfinishedAnnotations(encounterId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.requeue}/${encounterId}`;
    return this.httpClient.get(url);
  }

  public requeueAllItems(encounterId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.requeueAll}/${encounterId}`;
    return this.httpClient.get(url);
  }

  public getYearlySightings(animalId: string): Observable<Map<number, number>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.yearlySightings}/${animalId}`;
    return this.httpClient.get<Map<number, number>>(url);
  }

  getBasicEncounters(populationId: string): Observable<Array<EncounterDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.all}/basic/${populationId}`;
    return this.httpClient.get<Array<EncounterDto>>(url);
  }

  mergeEncounters(dto: AnimalMergeDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.all}/merge`;
    return this.httpClient.post<EncounterDto>(url, dto);
  }

  reassociate(encounterId: string) {
    const url = `${environment.server.baseUrl}/api/annotation/reassociate/${encounterId}`;
    return this.httpClient.get(url);
  }

  removePredictions(encounterId: string): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.removePredictions}/${encounterId}`;
    return this.httpClient.get<ResponseDto>(url);
  }

  autoassignSide(encounterId: string){
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.all}/autoassign/side/${encounterId}`;
    return this.httpClient.get(url);
  }

  getOrganizationsForEncounter(encounterId: string): Observable<Array<OrganizationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.association}/${encounterId}`;
    return this.httpClient.get<Array<OrganizationDto>>(url);
  }

  addOrganizationsToEncounter(encounterId: string, organizationIds: Array<string>) {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.association}/${encounterId}`;
    return this.httpClient.put<Array<OrganizationDto>>(url, {encounterId: encounterId, newOrganizationIds: organizationIds});
  }
  public getNextEncounter(id: string): Observable<EncounterDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.next}/${id}`;
    return this.httpClient.get<EncounterDto>(url);
  }

  public getPreviousEncounter(id: string): Observable<EncounterDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.prev}/${id}`;
    return this.httpClient.get<EncounterDto>(url);
  }

  public getNextEncounterWithRequest(id: string, request: EncounterPageRequestDto): Observable<EncounterDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.next}/${id}`;
    return this.httpClient.post<EncounterDto>(url, request);
  }


  public getPreviousEncounterWithRequest(id: string, request: EncounterPageRequestDto): Observable<EncounterDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.prev}/${id}`;
    return this.httpClient.post<EncounterDto>(url, request);
  }

  public changePopulation(encounterId: string, newPopulationId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.changepopulation}/${encounterId}/${newPopulationId}`;
    return this.httpClient.get(url);
  }

  updateEncounterLicense(encounterId?: string, licenseId?: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.license}/${encounterId}/${licenseId}`;
    return this.httpClient.get<ResponseDto>(url);
  }
}
