<div class="population-description-container" *ngIf="population && population.description !== undefined">
  <h3>Population Description <button mat-icon-button (click)="editing = !editing"><mat-icon>edit</mat-icon></button></h3>
  <div class="population-description" *ngIf="!editing">
    <div [innerHTML]="population.description"></div>
  </div>
  <div class="population-description-edit" *ngIf="editing">
    <ckeditor [editor]="Editor" [data]="population.description" (change)="log($event)" [tagName]="'editor'"></ckeditor>
    <button mat-raised-button color="accent" (click)="update()">Update Description</button>
    <button mat-raised-button color="warn" (click)="editing = !editing">Cancel</button>
  </div>

</div>
