import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {map, Observable, startWith} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {FormControl} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {LocationService} from "../../../../../services/location/location.service";
import {PopulationService} from "../../../../../services/population/population.service";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {EncounterPageRequestDto} from "../../../../../models/dto/encounter/encounterPageRequestDto";

@Component({
  selector: 'app-photographer-selection',
  templateUrl: './photographer-selection.component.html',
  styleUrls: ['./photographer-selection.component.scss']
})
export class PhotographerSelectionComponent implements OnInit, OnChanges {

  @Input() workspace: WorkspaceDto | undefined;
  @Input() population: PopulationDto | undefined;
  @Input() request: EncounterPageRequestDto | undefined;
  @Output() chosenPhotographers: EventEmitter<Array<UserProfileDto>> = new EventEmitter<Array<UserProfileDto>>();

  @ViewChild('photographerInput') photographerInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  photographers: Array<UserProfileDto> | undefined;
  photographerControl = new FormControl();
  public filteredPhotographers: Observable<string[]> | undefined;
  public selectedPhotographers: string[] = new Array<string>();


  constructor(
    private locationService: LocationService,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.request && this.request.photographers && this.photographers) {

      const photographers = this.photographers.filter(a => this.request?.photographers?.includes(a.id!));
      for (let a of photographers) {

        if (this.selectedPhotographers.indexOf(`${a.firstName} ${a.lastName}`) == -1) {
          this.selectedPhotographers.push(`${a.firstName} ${a.lastName}`);
        }

      }
      this.chosenPhotographers.emit(this.getPhotographers());
    }
    }

  public reset() {
    this.photographerControl.reset();
    this.selectedPhotographers = new Array<string>();
    this.chosenPhotographers.emit(this.getPhotographers());
  }

  addPhotographer(event: MatChipInputEvent): void {
    const value = event.value;
    if (value) {
      this.selectedPhotographers.push(value);
    }
    event.chipInput!.clear();
    this.photographerControl.setValue(null);
    this.chosenPhotographers.emit(this.getPhotographers());
  }

  removePhotographer(photographer: string): void {
    const index = this.selectedPhotographers.indexOf(photographer);

    if (index >= 0) {
      this.selectedPhotographers.splice(index, 1);
    }
    this.chosenPhotographers.emit(this.getPhotographers());
  }


  selectedPhotographer(event: MatAutocompleteSelectedEvent): void {
    this.selectedPhotographers.push(event.option.value);
    this.photographerInput.nativeElement.value = '';
    this.photographerControl.setValue(null);
    this.chosenPhotographers.emit(this.getPhotographers());
  }

  private getPhotographers() {
    return this.photographers!.filter( a => this.selectedPhotographers.indexOf( `${a.firstName} ${a.lastName}`) !== -1)
  }

  private _filterPhotographers(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.photographers!.map(a => `${a.firstName} ${a.lastName}`).filter(a => a.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.workspaceService.workspace.subscribe( res => {
      if (res && res.settings) {
        this.population = res.settings!.population;
        if (res.settings!.population?.id) {
          this.populationService
            .getPopulationPhotographers(res.settings!.population.id)
            .subscribe(res => {
              this.photographers = res;
              this.filteredPhotographers = this.photographerControl.valueChanges.pipe(
                startWith(null),
                map((photographer: string | null) => (photographer ? this._filterPhotographers(photographer) : this.photographers!.map(a => `${a.firstName} ${a.lastName}`).slice())),
              );

              if (this.request && this.request.photographers && this.photographers) {

                const photographers = this.photographers.filter(a => this.request?.photographers?.includes(a.id!));
                for (let a of photographers) {

                  if (this.selectedPhotographers.indexOf(`${a.firstName} ${a.lastName}`) == -1) {
                    this.selectedPhotographers.push(`${a.firstName} ${a.lastName}`);
                  }

                }
                this.chosenPhotographers.emit(this.getPhotographers());
              }
            })

        }
      }

    })
  }
}
