<div class="encounter-comments-section">
  <div class="encounter-comments-section">
    <div class="hasComments" *ngIf="hasComments && loadedUser">
      <h4>Comments</h4>
      <div class="commentTree" *ngFor="let comment of commentSection.root!">
        <app-comment-template [commentElement]="comment" [layer]="layer" [commentSection]="commentSection" [currentUserID]="UserID" [currentUserImage]="UserImage" (reload)="loadComments()"></app-comment-template>
      </div>
    </div>
    <div class="noComments" *ngIf="!hasComments">
      <div class="noCommentContent">
        <h4><span class="content">No Comments yet. Tell us what you think of this Encounter.</span></h4>
      </div>
    </div>
    <!--NewComment-->
    <div class="encounter-new-Comment">
      <div class="comment-actions" *ngIf="!editing">
        <!--<button mat-stroked-button color="accent" (click)="">Reply</button>-->
        <!-- notify, like, dislike encounter *ngIf="isLoggedIn" -->
        <button mat-icon-button color="accent" class="profile-edit-button" (click)="openEditor()"><mat-icon>add_comment</mat-icon></button>
      </div>
      <div class="comment-edit" *ngIf="editing">
        <ckeditor [editor]="Editor" [data]="EditorInit" (change)="log($event)" [tagName]="'editor'"></ckeditor>
        <div class="button-row">
          <button mat-icon-button color="accent" (click)="addComment()"><mat-icon>done</mat-icon></button>
          <button mat-icon-button color="accent" (click)="closeEditor()"><mat-icon>cancel</mat-icon></button>
        </div>

      </div>
    </div>

  </div>
</div>
