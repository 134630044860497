<div class="content-container">
  <h3 [matTooltipPosition]="'before'" matTooltip="Change the population of an encounter">Switch Population</h3>
  <mat-form-field *ngIf="populations; else loading" color="accent" class="full-width">
    <mat-label>Choose Population </mat-label>
    <mat-select [formControl]="selectedPopulation" (valueChange)="announceUpdates()">
      <mat-option *ngFor="let population of populations" [value]="population.id">{{population.displayName}}</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-template #loading>
    <h4>No Populations found. </h4>
  </ng-template>
</div>
