<div class="map-container">
  <div class="map-frame">
    <div id="events-map"></div>
  </div>
</div>
<div class="heatmap" *ngIf="heatmap">

</div>
<!--<div class="empty-content" *ngIf="locations === undefined || locations?.length == 0">-->
<!--  <h3>No Locations Selected</h3>-->
<!--</div>-->
