import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {IImageService} from "../../../services/files/images/image.service.interface";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import {TimeService} from "../../../services/utilities/time.service";
import {AnnotatedImage} from "../../../models/items/files/images/annotated-images/annotated-image.model";
import {GalleryImageDialogComponent} from "./gallery-image-dialogs/gallery-image.dialog";
import {WebFileService} from "../../../services/files/web-file.service";
import {FileSortingService} from "../../../services/files/sorting/file-sorting.service";
import {SortCriteria} from "../../../services/files/sorting/sort-criteria.enum";
import {FileGroupingService} from "../../../services/files/sorting/file-grouping.service";
import {ImageService} from "../../../services/utilities/image.service";
import {PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {DateService} from "../../../services/utilities/date.service";
import {AnimalDto} from "../../../models/dto/animal/animalDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ImageAnnotation} from "../../../models/annotation/image/annotation.model";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {EncounterDto} from "../../../models/dto/encounter/encounterDto";
import {AnimalProfileDto} from "../../../models/dto/animal/animalProfileDto";
import {ResponsiveDesignService} from "../../../services/design/responsive-design.service";
import {Observable} from "rxjs";
import {AnimalService} from "../../../services/animal/animal.service";
import {WorkspaceService} from "../../../services/workspace/workspace.service";


@Component({
  selector: 'global-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() images: Array<IFileItem> | undefined | null;
  @Input() selectedImageId?: string;
  @Input() allowAnnotation: boolean = true;
  @Input() showAnnotations: boolean = true;
  @Input() showMetaData: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() encounter: EncounterDto | undefined;
  @Input() sortByEncounter: boolean = false;
  @Input() user: UserProfileDto | undefined;
  @Input() suggestions: Array<AnimalDto> | null = new Array<AnimalDto>();
  @Input() markForDeletion: boolean | undefined;
  @Input() markForSideLabel: boolean | undefined;
  @Input() tab: number = 1;
  @Input() animals: Array<AnimalProfileDto> | undefined;
  @Input() animalObs: Observable<Array<AnimalDto>> | undefined;
  @Output() itemsLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() annotationRemoved: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationConfirmed: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationUpdated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationCreated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();

  public groupedImages: Map<string | number, Array<IFileItem>> = new Map<string | number, Array<IFileItem>>();
  public groupKeys: Array<string | number> = new Array<string>();
  public selectedImage: AnnotatedImage | undefined;
  public sortAscending = false;
  public sortDirectionString = this.getSortDirectionString();
  public sortCriteria = SortCriteria.encounterDate;
  public availableSortCriteria = SortCriteria;
  public uploadText = "Add Media"
  public fetchComplete = false;
  public continuousViewMode = true;
  public viewModeIconString = this.getViewModeIconString();
  public population: PopulationDto | undefined;
  private flatMappedImages: Array<IFileItem> = new Array<IFileItem>();

  @ViewChild(GalleryImageDialogComponent) imageDialog!: GalleryImageDialogComponent;
  private openable: boolean = true;

  constructor(
    public log: ILoggingService,
    public imageService: IImageService,
    public timeService: TimeService,
    public webFileService: WebFileService,
    public sortService: FileSortingService,
    public groupService: FileGroupingService,
    public imageUtilities: ImageService,
    public populationService: PopulationService,
    public dateService: DateService,
    public designService: ResponsiveDesignService,
    private animalService: AnimalService,
    private workspaceService: WorkspaceService
  ) {
  }

  ngAfterViewInit(): void {
    if (this.selectedImageId && this.images) {
      const img = this.images!.find(a => a.id == this.selectedImageId)
      if (img) {
        this.selectImage(img);
      }
    }
    }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["images"] !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {}

  ngOnInit(): void {
    // this.populationService.population.subscribe(res => this.population = res);
    if (this.sortByEncounter && this.images !== undefined) {
      this.sortCriteria = SortCriteria.encounterDate;
      this.groupItems();
      this.continuousViewMode = false;
    }
    this.workspaceService.workspace.subscribe(res => {
      this.allowAnnotation = (res.settings?.populationRoles?.professional || res.settings?.populationRoles?.administrator || res.settings?.populationRoles?.expert) || false;
    })

    this.designService.mobile.subscribe( res => {
      this.openable = !res;
    })

    this.designService.tablet.subscribe( res => {
      this.openable = !res;
    })

  }

  getHeadlineForEncounterGroup(map: Map<string | number, Array<IFileItem>>, key: string | number) {
    const entry = map.get(key)![0];
    const dateString = this.dateService.formatDateFromAny(entry.encounter?.dateTime, false);
    const locationName = entry.metaData.location ? entry.metaData.location.name : entry.encounter?.location?.name;
    return`${this.population?.abbreviation} ${dateString} ${entry.metaData.photographerName} ${locationName}`;
  }

  selectImage(image: IFileItem) {
    if (!this.openable) {
      this.log.info("Tool not designed for mobile devices. Please use a desktop or laptop to annotate or view larger images or details");
      return;
    }
    this.selectedImage = image as AnnotatedImage;
    if (this.animals === undefined && this.animalObs !== undefined) {
      this.animalObs.subscribe({
        next: (value: Array<AnimalDto>) => {
          this.animals = this.animalService.concatIds(value);
          this.log.info(`Fetched population information. Found ${this.animals.length} individuals`);
          this.imageDialog.openDialog(this.selectedImage!, this.suggestions ?? [], this.user, this.animals as Array<AnimalDto>);
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not fetch individuals from population: ${value.message}`)
        }
      })
    } else {
      this.imageDialog.openDialog(this.selectedImage!, this.suggestions ?? [], this.user, this.animals as Array<AnimalDto>);
    }

  }

  maybePrefix(imageString: string | undefined) {
    if (typeof imageString == "string") {
      if (imageString.indexOf("base64") == -1) {
        return this.imageUtilities.prefixImg(imageString)
      }
    }
    return imageString;
  }

  getSortDirectionString() {
    if (this.sortAscending) {
      return "Sorting Ascending"
    }
    return "Sorting Descending";
  }

  getViewModeString() {
    if (this.continuousViewMode) {
      return "Viewing Continuous"
    }
    return "Viewing Grid";
  }

  getViewModeIconString() {
    if (this.continuousViewMode) {
      return "view_list"
    }
    return "grid_view";
  }

  toggleSortDirection() {
    this.sortAscending = !this.sortAscending;
    this.sortDirectionString = this.getSortDirectionString();
    if (this.continuousViewMode) {
      this.sortItems();
    } else {
      this.groupItems();
    }

  }

  sortItems() {
    if (this.images) {
      this.images = this.sortService.sortItems(this.images, this.sortCriteria, this.sortAscending);
    }

  }

  groupItems() {
    if (this.images) {
      this.groupedImages = this.groupService.groupItems(this.images, this.sortCriteria, this.sortAscending);
      this.groupKeys = Array.from( this.groupedImages.keys() );
      this.flatMappedImages = new Array<IFileItem>();
      for (let k of this.groupedImages.keys()) {
        this.flatMappedImages = this.flatMappedImages.concat(this.groupedImages.get(k)!)
      }
    }

  }

  toggleSortCriteria(criteria: number) {
    this.sortCriteria = criteria;
    if (this.continuousViewMode) {
      this.sortItems()
    } else {
      this.groupItems();
    }
  }

  toggleViewMode() {
    this.continuousViewMode = !this.continuousViewMode;
    this.viewModeIconString = this.getViewModeIconString();
    if (this.continuousViewMode) {
      this.sortItems()
    } else {
      this.groupItems();
    }
  }

  loadNextImage() {
    let imageArray = this.images!;
    let currentIdx = imageArray.indexOf(this.selectedImage as AnnotatedImage);
    if (currentIdx === -1) {
      imageArray = this.flatMappedImages;
      currentIdx = imageArray.indexOf(this.selectedImage as AnnotatedImage);
    }
    if (currentIdx !== undefined && currentIdx != -1) {
      let nextIdx = currentIdx + 1;
      if (imageArray && currentIdx == imageArray?.length - 1) {
        nextIdx = 0;
      }
      if (imageArray) {
        const nextImage = imageArray[nextIdx];
        this.selectImage(nextImage);
      }
    }
  }

  loadPreviousImage() {
    let imageArray = this.images!;
    let currentIdx = imageArray.indexOf(this.selectedImage as AnnotatedImage);
    if (currentIdx === -1) {
      imageArray = this.flatMappedImages;
      currentIdx = imageArray.indexOf(this.selectedImage as AnnotatedImage);
    }
    if (currentIdx !== undefined && currentIdx != -1) {
      let nextIdx = currentIdx - 1;
      if (imageArray) {
        if (currentIdx == 0) {
          nextIdx = imageArray.length - 1;
        }
        const nextImage = imageArray[nextIdx];
        this.selectImage(nextImage);
      }
    }
  }


  getAnnotationHeader(image: IFileItem) {
    const i = image as AnnotatedImage;
    if (i.annotations !== undefined) {
      let val = "";
      if (i.annotations.length == 1) {
        val = `${i.annotations.length} Annotation\n`;
      } else if (i.annotations.length > 1) {
        val = `${i.annotations.length} Annotations\n`;
      }
      return val;



    }
    return undefined;
  }
  getAnnotationOverlayLines(image: IFileItem) {
    let val = [];
    const i = image as AnnotatedImage;
    for (let a of i.annotations) {
      a.tag = a.tag?.replace("Garbage", "Unknown");
      let line = ""
      line += `- ${a.tag}`.replace("Model Suggestion: ", "");
      if (!a.confirmed && a.tag?.toLowerCase() !== "unknown") {
        line += ` (suggested)`;
      }
      val.push(line);

    }
    return val;
  }

  deleteImage(image: IFileItem) {
    if (this.images === undefined || this.images === null || this.images.length === 0) {
      return;
    }
    this.images!.splice(this.images!.indexOf(image), 1)
    this.imageService
      .deleteImage(image.id)
      .subscribe({
        next: (value: any) => {
          this.log.info(`Image removed.`, true);
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Image could not be deleted: ${value.message}`, true)
        }
      })
  }



  removeAnnotation($event: ImageAnnotation) {
    this.annotationRemoved.emit($event);
  }

  updateAnnotation($event: ImageAnnotation) {
    this.annotationUpdated.emit($event);
  }

  confirmAnnotation($event: ImageAnnotation) {
    this.annotationConfirmed.emit($event);
  }

  markImage(image: IFileItem) {
    image.marked = !image.marked;
  }

  createAnnotation($event: ImageAnnotation) {
    this.annotationCreated.emit($event);
  }
}
