import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EncounterDto } from '../../../../../../../models/dto/encounter/encounterDto';
import { PopulationDto } from '../../../../../../../models/dto/population/populationDto';
import { OrganizationDto } from '../../../../../../../models/dto/user/organization/organizationDto';
import { UserProfileDto } from '../../../../../../../models/dto/user/userProfileDto';
import { EncountersService } from '../../../../../../../services/encounters/encounters.service';
import { PopulationService } from '../../../../../../../services/population/population.service';

@Component({
  selector: 'app-update-population-component',
  templateUrl: './update-population-component.component.html',
  styleUrls: ['./update-population-component.component.scss']
})
export class UpdatePopulationComponent {
  @Input() encounter?: EncounterDto;
  @Input() submissionUser!: UserProfileDto | undefined;
  @Output() populationid: EventEmitter<string> = new EventEmitter<string>();

  public allUserPopulations: Array<PopulationDto> = new Array<PopulationDto>();
  public selectablePopulations: Array<PopulationDto> = new Array<PopulationDto>();
  public inProgress = false;
  public population: PopulationDto | undefined;
  selectedPopulation: UntypedFormControl = new UntypedFormControl();
  constructor(
    private encounterService: EncountersService,
    private populationService: PopulationService

  ) { }

  ngOnInit() {
    this.populationService.getUserRightedPopulations().subscribe(res => {
      this.allUserPopulations = res;
      var allUserPopulations2 = res.map(obj => ({ ...obj }));
      this.populationService.population.subscribe(res => this.population = res);
      var index = allUserPopulations2.findIndex((element) => element.id == this.population!.id)
      allUserPopulations2.splice(index, 1);
      this.selectablePopulations = allUserPopulations2;
    })

  }
  updatePopulation() {
    this.inProgress = true;
    //still emit no harm changing twice, and if we cancel we still changed with button
    this.populationid.emit(this.selectedPopulation.value);
    //change Population and change all assigned animals, we need a new population service entry
    this.encounterService.changePopulation(this.encounter!.id!, this.selectedPopulation.value).subscribe();
    //this.populationService.select(this.selectedPopulation.value);
    this.populationService.getPopulationById(this.selectedPopulation.value).subscribe(res => {
      this.population = res;
      var allUserPopulations2 = this.allUserPopulations.map(obj => ({ ...obj }));
      var index = allUserPopulations2.findIndex((element) => element.id == this.population!.id)
      allUserPopulations2.splice(index, 1);
      this.selectablePopulations = allUserPopulations2;
      this.inProgress = false;
    });
  }
}
