<div class="content-container" *ngIf="application && !processing">
  <div class="application-header">
    <h2><span>[{{application.status!.status}}]</span>Application for new population: {{application.displayName}}</h2>
    <div class="buttons">
      <button mat-stroked-button color="accent" *ngIf="application.status!.status == 'NEW'" (click)="acknowledge()">Acknowledge Application</button>
      <button mat-stroked-button color="accent" *ngIf="(application.status!.status == 'IN REVIEW' || application.status!.status == 'IN PROGRESS') && !commenting" (click)="approve()">Approve Application</button>
      <button mat-stroked-button color="accent" *ngIf="(application.status!.status == 'IN REVIEW' || application.status!.status == 'IN PROGRESS') && !commenting" (click)="reject()">Reject Application</button>
      <button mat-stroked-button color="accent" *ngIf="(application.status!.status == 'REJECTED') && !commenting" (click)="reopen()">Re-open Application</button>
    </div>

  </div>
  <div class="application-section applicant" *ngIf="application.applicant">
    <h3>Applicant Information</h3>
    <h4><b>Name</b>: {{application.applicant.firstName}} {{application.applicant.lastName}}</h4>
    <h4><b>Email</b>: <a [href]="getMailLink(application.applicant!)">{{application.applicant.email}}</a></h4>
  </div>
  <div class="application-section authorities-section">
    <h3>Designated Population Authorities</h3>
    <div class="authorities">
      <div class="authority" *ngIf="application.authorityA">
        <h4><b>Authority 1</b></h4>
        <h4><b>Name</b>: {{application.authorityA!.firstName}} {{application.authorityA!.lastName}}</h4>
        <h4><b>Email</b>: <a [href]="getMailLink(application.authorityA!)">{{application.authorityA!.email}}</a></h4>
      </div>
      <div class="authority"  *ngIf="application.authorityB">
        <h4><b>Authority 2</b></h4>
        <h4><b>Name</b>: {{application.authorityB!.firstName}} {{application.authorityB!.lastName}}</h4>
        <h4><b>Email</b>: <a [href]="getMailLink(application.authorityB!)">{{application.authorityB!.email}}</a></h4>
      </div>
    </div>
  </div>
  <div class="application-section messages">
    <div class="applicant-message">
      <h3>Applicant's Message</h3>
      <p>{{application.message}}</p>
    </div>
    <div class="application-section applicant-response" *ngIf="application.comments">
      <h3>Reviewer Comments</h3>
      <p>{{application.comments}}</p>
    </div>
    <div class="reviewer-comments" *ngIf="commenting && update">
      <mat-form-field>
        <mat-label>Review Comments to be sent to the Authorities</mat-label>
        <textarea matInput [(ngModel)]="update.comments"></textarea>
      </mat-form-field>
      <div class="buttons">
        <button mat-stroked-button color="warn" (click)="commenting = false">Cancel</button>
        <button mat-stroked-button color="accent" (click)="putUpdate()">Submit</button>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<ng-template [ngIf]="processing">
  <app-loading-overlay [criteria]="processing" [title]="'Processing...'"></app-loading-overlay>
</ng-template>


