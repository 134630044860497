
import {Component, Inject, OnInit} from '@angular/core';
import {AnimalRelationshipDto} from "../../../../../models/dto/animal/animalRelationshipDto";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {AnimalRelationshipTypeDto} from "../../../../../models/dto/animal/animalRelationshipTypeDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface RelationshipUpdateDialogData {
  relationship: AnimalRelationshipDto
}

@Component({
  selector: 'dialog-animal-relationship-update',
  templateUrl: 'relationship-update-dialog.component.html',
  styleUrls: ["relationship-update-dialog.component.scss"]
})
export class RelationshipUpdateDialogComponent implements OnInit {
  relationships: Array<AnimalRelationshipTypeDto> | undefined;
  customRelationship = new UntypedFormGroup({
    relationshipType: new UntypedFormControl()
  })
  constructor(
    public dialogRef: MatDialogRef<RelationshipUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnimalRelationshipDto,
    private animalService: AnimalService,
    private logService: ILoggingService
  ) {}

  ngOnInit() {
    this.animalService.getRelationshipTypes().subscribe({
      next: (value: Array<AnimalRelationshipTypeDto>) => {
        this.relationships = value;
      },
      error: (error: HttpErrorResponse) => {
        this.logService.error(`Error fetching relationship types: ${error.message}`, true);
      }
    })
  }

  updateType(event: any) {
    this.data.relationshipType = event.value;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
