import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Observable} from "rxjs";
import {FormControl, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualSelectionComponent} from "./individual-selection/individual-selection.component";
import {LocationSelectionComponent} from "./location-selection/location-selection.component";
import {PhotographerSelectionComponent} from "./photographer-selection/photographer-selection.component";
import {EncounterStatusSelectionComponent} from "./encounter-status-selection/encounter-status-selection.component";
import {EncounterPageRequestDto} from "../../../../models/dto/encounter/encounterPageRequestDto";
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {WorkspaceDto} from "../../../../models/dto/workspace/workspaceDto";
import {DataSourceDto} from "../../../../models/dto/animal/animalSearchDto";
import {LocationService} from "../../../../services/location/location.service";
import {PopulationService} from "../../../../services/population/population.service";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";
import {Location} from "../../../../models/location/location.model";
import {
  EncounterCompleteStatusSelectionComponent
} from "./encounter-complete-status-selection/encounter-complete-status-selection.component";
import {QueryService} from "../../../../services/query/query.service";
import {BehaviorSelectionComponent} from "./behavior-selection/behavior-selection.component";
import {AnimalBehaviorDto} from "../../../../models/dto/behavior/animalBehaviorDto";
const moment = require("moment");

@Component({
  selector: 'app-encounter-selection-drawer',
  templateUrl: './encounter-selection-drawer.component.html',
  styleUrls: ['./encounter-selection-drawer.component.scss'],
})
export class EncounterSelectionDrawerComponent implements OnInit, OnChanges {
  @Input() request: EncounterPageRequestDto | undefined;
  @Input() animals: Array<AnimalDto> | undefined;
  @ViewChild(IndividualSelectionComponent) individualSelection!: IndividualSelectionComponent;
  @ViewChild(LocationSelectionComponent) locationSelection!: LocationSelectionComponent;
  @ViewChild(PhotographerSelectionComponent) photographerSelection!: PhotographerSelectionComponent;
  @ViewChild(EncounterStatusSelectionComponent) statusSelection!: EncounterStatusSelectionComponent;
  @ViewChild(BehaviorSelectionComponent) behaviorSelection!: BehaviorSelectionComponent;
  @ViewChild(EncounterCompleteStatusSelectionComponent) statusCompleteSelection!: EncounterCompleteStatusSelectionComponent;

  @Output() requestUpdated: EventEmitter<EncounterPageRequestDto> = new EventEmitter<EncounterPageRequestDto>();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  public searchStarted: boolean = false;
  public searchFinished: boolean = false;
  public locationObs: Observable<Array<Location>> | undefined;
  public userObs: Observable<Array<UserProfileDto>> | undefined;
  // public photographerControl: UntypedFormControl = new UntypedFormControl(undefined);
  // public locationControl: UntypedFormControl = new UntypedFormControl(undefined);
  public population: PopulationDto | undefined;
  public workspace: WorkspaceDto | undefined;
  public locations: Array<Location> | undefined;
  public behaviors: Array<string> = new Array<string>();

  public selectedAnimals: Array<AnimalDto> | undefined;
  public selectedPhotographers: Array<UserProfileDto> | undefined;
  public selectedLocations: Array<Location> | undefined;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });
  searching: any = false;
  selectedStatus: any;
  public selectedCompletionStatus: any;


  constructor(
    private locationService: LocationService,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService,
    private queryService: QueryService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.hasOwnProperty("request")) {
        this.request = changes.request.currentValue;
      }
  }

  ngOnInit(): void {

    this.workspaceService.workspace.subscribe(res => {
      if (res && res.settings && res.settings.population)
      this.workspace = res;
      this.population = res.settings?.population;
    })

    this.locationService.getLocationsFromServer().subscribe(res => this.locations = res);
    if (this.request) {
      // this.request!.dataSources = new DataSourceDto();
      if (this.request.start) {
        this.range.get("start")?.setValue(moment(this.request.start))
        this.range.get("end")?.setValue(moment(this.request.end));
      }
    }

  }

  submit() {
    this.request = {
      start: this.range.get('start')?.value?.toDate(),
      end: this.range.get('end')?.value?.toDate(),
      status: this.selectedStatus,
      locations: this.selectedLocations ? this.selectedLocations.map(a => a.name!) : undefined,
      photographers: this.selectedPhotographers ? this.selectedPhotographers.map( a=> a.id!) : undefined,
      individuals: this.selectedAnimals ? this.selectedAnimals.map(a => a.id!) : undefined,
      populationId: this.request?.populationId,
      encounterLimit: this.request?.encounterLimit,
      dataSources: this.request?.dataSources,
      complete: this.selectedCompletionStatus,
      behaviors: this.behaviors

    };


    this.requestUpdated.emit(this.request);
  }

  reset() {
    this.range.get('start')?.reset()
    this.range.get('end')?.reset()
    this.photographerSelection.reset();
    this.individualSelection.reset();
    this.locationSelection.reset();
    this.statusSelection.reset();
    this.statusCompleteSelection.reset();
    this.submit();
  }

  sortLocations(locations: Array<Location>) {
    return locations.sort((a, b) => a.name!.localeCompare(b.name!));
  }

  resetDataSources() {
    this.request!.dataSources = new DataSourceDto();
  }

  resetDate() {
    this.range.get('start')?.reset()
    this.range.get('end')?.reset()
  }
}
