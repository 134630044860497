import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrganizationProfileDto } from '../../../../../models/dto/user/organization/organizationProfileDto';
import { organizationProfileImageDto } from '../../../../../models/dto/user/organization/organizationProfileImageDto';
import { ErrorHandlerService } from '../../../../../services/error/error-handler.service';
import { ILoggingService } from '../../../../../services/logging/logging.service.interface';
import { UserOrganizationService } from '../../../../../services/user/organization/user-organization.service';
import { ImageService } from '../../../../../services/utilities/image.service';
import { UuidService } from '../../../../../services/utilities/uuid.service';
import { OrganizationProfilePictureUpdateDialogComponent } from './organization-profile-picture-update-dialog/organization-profile-picture-update-dialog.component';

@Component({
  selector: 'app-organization-profile-picture',
  templateUrl: './organization-profile-picture.component.html',
  styleUrls: ['./organization-profile-picture.component.scss']
})
export class OrganizationProfilePictureComponent implements OnInit {
  @Input() organizationProfile: OrganizationProfileDto | undefined;
  @Input() administrationAccess: boolean = true;
  //public organizationImage: organizationProfileImageDto | undefined | null;
  public organization_Logo: string = "";
  public organization_Name: string = "";
  private image: organizationProfileImageDto | undefined;
  constructor(
    private route: ActivatedRoute,
    private organizationservice: UserOrganizationService,
    public imageService: ImageService,
    private errorService: ErrorHandlerService,
    private dialog: MatDialog,
    private uuidService: UuidService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.organizationProfile === undefined) return;
    this.organization_Logo = this.organizationProfile!.organization_Logo;
    this.organization_Name = this.organizationProfile!.organization_Name;
    //TODO Organization Banner
    //this.organizationservice.get
    /*this.organizationservice.getOrganizationProfileBanner(this.organizationProfile.organization_Id).subscribe({
      next: (value: organizationProfileImageDto | null | undefined) => {
        if (value && value.Logo) {
          value.Logo = this.imageService.maybePrefix(value.Logo);
        }
        this.organizationImage = value;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleRequestError("Fetching Organization Profile Banner", err);
      }
    })*/
  }
  public getJdenticon() {
    return this.organizationProfile!.organization_Name;
  }
  openPhotoUpdateDialog()
  {
    const ref = this.dialog.open(OrganizationProfilePictureUpdateDialogComponent, {
      data: {
        organizationImage: {
          image_id: this.uuidService.generate(),
          Id: this.organizationProfile?.organization_Id,
          Logo: ""
        }
      }
    })
    ref.afterClosed().subscribe(res => {
      if (res) {
        this.organizationservice.setOrganizationProfileBanner(res).subscribe({
          next: () => {
            this.log.info(`Profile image updated`)
            this.ngOnInit();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.handleRequestError("Updating profile image", err);
          }
        })
        //Change Organization Image In HTML
        const Image = document.getElementById(
          'Image',
        ) as HTMLImageElement | null;
        this.image = res;
        Image!.src = this.image!.Logo;

      }
    })
  }
}
