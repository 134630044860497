import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  maybePrefix(encodedString: string) {
    if (encodedString.indexOf("base64") == -1) {
      return this.prefixImg(encodedString);
    }
    return encodedString;
  }
  prefixImg(encodedString: string) {
    if (encodedString.toLowerCase().indexOf(".jpg") === -1) {
      return `data:image/jpeg;base64,${encodedString}`;
    }
    return encodedString;
  }


  arrayBufferToBase64( buffer: ArrayBuffer ): string {
    let binary = '';
    let bytes = new Uint8Array( buffer );
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
}
