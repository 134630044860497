<div class="profile-image-container">
  <div class="image-container">
    <div class="default" *ngIf="user !== undefined && !user!.userImage">
      <svg width="75" height="75" [data-jdenticon-value]="getJdenticon(user!)"></svg>
    </div>
    <div class="user-profile-image" *ngIf="user !== undefined && user!.userImage && user!.userImage!.image">
      <img [src]="imageService.maybePrefix(user!.userImage!.image)" [alt]="user!.firstName" [routerLink]="getUserLink(user!)">
    </div>
    <div class="image-edit-overlay" *ngIf="!foreign" (click)="openPhotoUpdateDialog()">
      <mat-icon>photo_camera</mat-icon>
      <h2>Update Image</h2>
    </div>
  </div>
  <div class="user-name" *ngIf="showName">
    <h4>{{user?.firstName}} {{user?.lastName}}</h4>
  </div>

</div>

