import {Component, Inject, Input, OnInit} from '@angular/core';
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {PopulationRoleDto} from "../../../../../models/dto/population/populationRoleDto";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


export interface NewUserInvitation {
  user: UserProfileDto;
  message: string;

  role: string;
  roles: Array<PopulationRoleDto>

}
@Component({
  selector: 'app-membership-invitation',
  templateUrl: './membership-invitation.component.html',
  styleUrls: ['./membership-invitation.component.scss']
})
export class MembershipInvitationComponent implements OnInit {

  public newUser: UserProfileDto | undefined;
  public message: string | undefined;
  constructor(
    public dialogRef: MatDialogRef<MembershipInvitationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewUserInvitation,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.newUser = this.data.user;
    this.message = this.data.message;
  }

  submit() {
    this.data.user.username = this.data.user.email;
    this.dialogRef.close(this.data);
  }

  validString(s: string | undefined): boolean {
    return s !== undefined && s !== null && s.length > 0;
  }

  inviteDisabled(): boolean {
    return this.validString(this.data.user.firstName) && this.validString(this.data.user.lastName) && this.validString(this.data.user.email);
  }
}
