<h1 mat-dialog-title>Image Search</h1>
<div *ngIf="!images">
  <h2 mat-dialog-title>No Images Yet. Annotate new images from encounters.</h2>
</div>
<div mat-dialog-content class="dialog-content-column" *ngIf="images">
  <div class="animal-image" *ngFor="let image of images"
        [matTooltip]="getTooltipContent(image)"
        [matTooltipPosition]="'after'"
        matTooltipClass="nl-tooltip"
        (click)="select(image)"
        [ngClass]="{'selected': image.active}">
    <div class="animal-image-container" *ngIf="image.extractedImageObs | async as i; else loading">
      <img [src]="i.uri" [alt]="data.profile.identifier" />
    </div>
  </div>

</div>
<div mat-dialog-actions class="buttons">
  <button mat-stroked-button (click)="onNoClick()">Close</button>
  <button *ngIf="hasSelected" mat-stroked-button (click)="onUpdate()">Update</button>
</div>
<ng-template #loading>
  <app-gallery-preview-loading-image></app-gallery-preview-loading-image>
</ng-template>
