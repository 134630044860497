import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {
  PopulationCreationApplicationDto,
  PopulationCreationApplicationUpdate
} from "../../models/dto/population/populationCreationApplicationDto";

@Injectable({
  providedIn: 'root'
})
export class PopulationManagementService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public createApplication(dto: PopulationCreationApplicationDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}`;
    return this.httpClient.post(url, dto);
  }
  public updateApplication(dto: PopulationCreationApplicationUpdate) {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}`;
    return this.httpClient.put(url, dto);
  }

  public getApplication(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}/${id}`;
    return this.httpClient.get(url);
  }

  public getApplications() {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}`;
    return this.httpClient.get(url);
  }

  public getOpenApplications() {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}/open`;
    return this.httpClient.get(url);
  }

  public getClosedApplications() {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}/closed`;
    return this.httpClient.get(url);
  }

  public getApprovedApplications() {
    const url = `${environment.server.baseUrl}${environment.server.api.populationManagement.base}/approved`;
    return this.httpClient.get(url);
  }
}
