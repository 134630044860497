<div class="form-content-container">
  <mat-card class="login-card">
    <mat-card-title><h3>Reset Password</h3></mat-card-title>
    <mat-card-content>
      <form [formGroup]="resetPasswordForm" autocomplete="off" novalidate (ngSubmit)="resetPassword(resetPasswordForm.value)">
        <input hidden="hidden" [value]="email" id="username" />
        <mat-form-field color="accent" class="full-width">
          <mat-label>Password:</mat-label>
          <input matInput placeholder="password" formControlName="password" type="password"/>
          <div *ngIf="hasError('password', 'required')">
            <mat-error>Password is required</mat-error>
          </div>
          <div *ngIf="hasError('password', 'minlength')">
            <mat-error>Password must be at least 8 characters</mat-error>
          </div>
        </mat-form-field>
        <mat-form-field color="accent" class="full-width">
          <mat-label>Confirm Password:</mat-label>
          <input matInput placeholder="confirm" formControlName="confirm" type="password"/>
          <div *ngIf="validateControl('confirm') && hasError('confirm', 'required')">
            <mat-error>Confirmation is required</mat-error>
          </div>
          <div *ngIf="hasError('confirm', 'mustMatch')">
            <mat-error>Passwords must match</mat-error>
          </div>
        </mat-form-field>
        <div class="login-errors" *ngIf="showError">
<!--          <mat-error>{{errorMessage}}</mat-error>-->
          <div class="errors" *ngIf="errors && errors.length > 0">
            <mat-error *ngFor="let error of errors" [innerHTML]="error"></mat-error>
          </div>
        </div>
        <div class="login-errors" *ngIf="showSuccess">
          <h4> Your password has been reset. Please <a mat-button [routerLink]="['/login']"> click here to log in. </a></h4>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="accent" (click)="resetPassword(resetPasswordForm.value)" class="button-with-spinner" [disabled]="!resetPasswordForm.valid" *ngIf="!showSuccess">
        <div class="button-content">
          <span>Reset Password</span>
          <span *ngIf="loading" class="login-spinner-container"><mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2" color="accent"></mat-progress-spinner>
        </span>
        </div>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
