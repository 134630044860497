import {Injectable} from "@angular/core";
import {Item} from "../../../models/items/item.model";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: 'root'
})
export class IItemStorageService {

  constructor(public log: ILoggingService) {}

  storeKeyValue(key: string, value: Item): void {}
  getItem(key: string): Item | null {return null; }

  store(key: string, value: any): void {}

  fetch(key: string): Item | undefined { return undefined; }


  remove(STORAGEKEY: string): void {}
}
