import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {Router} from "@angular/router";
import {CoOccurrenceDto} from "../../../../../models/dto/animal/CoOccurrenceDto";

export interface CoOccurrenceYearlyReport {
  year: number;
  dtos: Array<CoOccurrenceDto>;
}

export interface CoOccurrenceReport {
  reports: Array<CoOccurrenceYearlyReport>
}
@Component({
  selector: 'app-profile-relationship-information',
  templateUrl: './profile-relationship-information.component.html',
  styleUrls: ['./profile-relationship-information.component.scss']
})
export class ProfileRelationshipInformationComponent implements OnInit, OnChanges {
  @Input() profile: AnimalProfileDto | undefined;
  @Input() coOccurrenceCount: number = 3;
  @Input() yearCount = 5;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  public years: Array<number> = []
  public coOccurrenceMap: CoOccurrenceReport | undefined;
  public totalCoOccurrence: Array<CoOccurrenceDto> = new Array<CoOccurrenceDto>();
  public currentYear: number | undefined;
  public editingCoOccurrenceYear: boolean = false;
  constructor(
    private animalService: AnimalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.profile === undefined || this.profile.coOccurrenceReport === undefined) return;
    this.coOccurrenceMap = undefined;
    this.totalCoOccurrence = new Array<CoOccurrenceDto>();
    this.coOccurrenceMap = this.profile.coOccurrenceReport;
    this.years = this.coOccurrenceMap!.reports.map(r => r.year).sort((a, b) => b - a);
    this.currentYear = Math.max(...this.years);

    this.buildTotalOccurrence(JSON.parse(JSON.stringify(this.profile.coOccurrenceReport)));
  }

  private buildTotalOccurrence(rep: CoOccurrenceReport) {
    const animalMap: Map<string, CoOccurrenceDto> = new Map<string, CoOccurrenceDto>();
    for (let values of rep.reports) {
      for (let coOc of values.dtos) {
        if (!animalMap.has(coOc.animal.id)) {
          animalMap.set(coOc.animal.id, coOc)
        } else {
          let existing = animalMap.get(coOc.animal.id);
          existing!.coOccurrence += coOc.coOccurrence;
          animalMap.set(coOc.animal.id, existing!)
        }
      }
    }

    this.totalCoOccurrence = Array.from(animalMap.values()).sort( (a, b) => b.coOccurrence - a.coOccurrence);
  }

  public getSortedOccurrences(year: number) {
    let yearValues = this.coOccurrenceMap?.reports.find(e => e.year == year);
    if (!yearValues) return;
    return yearValues.dtos.sort((a, b) => b.coOccurrence - a.coOccurrence);
  }

  private setLoaded() {
    this.loaded.emit(true);
  }

  getRouterLink(coOc: CoOccurrenceDto) {
    return `/individuals/${coOc.animal.id}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile.previousValue !== undefined || changes.profile.previousValue?.encounters !== undefined && (changes.profile.previousValue?.encounters !== changes.profile.currentValue?.encounters)) {
      this.ngOnInit();
    }
  }
}
