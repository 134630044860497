import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EncounterDto} from "../../../../models/dto/encounter/encounterDto";
import {DateService} from "../../../../services/utilities/date.service";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {merge, Observable, of} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ImageService} from "../../../../services/utilities/image.service";
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-encounter-timeline',
  templateUrl: './encounter-timeline.component.html',
  styleUrls: ['./encounter-timeline.component.scss']
})
export class EncounterTimelineComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() encounters: Array<EncounterDto> | undefined;
  @Input() organizationId: string | undefined;
  @Input() userId: string | undefined;
  @Input() title: string | undefined;
  @Input() animalId: string | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public encountersObs: Observable<Array<EncounterDto>> | undefined;
  public displayedColumns: string[] = ["date", "location", "stats", "organization", "user"];
  public resultsLength = 0;
  isLoadingResults = true;
  public data: Array<EncounterDto> = []

  constructor(
    private dateService: DateService,
    private encounterService: EncountersService,
    public imageService: ImageService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.ngOnInit();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.initialize();
  }

  public initialize() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          if (this.organizationId) {
            return this.encounterService
              .getEncountersForOrganization(this.organizationId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction)
              .pipe(catchError(() => of(null)))
          } else if (this.animalId) {
            return this.encounterService
              .getEncountersForAnimal(this.animalId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction)
              .pipe(catchError(() => of(null)))
          } else if (this.userId) {
            return this.encounterService
              .getEncountersForUser(this.userId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction)
              .pipe(catchError(() => of(null)))
          } else if (this.encounters) {
            return of({totalCount: this.encounters.length, encounters: this.encounters})
              .pipe(catchError(() => of(null)))
          }
          return of({totalCount: 0, encounters: []}).pipe(catchError(() => of(null)));


        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.totalCount ?? 0;
          return data.encounters;
        }),
      )
      .subscribe(data => (this.data = data ?? []));
  }

  ngOnInit(): void {

  }

  formatDate(d: any) {
    return this.dateService.formatDateFromAny(d, false)
  }

  getEncounterLink(entry: EncounterDto) {
    return `/encounters/${entry.id}`;
  }

  getAnimalList(animals: Array<{ identifier: string; id: string }>) {
    return animals.map(e => e.identifier).join(", ");
  }

  getUserLink(user: any) {
    return `/users/profile/${user.id}`
  }

  getOrgLink(org: any) {
    return `/organization/profile/${org.id}`;
  }
}
