import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AnnotateComponent} from "./annotate/annotate.component";

import {IImageService} from "../../../services/files/images/image.service.interface";
import {AnnotatedImage} from "../../../models/items/files/images/annotated-images/annotated-image.model";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {IFileStorageService} from "../../../services/storage/file/file-storage.service.interface";

import {Observable, Subscription} from "rxjs";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import {WebFileService} from "../../../services/files/web-file.service";
import {ImageAnnotation} from "../../../models/annotation/image/annotation.model";
import {PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {AnnotationService} from "../../../services/annotation/annotation.service";
import {ResponseDto} from "../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-image-annotation',
  templateUrl: './image-annotation.component.html',
  styleUrls: ['./image-annotation.component.scss']
})
export class ImageAnnotationComponent implements OnInit, OnDestroy {
  public files: Array<IFileItem> | undefined = [];
  public image: AnnotatedImage | undefined;
  public population: PopulationDto | undefined;
  public saving = false;

  @Input() fileItem: IFileItem | undefined;
  @Input() user: UserProfileDto | undefined;
  @Input() tab: number = 0;
  @Output() annotationUpdated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationRemoved: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationConfirmed: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationCreated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();

  @ViewChild(AnnotateComponent) annotateComponent!: AnnotateComponent;

  private subs: Array<Subscription> = [];
  constructor(
    private storageService: IFileStorageService,
    private imageService: IImageService,
    private log: ILoggingService,
    private webFileService: WebFileService,
    private populationService: PopulationService,
    private annotationService: AnnotationService
  ) { }

  ngOnInit(): void {
    const sub = this.populationService.population.subscribe(res => this.population = res);
    this.subs.push(sub)
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }


  updateContext(file: IFileItem) {
    this.saving = true;
    this.webFileService.createAnnotations(file, this.population?.id!).subscribe(res => {
      this.saving = false;
    })
    this.storageService.update(file);
  }

  updateAnnotation(annotation: ImageAnnotation) {
    this.annotationUpdated.emit(annotation);
  }

  removeAnnotation(annotation: ImageAnnotation) {
    this.saving = true;
    this.annotationRemoved.emit(annotation);
    this.webFileService
      .deleteAnnotation(annotation)
      .subscribe(res => {
        this.saving = false;
    })
  }

  confirmAnnotation(annotation: ImageAnnotation) {
    this.annotationConfirmed.emit(annotation);
  }

  createAnnotation(annotation: ImageAnnotation) {
    this.saving = true;
    this.annotationService.createAnnotation(annotation).subscribe({
      next: (val: ResponseDto) => {
        if (val.successful) {
          this.log.info(`Annotation for ${annotation.tag} successfully created`);
        } else {
          this.log.error(`Could not create annotation: ${val.errorMessages.join(', ')}`)
        }
        this.saving = false;
      }, error: (err: HttpErrorResponse) => {
        this.log.error(`Failed to create annotation: ${err.message}`);
        this.saving = false;
      }
    })
  }
}
