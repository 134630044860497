import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FileItem, IFileItem} from "../../models/items/files/file-item.model";
import {FileUploadResponse} from "../../models/response/file/file-upload.response.model";
import {flatMap, map, mergeMap, Observable} from "rxjs";
import {resetFakeAsyncZone} from "@angular/core/testing";
import {ImageAnnotation} from "../../models/annotation/image/annotation.model";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebFileService {

  constructor(private httpClient: HttpClient) {}


  updateLocation(fileItem: IFileItem): Observable<any> {

    const url = `${environment.server.baseUrl}${environment.server.api.location}`;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(url, JSON.stringify(fileItem), headers);
  }

  createAnnotations(fileItem: FileItem, populationId: string): Observable<any> {
    const item: FileItem = JSON.parse(JSON.stringify(fileItem));
    item.image = undefined;
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.create}/${populationId}`
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(url, JSON.stringify(item), headers);
  }

  deleteAnnotation(annotation: ImageAnnotation): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.delete}/${annotation.id}`;
    return this.httpClient.delete(url);
  }

  confirmAnnotation(annotation: ImageAnnotation, populationId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.confirm}/${populationId}`;
    return this.httpClient.post(url, annotation);
  }
  updateAnnotation(annotation: ImageAnnotation, populationId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.update}/${populationId}`;
    return this.httpClient.put(url, annotation);
  }


}
