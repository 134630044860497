import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AnimalBehaviorDto} from "../../models/dto/behavior/animalBehaviorDto";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BehaviorService {

  constructor(private http: HttpClient) { }

  public getEncounterBehaviors(encounterId: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.encounter}/${encounterId}`;
    return this.http.get(url);
  }

  public getPopulationBehaviors(populationId: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.population}/${populationId}`;
    return this.http.get(url);
  }

  public getBehavior(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.base}/${id}`;
    return this.http.get(url);
  }

  public createEncounterBehavior(encounterId: string, populationId: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.encounter}/${encounterId}/${populationId}`;
    return this.http.get(url);
  }

  public createBehavior(behavior: AnimalBehaviorDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.base}`;
    return this.http.post(url, behavior);
  }

  public updateBehavior(behavior: AnimalBehaviorDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.base}`;
    return this.http.put(url, behavior);
  }

  public deleteBehavior(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.base}/${id}`;
    return this.http.delete(url);
  }

  public deleteEncounterBehavior(encounterId: string, behaviorId: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.behavior.encounter}/${encounterId}/${behaviorId}`;
    return this.http.delete(url);
  }


}
