import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {environment} from "../../../environments/environment";
import {NavigationEntry} from "../../../models/navigation/entry";
import {PopulationRoleSet, PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {AuthenticationService} from "../../../services/user/authentication.service";
import {Router} from "@angular/router";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {WorkspaceService} from "../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../models/dto/workspace/workspaceDto";
import {StyleManagerService} from "../../../services/style-manager/style-manager.service";
import {MatSidenav} from "@angular/material/sidenav";
import {NotificationDto} from "../../../models/dto/notification/notificationDto";
import {
  NotificationDropdownComponent
} from "../notifications/notification-center/notification-dropdown/notification-dropdown.component";
import {ResponsiveDesignService} from "../../../services/design/responsive-design.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() inputSideNav?: MatSidenav;
  @Output() notificationsFetched: EventEmitter<Array<NotificationDto>> = new EventEmitter<Array<NotificationDto>>();
  @Output() populationChanged: EventEmitter<PopulationDto> = new EventEmitter<PopulationDto>();
  @ViewChild(NotificationDropdownComponent) notificationComponent!: NotificationDropdownComponent;
  title?: string;
  isDark?: boolean = true;
  public drawerContent = 'population';
  public drawerPosition: 'start' | 'end' = 'start'
  public primaryEntries: Array<NavigationEntry> = [];
  public secondaryEntries: Array<NavigationEntry> = [];
  public alternateEntries: Array<NavigationEntry> = []
  public userMenuEntries: Array<NavigationEntry> = []

  public population?: PopulationDto;
  public populationRights?: PopulationRoleSet;
  public user?: UserProfileDto;
  private workspace?: WorkspaceDto
  public logo = environment.darkLogo

  public mobileMenu = false;

  public loading = false;


  constructor(
    private populationService: PopulationService,
    public authService: AuthenticationService,

    private router: Router,
    private workspaceService: WorkspaceService,
    private styleManager: StyleManagerService,
    private designService: ResponsiveDesignService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.title = environment.appName
    this.workspaceService.workspace.subscribe(res => {
      if (res && Object.keys(res).length > 0) {
        this.workspace = res;
        this.user = this.workspace.settings!.user;
        this.population = this.workspace.settings!.population;
        this.populationRights = this.workspace.settings!.populationRoles;
        this.getLinks();
        this.loading = false;
      }
    });
    this.styleManager.isDarkObs.subscribe( res => {
      if (res) {
        this.logo = environment.darkLogo;
      } else {
        this.logo = environment.lightLogo;
      }
      this.isDark = res;
    })

    this.designService.mobileOrTablet.subscribe(res => {
      this.mobileMenu = res;
    })

  }

  toggleTheme() {
    this.styleManager.toggleDarkTheme();
    this.isDark = !this.isDark;
  }

  private resetLinks() {
    this.primaryEntries = [];
    this.secondaryEntries = [];
    this.alternateEntries = [];
    this.userMenuEntries = [];
  }

  private getLinks() {
    this.resetLinks();

    const menuEntries = [
      new NavigationEntry("/profile", "Profile", "person"),
      new NavigationEntry("/administration", "Administration", "corporate_fare")
    ];
    this.userMenuEntries.push(...menuEntries);
    this.addViewer();
    if (this.populationRights && this.populationRights.administrator || this.populationRights?.professional || this.populationRights?.expert) {

      this.addExport();
    }
    //this.addOrganization();

  }
  private addExport() {
    const mainEntries = [
      new NavigationEntry("/export", "Export Data", "file_download")
    ];
    this.secondaryEntries.push(...mainEntries);

  }

  private addViewer() {
    if (!this.population) return;
    const mainEntries = [
      new NavigationEntry("/submit", "Submit Data", "publish"),
      // new NavigationEntry("/encounters", "Search", "search"),
      new NavigationEntry("/individuals", "Individuals", "image_search"),
      new NavigationEntry("/encounters", "Encounters", "photo_library"),
    ]
    this.primaryEntries.push(...mainEntries);

  }

  private addOrganization() {
    const mainEntries = [
      new NavigationEntry("/organizations", "Organizations", "organization_list")
    ]
    this.primaryEntries.push(...mainEntries);
  }

  private addLogin() {
    this.alternateEntries.push(new NavigationEntry("/login", "Log In", "login"));
  }

  public logout = () => {
    this.authService.logout();
    this.resetLinks();
    this.router.navigate(["/"]);
  }

  navigate(route: string) {
    this.router.navigate([route])
  }

  public markRead(notification: NotificationDto) {
    this.notificationComponent.markRead(notification)
  }

  updateWorkspace() {

  }

  switchWorkspace($event: PopulationDto) {
    this.populationChanged.emit($event);
  }
}
