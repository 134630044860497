import {Component, Inject, OnInit} from '@angular/core';
import {AnnotatedImage} from "../../../../../models/items/files/images/annotated-images/annotated-image.model";
import {ImageAnnotation} from "../../../../../models/annotation/image/annotation.model";
import {AnimalDto} from "../../../../../models/dto/animal/animalDto";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";


export interface DialogData {
  orcaTag: string;
  image: AnnotatedImage,
  suggestions: Array<AnimalDto>,
  animals?: Array<AnimalDto>
}

@Component({
  selector: 'annotate-dialog',
  templateUrl: 'annotate-dialog.component.html',
  styleUrls: ['./annotate-dialog.component.scss']
})
export class AnnotateDialogComponent {
  orcaTag: string = "";

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(AnnotateDialogContentComponent, {
      width: '250px',
      data: {orcaTag: this.orcaTag},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.orcaTag = result;
    });
  }
}

@Component({
  selector: 'annotate-dialog-content',
  templateUrl: 'annotate-dialog-content.component.html',
})
export class AnnotateDialogContentComponent implements OnInit{
  private readonly enterKeyCode = 13;
  public suggestedTags: Array<string> = [];

  idControl = new FormControl<string | AnimalDto>('');
  filteredOptions?: Observable<AnimalDto[]>;
  public newId: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AnnotateDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  displayFn(user: AnimalDto): string {
    return user && user.identifier ? user.identifier : '';
  }

  private _filter(name: string): AnimalDto[] {
    const filterValue = name.toLowerCase();

    return this.data.animals!.filter(option => option.identifier.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
      const tags: Array<string> = [];

    this.filteredOptions = this.idControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.identifier;
        return name ? this._filter(name as string) : this.data.animals!.slice();
      }),
    );
      for(let item of this.data.suggestions) {
        tags.push(item.identifier);
      }

      if (this.data.image !== undefined && this.data.image.metaData.caption) {
        // tags.push(...this.data.image.metaData.caption.split(" "));

        if (this.data.image.annotations !== undefined && this.data.image.annotations.length > 0) {
          this.data.image.annotations.forEach( (a: ImageAnnotation) => {

            if (a.tag !== undefined && tags.indexOf(a.tag) !== -1) {
              tags.splice(tags.indexOf(a.tag), 1);
            }
          })
        }
      }
      this.suggestedTags = [...new Set(tags)];

    }

  onNoClick(): void {
    this.dialogRef.close();
  }



  enterSubmit($event: any): void {
    if ($event.keyCode === this.enterKeyCode) {
      this.dialogRef.close(this.data.orcaTag);
    }
  }

  chooseSuggested(tag: string) {

    const animal = this.data.animals?.find(a => a.identifier == tag);
    if (animal !== undefined) {
      this.idControl.setValue(animal);
    }
    this.data.orcaTag = tag;
  }

  setTag($event: MatAutocompleteSelectedEvent) {
    this.data.orcaTag = $event.option.value.identifier
  }
}
