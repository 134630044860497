import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EncounterDto} from "../../../../../models/dto/encounter/encounterDto";
import {DateService} from "../../../../../services/utilities/date.service";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {LicenseService} from "../../../../../services/license/license.service";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {EncountersService} from "../../../../../services/encounters/encounters.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import { EncounterPageRequestDto } from '../../../../../models/dto/encounter/encounterPageRequestDto';

@Component({
  selector: 'app-encounter-page-sidebar',
  templateUrl: './encounter-page-sidebar.component.html',
  styleUrls: ['./encounter-page-sidebar.component.scss']
})
export class EncounterPageSidebarComponent implements OnInit, OnChanges {
  @Input() dto: EncounterDto | undefined;
  @Input() workspace: WorkspaceDto | undefined;
  @Input() request: EncounterPageRequestDto | undefined;
  public internalActive  = false;
  private subs = new Array<Subscription>();
  public nextEnc: EncounterDto | undefined;
  public prevEnc: EncounterDto | undefined;
  constructor(
    private dateService: DateService,
    public animalService: AnimalService,
    private licenseService: LicenseService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private encounterService: EncountersService,
    private log: ILoggingService,

    ) { }

  ngOnInit(): void {
    if (this.workspace && this.workspace.settings && this.workspace.settings.populationRoles) {
      if (this.workspace.settings.populationRoles.professional || this.workspace.settings.populationRoles.administrator) {
        this.internalActive = true;
      }
    }
    // this.loadnextEncounter();
    // this.loadpreviousEncounter();
  }

  public formatDate(date: Date | any) {
    return this.dateService.formatDateFromAny(date, false, true, true, true, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  getAnimalLink(animal: { identifier: string; id: string }) {
    return `/individuals/${animal.id}`;
  }

  getLicenseLogos() {
    if (this.dto && this.dto.license) {
      return this.licenseService.getLogosForLicense(this.dto?.license)
    }
    return [];
  }

  getUserLink(user: any) {
    return `/users/profile/${user.id}`;
  }

  getOrgLink(org: any) {
    return `/organization/profile/${org.id}`;
  }

  loadpreviousEncounter() {
    if (this.request != undefined) {
      this.subs.push(this.encounterService.getPreviousEncounterWithRequest(this.dto!.id!, this.request!).subscribe({
        next: (value: EncounterDto) => {
          this.prevEnc = value;
        },
        error: (value: HttpErrorResponse) => {
          if (value.status == 401) {
            this.log.error(`You are unauthorized`)
          }

          if (value.status == 400) {
            this.log.error(`This encounter does not exist`)
          }
        }
      }));
    }
    else {
      this.subs.push(this.encounterService.getPreviousEncounter(this.dto!.id!).subscribe({
        next: (value: EncounterDto) => {
          this.prevEnc = value;
        },
        error: (value: HttpErrorResponse) => {
          if (value.status == 401) {
            this.log.error(`You are unauthorized`)
          }

          if (value.status == 400) {
            this.log.error(`This encounter does not exist`)
          }
        }
      }));
    }

  }
  loadnextEncounter() {
    if (this.request != undefined) {
      this.subs.push(this.encounterService.getNextEncounterWithRequest(this.dto!.id!, this.request!).subscribe({
        next: (value: EncounterDto) => {
          this.nextEnc = value;
        },
        error: (value: HttpErrorResponse) => {
          if (value.status == 401) {
            this.log.error(`You are unauthorized`)
          }

          if (value.status == 400) {
            this.log.error(`This encounter does not exist`)
          }
        }
      }));
    }
    else {
      this.subs.push(this.encounterService.getNextEncounter(this.dto!.id!).subscribe({
        next: (value: EncounterDto) => {
          this.nextEnc = value;
        },
        error: (value: HttpErrorResponse) => {
          if (value.status == 401) {
            this.log.error(`You are unauthorized`)
          }

          if (value.status == 400) {
            this.log.error(`This encounter does not exist`)
          }
        }
      }));
    }

  }

  previousEncounter() {
    if (this.request != undefined) {
      this.router.navigate([`/encounters/${this.prevEnc!.id}`], { queryParams: { request: JSON.stringify(this.request) } });
    }
    else {
      this.router.navigate([`/encounters/${this.prevEnc!.id}`]);
    }

  }

  nextEncounter() {
    if (this.request != undefined) {
      this.router.navigate([`/encounters/${this.nextEnc!.id}`], { queryParams: { request: JSON.stringify(this.request) } });
    }
    else {
      this.router.navigate([`/encounters/${this.nextEnc!.id}`]);
    }

  }
}
