<div class="section-header">
  <h3>Members</h3>
  <span class="spacer"></span>
  <button mat-stroked-button color="accent" (click)="openMemberAddition()"><mat-icon>person</mat-icon>Add Member</button>
</div>
<div *ngIf="populationMembers; else loading">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let member of populationMembers" class="population-member">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-header">
            {{member.userProfileDto?.firstName}} {{member.userProfileDto?.lastName}}
          </div>
          <div class="title-subheader">
            <span class="role" *ngIf="member.roleName !== 'Novice'"> ({{member.roleName}})</span>
            <span class="new-role-status" *ngIf="!member.confirmed">New Member
                      <span class="member-since" *ngIf="member.memberSince !== undefined">
                         (Since {{getDateString(member.memberSince)}})
                      </span>
                    </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="population-user-content">
        <h4 *ngIf="member.memberSince !== undefined">Member Since: {{getDateString(member.memberSince)}}</h4>
        <h4><a [routerLink]="['/users', member.userProfileDto!.id!]">Profile</a></h4>
      </div>
      <mat-divider></mat-divider>
      <div class="population-user-role-update" *ngIf="populationRights !== undefined && (populationRights.professional  || populationRights.administrator)">
        <div class="population-user-confirm" *ngIf="!member.confirmed">
          <button mat-stroked-button color="accent" (click)="confirmPopulationMember(member)">Confirm {{member.userProfileDto!.firstName}} {{member.userProfileDto!.lastName}}</button>
        </div>

        <div class="population-use-role-change" *ngIf="member.confirmed">
          <h3>Update User Role</h3>
          <mat-radio-group
            class="role-radio-group"
            [(ngModel)]="member.roleName"
            (ngModelChange)="member.roleUpdated = true"
            *ngIf="populationRoles as roles"
          >
            <mat-radio-button class="example-radio-button" *ngFor="let role of roles" [value]="role.normalizedName">
              {{role.name}}
            </mat-radio-button>
          </mat-radio-group>
          <button mat-stroked-button color=accent *ngIf="member.roleUpdated" (click)="updateRole(member)">Change Role to {{member.roleName}}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #loading>
  <h4>Getting population members...</h4>
</ng-template>
