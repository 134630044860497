import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from '@angular/core';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commentElementData } from "../../../../../models/dto/comments/commentElement";



import { CommentSectionDto } from '../../../../../models/dto/comments/CommentSectionDto';
import { NewCommentDto } from '../../../../../models/dto/comments/NewCommentDto';
import { ResponseDto } from '../../../../../models/dto/response/responseDto';
import { UserImageDto } from '../../../../../models/dto/user/userImageDto';
import { CommentService } from '../../../../../services/comments/comment.service';
import { ErrorHandlerService } from '../../../../../services/error/error-handler.service';
import { ILoggingService } from '../../../../../services/logging/logging.service.interface';
import { ImageService } from '../../../../../services/utilities/image.service';
import {EncounterDto} from "../../../../../models/dto/encounter/encounterDto";
@Component({
  selector: 'app-encounter-comments',
  templateUrl: './encounter-comments.component.html',
  styleUrls: ['./encounter-comments.component.scss']
})
export class EncounterCommentsComponent implements OnInit {
  @Input() commentSection!: CommentSectionDto;
  @Input() encounter?: EncounterDto

  // @ts-ignore
  public Editor = ClassicEditor as { create: any; };
  public content = "";

  public editing = false;
  public layer: number;
  public hasComments: boolean = false;
  //public commentTree: Array<commentElementData> = [];

  public isLoggedIn = false;
  public loadedUser = false;
  public UserID = "";
  public UserImage = "";
  public EditorInit = "";
  constructor(
    private _log: ILoggingService,
    private commentService: CommentService,
    private imageService: ImageService,
    private errorHandler: ErrorHandlerService
  ) {
    this.layer = 0;

  }

  ngOnInit(): void {
    this.commentService.getUser().subscribe({
      next: (value: UserImageDto) => { this.loadedUser = true; this.isLoggedIn = true; this.UserID = value.userId; this.UserImage = value.image },
      error: (err: any) => { this.loadedUser = true; this.isLoggedIn = false; }
    })
    if (this.commentSection.root != undefined && this.commentSection.root.length > 0) {
      this.hasComments = true;
    }
  }

  log($event: ChangeEvent) {
    this.content = $event.editor.data.get();

  }


  openEditor(): void {
    this.editing = true;
  }

  closeEditor(): void {
    this.editing = false;
  }

  loadComments() {
    if (this.encounter && this.encounter.id) {
      this.commentService.getCommentSection(this.encounter.id).subscribe({
        next: (value: CommentSectionDto) => {
          this.commentSection = value;
        }
      });
    }
  }

  addComment() {
    // @ts-ignore
    this.editing = false;
    this.hasComments = true;
    const commentdata: NewCommentDto = {
      commentSectionID: this.commentSection.id,
      userID: this.UserID,
      content: this.content,
      responseID: "",
      postDateTime: new Date()
    };

    this.commentService.addComment(commentdata).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this._log.error(`Comment could not be added. : ${response.errorMessages.join(', ')}`, true);
        }
        else {
          this._log.info(`Comment added.`, true);
          this.loadComments();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Add Comment", err);
      }
    });
  }


}

