import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";
import {DetectionSet} from "../../../models/detection/detection-set.model";
import {FileItem, IFileItem} from "../../../models/items/files/file-item.model";
import {v4 as uuidv4} from "uuid";
import {ILoggingService} from "../../logging/logging.service.interface";


@Injectable({
  providedIn: 'root'
})
export class LocalDetectionFileService {
  constructor(public log: ILoggingService) {}
  public folder = "assets/test/detections_test/"
  items = new BehaviorSubject<Array<IFileItem>>([]);

  getItems(path: string) {
    const images: Array<IFileItem> = this.files.filter(value => value.indexOf(".txt") === -1).map(file => {
      return new FileItem(`${this.folder}${file}`)
    })
    this.items.next(images);
  }

  getDetectionForFile(file: IFileItem) {
    const selection = this.files.filter( value => value.split(".")[0] === file.name.split(".")[0])[1]
    return `${this.folder}${selection}`
  }

  private files = [
    "KWT_2011-01-21_MMalleson_ConstanceBank_0033.png",
    "KWT_2011-01-21_MMalleson_ConstanceBank_0033.txt",
    "KWT_2011-02-05_CMcMillan_MalcolmPoint_2036.png",
    "KWT_2011-02-05_CMcMillan_MalcolmPoint_2036.txt",
    "KWT_2011-03-03_GEllis_GabriolaIsland_2745.png",
    "KWT_2011-03-03_GEllis_GabriolaIsland_2745.txt",
    "KWT_2011-03-03_GEllis_GabriolaIsland_2868.png",
    "KWT_2011-03-03_GEllis_GabriolaIsland_2868.txt",
    "KWT_2011-03-03_GEllis_GabriolaIsland_2901.png",
    "KWT_2011-03-03_GEllis_GabriolaIsland_2901.txt",
    "KWT_2011-03-05_JTowers_BeaverCove_5682.png",
    "KWT_2011-03-05_JTowers_BeaverCove_5682.txt",
    "KWT_2011-03-05_JTowers_BeaverCove_5686.png",
    "KWT_2011-03-05_JTowers_BeaverCove_5686.txt",
    "KWT_2011-03-22_BGisborne_JunctionPass_2327.png",
    "KWT_2011-03-22_BGisborne_JunctionPass_2327.txt",
    "KWT_2011-03-23_BGisborne_ForbesIsland_2464.png",
    "KWT_2011-03-23_BGisborne_ForbesIsland_2464.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_49_2.png",
    "KWT_2011-04-06_LRobinson_Blinkhorn_49_2.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_52_2.png",
    "KWT_2011-04-06_LRobinson_Blinkhorn_52_2.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_53_2.png",
    "KWT_2011-04-06_LRobinson_Blinkhorn_53_2.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_75_2.png",
    "KWT_2011-04-06_LRobinson_Blinkhorn_75_2.txt",
    "KWT_2011-04-06_MFournier_Blinkhorn_8559.png",
    "KWT_2011-04-06_MFournier_Blinkhorn_8559.txt",
    "KWT_2011-04-06_MFournier_Blinkhorn_8703.png",
    "KWT_2011-04-06_MFournier_Blinkhorn_8703.txt",
    "KWT_2011-05-16_MFournier_JohnstoneStrait_0826.png",
    "KWT_2011-05-16_MFournier_JohnstoneStrait_0826.txt",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366.png",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366.txt",
    "KWT_2011-05-25_GEllis_MistakenIsland_4007.png",
    "KWT_2011-05-25_GEllis_MistakenIsland_4007.txt",
    "KWT_2011-07-10_GEllis_NorthumberlandChannel_5783.png",
    "KWT_2011-07-10_GEllis_NorthumberlandChannel_5783.txt",
    "KWT_2011-07-22_DMatkin_BeardsleeSEAK_022.png",
    "KWT_2011-07-22_DMatkin_BeardsleeSEAK_022.txt",
    "KWT_2011-07-29_MGreenfelder_TracyArmSEAK_039.png",
    "KWT_2011-07-29_MGreenfelder_TracyArmSEAK_039.txt",
    "KWT_2011-09-01_GEllis_CapeJames_6546.png",
    "KWT_2011-09-01_GEllis_CapeJames_6546.txt",
    "KWT_2011_08-24_JTowers_CormorantChannel_3485.png",
    "KWT_2011_08-24_JTowers_CormorantChannel_3485.txt",
    "KWT_2014-02-03_JFord_DepartureBay_1698.png",
    "KWT_2014-02-03_JFord_DepartureBay_1698.txt",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493.png",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5105.png",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5105.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128.png",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_08.png",
    "KWT_2015-03-28_PSchulze_FortuneChannel_08.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_09.png",
    "KWT_2015-03-28_PSchulze_FortuneChannel_09.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_10.png",
    "KWT_2015-03-28_PSchulze_FortuneChannel_10.txt",
    "KWT_2015-05-07_NSinclair_WhiteRockPassage_9597.png",
    "KWT_2015-05-07_NSinclair_WhiteRockPassage_9597.txt",
    "KWT_2015-05-07_NSinclair_WhiteRockPassage_9603.png",
    "KWT_2015-05-07_NSinclair_WhiteRockPassage_9603.txt",
    "KWT_2015-05-09_MMalleson_OakBay_9159.png",
    "KWT_2015-05-09_MMalleson_OakBay_9159.txt",
    "KWT_2015-05-25_EAckermann_OakBay_3255.png",
    "KWT_2015-05-25_EAckermann_OakBay_3255.txt",
    "KWT_2015-08-08_JTowers_PearceIslands_4176.png",
    "KWT_2015-08-08_JTowers_PearceIslands_4176.txt",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006.png",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006.txt",
    "KWT_2015-08-19_SPariak_CleoIsland_0354.png",
    "KWT_2015-08-19_SPariak_CleoIsland_0354.txt",
    "KWT_2015-08-19_SPariak_CleoIsland_0360.png",
    "KWT_2015-08-19_SPariak_CleoIsland_0360.txt",
    "KWT_2015-08-25_SParniak_BrokenGroup_0004.png",
    "KWT_2015-08-25_SParniak_BrokenGroup_0004.txt",
    "KWT_2015-09-04_JTowers_CormorantChannel_1682.png",
    "KWT_2015-09-04_JTowers_CormorantChannel_1682.txt",
    "KWT_2015-10-02_CMcMillan_BlackneyPass_7864.png",
    "KWT_2015-10-02_CMcMillan_BlackneyPass_7864.txt",
    "KWT_2015-10-11_AStroeder_CalmChannelButeInlet_0816.png",
    "KWT_2015-10-11_AStroeder_CalmChannelButeInlet_0816.txt",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9602.png",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9602.txt",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9608.png",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9608.txt",
    "KWT_2016-02-01_MMalleson_RacePass_9710.png",
    "KWT_2016-02-01_MMalleson_RacePass_9710.txt",
    "KWT_2016-02-01_MMalleson_RacePass_9758.png",
    "KWT_2016-02-01_MMalleson_RacePass_9758.txt",
    "KWT_2016-04-07_GSutton_SidneyChannel_1246.png",
    "KWT_2016-04-07_GSutton_SidneyChannel_1246.txt",
    "KWT_2016-04-18_MMalleson_EastConstanceBank_1782.png",
    "KWT_2016-04-18_MMalleson_EastConstanceBank_1782.txt",
    "KWT_2016-12-30_DEllifrit_SpiedenIslandWA_075.png",
    "KWT_2016-12-30_DEllifrit_SpiedenIslandWA_075.txt",
    "KWT_2017-01-26_MMalleson_WilliamHead_032.png",
    "KWT_2017-01-26_MMalleson_WilliamHead_032.txt",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043.png",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043.txt",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_043.png",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_043.txt",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_063.png",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_063.txt",
    "KWT_2017-03-20_MMalleson_SouthDiscoveryIsland_019.png",
    "KWT_2017-03-20_MMalleson_SouthDiscoveryIsland_019.txt",
    "KWT_2017-04-13_TShaw_StraitofGeorgia_0659.png",
    "KWT_2017-04-13_TShaw_StraitofGeorgia_0659.txt",
    "KWT_2017-04-29_GSutton_BelleChainIslets_0105.png",
    "KWT_2017-04-29_GSutton_BelleChainIslets_0105.txt",
    "KWT_2017-05-08_MMalleson_WilliamHead_9663.png",
    "KWT_2017-05-08_MMalleson_WilliamHead_9663.txt",
    "KWT_2017-05-08_MMalleson_WilliamHead_9668.png",
    "KWT_2017-05-08_MMalleson_WilliamHead_9668.txt",
    "KWT_2017-05-19_MMalleson_SecretaryIsland_9838.png",
    "KWT_2017-05-19_MMalleson_SecretaryIsland_9838.txt",
    "KWT_2017-05-22_RMcDonell_CampbellRiver_002.png",
    "KWT_2017-05-22_RMcDonell_CampbellRiver_002.txt",
    "KWT_2017-06-09_GSutton_StraitofGeorgia_0493.png",
    "KWT_2017-06-09_GSutton_StraitofGeorgia_0493.txt",
    "KWT_2017-06-14_MMalleson_BeecheyHead_1104.png",
    "KWT_2017-06-14_MMalleson_BeecheyHead_1104.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2585.png",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2585.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2607.png",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2607.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2630.png",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2630.txt",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71595.png",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71595.txt",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71610.png",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71610.txt",
    "KWT_2017-07-11_JForde_TreeIsland_002.png",
    "KWT_2017-07-11_JForde_TreeIsland_002.txt",
    "KWT_2017-07-20_GSutton_TumboIsland_0727.png",
    "KWT_2017-07-20_GSutton_TumboIsland_0727.txt",
    "KWT_2017-08-09_JForde_BartlettIsland_2791.png",
    "KWT_2017-08-09_JForde_BartlettIsland_2791.txt",
    "KWT_2017-08-14_DMatkin_BartlettCoveSEAK_013.png",
    "KWT_2017-08-14_DMatkin_BartlettCoveSEAK_013.txt",
    "KWT_2017-08-20_BGisborne_CarmanahPoint_3063.png",
    "KWT_2017-08-20_BGisborne_CarmanahPoint_3063.txt",
    "KWT_2017-08-23_MMalleson_CoyoteBankWA_4631.png",
    "KWT_2017-08-23_MMalleson_CoyoteBankWA_4631.txt",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453.png",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453.txt",
    "KWT_2017-09-01_MMalleson_VGBuoy_4961.png",
    "KWT_2017-09-01_MMalleson_VGBuoy_4961.txt",
    "KWT_2017-09-14_JTowers_WeyntonPass_8593.png",
    "KWT_2017-09-14_JTowers_WeyntonPass_8593.txt",
    "KWT_2017-09-22_MMalleson_JohnstoneReef_7293.png",
    "KWT_2017-09-22_MMalleson_JohnstoneReef_7293.txt",
    "KWT_2017-09-25_MMalleson_BecherBay_8004.png",
    "KWT_2017-09-25_MMalleson_BecherBay_8004.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8944.png",
    "KWT_2017-10-02_MMalleson_Sheringham_8944.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8949.png",
    "KWT_2017-10-02_MMalleson_Sheringham_8949.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8950.png",
    "KWT_2017-10-02_MMalleson_Sheringham_8950.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8954.png",
    "KWT_2017-10-02_MMalleson_Sheringham_8954.txt",
    "KWT_2017-10-09_MMalleson_JuandeFucaStrait_9307.png",
    "KWT_2017-10-09_MMalleson_JuandeFucaStrait_9307.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3135.png",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3135.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3250.png",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3250.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3307.png",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3307.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3342.png",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3342.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4333.png",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4333.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613.png",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613.txt",
    "KWT_2018-04-08_MMalleson_SwansonChannel_4495.png",
    "KWT_2018-04-08_MMalleson_SwansonChannel_4495.txt",
    "KWT_2018-04-19_MMalleson_KelpReef_5099.png",
    "KWT_2018-04-19_MMalleson_KelpReef_5099.txt",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677.png",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677.txt",
    "KWT_2018-08-01_GSutton_EastPoint_2725.png",
    "KWT_2018-08-01_GSutton_EastPoint_2725.txt",
    "KWT_2018-08-21_MMalleson_Sooke_9576.png",
    "KWT_2018-08-21_MMalleson_Sooke_9576.txt",
    "KWT_2018-08-25_MMalleson_Victoria_9807.png",
    "KWT_2018-08-25_MMalleson_Victoria_9807.txt",
    "KWT_2018-10-24_MMalleson_PedderBay_9381.png",
    "KWT_2018-10-24_MMalleson_PedderBay_9381.txt",
    "KWT_2018-11-16_JTowers_WeyntonPass_0764.png",
    "KWT_2018-11-16_JTowers_WeyntonPass_0764.txt"
  ]

}
