<div class="population-preview-container" *ngIf="population" [routerLink]="getRouterLink(population)">
  <!--<div class="default" *ngIf="!population.organization_Population!.banner">
    <svg width="200" height="200" [data-jdenticon-value]="getJdenticon(population)"></svg>
  </div>
  <div class="population-profile-image" *ngIf="population.organization_Population!.banner">
    <img [src]="population.organization_Population!.banner" [alt]="population.organization_Population!.displayName">
  </div>-->
  <div class="population-overlay shader">
    <div class="population-title">
      <h3><span *ngIf="population">{{population.organization_Population!.displayName}}</span></h3>
    </div>
    <div class="population-description">
      <h4><span class="summary-category">Encounters: </span><span class="count summary-value">{{population.organization_Population_Encounters!.length}}</span></h4>
      <h4><span class="summary-category">Images: </span><span class="count summary-value">{{population.organization_Population_Images}}</span></h4>
      <h4><span class="summary-category">Detections: </span><span class="count summary-value">{{population.organization_Population_Images}}</span></h4>
    </div>
  </div>
</div>
