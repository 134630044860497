import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {DateService} from "../../../../../services/utilities/date.service";
import {EncountersService} from "../../../../../services/encounters/encounters.service";

@Component({
  selector: 'app-profile-sighting-information',
  templateUrl: './profile-sighting-information.component.html',
  styleUrls: ['./profile-sighting-information.component.scss']
})
export class ProfileSightingInformationComponent implements OnInit, OnChanges {
  @Input() profile: AnimalProfileDto | undefined;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() yearUpdated: EventEmitter<number | undefined> = new EventEmitter<number | undefined>();
  @Output() allYears: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

  public encountersPerYear: Map<number, number> = new Map<number, number>();
  public years: Array<number> | undefined;
  public currentlySelectedYear: number | undefined;
  public editingSightingsPerYear: boolean = false;

  constructor(
    private dateService: DateService,
    private encounterService: EncountersService
  ) { }

  ngOnInit(): void {
    if (this.profile === undefined || this.profile.yearlySightings === undefined) {return;}
    this.loaded.emit(true);
    let map = new Map(Object.entries(this.profile.yearlySightings));
    const encounterMap = new Map<number, number>();
    let _y = 0;
    for (let key of map.keys()) {
      let y = parseInt(key, 10);
      if (y > _y) {
        _y = y;
      }
      encounterMap.set(y, map.get(key) as number);
    }

    this.encountersPerYear = encounterMap;
    this.years = Array.from(this.encountersPerYear.keys()).sort((a, b) => b - a);
    this.allYears.emit(this.years);
    this.currentlySelectedYear = _y;
  }

  getLastEncounterDate() {
    if (this.profile?.encounters && this.profile!.encounters?.length > 0) {
      const encounter = this.profile.encounters[0];
      const dt = encounter.dateTime;
      if (dt) {
        return this.dateService.formatDateFromAny(dt, false)
      }
    }
    return 'Unknown';
  }

  updateSightingsPerYear() {
    this.editingSightingsPerYear = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  emitYear(year: number | undefined) {
    this.yearUpdated.emit(year);
  }

  endEdit() {
    this.editingSightingsPerYear = false;
    this.emitYear(this.currentlySelectedYear)
  }
}
