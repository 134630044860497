<div class="content-container">
  <h3>Submit For Another Contributor</h3>
  <div class="user-select dark-theme" *ngIf="usersObs | async as users else loading">
    <mat-form-field color="accent"  class="full-width">
      <mat-label>Contributor to Submit As</mat-label>
      <mat-select (selectionChange)="updateSubmissionUser($event)" [(ngModel)]="selectUser">
        <mat-option *ngFor="let userDto of users" [value]="userDto">{{userDto.firstName}} {{userDto.lastName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="button-row">
      <button mat-stroked-button color="accent" (click)="createNewSubmissionUser()">Don't see them in the List? Create a New User</button>
      <button mat-stroked-button color="accent" (click)="resetSubmissionUser()" [disabled]="originalUser === submissionUser" *ngIf="originalUser !== undefined">Reset User to {{originalUser.firstName}} {{originalUser.lastName}}</button>
    </div>

  </div>
</div>

<ng-template #loading>
  <h4>Loading users...</h4>
</ng-template>
