import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../services/user/authentication.service";
import {PopulationService} from "../services/population/population.service";

@Injectable({
  providedIn: 'root'
})
export class PopulationAdminGuard  {
  constructor(private authService: AuthenticationService, private router: Router, private populationService: PopulationService) {
  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
    if (route.params["id"]) {
      return await this.checkAuth(route.params["id"]);
    }

    this.router.navigate(['/forbidden'], {queryParams: {returnUrl: state.url}});
    return false;
  }

  private async checkAuth(popId: string): Promise<boolean> {
    return await new Promise<boolean>((resolve, reject) => {
      this.populationService.isUserPopulationAdministrator(popId)
        .subscribe({
          next: (value: boolean) => resolve(value),
          error: (_) => reject
        })
    });
  }

}
