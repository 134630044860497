import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IImageService} from "./image.service.interface";
import {FileItem, IFileItem} from "../../../models/items/files/file-item.model";
import {BehaviorSubject, mergeMap, Observable, of} from "rxjs";
import {ILoggingService} from "../../logging/logging.service.interface";
import {IFileStorageService} from "../../storage/file/file-storage.service.interface";
import {ImageMetaDataService} from "./meta/image-meta-data.service";
import {LocationService} from "../../location/location.service";
import {FileUploadResponse} from "../../../models/response/file/file-upload.response.model";
import {FileItemGetAllResponse} from "../../../models/response/file-item/file-item.get.all.response.model";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {ImageService} from "../../utilities/image.service";
import {IMetaData} from "../../../models/data/files/meta-data.model";


@Injectable({
  providedIn: "root"
})
export class WebImageService implements IImageService {
  images = new BehaviorSubject<Array<IFileItem>>([]);
  _images: Array<Observable<IFileItem>> = new Array<Observable<IFileItem>>();
  directory = new BehaviorSubject<string[]>([]);
  dataDirectories: Map<string, BehaviorSubject<Array<IFileItem>>> = new Map<string, BehaviorSubject<Array<IFileItem>>>();

  constructor(
    public log: ILoggingService,
    public fileStorageService: IFileStorageService,
    public imageMetaDataService: ImageMetaDataService,
    public locationService: LocationService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public imageUtilities: ImageService,
  ) {}

  arrayBufferToImageSrc(buffer: ArrayBuffer): SafeUrl | undefined  {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + window.btoa( binary ));
  }

  uploadFile(file: File, populationId: string, encounterId: string): Observable<any> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const url = `${environment.server.baseUrl}${environment.server.api.file.save}/${populationId}/${encounterId}`;
    return this.httpClient.post(url, formData, {reportProgress: true, observe: 'events'});
  }


  fetchImage(fileItem: IFileItem): Observable<any> {
    const url = `${environment.server.baseUrl}/api/file/download`
    return this.httpClient.post(url, JSON.stringify(fileItem), {headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: "arraybuffer"});
  }

  fetchImageFromId(fileItemId: string): Observable<any> {
    const url = `${environment.server.baseUrl}/api/file/download/${fileItemId}`
    // return this.httpClient.get(url, {responseType: "arraybuffer"});
    return this.httpClient.get(url);
  }

  fetchThumbnailFromId(fileItemId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.thumbnail}/${fileItemId}`;
    return this.httpClient.get(url);
  }

  addDataDirectory(path: string): void {
  }

  fetchImageObservables(): Array<Observable<IFileItem>> | undefined {
    return undefined;
  }

  fetchImages(): Observable<IFileItem[]> | undefined {
    return undefined;
  }

  getImages(path: string): void {
  }

  navigateDirectory(path: string): void {
  }

  getFileItems(): Observable<FileItemGetAllResponse> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.getAll}`
    return this.httpClient.get<FileItemGetAllResponse>(url);
  }

  getEncounterGalleryByPopulation(populationId: string): Observable<FileItemGetAllResponse> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.getAllByEncounterPopulation}/${populationId}`;
    return this.httpClient.get<FileItemGetAllResponse>(url);
  }


  saveNewImage(fileItem: IFileItem, content: ArrayBuffer): Observable<any> {
    return this._postNewImage(fileItem, content);
  }

  initializeImage(bufferContent: ArrayBuffer, fileItem: IFileItem): Observable<IFileItem> {
    const item: IFileItem = this.imageMetaDataService.initializeMetaDataFromBuffer(bufferContent, fileItem);
    return of(item);

  }

  _postNewImage(fileItem: FileItem, content: string | ArrayBuffer) {
    const copy: FileItem = JSON.parse(JSON.stringify(fileItem));
    const url = `${environment.server.baseUrl}${environment.server.api.file.saveNew}`;
    if (copy.image == undefined && typeof content != "string") {
      copy.image = this.imageUtilities.arrayBufferToBase64(content);
    }
    return this.httpClient.post<FileUploadResponse>(url, copy);
  }

  _putNewImage(fileItem: IFileItem, content: string | ArrayBuffer) {
    const copy: FileItem = JSON.parse(JSON.stringify(fileItem));
    const url = `${environment.server.baseUrl}${environment.server.api.file.overwriteNew}`;
    if (copy.image == undefined && typeof content != "string") {
      copy.image = this.imageUtilities.arrayBufferToBase64(content);
    }
    return this.httpClient.post<FileUploadResponse>(url, copy);
  }

  overWriteImage(fileItem: IFileItem, content: ArrayBuffer): Observable<any> {
    return this._putNewImage(fileItem, content);
  }

  deleteImage(itemId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.delete}/${itemId}`;
    return this.httpClient.delete(url);
  }

  updateMetaData(meta: IMetaData): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.file.metaData}/${meta.id}`;
    return this.httpClient.put(url, meta);
  }


}
