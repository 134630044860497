<div mat-dialog-title>New API Key Access</div>
<div mat-dialog-content>
  <h1>Be aware: Those accounts with API access have a great deal of power to influence data with the system.</h1>
  <h3>They can</h3>
  <ul>
    <li>Query data</li>
    <li>Download data</li>
    <li>Update data</li>
  </ul>
  <h3>Only give out these keys if you trust the individual completely.</h3>

  <div class="key-configuration">
    <div class="user-selection" *ngIf="users">
      <mat-form-field color="accent" class="full-width">
        <mat-label>User</mat-label>
        <mat-select [(ngModel)]="selectedUser" (ngModelChange)="updateData()">
          <mat-option *ngFor="let user of users" [value]="user">{{user.firstName}} {{user.lastName}} ({{user.email}})</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="expiration-date-selection">
      <mat-form-field class="full-width">
        <mat-label>Expiration Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="expirationDate" (ngModelChange)="updateData()">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions class="button-row">
  <button mat-stroked-button [mat-dialog-close]="data" cdkFocusInitial color="accent" >Create Key</button>
  <button mat-stroked-button (click)="onNoClick()" color="warn">Cancel</button>
</div>
