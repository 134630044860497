import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {v4 as uuidv4} from "uuid";
import {AnnotateDialogComponent, AnnotateDialogContentComponent} from "./annotate-dialog/annotate-dialog.component";

import {ImageAnnotation} from "../../../../models/annotation/image/annotation.model";

import {AnnotatedImage} from "../../../../models/items/files/images/annotated-images/annotated-image.model";
import {BoxSettings, DrawStyle} from "../../../../settings/annotation/box/box-settings.settings";
import {SafeUrl} from "@angular/platform-browser";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {StyleManagerService} from "../../../../services/style-manager/style-manager.service";
import {EncounterDto} from "../../../../models/dto/encounter/encounterDto";
import {A, W} from "@angular/cdk/keycodes";
import {AnimalService} from "../../../../services/animal/animal.service";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";
import {AnimalProfileDto} from "../../../../models/dto/animal/animalProfileDto";
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {AnnotationService} from "../../../../services/annotation/annotation.service";
import {ResponseDto} from "../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'global-annotate',
  templateUrl: './annotate.component.html',
  styleUrls: ['./annotate.component.scss']
})
export class AnnotateComponent implements OnInit, AfterViewInit, OnChanges, AfterContentInit {

  @Input() image!: AnnotatedImage;
  @Input() src!: string | SafeUrl;
  @Input() encounter: EncounterDto | undefined;
  @Input() animals: Array<AnimalProfileDto> | undefined;
  @Input() maxCanvasWidth = 1200;
  @Input() maxCanvasHeight = 800;
  @Input() xOffsetCorrect = 0;
  @Input() yOffsetCorrect = 0;
  @Input() sidebar: boolean = true;
  @Input() allowAnnotation: boolean = true;
  @Input() showAnnotations: boolean = true;
  @Input() showMetaData: boolean = true;
  @Input() suggestions: Array<AnimalDto> = new Array<AnimalDto>();
  @Input() user: UserProfileDto | undefined;
  @Input() tab: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.maxCanvasWidth = event.target.innerWidth * 0.8;
    this.maxCanvasHeight = this.maxCanvasWidth * 0.6
    this.ngOnInit();
    this.ngAfterViewInit();
  }



  @Output() imageUpdated: EventEmitter<AnnotatedImage> = new EventEmitter<AnnotatedImage>();
  @Output() locationUpdated: EventEmitter<AnnotatedImage> = new EventEmitter<AnnotatedImage>();
  @Output() annotationRemoved: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationConfirmed: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationUpdated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationCreated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();

  public imageLoaded = false;
  public saving = false;

  private zooms = 0;
  private zoomIntensity = 0.1;
  private scale = 1;
  private originX = 0;
  private originY = 0;
  private zoomFactor = 1;
  private visibleWidth = this.maxCanvasWidth;
  private visibleHeight = this.maxCanvasHeight;
  private xScaleComponent = 1;
  private yScaleComponent = 1;

  public id = 1000;
  public panModeActive = !this.allowAnnotation;
  public boxModeActive = this.allowAnnotation;
  public newAnnotation: ImageAnnotation | undefined = undefined;
  private ctx: any = undefined;

  private panning = false;
  public isDark: boolean = true;

  @ViewChild("canvasElement", {static: false}) canvasEl: ElementRef | undefined;
  @ViewChild("imageElement", {static: false}) imageEl: ElementRef | undefined;
  @ViewChild(AnnotateDialogComponent) labelDialog!: AnnotateDialogComponent;

  private existingAnnotations: Array<ImageAnnotation> = new Array<ImageAnnotation>();
  private startPanXY: [number, number] | undefined;
  private displayAnnotations = true;

  constructor(
    public log: ILoggingService,
    public styleManager: StyleManagerService,
    public animalService: AnimalService,
    public workspaceService: WorkspaceService,
    private annotationService: AnnotationService
    ) {}

  updateLocation(annotatedImage: AnnotatedImage) {
    this.locationUpdated.emit(annotatedImage)
  }

  updateContext(annotatedImage: AnnotatedImage) {
    this.imageUpdated.emit(annotatedImage);
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.ngOnInit();
      this.ngAfterViewInit();
  }

  ngAfterContentInit() {

  }

  highlightAnnotation(annotation: ImageAnnotation) {
    this.redraw();
  }

  removeHighlights() {
    this.redraw();
  }


  ngOnInit() {
    const imgW = this.image.metaData.width!;
    const imgH = this.image.metaData.height!;
    const ratio = imgH / imgW;
    this.maxCanvasWidth = Math.min(this.maxCanvasWidth, window.innerWidth * 0.7);
    this.maxCanvasHeight = Math.min(this.maxCanvasWidth, this.maxCanvasWidth * ratio);
    this.styleManager.isDarkObs.subscribe(res => this.isDark = res)
    if (this.image.metaData !== undefined) {

      if (!imgW || !imgH) { return; }
      const xFactor = this.maxCanvasWidth / imgW;
      const yFactor = this.maxCanvasHeight / imgH;

      for (let annotation of this.image.annotations) {
        annotation.box.canvasX = annotation.box.x * xFactor;
        annotation.box.canvasY = annotation.box.y * yFactor;
        annotation.box.canvasW = annotation.box.w * xFactor;
        annotation.box.canvasH = annotation.box.h * yFactor;
        annotation.new = false;
        annotation.display = true;
        this.existingAnnotations.push(annotation);

      }
    }
  }

  /**
   * Initializes the image, canvas and context.
   *
   * @returns void
   */
  public ngAfterViewInit(): void {
    if (this.canvasEl !== undefined && this.imageEl !== undefined) {
      const canvas = this.canvasEl.nativeElement;
      canvas.addEventListener("contextmenu", (e: any) => {
        e.preventDefault();
      })
      this.ctx = this.canvasEl.nativeElement.getContext('2d');
      const image = this.imageEl.nativeElement

      image.addEventListener("load", () => {
        this.imageLoaded = true;
        if (this.imageEl?.nativeElement.naturalWidth < this.maxCanvasWidth) {
          this.maxCanvasWidth = this.imageEl?.nativeElement.naturalWidth;

        }
        if (this.imageEl?.nativeElement.naturalHeight < this.maxCanvasHeight) {
          this.maxCanvasHeight = this.imageEl?.nativeElement.naturalHeight;
        }

        const xFactor = this.maxCanvasWidth / this.imageEl?.nativeElement.naturalWidth;
        const yFactor = this.maxCanvasHeight / this.imageEl?.nativeElement.naturalHeight;
        if (this.imageEl?.nativeElement.naturalWidth < this.imageEl?.nativeElement.naturalHeight) {
          this.maxCanvasHeight *= xFactor;
          this.maxCanvasWidth *= yFactor;
        }

        this.visibleWidth = this.maxCanvasWidth;
        this.visibleHeight = this.maxCanvasHeight;

        canvas.width = this.maxCanvasWidth;
        canvas.height = this.maxCanvasHeight;

        this.xScaleComponent = this.imageEl?.nativeElement.naturalWidth / canvas.width;
        this.yScaleComponent = this.imageEl?.nativeElement.naturalHeight / canvas.height;

        this.ctx.drawImage(image, 0, 0, this.maxCanvasWidth, this.maxCanvasHeight);
        this.redraw();
      })

    }
  }

  /**
   * Redraws the image
   */
  private restoreImage(): void {
    if (this.canvasEl !== undefined && this.imageEl !== undefined) {
      this.ctx.drawImage(this.imageEl.nativeElement, 0, 0, this.maxCanvasWidth, this.maxCanvasHeight);
    }

  }

  private getMouseXY($event: any) {
    const rect = $event.target.getBoundingClientRect();
    const x = ($event.clientX - rect.left) / Math.exp(this.zooms * this.zoomIntensity) + this.originX;
    const y = ($event.clientY - rect.top) / Math.exp(this.zooms * this.zoomIntensity) + this.originY

    return [x, y];
  }


  private zoom(x: number, y: number, factor: number) {
    const zoomIntensity = 0.1;
    if (factor < 0) {
      if (this.zooms <= 0) {
        this.restoreCanvas();
        return;
      } else {
        this.zooms -= 1;
      }
    } else {
      this.zooms += 1;
    }
    const zoom = Math.exp(factor * zoomIntensity);
    this.zoomFactor = zoom;
    this.ctx.translate(this.originX, this.originY);
    this.originX -= x / (this.scale * zoom) - x / this.scale;
    this.originY -= y / (this.scale * zoom) - y / this.scale;
    this.ctx.scale(zoom, zoom);
    if (this.originX <= 0) {
      this.originX = 0;
    }
    if (this.originY <= 0) {
      this.originY = 0;
    }
    if (this.originX + this.visibleWidth >= this.maxCanvasWidth) {
      this.originX = this.maxCanvasWidth - this.visibleWidth - 1;
    }

    if (this.originY + this.visibleHeight >= this.maxCanvasHeight) {
      this.originY = this.maxCanvasHeight - this.visibleHeight - 1;
    }
    this.ctx.translate(-this.originX, -this.originY);
    this.redraw();

    this.scale *= zoom;
    this.visibleWidth = this.maxCanvasWidth / this.scale;
    this.visibleHeight = this.maxCanvasHeight / this.scale;
  }



  public mouseZoom($event: any): void {
    $event.preventDefault();
    const xy = this.getMouseXY($event);
    const scroll = $event.deltaY < 0 ? 1 : -1;
    this.zoom(xy[0], xy[1], scroll);

  }

  /**
   * Zooms in to the center of the canvas
   */
  public zoomIn(): void {
    this.zoom(this.maxCanvasWidth / 2, this.maxCanvasHeight / 2, 1);
  }

  /**
   * Zooms out from the center of the canvas
   */
  public zoomOut(): void {
    this.zoom(this.maxCanvasWidth / 2, this.maxCanvasHeight / 2, -1);
  }

  public togglePanMode(): void {
    this.panModeActive = !this.panModeActive;
    if (this.panModeActive) {
      this.boxModeActive = false;
    }
  }
  public toggleBoxMode(): void {
    this.boxModeActive = !this.boxModeActive;
    if (this.boxModeActive) {
      this.panModeActive = false;
    }
  }

  /**
   * Resets the canvas with the default identity matrix, the origins, and the zoom factor.
   */
  public restoreCanvas(): void {
    this.ctx.clearRect(0, 0, this.maxCanvasWidth, this.maxCanvasHeight);
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.originX = 0;
    this.originY = 0;
    this.zooms = 0;
    this.redraw();
  }


  public redraw() {
    if (this.canvasEl !== undefined && this.imageEl !== undefined) {

      this.restoreImage();
      if (this.image.annotations) {
        for (let annotation of this.image.annotations!) {
          if (!annotation.display && !annotation.highlight) {continue;}
          this.drawBox(
            annotation.box.canvasX,
            annotation.box.canvasY,
            annotation.box.canvasW,
            annotation.box.canvasH,
            DrawStyle.STROKE,
            annotation.highlight !== undefined ? annotation.highlight : false);
          this.drawBox(
            annotation.box.canvasX + BoxSettings.tagSettings.box.xOffset,
            annotation.box.canvasY + BoxSettings.tagSettings.box.yOffset,
            annotation.tag ? this.ctx.measureText(annotation.tag).width + 10 : BoxSettings.tagSettings.box.w,
            BoxSettings.tagSettings.box.h,
            DrawStyle.FILL,
            false);
          this.drawText(
            annotation.tag,
            annotation.box.canvasX + BoxSettings.tagSettings.xOffset,
            annotation.box.canvasY + BoxSettings.tagSettings.yOffset,
            DrawStyle.FILL
          )
        }
      }
    }
  }

  private drawBox(x: number, y: number, w: number, h: number, style: DrawStyle, highlight: boolean) {
    if (style === DrawStyle.STROKE) {
      this.ctx.strokeStyle = highlight ? BoxSettings.highlightColor : BoxSettings.strokeStyle;
      this.ctx.lineWidth = BoxSettings.lineWidth;
      this.ctx.strokeRect(x, y, w, h);
    } else {
      this.ctx.fillStyle = BoxSettings.strokeStyle;
      this.ctx.fillRect(x, y, w, h);
    }
  }

  private drawText(content: string | undefined, x: number, y: number, style: DrawStyle) {
    if (content === undefined) { return;}
    if (style === DrawStyle.FILL) {
      this.ctx.fillStyle = BoxSettings.tagSettings.tagStyle;
      this.ctx.fillText(content.toString(), x, y);
    }
  }

  public startRect($event: any): void {
    if (!this.allowAnnotation) return;
    if (this.canvasEl !== undefined && this.imageEl !== undefined && this.boxModeActive) {
      let xy = this.getMouseXY($event);
      const newAnnotationId = uuidv4().toString();
      this.newAnnotation = {
        id: newAnnotationId,
        tag: undefined,
        fileItemId: this.image.id,
        box: {
          id: uuidv4().toString(),
          canvasX: xy[0],
          canvasY: xy[1],
          canvasH: 1,
          canvasW: 1,
          x: xy[0] * this.xScaleComponent,
          y: xy[1] * this.yScaleComponent,
          w: 1,
          h: 1,
          annotationId: newAnnotationId
        }
      }
    }
  }

  public drawDynamic($event: any, finished: boolean) {
    if (!this.allowAnnotation) return;
    this.redraw();
    if (this.newAnnotation === undefined) {return; }

    let xy = this.getMouseXY($event);
    this.newAnnotation = {
      id: this.newAnnotation.id,
      tag: this.newAnnotation.tag,
      fileItemId: this.image.id,
      box: {
        id: this.newAnnotation.box.id,
        canvasX: this.newAnnotation.box.canvasX,
        canvasY: this.newAnnotation.box.canvasY,
        canvasW: xy[1] - this.newAnnotation.box.canvasY,
        canvasH: xy[1] - this.newAnnotation.box.canvasY,
        x: this.newAnnotation.box.canvasX * this.xScaleComponent,
        y: this.newAnnotation.box.canvasY * this.yScaleComponent,
        w: (xy[1] - this.newAnnotation.box.canvasY) * this.yScaleComponent,
        h: (xy[1] - this.newAnnotation.box.canvasY) * this.yScaleComponent,
        annotationId: this.newAnnotation.id,
      },
      display: true
    }
    this.drawBox(
      this.newAnnotation.box.canvasX,
      this.newAnnotation.box.canvasY,
      this.newAnnotation.box.canvasH,
      this.newAnnotation.box.canvasH,
      DrawStyle.STROKE,
      false);

    if (finished) {
      this.handleAnnotateDialog(this.newAnnotation, this.image)

      this.newAnnotation = undefined;

    }
  }

  private validAnnotation(annotation: ImageAnnotation, image: AnnotatedImage): boolean {
    if (annotation.box.w == 0) return false;
    if (annotation.box.h == 0) return false;
    if (annotation.box.w + annotation.box.x > image.width) return false;
    if (annotation.box.h + annotation.box.y > image.height) return false;
    return true;
  }
  public handleAnnotateDialog(annotation: ImageAnnotation, image: AnnotatedImage, newAnnotation: boolean = true) {
    if (!this.validAnnotation(annotation, image)) {
      this.log.error("Please draw a box around the dorsal fin and saddle patch (if visible) of the animal you wish to label", true)
      this.redraw();
      return;
    }
    const existingTag = annotation.tag;
    const dialogRef = this.labelDialog.dialog.open(AnnotateDialogContentComponent, {
      width: '350px',
      data: {
        labelBox: annotation.tag,
        image: this.image,
        suggestions: this.suggestions,
        animals: this.animals
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result !== null && result.length > 0) {
        annotation.tag = result;
        annotation.confirmed = true;
        if (this.user) {
          annotation.tagger = this.user;
        }

        if (image.annotations === undefined) {
          image.annotations = [];
        }
        if (newAnnotation)  {
          image.annotations!.push(annotation);
          // this.imageUpdated.emit(this.image);
          this.annotationCreated.emit(annotation);
          this.makeNewAnnotation(annotation);

        } else {
          this.annotationUpdated.emit(annotation);
          this.persistUpdatedAnnotation(annotation, existingTag);
        }
        this.checkOverlap(annotation);

        // this.log.info(`Finished bounding box for ${annotation.tag}`);
      }

      this.redraw();
    });
  }


  public resizeRectangle($event: any): void {
    if (!this.allowAnnotation) return;
    if (this.canvasEl !== undefined && this.imageEl !== undefined && this.newAnnotation !== undefined && this.boxModeActive) {
      this.drawDynamic($event, false);
    }
  }

  public finishRectangle($event: any): void {
    if (!this.allowAnnotation) return;
    if (this.canvasEl !== undefined && this.imageEl !== undefined && this.newAnnotation !== undefined && this.boxModeActive) {
      this.drawDynamic($event, true);
    }
  }

  public removeAnnotation(annotation: ImageAnnotation): void {
    this.saving = true;
    const idx = this.image.annotations.indexOf(annotation);
    if (idx !== -1) {
      this.image.annotations.splice(idx, 1);
    }
    this.redraw();
    this.annotationService.deleteAnnotation(annotation).subscribe({
      next: (value: ResponseDto) => {
        if (value.successful) {
          this.log.info(`Annotation for ${annotation.tag} successfully removed`);
          this.saving = false;
        } else {
          this.log.error(`Could not delete annotation for ${annotation.tag}: ${value.errorMessages.join(", ")}`)
          this.image.annotations.push(annotation);
          this.redraw();
          this.saving = false;
        }

      }, error: (value: HttpErrorResponse) => {
        this.log.error(`Failed to delete annotation for ${annotation.tag}: ${value.error}`)
        this.image.annotations.push(annotation);
        this.redraw();
        this.saving = false;
      }
    })
  }

  public confirmAnnotation(annotation: ImageAnnotation): void {
    annotation.tag = annotation.tag!.replace("Model Suggestion: ", "");
    annotation.confirmed = true;
    if (this.user) {
      annotation.tagger = this.user
    }
    this.redraw();
    this.annotationConfirmed.emit(annotation);
  }

  public update(annotatedImage: AnnotatedImage): void {
    this.redraw();
    console.log("annotateDebugImageUpdated");
    this.imageUpdated.emit(annotatedImage);
  }

  public updateAnnotation(imageAnnotation: ImageAnnotation): void {
    this.handleAnnotateDialog(imageAnnotation, this.image, false);

  }

  public makeNewAnnotation(annotation: ImageAnnotation): void {
    annotation.populationId = this.image.populationId;
    annotation.encounterId = this.image.encounterId;
    this.saving = true;
    this.annotationService.createAnnotation(annotation).subscribe({
      next: (val: ResponseDto) => {
        if (val.successful) {
          this.log.info(`Annotation for ${annotation.tag} successfully created`);
        } else {
          this.log.error(`Could not create annotation: ${val.errorMessages.join(', ')}`)
          this.image.annotations.splice(this.image.annotations.indexOf(annotation), 1);
          this.redraw();
        }
        this.saving = false;
      }, error: (err: HttpErrorResponse) => {
        this.log.error(`Failed to create annotation: ${err.error}`);
        this.image.annotations.splice(this.image.annotations.indexOf(annotation), 1);
        this.redraw();
        this.saving = false;
      }
    })
  }

  public persistUpdatedAnnotation(annotation: ImageAnnotation, existingTag: string | undefined): void {
    annotation.populationId = this.image.populationId;
    annotation.encounterId = this.image.encounterId;
    this.saving = true;
    this.annotationService.updateAnnotation(annotation).subscribe({
      next: (val: ResponseDto) => {
        if (val.successful) {
          this.log.info(`Annotation for ${annotation.tag} successfully updated`);
        } else {
          this.log.error(`Could not update annotation: ${val.errorMessages.join(', ')}`)
          annotation.tag = existingTag;
          this.redraw();

        }
        this.saving = false;
      }, error: (err: HttpErrorResponse) => {
        this.log.error(`Failed to update annotation: ${err.error}`);
        annotation.tag = existingTag;
        this.redraw();
        this.saving = false;
      }
    })
  }

  public startEvent($event: any) {
    if (this.boxModeActive) {
      this.startRect($event);
    } else {
      this.startPan($event);
    }
  }

  public continueEvent($event: any) {
    if (this.boxModeActive) {
      this.resizeRectangle($event);
    } else {
      this.pan($event);
    }
  }

  public finishEvent($event: any) {
    if (this.boxModeActive) {
      this.finishRectangle($event);
    } else {
      this.finishPan($event);
    }
  }

  public startPan($event: any) {
    this.panning = true;
    // @ts-ignore
    this.startPanXY = this.getMouseXY($event);
  }

  public finishPan($event: any) {
    this.panning = false;
  }

  public pan($event: any) {
    if (this.panning) {
      let xy = this.getMouseXY($event);

      const originOffsetX = (this.startPanXY![0] - xy[0]);
      const originOffsetY = (this.startPanXY![1] - xy[1]);
      this.ctx.translate(this.originX, this.originY);
      this.originX -= originOffsetX / (this.scale * this.zoomFactor) - originOffsetX / this.scale;
      this.originY -= originOffsetY / (this.scale * this.zoomFactor) - originOffsetY / this.scale;
      if (this.originX <= 0) {
        this.originX = 0;
      }
      if (this.originY <= 0) {
        this.originY = 0;
      }
      if (this.originX + this.visibleWidth >= this.maxCanvasWidth) {
        this.originX = this.maxCanvasWidth - this.visibleWidth - 1;
      }

      if (this.originY + this.visibleHeight >= this.maxCanvasHeight) {
        this.originY = this.maxCanvasHeight - this.visibleHeight - 1;
      }
      this.ctx.translate(-this.originX, -this.originY);
      this.redraw();
    }
  }

  toggleBoxes() {
    this.displayAnnotations = !this.displayAnnotations;
    for (let annotation of this.image.annotations) {
      annotation.display = this.displayAnnotations;
    }
    this.redraw()
  }

  private checkOverlap(annotation: ImageAnnotation) {
    const annotationsToRemove = [];
    for (let target of this.image.annotations) {
      const w_intersection = Math.min(target.box.x + target.box.w, annotation.box.x + annotation.box.w) - Math.max(target.box.x, annotation.box.x);
      const h_intersection = Math.min(target.box.y + target.box.h, annotation.box.y + annotation.box.h) - Math.max(target.box.y, annotation.box.y);

      const I = w_intersection * h_intersection;
      const U = target.box.w * target.box.h + annotation.box.w * annotation.box.h - I;
      const IoU = I / U;
      if (IoU >= 0.33 && target.confirmed == false && target.id != annotation.id) {
        annotationsToRemove.push(target);
      }
    }

    for (let a of annotationsToRemove) {
      this.removeAnnotation(a);

    }



  }
}
