import {Injectable} from "@angular/core";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {SortCriteria} from "./sort-criteria.enum";


@Injectable({
  providedIn: "root"
})
export class FileGroupingService {

  groupItems(items: Array<IFileItem>, groupingCriteria: SortCriteria, ascending = false): Map<string | number, Array<IFileItem>> {
    switch (groupingCriteria) {
      case SortCriteria.creationDate:
        return FileGroupingService.groupByCreationDate(items, ascending);
      case SortCriteria.uploadDate:
        return FileGroupingService.groupByUploadDate(items, ascending);
      case SortCriteria.photographer:
        return FileGroupingService.groupByPhotographer(items, ascending);
      case SortCriteria.editor:
        return FileGroupingService.groupByEditor(items, ascending);
      case SortCriteria.encounterDate:
        return FileGroupingService.groupByEncounterDate(items, ascending);
      default:
        return new Map<string, Array<IFileItem>>();
    }
  }

  private static groupByEncounterDate(items: Array<IFileItem>, ascending = false): Map<string, Array<IFileItem>> {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    const map: Map<string, Array<IFileItem>> = new Map<string, Array<IFileItem>>();
    for (let item of copy) {
      if (typeof(item.encounter?.dateTime) == "string") {
        item.encounter!.dateTime = new Date(item.encounter?.dateTime);
      } else if (!item.encounter?.dateTime) {
        item.encounter!.dateTime = new Date(1, 1, 1, 0, 0, 0);
      }

      const date = item.encounter?.dateTime as Date;
      date.setHours(0, 0,0, 0);
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      const locationName = item.metaData.location ? item.metaData.location.name : item.encounter?.location?.name;
      const keyString = `${dateString}_${item.metaData.photographerName?.replace(" ", "")}_${locationName}`;
      if (!map.has(keyString)) {
        map.set(keyString, new Array<IFileItem>());
      }
      // @ts-ignore
      map.get(keyString).push(item);
    }

    return new Map([...map.entries()].sort((a, b) => {
      const aDate = new Date(a[0].slice(0, a[0].indexOf("_")));
      const bDate = new Date(b[0].slice(0, b[0].indexOf("_")));
      if (ascending) {
        // @ts-ignore
        return aDate - bDate;
      }
      // @ts-ignore
      return bDate - aDate;
    }));
  }


  private static groupByCreationDate(items: Array<IFileItem>, ascending = false): Map<string, Array<IFileItem>> {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    const map: Map<string, Array<IFileItem>> = new Map<string, Array<IFileItem>>();
    for (let item of copy) {
      if (typeof(item.metaData.creationDateTime) == "string") {
        item.metaData.creationDateTime = new Date(item.metaData.creationDateTime);
      } else if (!item.metaData.creationDateTime) {
        item.metaData.creationDateTime = new Date(1, 1, 1, 0, 0, 0);
      }

      const date = item.metaData.creationDateTime as Date;
      date.setHours(0, 0,0, 0);
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      if (!map.has(dateString)) {
        map.set(dateString, new Array<IFileItem>());
      }
      // @ts-ignore
      map.get(dateString).push(item);
    }

    return new Map([...map.entries()].sort((a, b) => {
      if (ascending) {
        // @ts-ignore
        return new Date(a[0]) - new Date(b[0])
      }
      // @ts-ignore
      return new Date(b[0]) - new Date(a[0])
    }));
  }

  private static groupByUploadDate(items: Array<IFileItem>, ascending = false): Map<string, Array<IFileItem>> {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    const map: Map<string, Array<IFileItem>> = new Map<string, Array<IFileItem>>();
    for (let item of copy) {
      if (typeof(item.metaData.uploadDateTime) == "string") {
        item.metaData.uploadDateTime = new Date(item.metaData.uploadDateTime);
      } else if (!item.metaData.uploadDateTime) {
        item.metaData.uploadDateTime = new Date(1, 1, 1, 0, 0, 0);
      }

      const date = item.metaData.uploadDateTime as Date;
      date.setHours(0, 0,0, 0);
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      if (!map.has(dateString)) {
        map.set(dateString, new Array<IFileItem>());
      }
      // @ts-ignore
      map.get(dateString).push(item);
    }

    return new Map([...map.entries()].sort((a, b) => {
      if (ascending) {
        // @ts-ignore
        return new Date(a[0]) - new Date(b[0])
      }
      // @ts-ignore
      return new Date(b[0]) - new Date(a[0])
    }));
  }

  private static groupByPhotographer(items: Array<IFileItem>, ascending = false): Map<string, Array<IFileItem>> {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items));
    const map: Map<string, Array<IFileItem>> = new Map<string, Array<IFileItem>>();
    for (let item of copy) {
      if (!item.metaData.photographerName) {
        item.metaData.photographerName = "Unknown";
      }
      if (!map.has(item.metaData.photographerName as string)) {
        map.set(item.metaData.photographerName as string, new Array<IFileItem>());
      }
      // @ts-ignore
      map.get(item.metaData.photographerName as string).push(item);
    }

    return new Map([...map.entries()].sort((a, b) => {
      if (ascending) {
        // @ts-ignore
        return a[0].toUpperCase().localeCompare(b[0].toUpperCase())
      }
      // @ts-ignore
      return b[0].toUpperCase().localeCompare(a[0].toUpperCase())
    }))
  }

  private static groupByEditor(items: Array<IFileItem>, ascending = false): Map<string, Array<IFileItem>> {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items));
    const map: Map<string, Array<IFileItem>> = new Map<string, Array<IFileItem>>();
    for (let item of copy) {
      if (!item.metaData.editorName) {
        item.metaData.editorName = "Unknown";
      }
      if (!map.has(item.metaData.editorName as string)) {
        map.set(item.metaData.editorName as string, new Array<IFileItem>());
      }
      // @ts-ignore
      map.get(item.metaData.editorName as string).push(item);
    }

    return new Map([...map.entries()].sort((a, b) => {
      if (ascending) {
        // @ts-ignore
        return a[0].toUpperCase().localeCompare(b[0].toUpperCase())
      }
      // @ts-ignore
      return b[0].toUpperCase().localeCompare(a[0].toUpperCase())
    }))
  }
}
