<div class="selection-content">
  <div class="selection-section-header" matTooltip="Has the encounter been reported as having photographs of all present individuals (complete) or not (incomplete)" matTooltipPosition="left">
    <h4>Encounter Complete</h4>
    <button mat-icon-button class="reset-button" (click)="reset()"><mat-icon>restart_alt</mat-icon></button>
  </div>
  <mat-form-field color="accent" class="full-width">
    <mat-label>Complete</mat-label>
    <mat-select [(ngModel)]="status" (ngModelChange)="updateStatusSelection()">
      <mat-option *ngFor="let s of statuses" [value]="s">{{s}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
