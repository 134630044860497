<div *ngIf="viewable">
  <div class="selection-section-header">
    <h4>Encounter Analysis Status</h4>
    <button mat-icon-button class="reset-button" (click)="reset()"><mat-icon>restart_alt</mat-icon></button>
  </div>
  <mat-form-field color="accent" class="full-width">
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="status" (ngModelChange)="updateStatusSelection()">
      <mat-option *ngFor="let s of statuses" [value]="s">{{s}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-divider></mat-divider>
</div>
