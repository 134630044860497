import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { WorkspaceDto } from 'src/models/dto/workspace/workspaceDto';
import {STATUS} from "../../../../../models/dto/status/statusChangeDto";
import {EncounterPageRequestDto} from "../../../../../models/dto/encounter/encounterPageRequestDto";

@Component({
  selector: 'app-encounter-status-selection',
  templateUrl: './encounter-status-selection.component.html',
  styleUrls: ['./encounter-status-selection.component.scss']
})
export class EncounterStatusSelectionComponent implements OnInit, OnChanges {
  @Input() workspace: WorkspaceDto | undefined;
  @Input() request: EncounterPageRequestDto | undefined;
  @Output() chosenStatus: EventEmitter<string>  = new EventEmitter<string>();

  public viewable: boolean = false;
  public status: string | undefined;
  public statuses = [
    "All",
    STATUS.invert(STATUS.NEW),
    STATUS.invert(STATUS.IN_PROGRESS),
    STATUS.invert(STATUS.FINISHED)
  ]



  ngOnInit(): void {
    if (this.request?.status) {
      this.status = STATUS.invert(this.request.status);
      this.updateStatusSelection()
    }
    if (this.workspace &&
      this.workspace.settings &&
      this.workspace.settings.populationRoles &&
      (this.workspace.settings.populationRoles.administrator || this.workspace.settings.populationRoles.professional)) {
      this.viewable = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  updateStatusSelection() {
    this.chosenStatus.emit(STATUS.invert(this.status))
  }

  reset() {
    this.status = undefined;
    this.chosenStatus.emit(this.status);
  }
}
