import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {UserViewResponseDto} from "../../../models/dto/response/user/management/userViewResponseDto";
import {RolesRequestResponseDto} from "../../../models/dto/response/user/management/rolesRequestResponseDto";
import {UserDto} from "../../../models/dto/response/user/management/userDto";
import {RoleUpdateResponseDto} from "../../../models/dto/response/user/management/roleUpdateREsponseDto";
import {ResponseDto} from "../../../models/dto/response/responseDto";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {Observable} from "rxjs";
import {User} from "oidc-client";
import {UserStatisticsResponseDto} from "../../../models/dto/response/user/userStatisticsResponseDto";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private httpClient: HttpClient) { }

  public getUsers() {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.users}`;
    return this.httpClient.get<UserViewResponseDto>(url);
  }

  public getUserDtos() {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.userDtos}`;
    return this.httpClient.get<Array<UserDto>>(url);
  }

  public getUser(userId: string): Observable<UserProfileDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.delete}/${userId}`;
    return this.httpClient.get<UserProfileDto>(url);
  }

  public getUserStatistics(userId: string): Observable<UserStatisticsResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.statistics}/${userId}`;
    return this.httpClient.get<UserStatisticsResponseDto>(url);
  }

  public getPopulationUsers(populationId: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.users}/population/${populationId}`;
    return this.httpClient.get<UserViewResponseDto>(url);
  }


  public getRoles() {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.roles}`;
    return this.httpClient.get<RolesRequestResponseDto>(url);
  }

  public updateRoles = (body: UserDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.updateRoles}`;
    return this.httpClient.put<RoleUpdateResponseDto>(url, body);
  }

  public deleteUser(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.userManagement.delete}/${id}`;
    return this.httpClient.delete<ResponseDto>(url);
  }
}
