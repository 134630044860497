import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SearchRequest} from "../../models/search/search-request.model";
import {SearchResponse} from "../../models/search/search-response.model";
import {SearchRequestDto} from "../../models/dto/search/searchRequestDto";
import {SearchResponseDto} from "../../models/dto/search/searchResponseDto";



@Injectable({
  providedIn: "root"
})
export class ISearchService {
  constructor(public httpClient: HttpClient) {}

  search(request: SearchRequestDto): Observable<SearchResponseDto> | undefined { return; }

}
