<div class="container">
  <div class="container-header">
    <h2>Keys</h2>
    <button mat-stroked-button (click)="addKey()"><mat-icon>add</mat-icon>Create Key</button>
  </div>
  <div class="invitations" *ngIf="keys !== undefined && keys.length > 0">
    <table mat-table [dataSource]="keys">
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef>CreationDate Date</th>
        <td mat-cell *matCellDef="let key">{{dateService.formatDateFromAny(key.creationDate, false)}}</td>
      </ng-container>
      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
        <td mat-cell *matCellDef="let key">{{dateService.formatDateFromAny(key.expirationDate, false)}}</td>
      </ng-container>
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>Key</th>
        <td mat-cell *matCellDef="let key">{{key.key}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let key">{{key.assignedUser.firstName}} {{key.assignedUser.lastName}}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let key">{{key.assignedUser.email}}</td>
      </ng-container>

      <ng-container matColumnDef="uses">
        <th mat-header-cell *matHeaderCellDef>API Uses</th>
        <td mat-cell *matCellDef="let key">{{key.uses}}</td>
      </ng-container>

      <ng-container matColumnDef="lastUse">
        <th mat-header-cell *matHeaderCellDef>Last API Use</th>
        <td mat-cell *matCellDef="let key">{{key.lastUse}}</td>
      </ng-container>

      <ng-container matColumnDef="valid">
        <th mat-header-cell *matHeaderCellDef>Valid Key</th>
        <td mat-cell *matCellDef="let key">{{!key.invalid}}</td>
      </ng-container>

      <ng-container matColumnDef="invalidate">
        <th mat-header-cell *matHeaderCellDef>Invalidate</th>
        <td mat-cell *matCellDef="let key"><button mat-icon-button (click)="invalidate(key)"><mat-icon>block</mat-icon></button></td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Remove</th>
        <td mat-cell *matCellDef="let key"><button mat-icon-button (click)="delete(key)"><mat-icon>delete</mat-icon></button></td>
      </ng-container>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">No data</td>
      </tr>

      <!--      &lt;!&ndash; Weight Column &ndash;&gt;-->
      <!--      <ng-container matColumnDef="weight">-->
      <!--        <th mat-header-cell *matHeaderCellDef>Weight</th>-->
      <!--        <td mat-cell *matCellDef="let element">{{element.weight}}</td>-->
      <!--      </ng-container>-->

      <!--      &lt;!&ndash; Symbol Column &ndash;&gt;-->
      <!--      <ng-container matColumnDef="symbol">-->
      <!--        <th mat-header-cell *matHeaderCellDef>Symbol</th>-->
      <!--        <td mat-cell *matCellDef="let element">{{element.symbol}}</td>-->
      <!--      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

</div>

<app-area-loading [criteria]="!loading" title="Loading Invitations..."></app-area-loading>
