import {Injectable} from "@angular/core";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {SortCriteria} from "./sort-criteria.enum";


@Injectable({
  providedIn: 'root'
})
export class FileSortingService {

  sortItems(items: Array<IFileItem>, criteria: number, ascending=false) {
    switch (criteria) {
      case SortCriteria.creationDate:
        return FileSortingService.sortByCreationDate(items, ascending);
      case SortCriteria.uploadDate:
        return FileSortingService.sortByUploadDate(items, ascending);
      case SortCriteria.photographer:
        return FileSortingService.sortByPhotographer(items, ascending);
      case SortCriteria.editor:
        return FileSortingService.sortByEditor(items, ascending);
      default:
        return items;
    }
  }

  private static sortByPhotographer(items: Array<IFileItem>, ascending = false) {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    copy.sort( (a: IFileItem, b: IFileItem) => {
      if (!a.metaData.photographerName) { a.metaData.photographerName = "Unknown"}
      if (!b.metaData.photographerName) { b.metaData.photographerName = "Unknown"}
      if (!ascending) {
        let c = a;
        a = b;
        b = c;
      }
      // @ts-ignore
      const nameA = a.metaData.photographerName.toUpperCase();
      // @ts-ignore
      const nameB = b.metaData.photographerName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
    return copy;
  }

  private static sortByEditor(items: Array<IFileItem>, ascending = false) {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    copy.sort( (a: IFileItem, b: IFileItem) => {
      if (!a.metaData.editorName) { a.metaData.editorName = "Unknown"}
      if (!b.metaData.editorName) { b.metaData.editorName = "Unknown"}
      if (!ascending) {
        let c = a;
        a = b;
        b = c;
      }
      // @ts-ignore
      const nameA = a.metaData.editorName.toUpperCase();
      // @ts-ignore
      const nameB = b.metaData.editorName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
    return copy;
  }

  private static sortByCreationDate(items: Array<IFileItem>, ascending= false) {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    for (let item of copy) {
      if (typeof(item.metaData.creationDateTime) == "string") {
        item.metaData.creationDateTime = new Date(item.metaData.creationDateTime);
      } else if (!item.metaData.creationDateTime) {
        item.metaData.creationDateTime = new Date(1, 1, 1, 0, 0, 0);
      }
    }
    if (ascending) {
      return copy.sort( (a: IFileItem, b: IFileItem) => {
        // @ts-ignore
        return a.metaData.creationDateTime - b.metaData.creationDateTime;
      })
    } else {
      return copy.sort( (a: IFileItem, b: IFileItem) => {
        // @ts-ignore
        return b.metaData.creationDateTime - a.metaData.creationDateTime;
      })
    }

  }

  private static sortByUploadDate(items: Array<IFileItem>, ascending= false) {
    let copy: Array<IFileItem> = JSON.parse(JSON.stringify(items))
    for (let item of copy) {
      if (typeof(item.metaData.uploadDateTime) == "string") {
        item.metaData.uploadDateTime = new Date(item.metaData.uploadDateTime);
      } else if (!item.metaData.uploadDateTime) {
        item.metaData.uploadDateTime = new Date(1, 1, 1, 0, 0, 0);
      }
    }
    if (ascending) {
      return copy.sort( (a: IFileItem, b: IFileItem) => {
        // @ts-ignore
        return a.metaData.uploadDateTime - b.metaData.uploadDateTime;
      })
    } else {
      return copy.sort( (a: IFileItem, b: IFileItem) => {
        // @ts-ignore
        return b.metaData.uploadDateTime - a.metaData.uploadDateTime;
      })
    }
  }
}
