<div class="location-selection-content">
  <div class="map-selection">
    <h2 class="desktop">Select The Location</h2>
    <app-select-map-dialog-map [defaultLatitude]="defaultLatitude!"
                               [defaultLongitude]="defaultLongitude!"
                               [singleSelect]="true"
                               [showMarker]="false"
                               [initialZoom]="initialZoom"
                               [maxZoom]="maxZoom"
                               [minZoom]="minZoom"
                               [useLocationForMarker]="useLocation"
                               [selectedLocation]="selectedLocation"
                               [height]="800"
                               (positionRemoved)="removeLocation($event)"
                               (positionSelected)="addLocation($event)"></app-select-map-dialog-map>
  </div>
  <div class="location-selection-criteria-container">
    <h2 class="mobile">Select the Location</h2>
    <mat-form-field color="accent" class="full-width">
      <mat-label>Location Selection Method</mat-label>
      <mat-select [(ngModel)]="selectionMethod">
        <mat-option [value]="'pin'">Drop a Pin</mat-option>
        <mat-option [value]="'latlng'">Enter Coordinates</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="location-selection-criteria pin-drop" *ngIf="selectionMethod=='pin'" [ngClass]="{'inactive-location-selection': !pinSelection}">
      <div class="location-selection-criteria-content">
        <mat-form-field color="accent" class="full-width">
          <mat-label>Location Name</mat-label>
          <input matInput placeholder="{{placeholderLocationName}}" [formControl]="locationName" (change)="changeLocationName1()" />
          <mat-error *ngIf="locationName.invalid">You must enter a location name if adding a new location</mat-error>
        </mat-form-field>
        <h5 *ngIf="selectedLocation !== undefined">Selected Coordinates: </h5>
        <h5 *ngIf="selectedLocation !== undefined">Latitude = {{dec2deg(selectedLocation.latitude!)[0]}}&deg; {{dec2deg(selectedLocation.latitude!)[1]}}'</h5>
        <h5 *ngIf="selectedLocation !== undefined">Longitude = {{dec2deg(selectedLocation.longitude!)[0]}}&deg; {{dec2deg(selectedLocation.longitude!)[1]}}'</h5>
      </div>
    </div>
    <div class="location-selection-criteria coordinates" *ngIf="selectionMethod=='latlng'" [ngClass]="{'inactive-location-selection': !knownSelection}">
      <div class="location-selection-criteria-content">
        <div class="lat-long-select">
          <mat-form-field color="accent" class="full-width">
            <mat-label>Location Name</mat-label>
            <input matInput placeholder="{{placeholderLocationName}}" [formControl]="locationName" (change)="changeLocationName2()" />
            <mat-error *ngIf="locationName.invalid">You must enter a location name if adding a new location</mat-error>
          </mat-form-field>
          <div class="latitude-select lat-long-select-component">
            <mat-form-field color="accent" class="full-width">
              <mat-label>Latitude (Degrees)</mat-label>
              <input matInput placeholder="Lat Deg" type="number" [(ngModel)]="selectedLocation!.latDeg" (ngModelChange)="updateLocationDegMin()" />
            </mat-form-field>
            <mat-form-field color="accent" class="full-width">
              <mat-label>Latitude (Minutes)</mat-label>
              <input matInput placeholder="Lat Min" type="number" max="60" min="0" [(ngModel)]="selectedLocation!.latMin" (ngModelChange)="updateLocationDegMin()" />
            </mat-form-field>
          </div>
          <div class="longitude-select lat-long-select-component">
            <mat-form-field color="accent" class="full-width">
              <mat-label>Longitude (Degrees)</mat-label>
              <input matInput placeholder="Long Deg" type="number" [(ngModel)]="selectedLocation!.lonDeg" (ngModelChange)="updateLocationDegMin()" />
            </mat-form-field>
            <mat-form-field color="accent" class="full-width">
              <mat-label>Longitude (Minutes)</mat-label>
              <input matInput placeholder="Long Min" type="number" max="60" min="0" [(ngModel)]="selectedLocation!.lonMin" (ngModelChange)="updateLocationDegMin()" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-close">
      <button mat-raised-button (click)="onNoClick()"><mat-icon>check_circle</mat-icon></button>
    </div>
  </div>
</div>
