<div class="content-container">
  <mat-card class="example-card">
    <mat-card-header *ngIf="population; else loading">
      <mat-card-subtitle>{{population.speciesName}}</mat-card-subtitle>
      <mat-card-title>{{population.displayName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="population">
      <mat-nav-list>
        <mat-list-item [routerLink]="getLink('settings')" >Population Settings</mat-list-item>
        <mat-list-item [routerLink]="getLink('contributors')">Population Contributors</mat-list-item>
        <mat-list-item [routerLink]="getLink('members')">Population Members</mat-list-item>
        <mat-list-item [routerLink]="getLink('individuals')">Population Individuals</mat-list-item>
      </mat-nav-list>

      <mat-divider></mat-divider>
    </mat-card-content>

    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *ngIf="!loading" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>

<!--  <app-population-settings-component [population]="population" [canLoad]="true"></app-population-settings-component>-->
<!--  <mat-divider></mat-divider>-->
<!--  -->
<!--  <mat-divider></mat-divider>-->
<!--  -->
</div>


<ng-template #loading>
  <app-loading-overlay [criteria]="!population" [title]="'Loading Population Settings...'"></app-loading-overlay>
</ng-template>
