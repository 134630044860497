import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DataAddComponent} from "./data-add/data-add.component";
import {MaterialModule} from "../material/material.module";
import {GalleryComponent} from "./gallery/gallery.component";
import {
  GalleryImageDialogComponent,
  GalleryImageDialogDataComponent
} from "./gallery/gallery-image-dialogs/gallery-image.dialog";
import {WebUploadDialog, WebUploadDialogData} from "./data-add/web-data-add-dialogs/upload.dialog";
import {AnnotateComponent} from "./image-annotation/annotate/annotate.component";
import {ImageAnnotationComponent} from "./image-annotation/image-annotation.component";
import {AnnotationListComponent} from "./image-annotation/annotate/annotation-list/annotation-list.component";
import {
  AnnotateDialogComponent,
  AnnotateDialogContentComponent
} from "./image-annotation/annotate/annotate-dialog/annotate-dialog.component";
import {LocationComponent} from "./image-annotation/location/location.component";
import {
  LocationDialogComponent,
  LocationDialogContent
} from "./image-annotation/location/location-dialog/location-dialog.component";
import {FileBrowseComponent} from "./file/browse/file-browse.component";
import {ImageViewingComponent} from "./image-viewing/image-viewing.component";
import {DragDropFileUploadDirective} from "../../directives/drag-drop-file-upload.directive";
import {MapService} from "../../services/map/map.service";
import {FileSortingService} from "../../services/files/sorting/file-sorting.service";
import {FileGroupingService} from "../../services/files/sorting/file-grouping.service";
import { AreaLoadingComponent } from './area-loading/area-loading.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ErrorComponent } from './error/error.component';
import {RouterModule} from "@angular/router";
import {PopulationSelectComponent} from "./population-select/population-select.component";
import {PopulationService} from "../../services/population/population.service";
import {NewPopulationDialogComponent} from "./population-select/new-population-dialog/new-population-dialog.component";
import {
  PopulationSelectDrawerComponent
} from "./population-select/population-select-drawer/population-select-drawer.component";
import { FileSelectComponent } from './file-select/file-select.component';
import { FileListComponent } from './file-list/file-list.component';
import { PopulationSidebarComponent } from './population-select/population-sidebar/population-sidebar.component';
import {PopulationFeedService} from "../../services/population/population-feed.service";
import { ImageInformationComponent } from './image-annotation/annotate/image-information/image-information.component';
import { PopulationTopNavigationComponent } from './population-select/population-top-navigation/population-top-navigation.component';
import { ImageViewingDialogComponent } from './image-viewing/image-viewing-dialog/image-viewing-dialog.component';
import { LoadingOverlayComponent } from './area-loading/loading-overlay/loading-overlay.component';
import { YearSelectionComponent } from './date/year-selection/year-selection.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { NavigationComponent } from './navigation/navigation.component';
import { UserMenuComponent } from './navigation/user-menu/user-menu.component';
import { NotificationDropdownComponent } from './notifications/notification-center/notification-dropdown/notification-dropdown.component';
import {MatMenuModule} from "@angular/material/menu";
import { AnnotationProfileComponent } from './image-annotation/annotate/annotation-profile/annotation-profile.component';
import { InvitationListComponent } from './invitations/invitation-list/invitation-list.component';
import { NewInvitationComponent } from './invitations/new-invitation/new-invitation.component';
import { GalleryPreviewLoadingComponent } from './gallery/gallery-preview-loading/gallery-preview-loading.component';
import { GalleryPreviewLoadingImageComponent } from './gallery/gallery-preview-loading/gallery-preview-loading-image/gallery-preview-loading-image.component';


@NgModule({
  declarations: [
    DataAddComponent,
    GalleryComponent,
    GalleryImageDialogComponent,
    GalleryImageDialogDataComponent,
    WebUploadDialog,
    WebUploadDialogData,
    AnnotateComponent,
    ImageAnnotationComponent,
    AnnotateComponent,
    AnnotationListComponent,
    AnnotateDialogContentComponent,
    AnnotateDialogComponent,
    LocationComponent,
    LocationDialogContent,
    LocationDialogComponent,
    FileBrowseComponent,
    ImageViewingComponent,
    DragDropFileUploadDirective,
    AreaLoadingComponent,
    ForbiddenComponent,
    ErrorComponent,
    PopulationSelectComponent,
    PopulationSelectDrawerComponent,
    NewPopulationDialogComponent,
    FileSelectComponent,
    FileListComponent,
    PopulationSidebarComponent,
    ImageInformationComponent,
    PopulationTopNavigationComponent,
    ImageViewingDialogComponent,
    LoadingOverlayComponent,
    YearSelectionComponent,
    UserIconComponent,
    NavigationComponent,
    UserMenuComponent,
    NotificationDropdownComponent,
    AnnotationProfileComponent,
    InvitationListComponent,
    NewInvitationComponent,
    GalleryPreviewLoadingComponent,
    GalleryPreviewLoadingImageComponent

  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MatMenuModule
  ],
    exports: [
        MaterialModule,
        DragDropFileUploadDirective,
        GalleryComponent,
        AreaLoadingComponent,
        AnnotateComponent,
        ErrorComponent,
        PopulationSelectComponent,
        PopulationSelectDrawerComponent,
        FileSelectComponent,
        FileListComponent,
        FileBrowseComponent,
        PopulationSidebarComponent,
        PopulationTopNavigationComponent,
        LoadingOverlayComponent,
        YearSelectionComponent,
        UserIconComponent,
        NavigationComponent,
        GalleryPreviewLoadingComponent,
        GalleryPreviewLoadingImageComponent
    ],
  providers: [
    MapService,
    FileSortingService,
    FileGroupingService,
    PopulationService,
    PopulationFeedService
  ]
})
export class GlobalModule { }

