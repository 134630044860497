<div class="selection-section-header">
  <h4>Photographers</h4>
  <button (click)="reset()" class="reset-button" mat-icon-button><mat-icon>restart_alt</mat-icon></button>
</div>

<mat-form-field color="accent" class="full-width">
  <mat-label>Photographer</mat-label>
  <mat-chip-grid #photographerChipGrid aria-label="Photographer selection" >
    <mat-chip-row *ngFor="let photographer of selectedPhotographers" (removed)="removePhotographer(photographer)">
      {{photographer}}
      <button matChipRemove [attr.aria-label]="'remove ' + photographer">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="Photographer" #photographerInput [formControl]="photographerControl"
         [matChipInputFor]="photographerChipGrid" [matAutocomplete]="auto"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         (matChipInputTokenEnd)="addPhotographer($event)"/>
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selectedPhotographer($event)">
    <mat-option *ngFor="let photographer of filteredPhotographers | async" [value]="photographer">
      {{photographer}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
