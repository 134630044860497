import {Component, Input, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {ImageService} from "../../../../../services/utilities/image.service";
import {
  PopulationBannerUpdateDialogComponent
} from "./population-banner-update-dialog/population-banner-update-dialog.component";
import {PopulationService} from "../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-population-banner',
  templateUrl: './population-banner.component.html',
  styleUrls: ['./population-banner.component.scss']
})
export class PopulationBannerComponent implements OnInit {
  @Input() population: PopulationDto | undefined;
  constructor(
    public imageService: ImageService,
    private dialog: MatDialog,
    private populationService: PopulationService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  updateBanner() {
    const existingBanner = this.population?.banner
    const ref = this.dialog.open(PopulationBannerUpdateDialogComponent, {
      data: this.population
    })
    ref.afterClosed().subscribe(res => {
      if (res && res.banner != existingBanner) {
        this.populationService.updatePopulationDetails(res.id, res).subscribe({
          next: (value) => {
            this.log.info(`Updated the banner for ${res.displayName}`)
          }, error: (value: HttpErrorResponse) => {
            this.errorHandler.handleRequestError("Updating Banner", value);
          }
        })
      }
    })
  }
}
