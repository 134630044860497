import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit {
  @Input() criteria: any = false;
  @Input() diameter: number = 75;

  @Input() title: string | undefined;
  color: any = "#FFFFFF";
  constructor() { }

  ngOnInit(): void {
  }

}
