import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../services/user/authentication.service";
import {
  PasswordConfirmationValidatorService
} from "../../../services/user/validators/password-confirmation-validator.service";
import {ActivatedRoute} from "@angular/router";
import {ResetPasswordDto} from "../../../models/dto/user/resetPasswordDto";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: UntypedFormGroup;
  showSuccess: boolean = false;
  showError: boolean = false;
  errorMessage: string = "";
  loading = false;
  private token: string = "";
  public email: string = "";
  public errors: Array<string> = new Array<string>();

  constructor(private authService: AuthenticationService, private passConfValidator: PasswordConfirmationValidatorService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      confirm: new UntypedFormControl('')
    });

    this.resetPasswordForm!.get('confirm')!.setValidators([Validators.required,
      this.passConfValidator.validateConfirmPassword(this.resetPasswordForm!.get('password')!)]);

    this.token = this.route.snapshot.queryParams['token'];
    this.email = this.route.snapshot.queryParams['email'];
  }

  public validateControl = (controlName: string) => {
    return this.resetPasswordForm.get(controlName)!.invalid && this.resetPasswordForm.get(controlName)!.touched
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.resetPasswordForm.get(controlName)!.hasError(errorName)
  }
  public resetPassword = (resetPasswordFormValue: any) => {
    this.showError = this.showSuccess = false;
    const resetPass = { ... resetPasswordFormValue };
    const resetPassDto: ResetPasswordDto = {
      password: resetPass.password,
      confirmPassword: resetPass.confirm,
      token: this.token,
      email: this.email
    }
    this.authService.resetPassword(resetPassDto)
      .subscribe({
        next:(_) => this.showSuccess = true,
        error: (err: HttpErrorResponse) => {
          this.showError = true;
          this.errorMessage = err.message;
          // @ts-ignore
          this.errors = err.error.errors;
        }})
  }

}
