<div class="content-container">
  <h2>Encounter Date</h2>
  <mat-form-field color="accent" class="full-width">
    <mat-label>Enter a date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="encounterDateTime" [max]="maxDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

</div>
