import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { organizationMemberStatisticsDto } from '../../../../../models/dto/user/organization/organizationMemberStatisticsDto';
import { UserImageDto } from '../../../../../models/dto/user/userImageDto';
import { UserProfileDto } from '../../../../../models/dto/user/userProfileDto';
import { ErrorHandlerService } from '../../../../../services/error/error-handler.service';
import { UserProfileService } from '../../../../../services/user/profile/user-profile.service';
import { ImageService } from '../../../../../services/utilities/image.service';

@Component({
  selector: 'app-organization-profile-member-preview',
  templateUrl: './organization-profile-member-preview.component.html',
  styleUrls: ['./organization-profile-member-preview.component.scss']
})
export class OrganizationProfileMemberPreviewComponent implements OnInit {

  @Input()member!: organizationMemberStatisticsDto;
  public userImage: UserImageDto | undefined | null;
  constructor(
    private profileService: UserProfileService,
    private errorService: ErrorHandlerService,
    private imageService: ImageService
  ) { }

  ngOnInit(): void {
    if (this.member === undefined) return;
    this.profileService.getProfilePicture(this.member.organization_Member).subscribe({
      next: (value: UserImageDto | null | undefined) => {
        if (value && value.image) {
          value.image = this.imageService.maybePrefix(value.image);
        }
        this.userImage = value;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.handleRequestError("Fetching User Profile Image", err);
      }
    })
  }


  getRouterLink(member: organizationMemberStatisticsDto) {
    return `/users/profile/${member.organization_Member.id}`;
  }

  public getJdenticon(member: organizationMemberStatisticsDto) {
    return `${member.organization_Member.firstName} ${member.organization_Member.lastName} ${member.organization_Member.email}`;
  }

  maybePrefix(thumbnail: string | undefined | null) {
    if (thumbnail === undefined || thumbnail === null) {
      return "";
    }
    return this.imageService.maybePrefix(thumbnail);
  }
}
