<div class="content-container">
  <div class="content">
    <div class="content-header">
      <div class="">
        <h1>Administration</h1>
      </div>
    </div>
    <div class="content-items">
      <app-organization-administration [organizations]="organizations"></app-organization-administration>
      <app-population-administration [populations]="populations"></app-population-administration>
      <app-platform-administration *ngIf="admin"></app-platform-administration>
    </div>
  </div>
</div>
