<div class="encounter-page-container">
  <div *ngIf="populationSettings !== undefined && encounter !== undefined" class="encounter-sidebar">
    <app-encounter-page-sidebar [dto]="encounter" [workspace]="workspace" [request] ="request"></app-encounter-page-sidebar>
  </div>
  <div class="encounter-content" *ngIf="populationSettings !== undefined && encounter !== undefined">
    <app-single-encounter-container
      [encounter]="encounter"
      [populationSettings]="populationSettings"
      [workspace]="workspace"
      [tooltip]="false"
      [title]="'Encounter Images'"
      [selectedImageId]="selectedImage"
      ></app-single-encounter-container>


    </div>
  <div class="encounter-comments" *ngIf="commentSection !== undefined">
    <app-encounter-comments [commentSection]="commentSection" [encounter]="encounter"></app-encounter-comments>
  </div>
</div>

