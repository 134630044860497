<div class="selection-content" *ngIf="dto">
  <div class="selection-options-container">
    <div class="selection-content-header">
      <h3>Search</h3>
    </div>
    <div class="selection-section">
      <div class="selection-section-header">
        <h4>Data Sources</h4>
        <button mat-icon-button class="reset-button" (click)="resetDataSources()"><mat-icon>restart_alt</mat-icon></button>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.dataSources!.publicData"
        >
          All Public Data
        </mat-checkbox>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.dataSources!.organizationalData"
        >
          My Organizations
        </mat-checkbox>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.dataSources!.personalData"
        >
          My Personal Data
        </mat-checkbox>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="selection-section" *ngIf="animals && selectedAnimals && filteredAnimals">
      <div class="selection-section-header">
        <h4>Individuals of Interest</h4>
        <button mat-icon-button class="reset-button" (click)="resetIndividuals()"><mat-icon>restart_alt</mat-icon></button>
      </div>
      <mat-form-field color="accent" class="full-width">
        <mat-label>Individuals</mat-label>
        <mat-chip-grid #chipGrid aria-label="Fruit selection" >
          <mat-chip-row *ngFor="let animal of selectedAnimals" (removed)="remove(animal)">
            {{animal}}
            <button matChipRemove [attr.aria-label]="'remove ' + animal">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Animal Identifier" #individualInput [formControl]="individualControl"
               [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="add($event)"/>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let animal of filteredAnimals | async" [value]="animal">
            {{animal}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
<!--      <mat-form-field  color="accent">-->
<!--        <mat-label>Individual ID</mat-label>-->
<!--        <mat-select multiple [(ngModel)]="dto.individuals">-->
<!--          <input matInput (keyup)="onKey($event)">-->
<!--          <mat-option [value]="animal.id" *ngFor="let animal of selectedAnimals">{{animal.identifier}}</mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
    </div>
    <mat-divider></mat-divider>
    <div class="selection-section">
      <div class="selection-section-header">
        <h4>Position</h4>
        <button mat-icon-button class="reset-button" (click)="resetPosition()"><mat-icon>restart_alt</mat-icon></button>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.position!.left"
        >
          Left Side
        </mat-checkbox>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.position!.right"
        >
          Right Side
        </mat-checkbox>
      </div>
    </div>
<!--    <mat-divider></mat-divider>-->
<!--    <div class="selection-section">-->
<!--      <div class="selection-section-header">-->
<!--        <h4>Attributes</h4>-->
<!--      </div>-->
<!--      <div class="selection-option">-->
<!--        <mat-checkbox class="selection-option-checkbox"-->
<!--                      color="accent"-->
<!--                      labelPosition="before"-->
<!--                      [(ngModel)]="dto.attributes!.eyepatch"-->
<!--        >-->
<!--          Eyepatch-->
<!--        </mat-checkbox>-->
<!--      </div>-->
<!--    </div>-->
    <mat-divider></mat-divider>
    <div class="selection-section">
      <div class="selection-section-header">
        <h4>Quality</h4>
        <button mat-icon-button class="reset-button" (click)="resetQuality()"><mat-icon>restart_alt</mat-icon></button>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.quality!.noteworthy"
        >
          Noteworthy
        </mat-checkbox>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.quality!.good"
        >
          Good
        </mat-checkbox>
      </div>
      <div class="selection-option">
        <mat-checkbox class="selection-option-checkbox"
                      color="accent"
                      labelPosition="after"
                      [(ngModel)]="dto.quality!.excellent"
        >
          Excellent
        </mat-checkbox>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="selection-section">
      <div class="selection-section-header">
        <h4>Time Range</h4>
        <button mat-icon-button class="reset-button" (click)="resetTime()"><mat-icon>restart_alt</mat-icon></button>
      </div>
      <mat-form-field  color="accent"  class="full-width">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input formControlName="start" matStartDate placeholder="Start date">
          <input formControlName="end" matEndDate placeholder="End date">
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
  </div>

  <div class="button-row">
    <button mat-stroked-button color="accent" (click)="submitSearch()"><span>Search</span></button>
    <button mat-stroked-button color="accent" (click)="reset()">Reset</button>
  </div>
</div>

