import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {PopulationDto } from 'src/models/dto/population/populationDto';
import {LocationService} from "../../../../../services/location/location.service";
import {PopulationService} from "../../../../../services/population/population.service";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {Location} from "../../../../../models/location/location.model";
import {EncounterPageRequestDto} from "../../../../../models/dto/encounter/encounterPageRequestDto";

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent implements OnInit, OnChanges {
  @Input() workspace: WorkspaceDto | undefined;
  @Input() population: PopulationDto | undefined;
  @Input() locations: Array<Location> | undefined;
  @Input() request: EncounterPageRequestDto | undefined;
  @Output() chosenLocations: EventEmitter<Array<Location>> = new EventEmitter<Array<Location>>();

  @ViewChild('locationInput') locationInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  locationControl = new FormControl();
  public filteredLocations: Observable<string[]> | undefined;
  public selectedLocations: string[] = new Array<string>();


  constructor(
    private locationService: LocationService,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService,
  ) { }

  public reset() {
    this.locationControl.reset();
    this.selectedLocations = new Array<string>();
    this.chosenLocations.emit(this.getLocations());
  }

  ngOnInit() {
    if (this.locations) {
      this.filteredLocations = this.locationControl.valueChanges.pipe(
        startWith(null),
        map((location: string | null) => (location ? this._filterLocations(location) : this.locations!.map(a => a.name!).slice())),
      );
    }

    if (this.request && this.request.locations && this.locations) {

      const locations = this.locations.filter(a => this.request?.locations?.includes(a.name!));
      for (let a of locations) {

        if (this.selectedLocations.indexOf(a.name!) == -1) {
          this.selectedLocations.push(a.name!);
        }
      }
      this.chosenLocations.emit(this.getLocations());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["locations"] && changes["locations"]["currentValue"]) {
      this.locations = changes["locations"]["currentValue"]
      this.filteredLocations = this.locationControl.valueChanges.pipe(
        startWith(null),
        map((location: string | null) => (location ? this._filterLocations(location) : this.locations!.map(a => a.name!).slice())),
      );
    }
    if (this.request && this.request.locations && this.locations) {

      const locations = this.locations.filter(a => this.request?.locations?.includes(a.name!));
      for (let a of locations) {
        if (this.selectedLocations.indexOf(a.name!) == -1) {
          this.selectedLocations.push(a.name!);
        }

      }
      this.chosenLocations.emit(this.getLocations());
    }
  }


  addLocation(event: MatChipInputEvent): void {
    const value = event.value;
    if (value) {
      this.selectedLocations.push(value);
    }
    event.chipInput!.clear();
    this.locationControl.setValue(null);
    this.chosenLocations.emit(this.getLocations());
  }

  removeLocation(photographer: string): void {
    const index = this.selectedLocations.indexOf(photographer);

    if (index >= 0) {
      this.selectedLocations.splice(index, 1);
    }
    this.chosenLocations.emit(this.getLocations());
  }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    this.selectedLocations.push(event.option.value);
    this.locationInput.nativeElement.value = '';
    this.locationControl.setValue(null);
    this.chosenLocations.emit(this.getLocations());
  }

  private _filterLocations(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.locations!.map(a => a.name!).filter(a => a.toLowerCase().includes(filterValue));
  }

  private getLocations() {
    return this.locations!.filter(a => this.selectedLocations.indexOf( a.name!) !== -1);
  }
}
