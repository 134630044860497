
<div class="about-banner" *ngIf="workspace && population && population.banner">
  <div class="banner-img">
    <img [src]="population.banner" />
  </div>
</div>

<div class="component-container" *ngIf="workspace">
  <div class="content" *ngIf="population ; else loading">
    <mat-divider></mat-divider>
    <div class="descriptive-content">
      <div class="blurb description">
        <h2>Information</h2>
        <div class="" [innerHTML]="population.description"></div>
      </div>
      <div class="stats description">
        <h2>Statistics</h2>
        <div class="stats-container" *ngIf="statistics; else loading">
          <h3><mat-icon>chevron_right</mat-icon>{{statistics.memberCount}} Contributors</h3>
          <h3><mat-icon>chevron_right</mat-icon>{{statistics.encounterCount}} Encounters</h3>
          <h3><mat-icon>chevron_right</mat-icon>{{statistics.imageCount}} Images</h3>
          <h3><mat-icon>chevron_right</mat-icon>{{statistics.detectionCount}} AI Fin Detections</h3>
          <h3><mat-icon>chevron_right</mat-icon>{{statistics.identityPredictionCount}} AI Predicted Identifications</h3>
          <h3><mat-icon>chevron_right</mat-icon>{{statistics.individualCount}} Unique Individuals</h3>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="contributors" *ngIf="contributors"   [ngClass]="{'dark-logo-background': isDark}">
      <a class="contributor" [href]="contributor.link" target="_blank" *ngFor="let contributor of contributors">
          <div class="contributor-logo centered">
            <img [src]="contributor.icon" [alt]="contributor.name">
          </div>
      </a>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-area-loading [criteria]="false" title="Fetching Population Information..."></app-area-loading>
</ng-template>
