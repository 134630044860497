import {Component, OnInit} from '@angular/core';
import {InvitationService} from "../../../../services/invitations/invitation.service";
import {InvitationDto, InvitationPrivilegeDto} from "../../../../models/dto/invitations/invitationDto";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../services/error/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {NewInvitationComponent} from "../new-invitation/new-invitation.component";
import {DateService} from "../../../../services/utilities/date.service";
import {ResponseDto} from "../../../../models/dto/response/responseDto";

@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.scss']
})
export class InvitationListComponent implements OnInit {

  public invitations?: Array<InvitationDto> = new Array<InvitationDto>();
  constructor(
    private invitationService: InvitationService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService,
    private dialog: MatDialog,
    public dateService: DateService
  ) {}

  public loading = false;
  public displayedColumns = ['expirationDate', 'limit', 'redeemed', "privileges", 'link', "delete"]

  ngOnInit() {
    this.loading = true;
    this.invitationService
      .getInvitations()
      .subscribe({
        next: (value: Array<InvitationDto>) => {
          this.invitations = value;
          this.loading = false;
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Fetching Invitations", value);
          this.loading = false;
        }

      })
  }

  addInvitation() {
    const ref = this.dialog.open(NewInvitationComponent, {
      data: {
        limit: 1,
        expirationDate: undefined,
        privileges: new Array<InvitationPrivilegeDto>()
      }
    })
    ref.afterClosed().subscribe(res => {
      if (res !== undefined) {
        this.ngOnInit();
      }
    })
  }

  delete(invitation: InvitationDto) {
    this.loading = true;
    this.invitationService
      .deleteInvitation(invitation.id!)
      .subscribe({
        next: (value: ResponseDto) => {
          this.log.info("Invitation deleted.")
          this.loading = false;
          this.ngOnInit();
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Deleting invitation", value);
          this.loading = false;
        }
      })
  }
}
