import {DataSourceDto} from "../animal/animalSearchDto";
import {AnimalBehaviorDto} from "../behavior/animalBehaviorDto";

export class EncounterPageRequestDto {
  start?: Date;
  end?: Date;
  encounterLimit?: number;
  populationId?: string;
  imageLimit?: number;
  photographers?: Array<string>;
  locations?: Array<string>;
  exclude?: Array<string>;
  view?: string;
  status?: string;
  startIdx?: number;
  endIdx?: number;
  maximumIdx?: number;
  complete?: string;

  dataSources?: DataSourceDto;

  individuals?: Array<string>;

  behaviors?: Array<string>;

  constructor(populationId: string, view: string, encounterLimit: number = 25) {
    this.dataSources = new DataSourceDto();
    this.populationId = populationId;
    this.encounterLimit = encounterLimit;
    this.view = view;
    this.startIdx = 0;
    this.endIdx = this.startIdx + this.encounterLimit;
  }

}
