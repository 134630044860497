import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "../services/user/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private authService: AuthenticationService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isUserAdmin()) {
      return true;
    }
    this.router.navigate(['/forbidden'], {queryParams: {returnUrl: state.url}});
    return false;
  }

}
