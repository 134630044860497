import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../services/user/authentication.service";
import {ForgotPasswordDto} from "../../../models/dto/user/forgotPasswordDto";
import {HttpErrorResponse} from "@angular/common/http";
import {Environment} from "@angular/cli/lib/config/workspace-schema";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  resetForm!: UntypedFormGroup;
  successMessage: string = "";
  errorMessage: string = "";
  showSuccess: boolean = false;
  showError: boolean = false;
  loading = false;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.resetForm = new UntypedFormGroup({
      email: new UntypedFormControl("", [Validators.required]),
    })
  }

  sendPasswordResetEmail(resetFormValue: any) {
    const forgotPass = { ...resetFormValue };
    const forgotPassDto: ForgotPasswordDto = {
      email: forgotPass.email,
      clientUri: `${environment.hostUrl}/resetpassword`
    }
    this.loading = true;
    this.authenticationService.forgotPassword(forgotPassDto)
      .subscribe({
        next: (_) => {
          this.showSuccess = true;
          this.successMessage = 'The link has been sent, please check your email to reset your password.';
          this.showError = false;
          this.loading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.showError = true;
          this.errorMessage = "Email not found";
          this.showSuccess = false;
          this.loading = false;
        }});
  }

}
