<div class="card-list" *ngIf="animals">
  <mat-card *ngFor="let animal of animals" class="individual-card">
    <mat-card-header>
      <mat-card-title>
        <h2>{{animal.identifier.split(" / ")[0]}}</h2>
      </mat-card-title>
      <mat-card-subtitle *ngIf="animal.alternateIds !== undefined && animal.alternateIds.length > 0">
        {{animal.alternateIds.join(', ')}}
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="animal.alternateIds === undefined || animal.alternateIds.length === 0">
        <br />
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="card-content">
      <div class="static" *ngIf="!animal.editing">
        <div class="individual-entry">
          <h4><b>Primary Identifier</b></h4>
          <h4>{{animal.identifier.split(" / ")[0]}}</h4>
        </div>
        <div class="individual-entry" *ngIf="animal.alternateIds !== undefined">
          <h4><b>Secondary Identifiers</b></h4>
          <h4>{{animal.alternateIds.join(' / ')}}</h4>
        </div>
        <div class="individual-entry">
          <h4><b>Nickname</b></h4>
          <h4>{{animal.nickname}}</h4>
        </div>
        <div class="individual-entry">
          <h4><b>Sex</b></h4>
          <h4>{{animal.sex}}</h4>
        </div>
        <div class="individual-entry">
          <h4><b>Birth Year</b></h4>
          <h4>{{animal.dateOfBirth}}</h4>
        </div>
        <div class="individual-entry">
          <h4><b>Maximum Death Year</b></h4>
          <h4>{{animal.dateOfDeath}}</h4>
        </div>
      </div>
      <div class="static" *ngIf="animal.editing">
        <div class="individual-entry alternate-ids">
          <h4>Alternate Identifiers</h4>
          <button mat-icon-button (click)="addAlternateId(animal)"><mat-icon>add</mat-icon></button>
          <div *ngIf="animal.alternateIdElements !== undefined">
            <div *ngFor="let altId of animal.alternateIdElements">
              <mat-form-field color="accent">
                <mat-label>Identifier</mat-label>
                <input matInput [(ngModel)]="altId.identifier" />
              </mat-form-field>
            </div>
          </div>

        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="button-row">
        <button mat-button *ngIf="!animal.editing" (click)="animal.editing = true">Update</button>
        <button mat-button *ngIf="animal.editing" (click)="save(animal)">Save Changes</button>
        <button mat-button *ngIf="animal.editing" (click)="animal.editing = false">Cancel</button>
        <button mat-button color=warn *ngIf="animal.editing" (click)="deleteAnimal(animal)">Delete</button>
      </div>

    </mat-card-actions>
  </mat-card>

</div>

<app-area-loading [criteria]="animals" title="Fetching Individuals"></app-area-loading>
