import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PopulationService} from "../../../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-population-members-page',
  templateUrl: './population-members-page.component.html',
  styleUrls: ['./population-members-page.component.scss']
})
export class PopulationMembersPageComponent implements OnInit, OnDestroy {
  public population?: PopulationDto

  private subs: Array<Subscription> = new Array<Subscription>()
  public loading = false;
  public id?: string;

  constructor(
    private route: ActivatedRoute,
    private populationService: PopulationService,
    private log: ILoggingService
  ) {
  }

  ngOnDestroy() {
    for(let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe({
        next: (value: any) => {
          if (value["id"]) {
            this.id = value["id"]
            this.loading = true;
            this.loadPopulation();
          }

        }
      })
    )
  }

  private loadPopulation() {
    if (!this.id) {
      this.loading = false;
      return;
    }
    this.subs!.push(this.populationService.getPopulationById(this.id).subscribe({
      next: (value:PopulationDto) => {
        this.population = value;
        this.loading = false;
      },
      error: (value: HttpErrorResponse) => {
        this.log.error(`Could not load population: ${value.message};`)
        this.loading = false;
      }
    }))
  }

}
