<div class="content-container" *ngIf="encounter && canView">
  <div class="content-header">
    <h3>{{title}}</h3>
    <button mat-icon-button (click)="editing = !editing" *ngIf="canEdit && !editing"><mat-icon>edit</mat-icon></button>
  </div>
  <div class="content notes" *ngIf="!editing && !internal" [innerHTML]="encounter.description"></div>
  <div class="content notes" *ngIf="!editing && internal" [innerHTML]="encounter.internalNotes"></div>
  <div class="content notes" *ngIf="editing">
<!--    <mat-form-field color="accent" class="full-width">-->
<!--      <mat-label>Encounter Notes</mat-label>-->
<!--      <textarea matInput [(ngModel)]="encounter!.description" (focusout)="saveNotes()"></textarea>-->
<!--    </mat-form-field>-->
    <div class="notes-container" *ngIf="!internal">
      <ckeditor [editor]="Editor" [data]="encounter.description!"  [tagName]="'editor'" (change)="update($event)"></ckeditor>

    </div>
    <div class="notes-container" *ngIf="internal">
      <ckeditor [editor]="Editor" [data]="encounter.internalNotes!"  [tagName]="'editor'" (change)="update($event)"></ckeditor>

    </div>
    <div class="button-row">
      <button mat-stroked-button color="accent" (click)="saveNotes()">Save</button>
      <button mat-stroked-button color="accent" (click)="editing = false">Cancel</button>
    </div>


  </div>
</div>
<mat-divider></mat-divider>
