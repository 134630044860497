import { ElectronImageService } from "../../../../services/files/images/image.service.electron";
import {LocalImageService} from "../../../../services/files/images/image.service.local";
import {environment} from "../../../../environments/environment";
import {IFileStorageService} from "../../../../services/storage/file/file-storage.service.interface";
import {ImageMetaDataService} from "../../../../services/files/images/meta/image-meta-data.service";
import {LocationService} from "../../../../services/location/location.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {HttpClient} from "@angular/common/http";
import {WebImageService} from "../../../../services/files/images/image.service.web";
import {DomSanitizer} from "@angular/platform-browser";
import {ImageService} from "../../../../services/utilities/image.service";

export let imageServiceFactory = (
  fileStorageService: IFileStorageService,
  metaDataService: ImageMetaDataService,
  locationService: LocationService,
  log: ILoggingService,
  httpClient: HttpClient,
  sanitizer: DomSanitizer,
  imageUtilities: ImageService
) => {
  if (environment.production && environment.electron) {
    return new ElectronImageService(log, fileStorageService, metaDataService, locationService, httpClient, sanitizer);
  }  else if ((environment.production && !environment.electron) || environment.server.debugMode) {
    return new WebImageService(log, fileStorageService, metaDataService, locationService, httpClient, sanitizer, imageUtilities)
  } else {
    return new WebImageService(log, fileStorageService, metaDataService, locationService, httpClient, sanitizer, imageUtilities);
  }
}
