<h1 mat-dialog-title>New Contributor</h1>
<div mat-dialog-content>
  <form [formGroup]="newUserForm">
    <mat-form-field color="accent">
      <mat-label>First Name</mat-label>
      <input matInput  formControlName="firstName"/>
    </mat-form-field>
    <mat-form-field  color="accent">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName"/>
    </mat-form-field>
    <mat-form-field color="accent">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" (keyup)="isValidEmail()" (ngModelChange)="isValidEmail()"/>
    </mat-form-field>

    <mat-checkbox formControlName="informUser"  [matTooltip]="'User will be informed by Email of their new account'" matTooltipPosition="before" color="accent">
      Inform User of their new Account?
    </mat-checkbox>

    <div *ngIf="!newUserForm.controls['email'].valid && newUserForm.controls['email'].touched">
      <span *ngIf="newUserForm.controls['email'].getError('taken')">That email already exists in our system</span>
    </div>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-button color="accent" [mat-dialog-close]="newUserForm.value" [disabled]="newUserForm.invalid">Save New Contributor</button>
  <button mat-button (click)="onNoClick()" >Cancel</button>
</div>
