import {Component, OnInit} from '@angular/core';
import {InvitationService} from "../../../../services/invitations/invitation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {InvitationValidationResponseDto} from "../../../../models/dto/invitations/invitationValidationResponseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../services/error/error-handler.service";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {
  PasswordConfirmationValidatorService
} from "../../../../services/user/validators/password-confirmation-validator.service";
import {MyErrorStateMatcher} from "../../login/login.component";
import {UserForRegistrationDto} from "../../../../models/dto/user/userForRegistrationDto";
import {InvitationRedemptionCompletionDto} from "../../../../models/dto/invitations/invitationRedemptionCompletionDto";
import {ResponseDto} from "../../../../models/dto/response/responseDto";

@Component({
  selector: 'app-invitation-redeem',
  templateUrl: './invitation-redeem.component.html',
  styleUrls: ['./invitation-redeem.component.scss']
})
export class InvitationRedeemComponent implements OnInit {

  constructor(
    private invitationService: InvitationService,
    private route: ActivatedRoute,
    private router: Router,
    private log: ILoggingService,
    public passwordConfirmationValidator: PasswordConfirmationValidatorService,
    private errorHandler: ErrorHandlerService
    ) {
  }
  matcher = new MyErrorStateMatcher();
  registrationErrors: any = [];
  public redemptionId: string | undefined;
  public invitationKey: string | undefined;
  registerForm!: UntypedFormGroup;
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  public validating = false;
  public registering = false;
  public validationErrorMessages: Array<string> = new Array<string>()
  ngOnInit() {
    this.registerForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
      confirm: new UntypedFormControl('')
    });
    this.registerForm.get("confirm")?.setValidators([
      Validators.required,
      this.passwordConfirmationValidator
        .validateConfirmPassword(<AbstractControl>this.registerForm.get('password'))
    ]);

    this.validating = true;
    this.route.params.subscribe(res => {
      if (res["key"] !== undefined) {
        this.invitationKey = res["key"];
        this.invitationService.validateKey(res["key"]).subscribe({
          next: (value: InvitationValidationResponseDto ) => {
            if (value.successful) {
              this.redemptionId = value.redemptionId!
            } else {
              this.log.error(`Could not validate invitation key`)
              this.validationErrorMessages = value.errorMessages
            }
            this.validating = false;
          },
          error: (value: HttpErrorResponse) => {
            this.errorHandler.handleRequestError("Validating Key", value);
            this.validationErrorMessages = [`${value.error} (${this.invitationKey})`]
            this.validating = false;
          }
        })
      }
    })
  }

  registerUser(registerFormValue: any) {
    this.registering = true;
    const formValues = { ...registerFormValue };
      const user: UserForRegistrationDto = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        password: formValues.password,
        confirmPassword: formValues.confirm
      };
    const dto: InvitationRedemptionCompletionDto = {
      user: user,
      redemptionId: this.redemptionId,
    }

    this.invitationService.completeRedemption(this.invitationKey!, dto).subscribe({
      next: (value: ResponseDto) => {
        this.log.info("Registration successful")
        this.router.navigate(['/login'])
        this.registering = false;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Registering user", value);
        this.registering = false;
      }
    })
  }
}
