import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/user/authentication.service";
import {environment} from "../../environments/environment";
import {StyleManagerService} from "../../services/style-manager/style-manager.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isDark = true;
  constructor(
    private authService: AuthenticationService,
    public themService: StyleManagerService
  ) {
  }
  headerImgSrc = environment.headerImg;
  // headerImgSrc = "assets/headerImages/JRT03879.jpg"

  title = environment.appName;
  subTitle = environment.slogan;
  links: Array<{target: string, name: string}> = []

  developers = environment.developers

  ngOnInit(): void {
    this.themService.isDarkObs.subscribe(res => {
      this.isDark = res;
    })
    if (!this.authService.isUserAuthenticated()) {
      this.links.push({target: "login", name: "Login"})
    } else {
      this.links.push(
      {
        target: "submit",
          name: "Add Your Data"
      },
      {
        target: "encounters",
          name: "Submit A Query"
      }
      )
    }
  }
}
