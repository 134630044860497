import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GlobalModule} from "../global/global.module";
import {RouterModule} from "@angular/router";
import { SubmitDataComponent } from './submit-data/submit-data.component';

import { DataExportComponent } from './data-export/data-export.component';
import {SubmitErrorDialogComponent} from "./submit-data/dialogs/submit-error-dialog/submit-error-dialog.component";
import {SelectMapComponent} from "./select-map/select-map.component";
import {SelectMapDialogComponent} from "./select-map/select-map-dialog/select-map-dialog.component";
import {MaterialModule} from "../material/material.module";
import {ISearchService} from "../../services/search/search.service.interface";
import {HttpClient} from "@angular/common/http";
import {searchServiceFactory} from "../../factories/services/search/search.service.factory";
import {DateService} from "../../services/utilities/date.service";
import {
  NewSubmissionUserDialogComponent
} from "./submit-data/dialogs/newSubmissionUserDialog/new-submission-user-dialog.component";
import { SubmissionUserComponent } from './submit-data/submission-user/submission-user.component';
import { SubmissionLocationComponent } from './submit-data/submission-location/submission-location.component';
import { SubmissionImagesComponent } from './submit-data/submission-images/submission-images.component';
import { SubmissionImagesPreviewComponent } from './submit-data/submission-images-preview/submission-images-preview.component';
import { SubmissionPreyComponent } from './submit-data/submission-prey/submission-prey.component';
import { AboutComponent } from './about/about.component';
import { EncounterGridComponent } from './encounter/encounter-grid/encounter-grid.component';
import { EncounterPreviewComponent } from './encounter/encounter-grid/encounter-preview/encounter-preview.component';
import { EncounterPageComponent } from './encounter/encounter-page/encounter-page.component';
import { EncounterPageSidebarComponent } from './encounter/encounter-page/encounter-page-sidebar/encounter-page-sidebar.component';
import { EncounterTimelineComponent } from './encounter/encounter-timeline/encounter-timeline.component';
import { SelectMapDialogMapComponent } from './select-map/select-map-dialog/select-map-dialog-map/select-map-dialog-map.component';
import { SubmissionOrganizationComponent } from './submit-data/submission-organization/submission-organization.component';
import { SubmissionDateComponent } from './submit-data/submission-date/submission-date.component';
import { SubmissionLimitationComponent } from './submit-data/submission-limitation/submission-limitation.component';
import { EncounterNotesComponent } from './encounter/encounter-page/encounter-page/encounter-notes/encounter-notes.component';
import { EncounterFlagsComponent } from './encounter/encounter-page/encounter-page/encounter-flags/encounter-flags.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatChipsModule} from "@angular/material/chips";
import { EncounterMergeComponent } from './encounter/encounter-page/encounter-page/encounter-merge/encounter-merge.component';
import { EventsMapComponent } from './events-map/events-map.component';
import { SubmissionNotesComponent } from './submit-data/submission-notes/submission-notes.component';
import { EncounterUserProfileImageComponent } from './encounter/encounter-timeline/encounter-user-profile-image/encounter-user-profile-image.component';
import {NgxJdenticonModule} from "ngx-jdenticon";
import { EncounterCommentsComponent } from './encounter/encounter-page/encounter-comments/encounter-comments.component';
import { CommentTemplateComponent } from './encounter/encounter-page/encounter-comments/comment-template/comment-template.component';
import { AnimalProfilesComponent } from './animal-profile/animal-profiles.component';
import {ProfileComponent} from "./animal-profile/profile/profile.component";
import {
  RelationshipUpdateDialogComponent
} from "./animal-profile/profile/relationshipUpdateDialog/relationship-update-dialog.component";
import {
  SingleEncounterContainerComponent
} from "./encounter/encounter-page/single-encounter-container/single-encounter-container.component";
import {ProfileManagementComponent} from "./animal-profile/profile/profile-management/profile-management.component";
import {
  ProfileImageManagementComponent
} from "./animal-profile/profile/profile-image-management/profile-image-management.component";
import {
  ProfileSightingInformationComponent
} from "./animal-profile/profile/profile-sighting-information/profile-sighting-information.component";
import {
  ProfileRelationshipInformationComponent
} from "./animal-profile/profile/profile-relationship-information/profile-relationship-information.component";
import {
  ImageSelectDialogComponent
} from "./animal-profile/profile/profile-image-management/image-select-dialog/image-select-dialog.component";
import {
  ProfileNotesManagementComponent
} from "./animal-profile/profile/profile-notes-management/profile-notes-management.component";
import {
  EncounterEditComponent
} from "./encounter/encounter-page/single-encounter-container/encounter-edit-component/encounter-edit.component";
import {
  UpdateDetailsComponent
} from "./encounter/encounter-page/single-encounter-container/encounter-edit-component/update-details-component/update-details.component";
import {
  UpdateLocationComponent
} from "./encounter/encounter-page/single-encounter-container/encounter-edit-component/update-location-component/update-location.component";
import {
  AddImagesComponent
} from "./encounter/encounter-page/single-encounter-container/encounter-edit-component/add-images/add-images.component";
import {ProfileTimelineComponent} from "./animal-profile/profile/profile-timeline/profile-timeline.component";
import {
  IndividualSearchDrawerComponent
} from "./animal-profile/individual-search-drawer/individual-search-drawer.component";
import {
  IndividualSearchGalleryComponent
} from "./animal-profile/individual-search-gallery/individual-search-gallery.component";
import {
  DeleteEncounterDialogComponent
} from "./encounter/encounter-page/single-encounter-container/encounter-edit-component/delete-encounter-dialog/delete-encounter-dialog.component";
import {ProfileMergeComponent} from "./animal-profile/profile/profile-merge/profile-merge.component";
import {
  IndividualSelectionComponent
} from "./encounter/encounter-selection-drawer/individual-selection/individual-selection.component";
import {
  PhotographerSelectionComponent
} from "./encounter/encounter-selection-drawer/photographer-selection/photographer-selection.component";
import {
  LocationSelectionComponent
} from "./encounter/encounter-selection-drawer/location-selection/location-selection.component";
import {
  EncounterStatusSelectionComponent
} from "./encounter/encounter-selection-drawer/encounter-status-selection/encounter-status-selection.component";
import {
  EncounterSelectionDrawerComponent
} from "./encounter/encounter-selection-drawer/encounter-selection-drawer.component";
import { EncounterMergeDialogComponent } from './encounter/encounter-page/single-encounter-container/encounter-edit-component/encounter-merge-dialog/encounter-merge-dialog.component';
import { EncounterCompletionStatusComponent } from './encounter/encounter-page/encounter-page-sidebar/encounter-completion-status/encounter-completion-status.component';
import { EncounterCompleteStatusSelectionComponent } from './encounter/encounter-selection-drawer/encounter-complete-status-selection/encounter-complete-status-selection.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import { UpdateOrganizationsComponent } from './encounter/encounter-page/single-encounter-container/encounter-edit-component/update-organizations/update-organizations.component';
import { SubmissionLicenseVerificationComponent } from './submit-data/submission-license-verification/submission-license-verification.component';
import { SubmissionBehaviorComponent } from './submit-data/submission-behavior/submission-behavior.component';
import {
  BehaviorSelectionComponent
} from "./encounter/encounter-selection-drawer/behavior-selection/behavior-selection.component";
import { ProfilePreviewComponent } from './animal-profile/profile-preview/profile-preview.component';
import { EncounterPredationComponent } from './encounter/encounter-page/encounter-page-sidebar/encounter-predation/encounter-predation.component';
import { BulkItemActionConfirmationComponent } from './encounter/encounter-page/single-encounter-container/encounter-edit-component/bulk-item-action-confirmation/bulk-item-action-confirmation.component';
import { UpdatePopulationComponent } from './encounter/encounter-page/single-encounter-container/encounter-edit-component/update-population-component/update-population-component.component';
import { SubmissionPopulationComponent } from './submit-data/submission-population/submission-population.component';
import { DataExportDownloadComponent } from './data-export/data-export-download/data-export-download.component';
import { DataExportReviewComponent } from './data-export/data-export-review/data-export-review.component';
import { SubmissionPreyLocationComponent, PreyLocationData  } from './submit-data/submission-prey/submission-prey-location/submission-prey-location.component';



@NgModule({
  declarations: [
    SubmitDataComponent,
    AnimalProfilesComponent,
    ProfileComponent,
    DataExportComponent,
    SubmitErrorDialogComponent,
    RelationshipUpdateDialogComponent,
    SelectMapComponent,
    SelectMapDialogComponent,
    SingleEncounterContainerComponent,
    NewSubmissionUserDialogComponent,
    SubmissionUserComponent,
    ProfileManagementComponent,
    ProfileImageManagementComponent,
    ProfileSightingInformationComponent,
    ProfileRelationshipInformationComponent,
    ImageSelectDialogComponent,
    ProfileNotesManagementComponent,
    EncounterEditComponent,
    UpdateDetailsComponent,
    UpdateLocationComponent,
    AddImagesComponent,
    SubmissionLocationComponent,
    SubmissionImagesComponent,
    SubmissionImagesPreviewComponent,
    SubmissionPreyComponent,
    ProfileTimelineComponent,
    AboutComponent,
    IndividualSearchDrawerComponent,
    EncounterGridComponent,
    EncounterPreviewComponent,
    EncounterPageComponent,
    EncounterPageSidebarComponent,
    EncounterTimelineComponent,
    EncounterSelectionDrawerComponent,
    SelectMapDialogMapComponent,
    SubmissionOrganizationComponent,
    SubmissionDateComponent,
    SubmissionLimitationComponent,
    IndividualSearchGalleryComponent,
    DeleteEncounterDialogComponent,
    EncounterNotesComponent,
    EncounterFlagsComponent,
    ProfileMergeComponent,
    EncounterMergeComponent,
    EventsMapComponent,
    EventsMapComponent,
    IndividualSelectionComponent,
    PhotographerSelectionComponent,
    LocationSelectionComponent,
    SubmissionNotesComponent,
    EncounterUserProfileImageComponent,
    EncounterCommentsComponent,
    CommentTemplateComponent,
    EncounterStatusSelectionComponent,
    EncounterMergeDialogComponent,
    EncounterCompletionStatusComponent,
    EncounterCompleteStatusSelectionComponent,
    UpdateOrganizationsComponent,
    SubmissionLicenseVerificationComponent,
    SubmissionBehaviorComponent,
    BehaviorSelectionComponent,
    ProfilePreviewComponent,
    EncounterPredationComponent,
    BulkItemActionConfirmationComponent,
    UpdatePopulationComponent,
    SubmissionPopulationComponent,
    DataExportDownloadComponent,
    DataExportReviewComponent,
    SubmissionPreyLocationComponent,
    PreyLocationData,
    //SubmissionPreyLocationDialogComponent,
  ],
    providers: [
        DateService,
        {
            provide: ISearchService,
            useFactory: searchServiceFactory,
            deps: [HttpClient]
      },
    ],
  imports: [
    CommonModule,
    GlobalModule,
    RouterModule,
    MaterialModule,
    MatMenuModule,
    MatChipsModule,
    CKEditorModule,
    NgxJdenticonModule,
    CKEditorModule,
    MatPaginatorModule,
    MatSortModule,

  ],

    exports: [
        SingleEncounterContainerComponent,
      EncounterTimelineComponent,
    ]
})
export class FinPrintModule { }
