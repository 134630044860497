<h1 mat-dialog-title>Join or Create a New Organization</h1>
<div mat-dialog-content class="content" *ngIf="currentUser">
  <mat-form-field color="accent">
    <mat-label>Organization Name</mat-label>
    <input matInput [(ngModel)]="data.name" placeholder="Team A..." [minlength]="3" (input)="updateCloseOrganizations()" />
  </mat-form-field>
  </div>
<div class="close-organizations" *ngIf="!createNewOrganization&&!updating && CloseOrganizations.length > 0">
  <h4>Organizations with Similar Names:</h4>
  <mat-action-list>
    <button mat-list-item class="proposal" *ngFor="let item of CloseOrganizations" (click)=navigate(item.id)>
      <span>{{item.name}}</span>
    </button>
  </mat-action-list>
  </div>
<div class="close-organizations" *ngIf="dirty && CloseOrganizations.length == 0 && !createNewOrganization">
  <h4>Don't see the one you're looking for?</h4>
  <button class="neworg" mat-stroked-button *ngIf="CloseOrganizations.length == 0" (click)="onCreateNewOrganization()" color="accent">Create a New Organization</button>
</div>
  <!--<mat-form-field color="accent" *ngIf="!createNewOrganization">
    <mat-label>Close Organizations</mat-label>
    <button mat-stroked-button *ngFor="let item of CloseOrganizations" (click)=navigate(item.url) color="warn">
    <span>{{item.name}}</span>
  </button>
  </mat-form-field>-->
<div mat-dialog-content class="content" *ngIf="createNewOrganization">
  <mat-form-field color="accent" *ngIf="data.allUsers | async as possibleUsers">
    <mat-label>Members</mat-label>
    <mat-select [(ngModel)]="data.users" multiple>
      <mat-option *ngFor="let user of removeCurrent(possibleUsers)" [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-buttons">
  <button mat-stroked-button (click)="onNoClick()" color="warn">Cancel</button>
  <button mat-stroked-button *ngIf="createNewOrganization" (click)="submit()" [disabled]="data.name.length < 3"cdkFocusInitial color="accent">Create Organization</button>
</div>
