import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExportRequestDto } from '../../../../models/dto/export/exportRequestDto';
import { PopulationDto } from '../../../../models/dto/population/populationDto';
import { UserProfileDto } from '../../../../models/dto/user/userProfileDto';
import { ErrorHandlerService } from '../../../../services/error/error-handler.service';
import { DataExportService } from '../../../../services/export/data-export.service';
import { PopulationService } from '../../../../services/population/population.service';
import { UserManagementService } from '../../../../services/user/management/user-management.service';

@Component({
  selector: 'app-data-export-review',
  templateUrl: './data-export-review.component.html',
  styleUrls: ['./data-export-review.component.scss']
})
export class DataExportReviewComponent implements OnInit {
  public id?: string;
  public exportRequest?: ExportRequestDto;
  public loading: boolean = false;
  public requestUser?: UserProfileDto;
  public population?: PopulationDto;
  public accepted: boolean = false;
  public declined: boolean = false;

  constructor(
    private dataExportService: DataExportService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private userService: UserManagementService,
    private populationService: PopulationService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.url.subscribe(
      val => {
        console.log(val[2].path);
        this.id = val[2].path;
        this.dataExportService.getExportReview(this.id).subscribe({
          next: (expo: ExportRequestDto) => {
            this.exportRequest = expo;
            this.userService.getUser(expo.userID!).subscribe({
              next: (user: UserProfileDto) => {
                this.requestUser = user;
                this.populationService.getPopulationById(expo.populationId!).subscribe({
                  next: (popu: PopulationDto) => {
                    this.population = popu;
                    console.log("loading done!")
                    this.loading = false;
                  }
                })
              }
            });

          }
        });
      });
    }

  handleReview(approved: boolean): void {
    if (approved) {
      this.accepted = true;
      this.declined = false;
    }
    else {
      this.accepted = false;
      this.declined = true;
    }
    this.dataExportService.sendExportReview(this.id!, approved).subscribe();
  }

}
