<mat-list *ngIf="image.annotations !== undefined && image.annotations.length > 0" (mouseleave)="removeAnnotationHighlights(true)">
  <mat-list-item *ngFor="let annotation of image.annotations" (mouseover)="activateAnnotationHighlighting(annotation)">
    <div class="annotation-content">
      <span>{{annotation.tag}}</span>
      <h5 *ngIf="annotation.tagger">Annotated By {{annotation.tagger.firstName}} {{annotation.tagger.lastName}}</h5>
      <div class="annotation-actions">
        <button mat-stroked-button color=accent (click)="confirm(annotation.id)" *ngIf="annotation.confirmed !== undefined &&  !annotation.confirmed && canEdit">Confirm</button>
        <button mat-stroked-button color=accent (click)="update(annotation.id)" *ngIf="canEdit">Update</button>
        <button mat-stroked-button color=warn (click)="deleteBox(annotation.id)" *ngIf="canEdit">Delete</button>
      </div>
      <div class="annotation-actions">
        <button mat-stroked-button color="accent" class="full-width" (click)="useForProfile(annotation)" *ngIf="canSetProfile && annotation.confirmed">Use For profile</button>
      </div>
      <mat-divider></mat-divider>
    </div>

  </mat-list-item>
</mat-list>
