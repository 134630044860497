<div mat-dialog-title *ngIf="data !== undefined && data.annotation !== undefined">
  <h1>Set Profile Picture for {{data.annotation.tag}}</h1>
</div>
<div mat-dialog-content>
  <div class="image-container" *ngIf="extractedUri as i; else loading">
    <img [src]="extractedUri" />
  </div>
  <ng-template #loading>
    <mat-progress-spinner [diameter]="50" mode="indeterminate" color="accent"></mat-progress-spinner>
  </ng-template>
  <mat-form-field class="full-width">
    <mat-label>Side</mat-label>
    <mat-select [(ngModel)]="data.side">
      <mat-option *ngFor="let option of options" [value]="option.value">{{option.display}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data">Ok</button>
  <button mat-button (click)="onNoClick()">Close</button>
</div>
