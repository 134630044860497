import {Injectable, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";

import {IPredictionFileService} from "./prediction-file.service.interface";
import {FileItem, IFileItem} from "../../../models/items/files/file-item.model";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: "root"
})
export class LocalPredictionFileService implements IPredictionFileService {
  constructor(public log: ILoggingService) {}

  private folder = "assets/test/predictions_test/"
  items: BehaviorSubject<Array<IFileItem>> = new BehaviorSubject<Array<IFileItem>>([]);

  getItems(path: string): void {
    const images: Array<IFileItem> = this.files.filter(value => value.indexOf(".txt") === -1).map(file => {
      return new FileItem(`${this.folder}${file}`)
    })
    this.items.next(images);
  }

  getPredictionsForFile(file: IFileItem): string {
    const selection = file.name.replace(".JPG", "_prediction.txt")
    return `${this.folder}${selection}`
  }

  private files = [
    "KWT_2011-03-05_JTowers_BeaverCove_5686_Fin_cropped_0_2011-03-05_18-06-57.JPG",
    "KWT_2011-03-05_JTowers_BeaverCove_5686_Fin_cropped_0_2011-03-05_18-06-57_prediction.txt",
    "KWT_2011-03-05_JTowers_BeaverCove_5686_Fin_cropped_1_2011-03-05_18-06-57.JPG",
    "KWT_2011-03-05_JTowers_BeaverCove_5686_Fin_cropped_1_2011-03-05_18-06-57_prediction.txt",
    "KWT_2011-03-22_BGisborne_JunctionPass_2327_Fin_cropped_0_2011-03-22_17-55-53.JPG",
    "KWT_2011-03-22_BGisborne_JunctionPass_2327_Fin_cropped_0_2011-03-22_17-55-53_prediction.txt",
    "KWT_2011-03-23_BGisborne_ForbesIsland_2464_Fin_cropped_0_2011-03-23_16-28-45.JPG",
    "KWT_2011-03-23_BGisborne_ForbesIsland_2464_Fin_cropped_0_2011-03-23_16-28-45_prediction.txt",
    "KWT_2011-03-23_BGisborne_ForbesIsland_2464_Fin_cropped_1_2011-03-23_16-28-45.JPG",
    "KWT_2011-03-23_BGisborne_ForbesIsland_2464_Fin_cropped_1_2011-03-23_16-28-45_prediction.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_52_2_Fin_cropped_0_2011-04-06_18-48-04.JPG",
    "KWT_2011-04-06_LRobinson_Blinkhorn_52_2_Fin_cropped_0_2011-04-06_18-48-04_prediction.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_53_2_Fin_cropped_0_2011-04-06_18-48-05.JPG",
    "KWT_2011-04-06_LRobinson_Blinkhorn_53_2_Fin_cropped_0_2011-04-06_18-48-05_prediction.txt",
    "KWT_2011-04-06_LRobinson_Blinkhorn_75_2_Fin_cropped_0_2011-04-06_18-54-17.JPG",
    "KWT_2011-04-06_LRobinson_Blinkhorn_75_2_Fin_cropped_0_2011-04-06_18-54-17_prediction.txt",
    "KWT_2011-04-06_MFournier_Blinkhorn_8559_Fin_cropped_0_2011-04-06_18-50-25.JPG",
    "KWT_2011-04-06_MFournier_Blinkhorn_8559_Fin_cropped_0_2011-04-06_18-50-25_prediction.txt",
    "KWT_2011-04-06_MFournier_Blinkhorn_8703_Fin_cropped_0_2011-04-06_18-59-28.JPG",
    "KWT_2011-04-06_MFournier_Blinkhorn_8703_Fin_cropped_0_2011-04-06_18-59-28_prediction.txt",
    "KWT_2011-05-16_MFournier_JohnstoneStrait_0826_Fin_cropped_0_2011-05-16_11-45-17.JPG",
    "KWT_2011-05-16_MFournier_JohnstoneStrait_0826_Fin_cropped_0_2011-05-16_11-45-17_prediction.txt",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_0_2011-05-18_14-18-49.JPG",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_0_2011-05-18_14-18-49_prediction.txt",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_1_2011-05-18_14-18-49.JPG",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_1_2011-05-18_14-18-49_prediction.txt",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_2_2011-05-18_14-18-49.JPG",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_2_2011-05-18_14-18-49_prediction.txt",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_3_2011-05-18_14-18-49.JPG",
    "KWT_2011-05-18_MMalleson_MiddleBank_2366_Fin_cropped_3_2011-05-18_14-18-49_prediction.txt",
    "KWT_2011-05-25_GEllis_MistakenIsland_4007_Fin_cropped_0_2011-05-25_15-17-23.JPG",
    "KWT_2011-05-25_GEllis_MistakenIsland_4007_Fin_cropped_0_2011-05-25_15-17-23_prediction.txt",
    "KWT_2011-07-10_GEllis_NorthumberlandChannel_5783_Fin_cropped_0_2011-07-10_15-16-04.JPG",
    "KWT_2011-07-10_GEllis_NorthumberlandChannel_5783_Fin_cropped_0_2011-07-10_15-16-04_prediction.txt",
    "KWT_2011-07-22_DMatkin_BeardsleeSEAK_022_Fin_cropped_0_2011-07-22_09-18-40.JPG",
    "KWT_2011-07-22_DMatkin_BeardsleeSEAK_022_Fin_cropped_0_2011-07-22_09-18-40_prediction.txt",
    "KWT_2011-07-22_DMatkin_BeardsleeSEAK_022_Fin_cropped_1_2011-07-22_09-18-40.JPG",
    "KWT_2011-07-22_DMatkin_BeardsleeSEAK_022_Fin_cropped_1_2011-07-22_09-18-40_prediction.txt",
    "KWT_2011-07-29_MGreenfelder_TracyArmSEAK_039_Fin_cropped_0_2011-07-29_16-40-44.JPG",
    "KWT_2011-07-29_MGreenfelder_TracyArmSEAK_039_Fin_cropped_0_2011-07-29_16-40-44_prediction.txt",
    "KWT_2014-02-03_JFord_DepartureBay_1698_Fin_cropped_0_2014-02-03_14-48-07.JPG",
    "KWT_2014-02-03_JFord_DepartureBay_1698_Fin_cropped_0_2014-02-03_14-48-07_prediction.txt",
    "KWT_2014-02-03_JFord_DepartureBay_1698_Fin_cropped_1_2014-02-03_14-48-07.JPG",
    "KWT_2014-02-03_JFord_DepartureBay_1698_Fin_cropped_1_2014-02-03_14-48-07_prediction.txt",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493_Fin_cropped_0_2014-08-28_07-19-37.JPG",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493_Fin_cropped_0_2014-08-28_07-19-37_prediction.txt",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493_Fin_cropped_1_2014-08-28_07-19-37.JPG",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493_Fin_cropped_1_2014-08-28_07-19-37_prediction.txt",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493_Fin_cropped_2_2014-08-28_07-19-37.JPG",
    "KWT_2014-08-28_MMalleson_GoochIsland_4493_Fin_cropped_2_2014-08-28_07-19-37_prediction.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5105_Fin_cropped_0_2014-09-06_15-41-26.JPG",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5105_Fin_cropped_0_2014-09-06_15-41-26_prediction.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5105_Fin_cropped_1_2014-09-06_15-41-26.JPG",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5105_Fin_cropped_1_2014-09-06_15-41-26_prediction.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128_Fin_cropped_0_2014-09-06_15-52-39.JPG",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128_Fin_cropped_0_2014-09-06_15-52-39_prediction.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128_Fin_cropped_1_2014-09-06_15-52-39.JPG",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128_Fin_cropped_1_2014-09-06_15-52-39_prediction.txt",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128_Fin_cropped_2_2014-09-06_15-52-39.JPG",
    "KWT_2014-09-06_BGisborne_PachenaPoint_5128_Fin_cropped_2_2014-09-06_15-52-39_prediction.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_08_Fin_cropped_0_2015-03-28_11-54-01.JPG",
    "KWT_2015-03-28_PSchulze_FortuneChannel_08_Fin_cropped_0_2015-03-28_11-54-01_prediction.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_09_Fin_cropped_0_2015-03-28_11-55-21.JPG",
    "KWT_2015-03-28_PSchulze_FortuneChannel_09_Fin_cropped_0_2015-03-28_11-55-21_prediction.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_09_Fin_cropped_1_2015-03-28_11-55-21.JPG",
    "KWT_2015-03-28_PSchulze_FortuneChannel_09_Fin_cropped_1_2015-03-28_11-55-21_prediction.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_10_Fin_cropped_0_2015-03-28_12-05-09.JPG",
    "KWT_2015-03-28_PSchulze_FortuneChannel_10_Fin_cropped_0_2015-03-28_12-05-09_prediction.txt",
    "KWT_2015-03-28_PSchulze_FortuneChannel_10_Fin_cropped_1_2015-03-28_12-05-09.JPG",
    "KWT_2015-03-28_PSchulze_FortuneChannel_10_Fin_cropped_1_2015-03-28_12-05-09_prediction.txt",
    "KWT_2015-05-07_NSinclair_WhiteRockPassage_9597_Fin_cropped_0_2015-05-07_18-05-12.JPG",
    "KWT_2015-05-07_NSinclair_WhiteRockPassage_9597_Fin_cropped_0_2015-05-07_18-05-12_prediction.txt",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_0_2015-05-09_06-28-22.JPG",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_0_2015-05-09_06-28-22_prediction.txt",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_1_2015-05-09_06-28-22.JPG",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_1_2015-05-09_06-28-22_prediction.txt",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_2_2015-05-09_06-28-22.JPG",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_2_2015-05-09_06-28-22_prediction.txt",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_3_2015-05-09_06-28-22.JPG",
    "KWT_2015-05-09_MMalleson_OakBay_9159_Fin_cropped_3_2015-05-09_06-28-22_prediction.txt",
    "KWT_2015-05-25_EAckermann_OakBay_3255_Fin_cropped_0_2015-05-25_18-32-58.JPG",
    "KWT_2015-05-25_EAckermann_OakBay_3255_Fin_cropped_0_2015-05-25_18-32-58_prediction.txt",
    "KWT_2015-08-08_JTowers_PearceIslands_4176_Fin_cropped_0_2015-08-08_16-10-24.JPG",
    "KWT_2015-08-08_JTowers_PearceIslands_4176_Fin_cropped_0_2015-08-08_16-10-24_prediction.txt",
    "KWT_2015-08-08_JTowers_PearceIslands_4176_Fin_cropped_1_2015-08-08_16-10-24.JPG",
    "KWT_2015-08-08_JTowers_PearceIslands_4176_Fin_cropped_1_2015-08-08_16-10-24_prediction.txt",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006_Fin_cropped_0_2015-08-17_12-33-24.JPG",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006_Fin_cropped_0_2015-08-17_12-33-24_prediction.txt",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006_Fin_cropped_1_2015-08-17_12-33-24.JPG",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006_Fin_cropped_1_2015-08-17_12-33-24_prediction.txt",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006_Fin_cropped_2_2015-08-17_12-33-24.JPG",
    "KWT_2015-08-17_JKalwa_BartlettIsland_0006_Fin_cropped_2_2015-08-17_12-33-24_prediction.txt",
    "KWT_2015-08-19_SPariak_CleoIsland_0354_Fin_cropped_0_2015-08-19_19-27-33.JPG",
    "KWT_2015-08-19_SPariak_CleoIsland_0354_Fin_cropped_0_2015-08-19_19-27-33_prediction.txt",
    "KWT_2015-08-19_SPariak_CleoIsland_0354_Fin_cropped_1_2015-08-19_19-27-33.JPG",
    "KWT_2015-08-19_SPariak_CleoIsland_0354_Fin_cropped_1_2015-08-19_19-27-33_prediction.txt",
    "KWT_2015-08-19_SPariak_CleoIsland_0360_Fin_cropped_0_2015-08-19_19-33-26.JPG",
    "KWT_2015-08-19_SPariak_CleoIsland_0360_Fin_cropped_0_2015-08-19_19-33-26_prediction.txt",
    "KWT_2015-08-25_SParniak_BrokenGroup_0004_Fin_cropped_0_2015-08-25_19-36-14.JPG",
    "KWT_2015-08-25_SParniak_BrokenGroup_0004_Fin_cropped_0_2015-08-25_19-36-14_prediction.txt",
    "KWT_2015-08-25_SParniak_BrokenGroup_0004_Fin_cropped_1_2015-08-25_19-36-14.JPG",
    "KWT_2015-08-25_SParniak_BrokenGroup_0004_Fin_cropped_1_2015-08-25_19-36-14_prediction.txt",
    "KWT_2015-09-04_JTowers_CormorantChannel_1682_Fin_cropped_0_2015-09-04_11-27-43.JPG",
    "KWT_2015-09-04_JTowers_CormorantChannel_1682_Fin_cropped_0_2015-09-04_11-27-43_prediction.txt",
    "KWT_2015-10-02_CMcMillan_BlackneyPass_7864_Fin_cropped_0_2015-10-02_18-25-52.JPG",
    "KWT_2015-10-02_CMcMillan_BlackneyPass_7864_Fin_cropped_0_2015-10-02_18-25-52_prediction.txt",
    "KWT_2015-10-11_AStroeder_CalmChannelButeInlet_0816_Fin_cropped_0_2015-10-11_15-18-34.JPG",
    "KWT_2015-10-11_AStroeder_CalmChannelButeInlet_0816_Fin_cropped_0_2015-10-11_15-18-34_prediction.txt",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9602_Fin_cropped_0_2016-01-14_16-33-25.JPG",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9602_Fin_cropped_0_2016-01-14_16-33-25_prediction.txt",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9608_Fin_cropped_0_2016-01-14_16-42-29.JPG",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9608_Fin_cropped_0_2016-01-14_16-42-29_prediction.txt",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9608_Fin_cropped_1_2016-01-14_16-42-29.JPG",
    "KWT_2016-01-14_MMalleson_OakBayFlats_9608_Fin_cropped_1_2016-01-14_16-42-29_prediction.txt",
    "KWT_2016-02-01_MMalleson_RacePass_9710_Fin_cropped_0_2016-02-01_13-15-33.JPG",
    "KWT_2016-02-01_MMalleson_RacePass_9710_Fin_cropped_0_2016-02-01_13-15-33_prediction.txt",
    "KWT_2016-02-01_MMalleson_RacePass_9710_Fin_cropped_1_2016-02-01_13-15-33.JPG",
    "KWT_2016-02-01_MMalleson_RacePass_9710_Fin_cropped_1_2016-02-01_13-15-33_prediction.txt",
    "KWT_2016-02-01_MMalleson_RacePass_9758_Fin_cropped_0_2016-02-01_15-41-29.JPG",
    "KWT_2016-02-01_MMalleson_RacePass_9758_Fin_cropped_0_2016-02-01_15-41-29_prediction.txt",
    "KWT_2016-02-01_MMalleson_RacePass_9758_Fin_cropped_1_2016-02-01_15-41-29.JPG",
    "KWT_2016-02-01_MMalleson_RacePass_9758_Fin_cropped_1_2016-02-01_15-41-29_prediction.txt",
    "KWT_2016-04-07_GSutton_SidneyChannel_1246_Fin_cropped_0_2016-04-07_15-14-26.JPG",
    "KWT_2016-04-07_GSutton_SidneyChannel_1246_Fin_cropped_0_2016-04-07_15-14-26_prediction.txt",
    "KWT_2016-04-18_MMalleson_EastConstanceBank_1782_Fin_cropped_0_2016-04-18_16-23-34.JPG",
    "KWT_2016-04-18_MMalleson_EastConstanceBank_1782_Fin_cropped_0_2016-04-18_16-23-34_prediction.txt",
    "KWT_2016-04-18_MMalleson_EastConstanceBank_1782_Fin_cropped_1_2016-04-18_16-23-34.JPG",
    "KWT_2016-04-18_MMalleson_EastConstanceBank_1782_Fin_cropped_1_2016-04-18_16-23-34_prediction.txt",
    "KWT_2016-12-30_DEllifrit_SpiedenIslandWA_075_Fin_cropped_0_2016-12-30_14-28-58.JPG",
    "KWT_2016-12-30_DEllifrit_SpiedenIslandWA_075_Fin_cropped_0_2016-12-30_14-28-58_prediction.txt",
    "KWT_2016-12-30_DEllifrit_SpiedenIslandWA_075_Fin_cropped_1_2016-12-30_14-28-58.JPG",
    "KWT_2016-12-30_DEllifrit_SpiedenIslandWA_075_Fin_cropped_1_2016-12-30_14-28-58_prediction.txt",
    "KWT_2017-01-26_MMalleson_WilliamHead_032_Fin_cropped_0_2017-01-26_12-27-20.JPG",
    "KWT_2017-01-26_MMalleson_WilliamHead_032_Fin_cropped_0_2017-01-26_12-27-20_prediction.txt",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_0_2017-02-21_12-11-48.JPG",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_0_2017-02-21_12-11-48_prediction.txt",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_1_2017-02-21_12-11-48.JPG",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_1_2017-02-21_12-11-48_prediction.txt",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_2_2017-02-21_12-11-48.JPG",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_2_2017-02-21_12-11-48_prediction.txt",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_3_2017-02-21_12-11-48.JPG",
    "KWT_2017-02-21_MMalleson_ConstanceBank_043_Fin_cropped_3_2017-02-21_12-11-48_prediction.txt",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_043_Fin_cropped_0_2017-02-23_13-42-09.JPG",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_043_Fin_cropped_0_2017-02-23_13-42-09_prediction.txt",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_063_Fin_cropped_0_2017-02-23_13-49-31.JPG",
    "KWT_2017-02-23_MMalleson_SatelliteChannel_063_Fin_cropped_0_2017-02-23_13-49-31_prediction.txt",
    "KWT_2017-03-20_MMalleson_SouthDiscoveryIsland_019_Fin_cropped_0_2017-03-20_15-36-34.JPG",
    "KWT_2017-03-20_MMalleson_SouthDiscoveryIsland_019_Fin_cropped_0_2017-03-20_15-36-34_prediction.txt",
    "KWT_2017-04-13_TShaw_StraitofGeorgia_0659_Fin_cropped_0_2017-04-13_15-02-09.JPG",
    "KWT_2017-04-13_TShaw_StraitofGeorgia_0659_Fin_cropped_0_2017-04-13_15-02-09_prediction.txt",
    "KWT_2017-04-29_GSutton_BelleChainIslets_0105_Fin_cropped_0_2017-04-29_12-02-01.JPG",
    "KWT_2017-04-29_GSutton_BelleChainIslets_0105_Fin_cropped_0_2017-04-29_12-02-01_prediction.txt",
    "KWT_2017-05-08_MMalleson_WilliamHead_9663_Fin_cropped_0_2017-05-08_11-39-15.JPG",
    "KWT_2017-05-08_MMalleson_WilliamHead_9663_Fin_cropped_0_2017-05-08_11-39-15_prediction.txt",
    "KWT_2017-05-08_MMalleson_WilliamHead_9668_Fin_cropped_0_2017-05-08_11-39-30.JPG",
    "KWT_2017-05-08_MMalleson_WilliamHead_9668_Fin_cropped_0_2017-05-08_11-39-30_prediction.txt",
    "KWT_2017-05-19_MMalleson_SecretaryIsland_9838_Fin_cropped_0_2017-05-19_12-14-05.JPG",
    "KWT_2017-05-19_MMalleson_SecretaryIsland_9838_Fin_cropped_0_2017-05-19_12-14-05_prediction.txt",
    "KWT_2017-05-22_RMcDonell_CampbellRiver_002_Fin_cropped_0_2017-05-22_13-31-04.JPG",
    "KWT_2017-05-22_RMcDonell_CampbellRiver_002_Fin_cropped_0_2017-05-22_13-31-04_prediction.txt",
    "KWT_2017-05-22_RMcDonell_CampbellRiver_002_Fin_cropped_1_2017-05-22_13-31-04.JPG",
    "KWT_2017-05-22_RMcDonell_CampbellRiver_002_Fin_cropped_1_2017-05-22_13-31-04_prediction.txt",
    "KWT_2017-06-09_GSutton_StraitofGeorgia_0493_Fin_cropped_0_2017-06-09_11-45-20.JPG",
    "KWT_2017-06-09_GSutton_StraitofGeorgia_0493_Fin_cropped_0_2017-06-09_11-45-20_prediction.txt",
    "KWT_2017-06-09_GSutton_StraitofGeorgia_0493_Fin_cropped_1_2017-06-09_11-45-20.JPG",
    "KWT_2017-06-09_GSutton_StraitofGeorgia_0493_Fin_cropped_1_2017-06-09_11-45-20_prediction.txt",
    "KWT_2017-06-14_MMalleson_BeecheyHead_1104_Fin_cropped_0_2017-06-14_17-22-53.JPG",
    "KWT_2017-06-14_MMalleson_BeecheyHead_1104_Fin_cropped_0_2017-06-14_17-22-53_prediction.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2585_Fin_cropped_0_2017-06-15_17-18-20.JPG",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2585_Fin_cropped_0_2017-06-15_17-18-20_prediction.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2607_Fin_cropped_0_2017-06-15_17-22-24.JPG",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2607_Fin_cropped_0_2017-06-15_17-22-24_prediction.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2607_Fin_cropped_1_2017-06-15_17-22-24.JPG",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2607_Fin_cropped_1_2017-06-15_17-22-24_prediction.txt",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2630_Fin_cropped_0_2017-06-15_17-35-43.JPG",
    "KWT_2017-06-15_JTowers_HaddingtonIsland_2630_Fin_cropped_0_2017-06-15_17-35-43_prediction.txt",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71595_Fin_cropped_0_2017-06-19_11-20-47.JPG",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71595_Fin_cropped_0_2017-06-19_11-20-47_prediction.txt",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71595_Fin_cropped_1_2017-06-19_11-20-47.JPG",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71595_Fin_cropped_1_2017-06-19_11-20-47_prediction.txt",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71610_Fin_cropped_0_2017-06-19_11-27-31.JPG",
    "KWT_2017-06-19_MMalleson_JuandeFucaStrait_71610_Fin_cropped_0_2017-06-19_11-27-31_prediction.txt",
    "KWT_2017-07-11_JForde_TreeIsland_002_Fin_cropped_0_2017-07-11_03-31-26.JPG",
    "KWT_2017-07-11_JForde_TreeIsland_002_Fin_cropped_0_2017-07-11_03-31-26_prediction.txt",
    "KWT_2017-07-20_GSutton_TumboIsland_0727_Fin_cropped_0_2017-07-20_13-07-35.JPG",
    "KWT_2017-07-20_GSutton_TumboIsland_0727_Fin_cropped_0_2017-07-20_13-07-35_prediction.txt",
    "KWT_2017-08-09_JForde_BartlettIsland_2791_Fin_cropped_0_2017-08-09_14-00-25.JPG",
    "KWT_2017-08-09_JForde_BartlettIsland_2791_Fin_cropped_0_2017-08-09_14-00-25_prediction.txt",
    "KWT_2017-08-14_DMatkin_BartlettCoveSEAK_013_Fin_cropped_0_2017-08-14_12-20-38.JPG",
    "KWT_2017-08-14_DMatkin_BartlettCoveSEAK_013_Fin_cropped_0_2017-08-14_12-20-38_prediction.txt",
    "KWT_2017-08-14_DMatkin_BartlettCoveSEAK_013_Fin_cropped_1_2017-08-14_12-20-38.JPG",
    "KWT_2017-08-14_DMatkin_BartlettCoveSEAK_013_Fin_cropped_1_2017-08-14_12-20-38_prediction.txt",
    "KWT_2017-08-20_BGisborne_CarmanahPoint_3063_Fin_cropped_0_2017-08-20_19-01-14.JPG",
    "KWT_2017-08-20_BGisborne_CarmanahPoint_3063_Fin_cropped_0_2017-08-20_19-01-14_prediction.txt",
    "KWT_2017-08-20_BGisborne_CarmanahPoint_3063_Fin_cropped_1_2017-08-20_19-01-14.JPG",
    "KWT_2017-08-20_BGisborne_CarmanahPoint_3063_Fin_cropped_1_2017-08-20_19-01-14_prediction.txt",
    "KWT_2017-08-23_MMalleson_CoyoteBankWA_4631_Fin_cropped_0_2017-08-23_13-00-29.JPG",
    "KWT_2017-08-23_MMalleson_CoyoteBankWA_4631_Fin_cropped_0_2017-08-23_13-00-29_prediction.txt",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453_Fin_cropped_0_2017-08-26_21-11-38.JPG",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453_Fin_cropped_0_2017-08-26_21-11-38_prediction.txt",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453_Fin_cropped_1_2017-08-26_21-11-38.JPG",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453_Fin_cropped_1_2017-08-26_21-11-38_prediction.txt",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453_Fin_cropped_2_2017-08-26_21-11-38.JPG",
    "KWT_2017-08-26_JSteven_GowlandRocks_2453_Fin_cropped_2_2017-08-26_21-11-38_prediction.txt",
    "KWT_2017-09-01_MMalleson_VGBuoy_4961_Fin_cropped_0_2017-09-01_11-02-45.JPG",
    "KWT_2017-09-01_MMalleson_VGBuoy_4961_Fin_cropped_0_2017-09-01_11-02-45_prediction.txt",
    "KWT_2017-09-14_JTowers_WeyntonPass_8593_Fin_cropped_0_2017-09-14_16-41-40.JPG",
    "KWT_2017-09-14_JTowers_WeyntonPass_8593_Fin_cropped_0_2017-09-14_16-41-40_prediction.txt",
    "KWT_2017-09-14_JTowers_WeyntonPass_8593_Fin_cropped_1_2017-09-14_16-41-40.JPG",
    "KWT_2017-09-14_JTowers_WeyntonPass_8593_Fin_cropped_1_2017-09-14_16-41-40_prediction.txt",
    "KWT_2017-09-22_MMalleson_JohnstoneReef_7293_Fin_cropped_0_2017-09-22_12-12-33.JPG",
    "KWT_2017-09-22_MMalleson_JohnstoneReef_7293_Fin_cropped_0_2017-09-22_12-12-33_prediction.txt",
    "KWT_2017-09-25_MMalleson_BecherBay_8004_Fin_cropped_0_2017-09-25_11-02-40.JPG",
    "KWT_2017-09-25_MMalleson_BecherBay_8004_Fin_cropped_0_2017-09-25_11-02-40_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8944_Fin_cropped_0_2017-10-02_12-27-29.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8944_Fin_cropped_0_2017-10-02_12-27-29_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8949_Fin_cropped_0_2017-10-02_12-27-56.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8949_Fin_cropped_0_2017-10-02_12-27-56_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8949_Fin_cropped_1_2017-10-02_12-27-56.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8949_Fin_cropped_1_2017-10-02_12-27-56_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8949_Fin_cropped_2_2017-10-02_12-27-56.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8949_Fin_cropped_2_2017-10-02_12-27-56_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8950_Fin_cropped_0_2017-10-02_12-27-57.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8950_Fin_cropped_0_2017-10-02_12-27-57_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8950_Fin_cropped_1_2017-10-02_12-27-57.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8950_Fin_cropped_1_2017-10-02_12-27-57_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8950_Fin_cropped_2_2017-10-02_12-27-57.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8950_Fin_cropped_2_2017-10-02_12-27-57_prediction.txt",
    "KWT_2017-10-02_MMalleson_Sheringham_8954_Fin_cropped_0_2017-10-02_14-34-47.JPG",
    "KWT_2017-10-02_MMalleson_Sheringham_8954_Fin_cropped_0_2017-10-02_14-34-47_prediction.txt",
    "KWT_2017-10-09_MMalleson_JuandeFucaStrait_9307_Fin_cropped_0_2017-10-09_10-54-26.JPG",
    "KWT_2017-10-09_MMalleson_JuandeFucaStrait_9307_Fin_cropped_0_2017-10-09_10-54-26_prediction.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3135_Fin_cropped_0_2018-01-22_16-13-15.JPG",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3135_Fin_cropped_0_2018-01-22_16-13-15_prediction.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3250_Fin_cropped_0_2018-01-22_16-12-16.JPG",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3250_Fin_cropped_0_2018-01-22_16-12-16_prediction.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3307_Fin_cropped_0_2018-01-22_16-24-09.JPG",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3307_Fin_cropped_0_2018-01-22_16-24-09_prediction.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3307_Fin_cropped_1_2018-01-22_16-24-09.JPG",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3307_Fin_cropped_1_2018-01-22_16-24-09_prediction.txt",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3342_Fin_cropped_0_2018-01-22_16-29-59.JPG",
    "KWT_2018-01-22_MMalleson_OakBayFlats_3342_Fin_cropped_0_2018-01-22_16-29-59_prediction.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4333_Fin_cropped_0_2018-03-30_11-56-55.JPG",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4333_Fin_cropped_0_2018-03-30_11-56-55_prediction.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4333_Fin_cropped_1_2018-03-30_11-56-55.JPG",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4333_Fin_cropped_1_2018-03-30_11-56-55_prediction.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_0_2018-03-30_12-05-06.JPG",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_0_2018-03-30_12-05-06_prediction.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_1_2018-03-30_12-05-06.JPG",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_1_2018-03-30_12-05-06_prediction.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_2_2018-03-30_12-05-06.JPG",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_2_2018-03-30_12-05-06_prediction.txt",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_3_2018-03-30_12-05-06.JPG",
    "KWT_2018-03-30_MMalleson_GreenPointWA_4613_Fin_cropped_3_2018-03-30_12-05-06_prediction.txt",
    "KWT_2018-04-08_MMalleson_SwansonChannel_4495_Fin_cropped_0_2018-04-08_13-17-17.JPG",
    "KWT_2018-04-08_MMalleson_SwansonChannel_4495_Fin_cropped_0_2018-04-08_13-17-17_prediction.txt",
    "KWT_2018-04-19_MMalleson_KelpReef_5099_Fin_cropped_0_2018-04-19_14-49-13.JPG",
    "KWT_2018-04-19_MMalleson_KelpReef_5099_Fin_cropped_0_2018-04-19_14-49-13_prediction.txt",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_0_2018-07-22_19-00-28.JPG",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_0_2018-07-22_19-00-28_prediction.txt",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_1_2018-07-22_19-00-28.JPG",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_1_2018-07-22_19-00-28_prediction.txt",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_2_2018-07-22_19-00-28.JPG",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_2_2018-07-22_19-00-28_prediction.txt",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_3_2018-07-22_19-00-28.JPG",
    "KWT_2018-07-22_GSutton_PointWhitehornWA_1677_Fin_cropped_3_2018-07-22_19-00-28_prediction.txt",
    "KWT_2018-08-01_GSutton_EastPoint_2725_Fin_cropped_0_2018-08-01_13-21-48.JPG",
    "KWT_2018-08-01_GSutton_EastPoint_2725_Fin_cropped_0_2018-08-01_13-21-48_prediction.txt",
    "KWT_2018-08-01_GSutton_EastPoint_2725_Fin_cropped_1_2018-08-01_13-21-48.JPG",
    "KWT_2018-08-01_GSutton_EastPoint_2725_Fin_cropped_1_2018-08-01_13-21-48_prediction.txt",
    "KWT_2018-08-01_GSutton_EastPoint_2725_Fin_cropped_2_2018-08-01_13-21-48.JPG",
    "KWT_2018-08-01_GSutton_EastPoint_2725_Fin_cropped_2_2018-08-01_13-21-48_prediction.txt",
    "KWT_2018-08-21_MMalleson_Sooke_9576_Fin_cropped_0_2018-08-21_14-30-22.JPG",
    "KWT_2018-08-21_MMalleson_Sooke_9576_Fin_cropped_0_2018-08-21_14-30-22_prediction.txt",
    "KWT_2018-08-21_MMalleson_Sooke_9576_Fin_cropped_1_2018-08-21_14-30-22.JPG",
    "KWT_2018-08-21_MMalleson_Sooke_9576_Fin_cropped_1_2018-08-21_14-30-22_prediction.txt",
    "KWT_2018-08-25_MMalleson_Victoria_9807_Fin_cropped_0_2018-08-25_18-25-22.JPG",
    "KWT_2018-08-25_MMalleson_Victoria_9807_Fin_cropped_0_2018-08-25_18-25-22_prediction.txt",
    "KWT_2018-10-24_MMalleson_PedderBay_9381_Fin_cropped_0_2018-10-24_15-08-33.JPG",
    "KWT_2018-10-24_MMalleson_PedderBay_9381_Fin_cropped_0_2018-10-24_15-08-33_prediction.txt",
    "KWT_2018-11-16_JTowers_WeyntonPass_0764_Fin_cropped_0_2018-11-16_10-30-01.JPG",
    "KWT_2018-11-16_JTowers_WeyntonPass_0764_Fin_cropped_0_2018-11-16_10-30-01_prediction.txt",
    "KWT_2018-11-16_JTowers_WeyntonPass_0764_Fin_cropped_1_2018-11-16_10-30-01.JPG",
    "KWT_2018-11-16_JTowers_WeyntonPass_0764_Fin_cropped_1_2018-11-16_10-30-01_prediction.txt"
  ]

}
