<div class="content-container">
  <div class="content">
    <div class="content-header">
      <h2>My Populations</h2>
<!--      <button mat-stroked-button (click)="joinPopulation()" color="accent"><mat-icon>add</mat-icon>Create / Join Population</button>-->
    </div>
    <mat-divider></mat-divider>
    <div class="population-list" *ngIf="populations | async as ps; else loading">
      <div class="population editable-line" *ngFor="let population of ps" [routerLink]="getLink(population)">
        <h3>{{population.displayName}}</h3>
        <h3>(<i>{{population.speciesName}}</i>)</h3>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25"  [color]="'accent'"></mat-progress-spinner>
</ng-template>
