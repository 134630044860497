import {Component, Input, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../models/dto/encounter/encounterDto";
import {WorkspaceDto} from "../../../../../../models/dto/workspace/workspaceDto";
import {StatusService} from "../../../../../../services/status/status.service";
import {STATUS, StatusChangeDto} from "../../../../../../models/dto/status/statusChangeDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../../../services/error/error-handler.service";
import {EncountersService} from "../../../../../../services/encounters/encounters.service";
import {NotificationService} from "../../../../../../services/notifications/notification.service";
import {ResponseDto} from "../../../../../../models/dto/response/responseDto";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";

@Component({
  selector: 'app-encounter-flags',
  templateUrl: './encounter-flags.component.html',
  styleUrls: ['./encounter-flags.component.scss']
})
export class EncounterFlagsComponent implements OnInit {
  @Input() encounter: EncounterDto | undefined;
  @Input() workspace: WorkspaceDto | undefined;

  public canEdit = false;
  public editing = false;
  public currentStatus: StatusChangeDto | undefined;

  public statuses = [
    {
      status: STATUS.NEW,
      display: "New",
    },
    {
      status: STATUS.IN_PROGRESS,
      display: "In Progress",
    },
    {
      status: STATUS.FINISHED,
      display: "Finished",
    },
  ]
  constructor(
    private statusService: StatusService,
    private errorHandler: ErrorHandlerService,
    private encounterService: EncountersService,
    private notificationService: NotificationService,
    private log: ILoggingService
  ) {}

  ngOnInit(): void {
    if (this.encounter === undefined || this.workspace?.settings?.populationRoles === undefined) return;
    this.setRights();
    if (this.encounter.status) {
      this.currentStatus = JSON.parse(JSON.stringify(this.encounter?.status));
    }

    if (this.canEdit && !this.encounter.status) {
      this.statusService
        .setStatus({itemId: this.encounter.id, status: STATUS.NEW})
        .subscribe({
          next: (value: StatusChangeDto) => {
            this.encounter!.status = value;
            this.currentStatus = JSON.parse(JSON.stringify(this.encounter?.status));
          },
          error : (value: HttpErrorResponse) => {
            this.errorHandler.handleRequestError("Updating status", value)
          }
      })
    }

  }
  private setRights() {
    if (this.workspace?.settings?.populationRoles?.administrator || this.workspace?.settings?.populationRoles?.professional) {
      this.canEdit = true;
    }
  }

  updateStatus() {
    if (this.currentStatus?.status == this.encounter?.status?.status) {
      this.editing = false;
      return;
    }
    this.statusService
      .setStatus({itemId: this.encounter!.id!, status: this.currentStatus?.status})
      .subscribe({
        next: (value: StatusChangeDto) => {
          this.encounter!.status = value;
          this.currentStatus = JSON.parse(JSON.stringify(this.encounter?.status))
          this.editing = false;
          this.notificationService.notifyEncounterStatusChange(this.encounter!.id!).subscribe(res => {})
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Updating status", value)
          this.editing = false;
        }
      });
    if (this.currentStatus?.status == STATUS.FINISHED) {
      this.encounterService
        .removePredictions(this.encounter!.id!)
        .subscribe({
          next: (value: ResponseDto) => {
            if (value.successful) {
              this.log.info(`Encounter Cleanup Successful`)
            } else {
              this.log.info(`Encounter Cleanup Failed: ${value.errorMessages.join(', ')}`)
            }
          }, error: (value: HttpErrorResponse) => {
            this.errorHandler.handleRequestError("Removing Extraneous Predictions", value)
          }
        })
    }
  }

  getStatus() {
    return STATUS.invert(this.encounter?.status?.status!);
  }
}
