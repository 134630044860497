<div class="selection-section" *ngIf="animals && selectedAnimals && filteredAnimals">
  <div class="selection-section-header">
    <h4>Individuals of Interest</h4>
    <button (click)="reset()" class="reset-button" mat-icon-button><mat-icon>restart_alt</mat-icon></button>
  </div>
  <mat-form-field color="accent" class="full-width">
    <mat-label>Individuals</mat-label>
    <mat-chip-grid #individualChipGrid aria-label="individual selection" >
      <mat-chip-row *ngFor="let animal of selectedAnimals" (removed)="removeIndividual(animal)">
        {{animal}}
        <button matChipRemove [attr.aria-label]="'remove ' + animal">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Animal Identifier" #individualInput [formControl]="individualControl"
           [matChipInputFor]="individualChipGrid" [matAutocomplete]="auto"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="addIndividual($event)"/>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selectedIndividual($event)">
      <mat-option *ngFor="let animal of filteredAnimals | async" [value]="animal">
        {{animal}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
