import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import {AuthenticationService} from "../../../services/user/authentication.service";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UserForRegistrationDto} from "../../../models/dto/user/userForRegistrationDto";
import {UserForUpdateDto} from "../../../models/dto/user/userForUpdateDto";
import {
  PasswordConfirmationValidatorService
} from "../../../services/user/validators/password-confirmation-validator.service";
import {UserContentService} from "../../../services/user/content/user-content.service";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {UserStatisticsResponseDto} from "../../../models/dto/response/user/userStatisticsResponseDto";
import {AuthenticationResponseDto} from "../../../models/dto/response/user/authenticationResponseDto";
import {AuthenticationUpdateResponseDto} from "../../../models/dto/response/user/authenticationUpdateResponseDto";
import {EncountersService} from "../../../services/encounters/encounters.service";
import {Encounter} from "../../../models/encounters/encounter.model";
import {ResetPasswordDto} from "../../../models/dto/user/resetPasswordDto";
import {pipe} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {UserManagementService} from "../../../services/user/management/user-management.service";
import {ErrorHandlerService} from "../../../services/error/error-handler.service";

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {
  public user: UserProfileDto | undefined;
  public profileEditActive: boolean = false;
  public passwordEditActive: boolean = false;
  public profileUpdateForm!: UntypedFormGroup;
  public passwordUpdateForm!: UntypedFormGroup;
  public images: Array<IFileItem> | undefined;
  public encounters: Array<Encounter> | undefined;
  public statistics: UserStatisticsResponseDto | undefined;
  public showPasswordUpdateSuccess = false;
  public showPasswordUpdateError = false;
  public passwordUpdateErrorMessage = "";
  public passwordLoading = false;
  public foreign = true;
  private id: string | undefined;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    public passwordConfirmationValidator: PasswordConfirmationValidatorService,
    private contentService: UserContentService,
    private userService: UserManagementService,

    private errorService: ErrorHandlerService,
    private encounterService: EncountersService) { }

  ngOnInit(): void {
    this.route.params.subscribe(res => {
      this.id = res["id"];
      if (this.id) {
        this.foreign = true;
        this.userService.getUser(this.id).subscribe({
          next: (user: UserProfileDto) => {
            this.user = user;
            this.loadForeignUser();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.handleRequestError("Fetching User", err);
          }
        });
      } else {
        this.foreign = false;
        this.contentService
          .getUser()
          .subscribe( (dto: UserProfileDto) => {
          this.user = dto;
          this.loadLocalUser();
          this.profileUpdateForm = new UntypedFormGroup({
            firstName: new UntypedFormControl(this.user.firstName),
            lastName: new UntypedFormControl(this.user.lastName),
            email: new UntypedFormControl(this.user.email, [Validators.required, Validators.email])
          });
        });
      }

    });
  }



  public toggleProfileEdit() {
    this.profileEditActive = !this.profileEditActive;
  }

  public togglePasswordEdit() {
    this.passwordEditActive = !this.passwordEditActive;
  }

  public updateProfile = (registerFormValue: any) => {
    const formValues = {...registerFormValue};
    const user: UserForUpdateDto = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
    };
    this.authService.updateUser(user).subscribe( (response: AuthenticationUpdateResponseDto) => {
    })
  }

  public updatePassword = (passwordRestForm: any) => {
    const formValues = {...passwordRestForm};
    const reset: ResetPasswordDto = {
      currentPassword: formValues.currentPassword,
      confirmPassword: formValues.newPasswordConfirm,
      password: formValues.newPassword,
      email: this.user?.email!,
    }
    this.passwordLoading = true;
    this.authService.userManualResetPassword(reset).subscribe({

      next: (response) => {
        this.showPasswordUpdateSuccess = true;
        this.showPasswordUpdateError = false;
        this.passwordUpdateForm.reset();
        this.passwordLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.showPasswordUpdateError = true;
        this.showPasswordUpdateSuccess = false;
        this.passwordUpdateErrorMessage = err.message;
        this.passwordLoading = false;
      }
    });
  }

  private loadUser() {
    this.userService.getUserStatistics(this.user?.id!).subscribe((response: UserStatisticsResponseDto) => {
      this.statistics = response;
    });
  }

  private loadForeignUser() {
    this.loadUser();
  }

  private loadLocalUser() {
    this.passwordUpdateForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(''),
      newPassword: new UntypedFormControl('', [Validators.required]),
      newPasswordConfirm: new UntypedFormControl('')
    });
    this.passwordUpdateForm.get("newPasswordConfirm")?.setValidators([
      Validators.required, this.passwordConfirmationValidator.validateConfirmPassword(<AbstractControl>this.passwordUpdateForm.get('newPassword'))
    ]);
    this.loadUser();


  }
}
