import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from './home/home.component';

import {HttpClient, HttpClientModule} from "@angular/common/http";


import {IImageService} from "../services/files/images/image.service.interface";
import {imageServiceFactory} from "../factories/services/files/images/image.factory";

import {IPredictionFileService} from "../services/files/predictions/prediction-file.service.interface";
import {predictionServiceFactory} from "../factories/services/files/predictions/prediction-file.factory";

import {IDetectionFileService} from "../services/files/detections/detection-file.service.interface";
import {detectionFileServiceFactory} from "../factories/services/files/detections/detection-file.factory";
import {IFileStorageService} from "../services/storage/file/file-storage.service.interface";
import {fileStorageFactory} from "../factories/services/files/storage/file-storage.factory";
import {ImageMetaDataService} from "../services/files/images/meta/image-meta-data.service";

import {PredictionService} from "../services/predictions/prediction.service";
import {GoogleMapsModule} from "@angular/google-maps";
import {IItemStorageService} from "../services/storage/item/item-storage.service.interface";
import {itemStorageFactory} from "../factories/services/storage/item/item-storage.factory";
import {GeocodingService} from "../services/geocoding/geocoding.service";

import {LocationService} from "../services/location/location.service";
import {ILoggingService} from "../services/logging/logging.service.interface";
import {loggingServiceFactory} from "../factories/services/logging/logging.service.factory";

import {ImageScalingService} from "../services/files/images/scale/image-scaling.service";
import {TimeService} from "../services/utilities/time.service";

import {MAT_DATE_LOCALE} from "@angular/material/core";
import {GlobalModule} from "./global/global.module";
import {FinPrintModule} from "./fin-print/fin-print.module";
import {FileSortingService} from "../services/files/sorting/file-sorting.service";
import {FileGroupingService} from "../services/files/sorting/file-grouping.service";
import {UserModule} from "./user/user.module";
import {JwtModule} from "@auth0/angular-jwt";
import {NgxJdenticonModule} from "ngx-jdenticon";
import {ImageService} from "../services/utilities/image.service";
import {UuidService} from "../services/utilities/uuid.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatMenuModule} from "@angular/material/menu";
import {Router, RouteReuseStrategy} from "@angular/router";

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GoogleMapsModule,
    GlobalModule,
    FinPrintModule,
    UserModule,
    NgxJdenticonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:5001", "localhost:7166", "localhost:7060"],
        disallowedRoutes: []
      }
    }),
    MatMenuModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    FileSortingService,
    FileGroupingService,
    ImageMetaDataService,
    UuidService,
    PredictionService,
    GeocodingService,
    LocationService,
    ImageScalingService,
    TimeService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-GB"
    },
    {
      provide: ILoggingService,
      useFactory: loggingServiceFactory,
      deps: [MatSnackBar, HttpClient]
    },
    {
      provide: IPredictionFileService,
      useFactory: predictionServiceFactory,
      deps: [ILoggingService]
    },
    {
      provide: IDetectionFileService,
      useFactory: detectionFileServiceFactory,
      deps: [ILoggingService]
    },
    {
      provide: IFileStorageService,
      useFactory: fileStorageFactory,
      deps: [ILoggingService]
    },
    {
      provide: IItemStorageService,
      useFactory: itemStorageFactory,
      deps: [ILoggingService]
    },
    {
      provide: IImageService,
      useFactory: imageServiceFactory,
      deps: [
        IFileStorageService,
        ImageMetaDataService,
        LocationService,
        ILoggingService,
        HttpClient,
        DomSanitizer,
        ImageService
      ]
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


