import {Injectable} from "@angular/core";
import {GeocodingService} from "../geocoding/geocoding.service";
import {IItemStorageService} from "../storage/item/item-storage.service.interface";
import {Observable, of} from "rxjs";
import { map } from "rxjs/operators";
import { Location } from "../../models/location/location.model"

import {ContentItem} from "../../models/items/content-item.model";
import {IFileItem} from "../../models/items/files/file-item.model";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {HashService} from "../utilities/hash.service";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private geocodingService: GeocodingService,
    private storageService: IItemStorageService,
    private hashService: HashService,
    private httpClient: HttpClient
  ) {}

  public getLocationById(locationId: string): Observable<Location> {
    const url = `${environment.server.baseUrl}${environment.server.api.location.get}/${locationId}`;
    return this.httpClient.get<Location>(url);
  }
  public getLocationsFromServer(): Observable<Array<Location>> {
    const url = `${environment.server.baseUrl}${environment.server.api.location.get}`
    return this.httpClient.get<Array<Location>>(url);
  }

  public getLocation(locationName: string): Observable<Location[]> {
    return this.geocodingService.getLocation(locationName).pipe(
      map( response => this.geocodingService.mapGeoCoderResponseToLocation(response)))
  }

  public assignLocationToFile(item: IFileItem): Observable<IFileItem> {
    if (!item.metaData.subLocation) {
      //console.log("No Location Information Available...")
      return of(item)
    }
    return this.fetchLocation(item.metaData.subLocation)
      .pipe( map
      ( (value: Array<Location>) => {
        item.metaData.possibleLocations = value;
        if (value !== undefined && value.length === 1) {
          item.metaData.location = value[0];
        }
        return item;
      }));
  }

  public fetchLocation(locationName: string): Observable<Location[]> {
    const key = this.hashService.hash(locationName);
    return this.getLocation(locationName).pipe( map(value => {
      this.storageService.store(key, value);
      return value;
    }))

  }


}
