import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as L from "leaflet";
import {MapService} from "../../../services/map/map.service";
import { Location } from "../../../models/location/location.model";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import 'leaflet-arrowheads';
import {LatLngTuple} from "leaflet";


@Component({
  selector: 'animal-track-select-map',
  templateUrl: './select-map.component.html',
  styleUrls: ['./select-map.component.scss']
})
export class SelectMapComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() singleSelect = false;
  @Input() selectedLocation: Location | undefined;
  @Input() selectedLocations: Array<Location> | undefined;
  @Input() allowDrag: boolean = false;
  @Input() allowReselect: boolean = true;
  @Input() initialZoom: number = 5;
  @Input() maxZoom: number = 30;
  @Input() minZoom: number = 1;
  @Input() defaultLatitude = 39.8282;
  @Input() defaultLongitude = -98.5795;
  @Input() width: number | undefined;
  @Input() height: number | undefined;

  @Output() positionSelected: EventEmitter<{lat: number, lng: number}> = new EventEmitter<{lat: number; lng: number}>();
  @Output() positionRemoved: EventEmitter<{lat: number, lng: number}> = new EventEmitter<{lat: number; lng: number}>();

  public map: any;
  public marker: any;


  private lastLatLng: {lat: number, lng: number} | undefined;


  constructor(
    public mapService: MapService,
    private log: ILoggingService
    ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedLocation"] !== undefined) {
      if (changes["selectedLocation"]["currentValue"] !== undefined) {
        const e = changes["selectedLocation"]["currentValue"]
        const event = {latlng: [e["latitude"], e["longitude"]], name: e["name"], mapName: e["mapName"]}
        if (this.map === undefined) {
          this.ngAfterViewInit();
        }
        this.addMarker(event);
      }
    }
    if (changes["selectedLocations"] !== undefined) {

      if (changes["selectedLocations"]["currentValue"] !== undefined) {
        if (this.map !== undefined) {
          this.map.off();
          this.map.remove();
          this.map = undefined;
        }
        const locations = JSON.parse(JSON.stringify(changes["selectedLocations"]["currentValue"]));

        for (let e of locations) {
          // @ts-ignore
          const event = {latlng: [e["latitude"], e["longitude"]], name: e["name"], mapName: e["mapName"]}
          this.selectedLocation = e;
          if (this.map === undefined) {
            this.ngAfterViewInit();
          }

          this.addMarker(event);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.map === undefined) {
      if (this.selectedLocation !== undefined &&
        this.selectedLocation.latitude !== undefined &&
        this.selectedLocation.longitude !== undefined) {
        this.initMap(this.selectedLocation.latitude!, this.selectedLocation.longitude!);
      } else {
        this.initMap(this.defaultLatitude, this.defaultLongitude);
      }
    }
  }

  private initMap(latitude: number, longitude: number): void {
    var container = document.getElementById("select-map");
    if (!container || this.map) return;
    const layers = this.getLayers();
    this.map = L.map(`select-map`, {
      center: [ latitude,  longitude ],
      zoom: this.initialZoom,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      layers: layers[0]
    });

    if (this.selectedLocations !== undefined) {
      const locs = this.selectedLocations.reverse();
      const coords: Array<LatLngTuple> = [];
      for(let loc of locs) {
        coords.push([loc.latitude!, loc.longitude!])
      }
      // const myVector = L.polyline(coords).arrowheads({size: '5px', frequency: '30px', color: 'black'});
      // myVector.addTo(this.map);
    }

    if (!this.allowReselect) {
      return;
    }



    this.map.addEventListener('click', (event: any) => {
      this.positionSelected.emit({lat: event.latlng.lat, lng: event.latlng.lng});
      if (this.singleSelect && this.lastLatLng === undefined) {
        this.lastLatLng = event.latlng;
        this.marker = L.marker(event.latlng,
          {draggable: this.allowDrag, autoPan: true, icon: this.mapService.createSvgIcon('ffffff')})
          .addTo(this.map);

        this.marker.addEventListener('click', (markerEvent: any) => {
          this.map.removeLayer(this.marker);
          this.lastLatLng = undefined;
          this.positionRemoved.emit({lat: markerEvent.latlng.lat, lng: markerEvent.latlng.lng});
        })

        this.marker.bindPopup(this.marker.getLatLng().toString(), {closeButton: false, offset: [0, -16]})
          .openPopup();
      } else if (this.singleSelect && this.lastLatLng !== undefined) {
        this.marker.setLatLng(event.latlng);
        this.marker.bindPopup(this.marker.getLatLng().toString(), {closeButton: false, offset: [0, -16]})
          .openPopup();
      }

      else {
        const marker = L.marker(event.latlng,
          {draggable: this.allowDrag, autoPan: true, icon: this.mapService.createSvgIcon('ffffff')})
          .addTo(this.map);
        marker.addEventListener('click', (markerEvent: any) => {
          this.map.removeLayer(marker);
          this.positionRemoved.emit({lat: markerEvent.latlng.lat, lng: markerEvent.latlng.lng});
        })
        marker.bindPopup(marker.getLatLng().toString(), {closeButton: false, offset: [0, -16]}).openPopup();
      }

      this.map.setView(event.latlng);


    });

  }

  private setMarker(event: any) {
    if (!this.map) return;
    let popupContent = event.mapName ?? event.name;
    if (popupContent === undefined || popupContent.length == 0) {
      popupContent = `Latitude: ${event.latlng[0]}, Longitude: ${event.latlng[1]}`;
    }
    if (this.singleSelect && this.lastLatLng === undefined) {
      this.lastLatLng = event.latlng;
      this.marker = L.marker(event.latlng,
        {draggable: false, autoPan: true, icon: this.mapService.createSvgIcon('ffffff')})
        .addTo(this.map);

      if (this.allowReselect) {
        this.marker.addEventListener('click', (markerEvent: any) => {
          this.map.removeLayer(this.marker);
          this.lastLatLng = undefined;
          this.positionRemoved.emit({lat: markerEvent.latlng.lat, lng: markerEvent.latlng.lng});
        })
      }

      this.marker.bindPopup(popupContent, {closeButton: false, offset: [0, -16]})
        .openPopup();
    } else if (this.singleSelect && this.lastLatLng !== undefined) {
      this.marker.setLatLng(event.latlng);
      this.marker.bindPopup(popupContent, {closeButton: false, offset: [0, -16]})
        .openPopup();

    }

  }

  private addMarker(event: any) {
    if (!this.map) return;
    if (this.singleSelect) {
      this.setMarker(event);
    }
    else {
      const marker = L.marker(event.latlng,
        {draggable: false, autoPan: true, icon: this.mapService.createSvgIcon('ffffff')})
        .addTo(this.map);
      if (this.allowReselect) {
        marker.addEventListener('click', (markerEvent: any) => {
          this.map.removeLayer(marker);
          this.positionRemoved.emit({lat: markerEvent.latlng.lat, lng: markerEvent.latlng.lng});
        })
      }
      let popupContent = event.mapName ?? event.name;
      if (popupContent === undefined || popupContent.length == 0) {
        popupContent = `Latitude: ${event.latlng[0]}, Longitude: ${event.latlng[1]}`;
      }
      marker.bindPopup(popupContent, {closeButton: false, offset: [0, -16]});
    }
    this.map.setView(event.latlng);
  }

  private getLayers() {
    const tileLayer = this.mapService.getTileLayer();
    const layers: any = [tileLayer];
    const base = {
      "World": tileLayer
    }


    return [layers, base];
  }

}
