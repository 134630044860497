import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnimalService} from "../../../../services/animal/animal.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {ImageService} from "../../../../services/utilities/image.service";
import {DateService} from "../../../../services/utilities/date.service";
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {ExtractedImage} from "../../../../models/dto/inference/verificationTaskDto";

@Component({
  selector: 'app-individual-search-gallery',
  templateUrl: './individual-search-gallery.component.html',
  styleUrls: ['./individual-search-gallery.component.scss']
})
export class IndividualSearchGalleryComponent implements OnInit {
  public profileUpdating: boolean = false;
  public imageObs?: Observable<ExtractedImage>;
  public date?: string;

  constructor(public dialogRef: MatDialogRef<IndividualSearchGalleryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { results: Array<AnimalDto>, selected: AnimalDto, imageId?: string, profileOptions?: boolean},
              private animalService: AnimalService,
              private logService: ILoggingService,
              public imageService: ImageService,
              public dateService: DateService) { }

  ngOnInit(): void {
    // @ts-ignore
    this.date = this.data.selected.date ?? this.data.selected.dateTime;
    if (this.data.selected.imageId !== undefined) {
      this.imageObs = this.animalService.getExtractedImageById(this.data.selected.imageId, "full");
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private switchSelected(newIdx: number) {
    this.data.selected = this.data.results[newIdx];
    this.imageObs = this.animalService.getExtractedImageById(this.data.selected.imageId, "full");
  }

  previous() {
    const idx = this.data.results.indexOf(this.data.selected);
    if (idx !== -1) {
      let prevIdx = idx - 1;
      if (prevIdx < 0) {
        prevIdx = this.data.results.length - 1;
      }
      this.imageObs = undefined;
      this.switchSelected(prevIdx);
    }
  }

  next() {
    const idx = this.data.results.indexOf(this.data.selected);
    if (idx !== -1) {
      let nextIdx = idx + 1;
      if (nextIdx >= this.data.results.length) {
        nextIdx = 0;
      }
      this.imageObs = undefined;
      this.switchSelected(nextIdx);
    }
  }

  getProfileLink(selected: AnimalDto) {
    return `/individuals/${selected.id}`;
  }

  getEncounterLink(selected: AnimalDto) {
    if (selected.encounterId && selected.fileItemId) {
      return `/encounters/${selected.encounterId!}`;
    }
    return "";
  }

  getQueryParams(selected: AnimalDto) {
    return {open: selected.fileItemId}
  }

  startProfileUpdate(selected: AnimalDto) {
    this.profileUpdating = true;
  }

  updateProfileImage(selected: AnimalDto) {
    if (!selected.side || !selected.imageId || !selected.id!) {return;}
    this.animalService.setSidedImage(selected.id, selected.side, selected.imageId!).subscribe(res => {
      this.logService.info(`Updated profile image for ${selected.identifier} (${selected.side} side)`)
    })
    this.profileUpdating = false;
  }
}
