import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {IFileItem} from "../../../../models/items/files/file-item.model";
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {EncounterPageRequestDto} from "../../../../models/dto/encounter/encounterPageRequestDto";
import {EncounterDto} from "../../../../models/dto/encounter/encounterDto";
import {PopulationSettingsDto} from "../../../../models/dto/population/populationSettingsDto";
import {PopulationRoleSet, PopulationService} from "../../../../services/population/population.service";
import {EncounterPageResponseDto} from "../../../../models/dto/encounter/encounterPageResponseDto";
import {IImageService} from "../../../../services/files/images/image.service.interface";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";
import {AnimalService} from "../../../../services/animal/animal.service";
import {ResponsiveDesignService} from "../../../../services/design/responsive-design.service";
import {QueryService} from "../../../../services/query/query.service";

@Component({
  selector: 'app-encounter-grid',
  templateUrl: './encounter-grid.component.html',
  styleUrls: ['./encounter-grid.component.scss']
})
export class EncounterGridComponent implements OnInit, OnDestroy {
  public items: Array<IFileItem> | undefined;
  public finishedLoading: boolean = false;
  public population: PopulationDto | undefined;
  public nextLoading: boolean = false;
  public nextResponse: EncounterPageRequestDto | undefined;
  public encounters: Array<EncounterDto> | undefined;
  public request: EncounterPageRequestDto | undefined;
  public populationSettings: PopulationSettingsDto | undefined;

  public populationRoleSet: PopulationRoleSet | undefined;
  public isMobile: boolean = false;
  public sidebarOpen: boolean = false;

  private subs: Array<Subscription> = [];
  animals: any;
  public nextRequested: boolean = false;
  public nextRequestedFinished: boolean = false;
  public searching = false;
  public searchFinished: boolean = true;
  public gridSelection: boolean = false;
  public mapSelection: boolean = true;
  view: any = "grid"

  selectionSizes = [
    5,
    10,
    25,
    50
  ]
  mapSizes = [
    50,
    100,
    200,
    400,
    500
  ]

  constructor(
    private imageService: IImageService,
    private log: ILoggingService,
    private populationService: PopulationService,
    private encounterService: EncountersService,
    private router: Router,
    private workspaceService: WorkspaceService,
    private animalService: AnimalService,
    private responsiveDesignService: ResponsiveDesignService,
    private queryService: QueryService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.responsiveDesignService.mobile.subscribe(res => {
      this.isMobile = res;
    })
    const workspaceSub = this.workspaceService.workspace.subscribe( res => {
      if (res && res.settings) {
        this.population = res.settings!.population;
        this.animalService
          .getAnimalPreviews(this.population!.id!, "all", true)
          .subscribe( res => {
          this.animals = res;
        })

        this.route.queryParams.subscribe(res => {
          const querySize = Object.keys(res).length
          if (querySize > 0) {
            this.request = this.queryService.invertEncounterQueryParams(res)
          } else {
            this.request = new EncounterPageRequestDto(this.population?.id!, this.view);
          }
          this.initGallery();
        })
      }

    })

    this.subs.push(workspaceSub);

  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  getPage(notFirstLoad = true) {
    this.request!.view = this.view;
    this.searchFinished = notFirstLoad;
    this.nextRequested = true;

    this.subs.push(this.encounterService.getEncounterPage(this.request!)
      .subscribe({
        next: (response: EncounterPageResponseDto) => {
          this.nextResponse = response.next;
          this.encounters = response.encounters
          this.nextRequested = false;
          this.searchFinished = true;
        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Error fetching encounters: ${error.message}`, true);
          this.nextRequested = false;
          this.searchFinished = true;
        }
      }));
  }

  initGallery() {
    // this.subs.push(this.populationService
    //   .getPopulationSettings(this.population?.id!)
    //   .subscribe( res => {this.populationSettings = res}));
    this.items = undefined;
    this.getPage(false);

  }

  loadNext() {
    if (this.nextResponse === undefined) {
      this.log.info("Unsure of which encounters should be loaded next.");
      return;
    }
    if (this.nextResponse === null) {
      this.log.info("No more encounters");
      return;
    }
    this.nextRequested = true;
    this.subs.push(this.encounterService.getEncounterPage(this.nextResponse!)
      .subscribe({
        next: (response: EncounterPageResponseDto) => {
          this.nextResponse = response.next;
          if (response.encounters) {
            this.encounters = this.encounters?.concat(response.encounters!);
          }
          this.nextRequested = false;
        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Error fetching encounters: ${error.message}`, true);
          this.nextRequested = false;
        }
      }));
  }

  setRequest(requestDto: EncounterPageRequestDto) {
    this.request = requestDto;
    const q = this.queryService.sanitizeEncounterQuery(this.request);
    this.router.navigate(['/encounters'], {queryParams: q})
    this.getPage();
  }

  removeEncounter($event: EncounterDto) {
    this.encounters?.splice(this.encounters?.indexOf($event), 1);
  }

  getRadioLabelPosition(): "before" | "after" {
    if (this.isMobile) {
      return "after";
    }
    return "before";
  }

  getMode(): "over" | "push" | "side" {
    if (this.isMobile) {
      return "over";
    }
    return "side";
  }

  firstPage() {
    if (this.nextResponse?.encounterLimit !== undefined && this.nextResponse.encounterLimit !== this.request?.encounterLimit) {
      this.nextResponse.encounterLimit = this.request?.encounterLimit;
    }
    this.nextResponse!.startIdx = 0;
    this.setRequest(this.nextResponse!);
  }

  previousPage() {
    if (this.nextResponse?.encounterLimit !== undefined && this.nextResponse.encounterLimit !== this.request?.encounterLimit) {
      this.nextResponse.encounterLimit = this.request?.encounterLimit;
    }
    this.nextResponse!.startIdx = this.nextResponse?.startIdx! - this.encounters!.length - this.request?.encounterLimit!;
    this.setRequest(this.nextResponse!);
  }

  nextPage() {
    if (this.nextResponse?.encounterLimit !== undefined && this.nextResponse.encounterLimit !== this.request?.encounterLimit) {
      this.nextResponse.encounterLimit = this.request?.encounterLimit;
    }
    this.setRequest(this.nextResponse!)
  }

  lastPage() {
    if (this.nextResponse?.encounterLimit !== undefined && this.nextResponse.encounterLimit !== this.request?.encounterLimit) {
      this.nextResponse.encounterLimit = this.request?.encounterLimit;
    }
    this.nextResponse!.startIdx! = this.nextResponse?.maximumIdx! - this.nextResponse!.encounterLimit!;
    this.setRequest(this.nextResponse!)
  }

  isLastPageDisabled() {
    if (this.nextResponse === undefined || this.nextResponse === null) return true;
    if (this.nextResponse!.maximumIdx !== undefined && this.nextResponse.startIdx !== undefined && (this.nextResponse?.maximumIdx <= this.nextResponse?.startIdx)) return true;
    return false;
  }

  isNextPageDisabled() {
    if (this.nextResponse === undefined || this.nextResponse === null) return true;
    if (this.nextResponse!.maximumIdx !== undefined && this.nextResponse.startIdx !== undefined && (this.nextResponse?.maximumIdx <= this.nextResponse?.startIdx)) return true;
    return false;
  }

  isPreviousPageDisabled() {
    if (this.nextResponse === undefined || this.nextResponse === null || this.request === undefined || this.request === null) return true;
    if (this.nextResponse.startIdx === 0) return true;
    if (this.request?.startIdx === 0) return true;
    if (this.nextResponse.startIdx !== undefined && (this.nextResponse.startIdx - this.nextResponse.encounterLimit! - 1 <= 0)) return true;
    return false;
  }

  isFirstPageDisabled() {
    if (this.nextResponse === undefined || this.nextResponse === null || this.request === undefined || this.request === null) return true;
    if (this.nextResponse.startIdx === 0) return true;
    if (this.request?.startIdx === 0) return true;
    if (this.nextResponse.startIdx !== undefined && (this.nextResponse.startIdx - this.nextResponse.encounterLimit! - 1 <= 0)) return true;
    return false;
  }

  updateLimit() {
    this.setRequest(this.request!);
  }

  switchView() {
    if (this.view === 'grid') {
      this.request!.encounterLimit = 25;
      this.setRequest(this.request!);
    } else {
      this.request!.encounterLimit = 100;
      this.setRequest(this.request!);
    }
  }
}
