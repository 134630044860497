import {Injectable} from "@angular/core";
import {LogLevel} from "../../models/logging/log-levels.enum";
import {ILoggingService} from "./logging.service.interface";
import {formatDate} from "@angular/common";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class WebLoggingService implements ILoggingService {
  level = LogLevel.INFO;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  levelMap = ["Information", "Debug", "Warning", "Error"]

  constructor(
    public snackBar: MatSnackBar,
    public httpClient: HttpClient
  ) {}

  setLevel(logLevel: LogLevel) {this.level = logLevel;}

  log(msg: string, level: number, snackbar = false, content?: any): void {
    const dateString = `${formatDate(Date.now(), 'yyyy-MM-ddTHH:mm:ss', 'en-GB')}`;
    const message: string = `${dateString} | ${this.levelMap[level]} | ${msg}`;
    if (content) {
    } else {
    }
    if (snackbar) {
      this.logSnackbar(message);
    }
    this.persistLog(dateString, level, msg).subscribe( () => {});
  }

  persistLog(logDateTime: string, level: number, msg: string) {
    const url = `${environment.server.baseUrl}/log`;
    const body = {
      logLevel: level,
      logTime: logDateTime,
      message: msg
    }
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(url, JSON.stringify(body), headers);
  }

  logSnackbar(msg: string): void {
    this.snackBar.open(msg, "Got it!", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 5000
    });
  }

  info(msg: string, snackbar= true, content?: any): void {
    this.log(msg, LogLevel.INFO, snackbar, content);
  }

  debug(msg: string, snackbar= true, content?: any): void {
    this.log(msg, LogLevel.DEBUG, snackbar, content);
  }

  warn(msg: string, snackbar= true, content?: any): void {
    this.log(msg, LogLevel.WARN, snackbar, content);
  }

  error(msg: string, snackbar= true, content?: any): void {
    this.log(msg, LogLevel.ERROR, snackbar, content);
  }

}
