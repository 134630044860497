<div class="admin-links" *ngIf="userMenuEntries">
  <button class="mat-icon-trigger-button" mat-stroked-button [matMenuTriggerFor]="menu">
    <!--            <mat-icon>menu_open</mat-icon>-->
    <div class="user-icon-button" *ngIf="user">
      <app-user-icon [user]="user"></app-user-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)=navigate(item.url) *ngFor="let item of userMenuEntries">
      <span>{{item.displayText}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="organization">
      <span>Organization</span>
    </button>
    <button mat-menu-item (click)="toggleTheme()">
      {{isDark ? 'Light Mode' : "Dark Mode" }}
    </button>
    <a mat-menu-item (click)="logout()">
      Logout
    </a>
  </mat-menu>

  <mat-menu #organization="matMenu" xPosition="before">
    <button mat-menu-item [matMenuTriggerFor]="myorganization" (menuOpened)="callGetData()"><span>My Organizations</span></button>
    <button mat-menu-item (click)=navigateOrganization()>
      <span>View Organizations</span>
    </button>
    <button mat-menu-item (click)=createOrganization()><span>Join/Create Organizations</span></button>
  </mat-menu>

  <mat-menu #myorganization="matMenu" xPosition="before">
    <ng-template matMenuContent>
      <button mat-menu-item (click)=navigateOrganizationByID(item.id) *ngFor="let item of userOrganizations">
        <span>{{item.name}}</span>
      </button>
    </ng-template>

  </mat-menu>
</div>
