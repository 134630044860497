import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataLimitationLicenseDto} from "../../../../models/dto/encounter/DataLimitationLicenseDto";
import {Subscription} from "rxjs";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {LicenseService} from "../../../../services/license/license.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EncounterCreationRequestDto} from "../../../../models/dto/encounter/encounterCreationRequestDto";
import {A} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-submission-license-verification',
  templateUrl: './submission-license-verification.component.html',
  styleUrls: ['./submission-license-verification.component.scss']
})
export class SubmissionLicenseVerificationComponent implements OnInit, OnDestroy {

  public licenses: Array<DataLimitationLicenseDto> | undefined;
  private subs: Array<Subscription> = new Array<Subscription>();
  public dataLimitation: string | undefined;
  public license: DataLimitationLicenseDto | undefined;
  public licenseLogo: any | undefined;
  public validSelection = true;


  public public = true;
  public attribute = false;
  public selectionsMade = false;
  public licenseTooltip = "";
  public limitLevel = 1;
  public errorMessages: Array<string> = new Array<string>();


  constructor(
    public dialogRef: MatDialogRef<SubmissionLicenseVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EncounterCreationRequestDto,
    private encounterService: EncountersService,
    private licenseService: LicenseService,
  ) {

  }



  onNoClick() {
    this.dialogRef.close();
  }

  updateLicense() {
    this.errorMessages = new Array<string>();
    if (this.public && !this.attribute) {
      this.limitLevel = 1;
      this.validSelection = true;

    } else if (this.public && this.attribute) {
      this.limitLevel = 2;
      this.validSelection = true;
    } else {
      this.limitLevel = 3;
      if (this.data.organizationIds === undefined || this.data.organizationIds.length == 0) {
        this.validSelection = false;
        if (!this.data.editing) {
          this.errorMessages.push("You have selected a license for organizational use but not selected any organizations. Please either select an organization or a different license.")
        } else {
          this.errorMessages.push("You have selected a license for organizational use but not selected any organizations. Please assign this encounter to an organization or choose a different license.")

        }
      }
    }
    const item = this.licenses!.findIndex(a => a.limitLevel == this.limitLevel);
    this.license = this.licenses![item];
    this.dataLimitation = this.license.id;
    this.licenseLogo = this.getLicenseLogos(this.license!)

  }

  ngOnInit(): void {
    this.subs.push(this.encounterService
      .getLicenses()
      .subscribe(res => {
          this.licenses = JSON.parse(JSON.stringify(res.sort((a, b) => a.limitLevel! - b.limitLevel!)));
          this.dataLimitation = this.licenses![0].id;
          this.data.dataLimitation = this.dataLimitation;
          this.license = this.licenses![0];
          this.licenseLogo = this.getLicenseLogos(this.license);
          this.data.dataLimitationConfirmed = false;
        }

      ))
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  getLicenseLogos(license: DataLimitationLicenseDto) {
    return this.licenseService.getLogosForLicense(license)
  }
}
