<h2 mat-dialog-title>Add A New Member</h2>
<div mat-dialog-content class="content" *ngIf="data.user">
  <mat-form-field color="accent">
    <mat-label>First Name</mat-label>
    <input matInput [(ngModel)]="data.user.firstName" required>
  </mat-form-field>
  <mat-form-field color="accent">
    <mat-label>Last Name</mat-label>
    <input matInput [(ngModel)]="data.user.lastName" required >
  </mat-form-field>
  <mat-form-field color="accent">
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="data.user.email" required>
  </mat-form-field>
  <mat-form-field color="accent">
    <mat-label>Assigned Role</mat-label>
    <mat-select [(ngModel)]="data.role" required>
      <mat-option *ngFor="let role of data.roles" [value]="role.id">{{role.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field color="accent">
    <mat-label>Personalize your invitation</mat-label>
    <textarea matInput [(ngModel)]="data.message"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-buttons" >
  <button mat-stroked-button (click)="onNoClick()" color="accent">Cancel</button>
  <button mat-stroked-button (click)="submit()" cdkFocusInitial color="accent" [disabled]="!inviteDisabled()">Invite Member</button>
</div>
