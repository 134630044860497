<div class="content-container">
  <div class="content">
    <div class="content-header">
      <h2>Platform Administration</h2>

<!--      <button mat-stroked-button (click)="createOrganization()" color="accent"><mat-icon>add</mat-icon> Create Organization</button>-->
    </div>
    <mat-divider></mat-divider>
    <div class="management-items">
      <div class="management-item editable-line" [routerLink]="'/administration/invitations'">
        <h3>Invitation Management</h3>
      </div>
      <div class="management-item editable-line" [routerLink]="'/administration/api'">
        <h3>API Management</h3>
      </div>
      <div class="management-item editable-line" [routerLink]="'/user-management'">
        <h3>Platform User Management</h3>
      </div>
      <div class="management-item editable-line" [routerLink]="'/population-management'">
        <h3>Population Application Management</h3>
      </div>
    </div>
<!--    <div class="population-list" *ngIf="organizations | async as os; else loading">-->
<!--      <div class="population editable-line" *ngFor="let org of os" [routerLink]="getLink(org)">-->
<!--        <h3>{{org.name}}</h3>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<!--<ng-template #loading>-->
<!--  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [color]="'accent'"></mat-progress-spinner>-->
<!--</ng-template>-->
