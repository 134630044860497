import {Component, Input, OnInit} from '@angular/core';
import {IFileItem} from "../../../../../models/items/files/file-item.model";
import {IImageService} from "../../../../../services/files/images/image.service.interface";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationService} from "../../../../../services/population/population.service";
import {EncounterDto} from "../../../../../models/dto/encounter/encounterDto";
import {AnnotatedImage} from "../../../../../models/items/files/images/annotated-images/annotated-image.model";
import {DateService} from "../../../../../services/utilities/date.service";
import {IMetaData} from "../../../../../models/data/files/meta-data.model";
import {META} from "@angular/cdk/keycodes";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";

@Component({
  selector: 'global-image-information',
  templateUrl: './image-information.component.html',
  styleUrls: ['./image-information.component.scss']
})
export class ImageInformationComponent implements OnInit {
  @Input() image: AnnotatedImage | undefined;
  @Input() encounter: EncounterDto | undefined;

  public editingMap: Map<string, boolean> = new Map<string, boolean>();
  public canEdit = false;
  private map = {
    0: "None",
    1: "Noteworthy",
    2: "Noteworthy",
    3: "Good",
    4: "Excellent",
    5: "Excellent"
  }
  constructor(
    private imgService: IImageService,
    private log: ILoggingService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe( res => {
      this.canEdit = (res.settings?.populationRoles?.administrator || res.settings?.populationRoles?.expert || res.settings?.populationRoles?.professional) ?? false;
    })
    if (typeof this.image?.metaData.creationDateTime === "string") {
      this.image!.metaData!.creationDateTime! = new Date(this.image!.metaData!.creationDateTime)
    }

  }

  setEditFalse() {
    for (let key of this.editingMap.keys()) {
      this.editingMap.set(key, false);
    }
  }

  toggleEdit(editId: string) {
    if (!this.canEdit) return;
    this.setEditFalse();
    this.editingMap.set(editId, true);
  }

  update() {
    this.setEditFalse();
    if (this.image) {
      this.imgService
        .updateMetaData(this.image?.metaData)
        .subscribe({
          next: (value: any) => {
            this.log.info("Updated meta data")
          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`Could not update meta data: ${value.error}`);
          }
        })
    }


  }

  mapQuality(quality: number | undefined) {
    if (quality === undefined) {
      return "Unknown";
    }
    // @ts-ignore
    return this.map[quality];
  }

  formatIDs() {
    return this.image?.annotations
      .filter(a => a.tag!.indexOf("Unknown") == -1 && a.confirmed)
      .sort((a, b) => a.box.x - b.box.x)
      .map(a => a.tag).join(", ")
  }

  formatDate() {
    if (typeof this.image?.metaData.creationDateTime === "string") {
      this.image!.metaData!.creationDateTime = new Date(this.image!.metaData!.creationDateTime);
    }
    return this.image?.metaData.creationDateTime!.toLocaleDateString() + " " + this.image?.metaData.creationDateTime!.toLocaleTimeString()
  }

  getImageDtString(metaData: IMetaData) {
    if (metaData.fileCreationDateTime) {

      const date = metaData.fileCreationDateTime.split("T")[0]
      const time = metaData.fileCreationDateTime.split("T")[1].split("-")[0]

      return `${date} ${time}`
    } else {
      return `${metaData.creationDateTime?.toLocaleDateString()} ${metaData.creationDateTime?.toLocaleTimeString()}`
    }
  }
}
