import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {NotificationService} from "../../../../../services/notifications/notification.service";
import {NotificationDto} from "../../../../../models/dto/notification/notificationDto";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {StyleManagerService} from "../../../../../services/style-manager/style-manager.service";
import {DateService} from "../../../../../services/utilities/date.service";
import {not} from "rxjs/internal/util/not";
import {A} from "@angular/cdk/keycodes";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {MatSidenav} from "@angular/material/sidenav";

@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {
  @Input() user: UserProfileDto | undefined;
  @Input() inputSideNav?: MatSidenav
  @Output() notificationsFetched: EventEmitter<Array<NotificationDto>> = new EventEmitter<Array<NotificationDto>>();
  public notifications: Array<NotificationDto> = new Array<NotificationDto>();
  public isDark = true;

  public unread?: string;

  private activeNotification?: string;
  private activeNotificationStart?: number;

  private readThreshold = 0.5;

  private subs: Array<Subscription> = new Array<Subscription>();
  public haveUnread: boolean = false;
  constructor(
    public dateService: DateService,
    private notificationService: NotificationService,
    private errorHandler: ErrorHandlerService,
    private styleManager: StyleManagerService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
      for (let sub of this.subs) {
        sub.unsubscribe();
      }
      this.notifications = new Array<NotificationDto>();
  }


  ngOnInit(): void {
    this.styleManager.isDarkObs.subscribe( res => {
      this.isDark = res;
    });

    this.subs.push(this.notificationService.getUserNotifications().subscribe({
      next: (notifications: Array<NotificationDto>) => {
        for (let n of notifications) {
          n.date = new Date(n.date);
        }
        this.notifications = notifications;
        this.notificationsFetched.emit(this.notifications);
        this.getNotificationCount();

      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError(`Could not get notifications`, err)
      }
    }))
  }

  setNotificationReadStart(notification: NotificationDto) {
    this.activeNotification = notification.id;
    this.activeNotificationStart = Date.now();

  }

  setNotificationReadEnd(notification: NotificationDto) {
    if (notification.id === this.activeNotification) {
      this.activeNotification = notification.id;
      const duration = Date.now() - this.activeNotificationStart!
      if (duration / 1000 >= this.readThreshold) {
        this.markRead(notification);
      }
    }

  }


  getNotificationCount() {
    const unread = this.notifications.filter(r => !r.read);
    if (unread.length <= 0) {
      this.haveUnread = false;
      return;
    } else {
      this.haveUnread = true;
    }
    if (unread.length < 10) {
      this.unread = `${unread.length}`;
    } else {
      this.unread =  "9+"
    }

  }

  markRead(notification: NotificationDto) {
    notification.read = true;
    this.subs.push(this.notificationService.markNotificationAsRead(notification.id).subscribe({
      next: (value: any) => {
        this.getNotificationCount();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError(`Could not mark notification as read`, err)
        notification.read = false;
      }
    }))

  }

  navigate(notification: NotificationDto) {
    const queryString = notification.link.split("?")
    if (queryString.length > 1) {
      const queryDict = {}
      const queries = queryString[1].split("&")
      for (let query of queries) {
        const ps = query.split("=")
        // @ts-ignore
        queryDict[ps[0]] = ps[1]
      }
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(
        [queryString[0]],
        { queryParams: queryDict}
      );
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(
        [queryString[0]],
      );
    }

  }
}
