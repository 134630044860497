import {Component, Input, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../models/dto/encounter/encounterDto";

@Component({
  selector: 'app-gallery-preview-loading',
  templateUrl: './gallery-preview-loading.component.html',
  styleUrls: ['./gallery-preview-loading.component.scss']
})
export class GalleryPreviewLoadingComponent implements OnInit{
  @Input() encounter?: EncounterDto
  @Input() count: number = 0;
  public previews: Array<any> = new Array<any>();

  ngOnInit(): void {
    this.previews = new Array<any>();
    const count = this.encounter?.preview?.imageCount ?? this.count;
    for (let i = 0; i < count; i++) {
      this.previews.push({});
    }
  }

}
