<div class="image-upload">
  <h2>Add Your Images!</h2>
  <div class="dropzone" appDragDropFileUpload (fileDropped)="onFileDropped($event)">
    <div class="dropzone-content">
      <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
      <mat-icon class="wiggle">upload</mat-icon>
      <h3>Drag and drop all (or at least the best) identification images of every whale present during your encounter here</h3>
      <h4>Please convert RAW image files into either jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|tif|TIF|tiff|TIFF format</h4>
      <button mat-button color="primary">
        <label for="fileDropRef"> Browse for files</label>
      </button>
    </div>
  </div>
</div>

<!--<div class="submission-loading-container" *ngIf="preparing">-->
<!--  <div class="submission-loading-content">-->
<!--    <div class="spinner-container" *ngIf="fileItems.length < files.length">-->
<!--      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="75" [strokeWidth]="2"></mat-progress-spinner>-->
<!--      <div class="spinner-text-container">-->
<!--        {{currentStepText}}-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="submission-step-text-list">-->
<!--      <div class="list-item">-->
<!--        <div class="list-item-icon">-->
<!--          <mat-progress-spinner *ngIf="files.length != fileItems.length" [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2"></mat-progress-spinner>-->
<!--        </div>-->
<!--        <span>Files prepared ({{fileItems.length}} / {{files.length}})</span>-->
<!--      </div>-->

<!--    </div>-->

<!--  </div>-->
<!--</div>-->
