<div class="uploaded-content">
  <h2 *ngIf="fileItems && fileItems.length > 0">Your Selected Images</h2>
  <div class="thumbnail-container" *ngIf="fileItems && fileItems.length > 0">
    <div class="thumbnail" *ngFor="let fileItem of fileItems">
      <div class="remove-button" (click)="removeImage(fileItem)">
        <mat-icon>delete</mat-icon>
      </div>
      <span>{{fileItem.name}}</span>
    </div>
  </div>
</div>
