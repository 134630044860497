import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

import {PredictionSet} from "../../../models/prediction/prediction-set";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: "root"
})
export class IPredictionFileService {
  constructor(public log: ILoggingService) {}
  items: BehaviorSubject<Array<IFileItem>> = new BehaviorSubject<Array<IFileItem>>([]);

  getItems(path: string): void { return } ;

  getPredictionsForFile(file: IFileItem): string { return "";}
}
