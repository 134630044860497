import {Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {IImageService} from "../../../../services/files/images/image.service.interface";
import {FileItem, IFileItem} from "../../../../models/items/files/file-item.model";
import {Observable} from "rxjs";
import {ImageScalingService} from "../../../../services/files/images/scale/image-scaling.service";
import {AnnotatedImage} from "../../../../models/items/files/images/annotated-images/annotated-image.model";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ImageAnnotation} from "../../../../models/annotation/image/annotation.model";
import {WebFileService} from "../../../../services/files/web-file.service";
import {GeocodingService} from "../../../../services/geocoding/geocoding.service";
import {GeocoderResponse} from "../../../../models/response/geocoding/geocoder.response.model";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {ImageService} from "../../../../services/utilities/image.service";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationService} from "../../../../services/population/population.service";
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {EncounterDto} from "../../../../models/dto/encounter/encounterDto";
import {AnimalProfileDto} from "../../../../models/dto/animal/animalProfileDto";
import {ResponsiveDesignService} from "../../../../services/design/responsive-design.service";

export interface GalleryImageDialogData {
  item: AnnotatedImage;
  suggestions: Array<AnimalDto>;
  nextEmitter: EventEmitter<any>;
  previousEmitter: EventEmitter<any>;
  allowAnnotation: boolean;
  showAnnotations: boolean;
  showMetaData: boolean;
  user?: UserProfileDto;
  tab?: number
  encounter?: EncounterDto,
  animals?: Array<AnimalProfileDto>
}

/**
 * @title Injecting data when opening a dialog
 */
@Component({
  selector: 'gallery-image-dialog',
  styleUrls: ['gallery-image.dialog.scss'],
  templateUrl: 'gallery-image.dialog.html',
})
export class GalleryImageDialogComponent implements OnInit, OnChanges {
  constructor(
    public dialog: MatDialog,
    private responsiveService: ResponsiveDesignService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }

  public dialogRef: MatDialogRef<any> | undefined;
  public tablet = false;
  public mobile = false;
  ngOnInit() {
    this.responsiveService.tablet.subscribe(res => {
      this.tablet = res;
    });
    this.responsiveService.mobile.subscribe(res => {
      this.mobile = res;
    })
  }

  public getWidth() {
    if (this.tablet || this.mobile) {
      return "100vw"
    } else {
      return undefined;
    }
  }

  @Input() allowAnnotation: boolean = true;
  @Input() showAnnotations: boolean = true;
  @Input() animals: Array<AnimalProfileDto> | undefined;
  @Input() showMetaData: boolean = true;
  @Input() encounter: EncounterDto | undefined;
  @Input() tab: number = 0;
  @Output() annotationRemoved: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationConfirmed: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationUpdated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationCreated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();

  @Output() previousImageSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextImageSelected: EventEmitter<any> = new EventEmitter<any>();

  @Output() contentChanged = new EventEmitter<any>();

  openDialog(item: IFileItem, suggestions: Array<AnimalDto>, user: UserProfileDto | undefined, animals: Array<AnimalDto>) {
    this.dialogRef = this.dialog.open(GalleryImageDialogDataComponent, {
      data: {
        item: item,
        suggestions: suggestions,
        nextEmitter: this.nextImageSelected,
        previousEmitter: this.previousImageSelected,
        allowAnnotation: this.allowAnnotation,
        showAnnotations: this.showAnnotations,
        showMetaData: this.showMetaData,
        user: user,
        tab: this.tab,
        encounter: this.encounter,
        animals: animals
      },
      width: this.getWidth(),
      maxWidth: this.getWidth()
    });
    const updatedDialog = this.dialogRef
      .componentInstance
      .annotationUpdated
      .subscribe((res: any) => {
        this.annotationUpdated.emit(res);
    })
    const confirmedDialog = this.dialogRef
      .componentInstance
      .annotationConfirmed
      .subscribe((res: any) => {
        this.annotationConfirmed.emit(res);
      })
    const removedDialog = this.dialogRef
      .componentInstance
      .annotationRemoved
      .subscribe((res: any) => {
        this.annotationRemoved.emit(res);
      })
  }

}

@Component({
  selector: 'gallery-image-dialog-data',
  styleUrls: ['gallery-image.dialog.scss'],
  templateUrl: 'gallery-image.dialog.data.html',
})
export class GalleryImageDialogDataComponent implements OnInit {
  src: string | SafeUrl | undefined;
  public population: PopulationDto | undefined;
  public saving = false;
  @Output() annotationRemoved: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationConfirmed: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationUpdated: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();

  constructor(
    public dialogRef: MatDialogRef<GalleryImageDialogDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GalleryImageDialogData,
    public imageService: IImageService,
    public scalingService: ImageScalingService,
    public domSanitizer: DomSanitizer,
    public webFileService: WebFileService,
    public geocodingService: GeocodingService,
    public loggingService: ILoggingService,
    public imageUtilities: ImageService,
    public populationService: PopulationService
    ) {}

  ngOnInit() {
    this.populationService.population.subscribe( res => {
      this.population = res;
    });

    if (!this.data.item.image) {
      this.imageService.fetchImageFromId(this.data.item.id).subscribe({
        next: (result: any) => {
          this.loggingService.info(`Image loaded`, true);
          this.data.item.image = result.uri;
          // this.maybePrefix();
        },
        error: (err: HttpErrorResponse) => {
          this.loggingService.error(`Image could not be found: ${err.message}`, true);
        }
      })
    }
    if (this.data.item.annotations !== undefined && this.data.item.annotations.length > 0) {
      this.prefixAnnotations();
    }

    this.maybePrefix();
  }

  prefixAnnotations() {
    for (let annotation of this.data.item.annotations) {
      if (annotation.tag !== undefined && annotation.tag.indexOf("Garbage") !== -1) {
        annotation.tag = "Unknown";
      }
      if (!annotation.confirmed && annotation.tag?.indexOf('Model Suggestion') == -1) {
        annotation.tag = `Model Suggestion: ${annotation.tag}`;
      }
    }
  }

  maybePrefix() {
    if (this.data.item !== undefined && typeof this.data.item.image === "string") {
      if (this.data.item.image?.indexOf("base64") == -1) {
        this.data.item.image = this.imageUtilities.prefixImg(this.data.item.image!);
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  previousImage(): void {
    this.data.previousEmitter.emit();
    this.dialogRef.close();
  }

  nextImage(): void {
    this.data.nextEmitter.emit();
    this.dialogRef.close();
  }

  enterSubmit($event: any): void {
    this.dialogRef.close();

  }

  updateContext(file: IFileItem) {
    this.saving = true;
    var year = file.metaData.creationDateTime!.getFullYear()
    if (file.metaData.creationDateTime!.getFullYear() == 1) {
      file.metaData.creationDateTime = new Date();
    }
    this.webFileService
      .createAnnotations(file, this.population?.id!)
      .subscribe(res => {
        this.saving = false;
        this.loggingService.info(`Annotation created.`)
    })
  }

  updateLocation(file: IFileItem) {
    if (file.metaData.location && file.metaData.location.latitude && file.metaData.location.longitude) {
      const lat = file.metaData.location.latitude;
      const lng = file.metaData.location.longitude;
      this.geocodingService.getLocationNameFromLatLng(lat, lng).subscribe( (res: GeocoderResponse) => {
        if (file.metaData.location) {
          file.metaData.location.name = this.geocodingService.mapGeoCoderResponseFromReverseToName(res);
        }
        this.webFileService.updateLocation(file).subscribe( res => {
          this.loggingService.info(`Location updated to ${res.result.location.name} at Lat: ${res.result.location.latitude}, Long:${res.result.location.longitude}`, true);
        });
      })
    }

  }

  removeAnnotation(annotation: ImageAnnotation) {
    this.saving = true;
    this.webFileService.deleteAnnotation(annotation).subscribe(res => {
      this.annotationRemoved.emit(annotation);
      this.loggingService.info(`Annotation removed for ${annotation.tag}`, true);
      this.saving = false;
    })
  }

  confirmAnnotation(annotation: ImageAnnotation) {
    this.saving = true;
    this.webFileService.confirmAnnotation(annotation, this.population?.id!).subscribe({
      next: (result) => {
        this.annotationConfirmed.emit(annotation);
        this.loggingService.info(`Annotation confirmation successful for ${annotation.tag}`, true);
        this.saving = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loggingService.error(`Annotation confirmation failed: ${error.message}`, true);
        this.saving = false;
      }
    })
  }



}
