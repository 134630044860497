import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnimalService} from "../../../services/animal/animal.service";
import {AnimalDto} from "../../../models/dto/animal/animalDto";
import {ActivatedRoute, Router} from "@angular/router";
import {ImageService} from "../../../services/utilities/image.service";
import {PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {from, mergeMap, Subscription, throwError} from "rxjs";
import {WorkspaceService} from "../../../services/workspace/workspace.service";
import {IItemStorageService} from "../../../services/storage/item/item-storage.service.interface";
import {DateService} from "../../../services/utilities/date.service";
import {IndividualSearchGalleryComponent} from "./individual-search-gallery/individual-search-gallery.component";
import {AnimalSearchDto, LifeStatus} from "../../../models/dto/animal/animalSearchDto";
import {ResponsiveDesignService} from "../../../services/design/responsive-design.service";
import {MatDialog} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../services/error/error-handler.service";
import {catchError} from "rxjs/operators";
import {QueryService} from "../../../services/query/query.service";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-animal-profiles',
  templateUrl: './animal-profiles.component.html',
  styleUrls: ['./animal-profiles.component.scss']
})
export class AnimalProfilesComponent implements OnInit, OnDestroy {
  public animals: Array<AnimalDto> | undefined;
  public fetchFinished = false;
  public fetchStarted = false;
  public searchResults: Array<AnimalDto> | undefined;
  public isMobile: boolean = false;
  public searchStarted: boolean = false;
  public searchFinished: boolean = false;
  public searchComplete: boolean = false;

  public population: PopulationDto | undefined;
  private popSub: Subscription | undefined;
  private animalSub: Subscription | undefined;

  public statuses: Array<LifeStatus> = [new LifeStatus("alive", "Alive"), new LifeStatus("deceased", "Deceased"), new LifeStatus("all", "All")]
  public selectedLifeStatus = this.statuses[0];

  public sidebarOpen: boolean = false;
  public request: AnimalSearchDto | undefined;
  public subs: Array<Subscription> = new Array<Subscription>();
  constructor(
    private animalService: AnimalService,
    private router: Router,
    public imageService: ImageService,
    public populationService: PopulationService,
    public workspaceService: WorkspaceService,
    private storageService: IItemStorageService,
    private dateService: DateService,
    private dialog: MatDialog,
    private responsiveDesignService: ResponsiveDesignService,
    private errorHandler: ErrorHandlerService,
    private queryService: QueryService,
    private route: ActivatedRoute,
    private log: ILoggingService,
    private cookieService: CookieService
    ) { }
  ngOnInit(): void {
    this.responsiveDesignService.mobile.subscribe(res => {
      this.isMobile = res;
    })

    this.route.queryParams.subscribe(res => {
      const querySize = Object.keys(res).length
      if (querySize > 0) {
        this.request = this.queryService.invertAnimalQueryParams(res);
        this.animals = [];
        this.getPage(this.request, false);
      }
      // else if (this.cookieService.get('IndividualsRequest')) {
      //   this.request = JSON.parse(this.cookieService.get('IndividualsRequest'));
      //   this.animals = [];
      //   console.log('Found search cookie.');
      //   this.getPage(this.request!);
      // }
      else
      {
        this.workspaceService.workspace.subscribe(res => {
          if (res.settings != undefined) {
            this.population = res.settings!.population;
            this.request = new AnimalSearchDto(this.population?.id!, this.selectedLifeStatus.value);
            this.animals = [];
            // this.getPage(this.request, true);
            this.animalService.getAnimalsComplete(this.population!.id!, this.selectedLifeStatus.value)
              .subscribe({
                next: value => {
                  this.animals = value;
                },
                error: err => {
                  this.animals = [];
                  this.log.error(`Could not fetch animals: ${err}`);
                }
              })
          }
          });
      }

    });

  }

  createImageFromBlob(image: Blob, animal: AnimalDto) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      animal.thumbnail = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }


  loadAnimals(animals: Array<AnimalDto>) {
    const obs = []

    for (let animal of animals) {
      animal.loading = true;
      const o = this.animalService.getAnimalPreviewThumbnail(animal.id);
      obs.push(o);
    }
    let idx = 0;
    this.subs.push(from(obs).pipe(
      mergeMap((o) => o, 2),
      catchError(e => throwError(e)) )
      .subscribe({
        next: (value: any) => {
          const byteCharacters = atob(value["file"]["fileContents"]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: value["file"]["contentType"]});

          let animal = animals.find(a=> a.id == value["id"])!;
          this.createImageFromBlob(blob, animal);
          animal.loading = false;
          idx++;
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError(`Loading Image`, value);
          idx++;
        }
      }));


  }

  ngOnDestroy() {
    this.popSub?.unsubscribe();
    this.animalSub?.unsubscribe();
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }


  visit(animal: AnimalDto) {
    const url = `/individuals/${animal.id}`
    this.router.navigate([url]);
  }

  groupMatrilines(animals: Array<AnimalDto>) {
    const map: Map<string, Array<AnimalDto>> = new Map<string, Array<AnimalDto>>();
    const animalList = [];
    for (let animal of animals) {
      let matriline = animal.identifier.substring(0, 4);
      if (!map.has(matriline)) {
        map.set(matriline, [])
      }
      map.get(matriline)!.push(animal)
      map.get(matriline)!.sort((a: AnimalDto, b: AnimalDto) => a.identifier.length - b.identifier.length);
    }

    let sortedMap = new Map([...map.entries()].sort());
    for (let e of sortedMap.keys()) {
      animalList.push(...map!.get(e)!);
    }

    return animalList;
  }

  updateResults($event: any) {
    if ($event) {
      this.searchResults = this.animalService.sortFamilies($event);
    } else {
      this.searchResults = $event;
    }

  }


  getQuality(quality: number | undefined) {
    return this.animalService.mapQuality(quality);
  }

  getDate(date: any) {
    return this.dateService.formatDateFromAny(date, false, true, true, true, false);
  }

  viewImage(animal: AnimalDto) {
    const ref = this.dialog.open(IndividualSearchGalleryComponent, {
      data: {
        results: this.searchResults,
        selected: animal,
        profileOptions: true
      },
      disableClose: true
    })
  }

  updateStatus() {

    this.ngOnInit();
  }

  getRadioLabelPosition(): "before" | "after" {
    if (this.isMobile) {
      return "after";
    }
    return "before";
  }

  getMode(): "over" | "push" | "side" {
    if (this.isMobile) {
      return "over";
    }
    return "side";
  }

  submitSearch(dto: AnimalSearchDto) {
    const q = this.queryService.sanitizeIndividualQuery(dto);
    this.router.navigate(['/individuals'], {queryParams: q})
    this.ngOnInit();
  }

  getPage(dto: AnimalSearchDto, noSearch: boolean = true) {

    this.searchStarted = true;
    this.animalService
      .searchAnimals(dto)
      .subscribe({
        next: (value: Array<AnimalDto>) => {
          if (value === null || value === undefined) {
            this.log.error("Your request could not be processed. Please refine your search to lessen the results or contact the administrators.")
            this.animals = [];
            this.searchFinished = false;
            this.searchStarted = false;
            return;

          }
          this.searchResults = this.animalService.concatIds(value);
          let v = "entry"
          if (value.length != 1) {
            v = "entries"
          }
          this.log.info(`Search Complete: ${value.length} ${v} found`);
          this.searchFinished = false;
          this.searchStarted = false;

        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not execute search: ${value.message}`);
        }
      })
  }

  getIndividualLink(animal: AnimalDto) {
    return `/individuals/${animal.id}`;
  }
}
