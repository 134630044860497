import {environment} from "../../../../environments/environment";
import {ElectronItemStorageService} from "../../../../services/storage/item/item-storage.service.electron";
import {WebItemStorageService} from "../../../../services/storage/item/item-storage.service.web";
import {LocalItemStorageService} from "../../../../services/storage/item/item-storage.service.local";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";

export let itemStorageFactory = (log: ILoggingService) => {
  if (environment.production && environment.electron) {
    return new ElectronItemStorageService(log)
  } else if (environment.production && !environment.electron) {
    return new WebItemStorageService(log)
  } else {
    return new LocalItemStorageService(log)
  }
}
