import {Injectable} from "@angular/core";
import {LogLevel} from "../../models/logging/log-levels.enum";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class ILoggingService {
  level = LogLevel.INFO;
  constructor(public snackBar: MatSnackBar, public httpClient: HttpClient) {}

  setLevel(logLevel: LogLevel) {this.level = logLevel;}

  info(msg: string, snackbar= true, content?: any): void {}
  debug(msg: string, snackbar= true, content?: any): void {}
  warn(msg: string, snackbar= true, content?: any): void {}
  error(msg: string, snackbar= true, content?: any): void {}

}
