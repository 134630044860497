<h1 mat-dialog-title>Update Your Organization Logo</h1>
<div mat-dialog-content>
  <input type="file" class="file-upload" (change)="addProfileImage($event)">
  <div class="image-container" *ngIf="data.organizationImage">
    <img [src]="imageService.maybePrefix(data.organizationImage.Logo)" />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.organizationImage" cdkFocusInitial>Update</button>
</div>
