
<mat-action-list *ngIf="behaviors">
  <mat-list-item *ngFor="let behavior of behaviors" class="auto-height">
    <div *ngIf="editingId !== behavior.id" (click)="startEdit(behavior)">
      {{behavior.description}}
    </div>
    <div *ngIf="editingId === behavior.id" class="behavior-edit">
      <mat-form-field class="full-width" color="accent" *ngIf="editingBehavior !== undefined">
        <mat-label>Behavior Description</mat-label>
        <input matInput [(ngModel)]="editingBehavior.description"/>
      </mat-form-field>
      <div class="button-row">
        <button mat-button (click)="updateBehavior()">Save Behavior</button>
        <button mat-button (click)="deleteBehavior(behavior.id)">Delete Behavior</button>
        <button mat-button (click)="cancelEdit()">Cancel</button>
      </div>

    </div>
  </mat-list-item>
</mat-action-list>
<div class="adding" *ngIf="adding">
  <mat-form-field *ngIf="newBehavior !== undefined" class="full-width" color="accent">
    <mat-label>Behavior Description</mat-label>
    <input matInput [(ngModel)]="newBehavior.description"/>
  </mat-form-field>
  <button mat-button (click)="createBehavior()">Save Behavior</button>
  <button mat-button (click)="adding = false">Cancel</button>
</div>
<button mat-button (click)="addBehavior()" *ngIf="!adding && !editing">Add Behavior</button>
