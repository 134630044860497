<h1 mat-dialog-title>{{data.selected.identifier }}</h1>
<div mat-dialog-content class="dialog-content">
<!--  <div class="image-container" *ngIf="data.imageId === undefined && data.selected.image !== undefined">-->
<!--    <img [src]="imageService.maybePrefix(data.selected.image!)" [alt]="data.selected.identifier"/>-->
<!--  </div>-->
  <div class="image-container" *ngIf="imageObs !== undefined">
    <div class="full-sized" *ngIf="imageObs | async as image; else loading">
      <img [src]="image.uri" [alt]="data.selected.identifier"/>
    </div>

  </div>
  <div class="details-container">
    <h2>Image Details</h2>
    <mat-divider></mat-divider>
    <div class="details-entry">
      <div class="key"><h4>Identifier</h4></div>
      <div class="value"><h4>{{data.selected.identifier}}</h4></div>
    </div>
    <mat-divider></mat-divider>
    <div class="details-entry">
      <div class="key"><h4>Location</h4></div>
      <div class="value"><h4>{{data.selected.location}}</h4></div>
    </div>
    <mat-divider></mat-divider>
    <div class="details-entry" *ngIf="date">
      <div class="key"><h4>Date</h4></div>
      <div class="value"><h4>{{dateService.formatDateFromAny(date!, false)}}</h4></div>
    </div>
    <mat-divider></mat-divider>
    <div class="details-entry">
      <div class="key"><h4>Photographer</h4></div>
      <div class="value"><h4>{{data.selected.photographer}}</h4></div>
    </div>
    <mat-divider></mat-divider>
    <div class="details-entry" *ngIf="data.profileOptions">
      <a mat-stroked-button color="accent" class="key full-width" [routerLink]="getProfileLink(data.selected)" (click)="onNoClick()" >View Profile</a>
    </div>
    <mat-divider></mat-divider>
    <div class="details-entry" *ngIf="data.profileOptions">
      <a mat-stroked-button color="accent" class="key full-width" (click)="startProfileUpdate(data.selected)" *ngIf="!profileUpdating">Make Image Profile Picture</a>
      <div class="profile-image-update-section full-width" *ngIf="profileUpdating">

          <mat-radio-group  [(ngModel)]="data.selected.side">
            <mat-radio-button [value]="'right'">Right</mat-radio-button>
            <mat-radio-button [value]="'left'">Left</mat-radio-button>
          </mat-radio-group>

        <div class="button-row-stacked" *ngIf="data.selected.side">
        <button class="full-width" mat-stroked-button color="accent" (click)="updateProfileImage(data.selected)">Set Profile Image ({{data.selected.side}})</button>
        <button class="full-width" mat-stroked-button color="accent" (click)="profileUpdating = false">Cancel</button>
        </div>
      </div>

    </div>
    <mat-divider></mat-divider>
    <div class="details-entry" *ngIf="getEncounterLink(data.selected).length > 0">
      <a mat-stroked-button class="key full-width" [routerLink]="getEncounterLink(data.selected)" color="accent" [queryParams]="getQueryParams(data.selected)" (click)="onNoClick()" >View Encounter Image Source</a>
    </div>

  </div>

</div>
<ng-template #loading>
  <app-gallery-preview-loading-image [width]="256" [height]="256"></app-gallery-preview-loading-image>
</ng-template>
<div mat-dialog-actions class="button-row">
  <button mat-stroked-button (click)="onNoClick()" cdkFocusInitial >Close</button>
<!--  <button mat-stroked-button [mat-dialog-close]="selectedImage" cdkFocusInitial>Confirm</button>-->
  <div class="direction-buttons"  *ngIf="data.results.length > 0">
    <button mat-icon-button color="accent" (click)="previous()"><mat-icon>chevron_left</mat-icon></button>
    <button mat-icon-button color="accent" (click)="next()"><mat-icon>chevron_right</mat-icon></button>
  </div>
</div>
