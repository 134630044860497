import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {
  AnimalSearchDto,
  DataSourceDto,
  LifeStatusDto,
  PositionDto, QualityDto
} from "../../../../models/dto/animal/animalSearchDto";
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AnimalService} from "../../../../services/animal/animal.service";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../../models/dto/workspace/workspaceDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {map, Observable, startWith} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-individual-search-drawer',
  templateUrl: './individual-search-drawer.component.html',
  styleUrls: ['./individual-search-drawer.component.scss']
})
export class IndividualSearchDrawerComponent implements OnInit, OnChanges {
  @Input() animals: Array<AnimalDto> | undefined;
  @Input() status: LifeStatusDto = new LifeStatusDto();
  @Input() dto?: AnimalSearchDto;

  @Output() searchComplete: EventEmitter<Array<AnimalDto>> = new EventEmitter<Array<AnimalDto>>();
  @Output() searchReset: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() searchSubmitted: EventEmitter<AnimalSearchDto> = new EventEmitter<AnimalSearchDto>();

  @ViewChild('individualInput') individualInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  private workspace?: WorkspaceDto;
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  public searchStarted: boolean = false;
  public searchFinished: boolean = false;
  public currentLifeStatus: undefined | LifeStatusDto = undefined;

  individualControl = new FormControl();
  public filteredAnimals: Observable<string[]> | undefined;
  public selectedAnimals: string[] = new Array<string>();

  constructor(
    private workspaceService: WorkspaceService,
    private animalService: AnimalService,
    private log: ILoggingService,
    private cookieService: CookieService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (
      (changes["animals"]["currentValue"])
    ) {
      this.animals = changes["animals"]["currentValue"]
      this.filteredAnimals = this.individualControl.valueChanges.pipe(
        startWith(null),
        map((animal: string | null) => (animal ? this._filter(animal) : this.animals!.map(a => a.identifier).slice())),
      );
    }
  }

  add(event: MatChipInputEvent): void {
    const value = event.value;
    if (value) {
      this.selectedAnimals.push(value);
    }
    event.chipInput!.clear();
    this.individualControl.setValue(null);
  }

  remove(animal: string): void {
    const index = this.selectedAnimals.indexOf(animal);

    if (index >= 0) {
      this.selectedAnimals.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedAnimals.push(event.option.value);
    this.individualInput.nativeElement.value = '';
    this.individualControl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.animals!.map(a => a.identifier).filter(animal => animal.toLowerCase().includes(filterValue));
  }





  ngOnInit(): void {
    if (this.animals) {
      this.filteredAnimals = this.individualControl.valueChanges.pipe(
        startWith(null),
        map((animal: string | null) => (animal ? this._filter(animal) : this.animals!.map(a => a.identifier).slice())),
      );
    }


    if (this.status) {
      this.currentLifeStatus = this.status;
    }

    this.workspaceService.workspace.subscribe( res => {
      this.workspace = res;
      if (this.workspace && this.workspace.settings) {
        this.dto = new AnimalSearchDto(this.workspace.settings!.population!.id!);
        this.dto.status = this.status.value
      }
    })



  }

  submitSearch() {
    const selectedAnimals = this.animals?.filter(a => this.selectedAnimals.indexOf(a.identifier) !== -1).map(a => a.id);
    if (!this.dto) {return;}

    // @ts-ignore
    this.dto!.startDate = this.range.get('start')?.value?.toDate();
    // @ts-ignore
    this.dto!.endDate = this.range.get('end')?.value?.toDate();

    this.searchStarted = true;
    this.dto.status = this.status.value;
    this.dto.individuals = selectedAnimals;
    this.cookieService.set('IndividualsRequest', JSON.stringify(this.dto));
    //this.cookieService.get('IndividualsRequest')
    this.searchSubmitted.emit(this.dto);

  }

  reset() {
    this.dto = new AnimalSearchDto(this.workspace!.settings!.population!.id!);
    this.cookieService.set('IndividualsRequest', JSON.stringify(this.dto));
    this.range.reset();
    this.individualControl.reset();
    this.selectedAnimals = new Array<string>();
    this.searchReset.emit(undefined);
  }

  resetDataSources() {
    this.dto!.dataSources = new DataSourceDto();
  }

  resetIndividuals() {
    this.individualControl.reset();
    this.selectedAnimals = new Array<string>();
  }

  resetPosition() {
    this.dto!.position = new PositionDto();
  }

  resetQuality() {
    this.dto!.quality = new QualityDto();
  }

  resetTime() {
    this.range.reset();
  }
}
