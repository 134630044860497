import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-area-loading',
  templateUrl: './area-loading.component.html',
  styleUrls: ['./area-loading.component.scss']
})
export class AreaLoadingComponent implements OnInit {

  @Input() criteria: any = false;
  @Input() diameter: number = 75;

  @Input() title: string | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
