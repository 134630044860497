import { Component, Input } from '@angular/core';
import { organizationPreviewDto } from '../../../../../models/dto/user/organization/organizationPreviewDto';
import {ImageService} from "../../../../../services/utilities/image.service";

@Component({
  selector: 'app-organization-preview',
  templateUrl: './organization-preview.component.html',
  styleUrls: ['./organization-preview.component.scss']
})
export class OrganizationPreviewComponent {
  @Input() organizationpreview!: organizationPreviewDto;
  constructor(public imageService: ImageService) {

  }


  public getJdenticon(organization: organizationPreviewDto) {
    return `${organization.name}`;
  }
  getRouterLink(organization: organizationPreviewDto) {
    return `/organization/profile/${organization.id}`;
  }

  maybePrefix(logo: string) {
    return this.imageService.maybePrefix(logo);
  }
}
