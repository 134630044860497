
<div class="error-container form-content-container" *ngIf="validationErrorMessages.length > 0">
  <mat-card class="login-card">
    <mat-card-title>Error Validating Invitation</mat-card-title>
    <mat-card-content>
      <h4 *ngFor="let v of validationErrorMessages">{{v}}</h4>
    </mat-card-content>
  </mat-card>
</div>
<div class="form-content-container">
  <mat-card class="login-card" *ngIf="redemptionId">
    <mat-card-title>Sign Up</mat-card-title>
    <mat-card-content>
      <form class="signup-form form" [formGroup]="registerForm" (ngSubmit)="registerUser(registerForm.value)">
        <mat-form-field class="full-width" color="accent">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>

        <mat-form-field class="full-width" color="accent" >
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>

        <mat-form-field class="full-width"  color="accent">
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"
                 placeholder="Ex. pat@example.com">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width"  color="accent">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" type="password" formControlName="password">
        </mat-form-field>
        <mat-form-field class="full-width" color="accent" >
          <mat-label>Repeat Password</mat-label>
          <input matInput placeholder="Repeat Password" type="password" formControlName="confirm"  (keyup.enter)="registerUser(registerForm.value)">
          <mat-error *ngIf="registerForm.get('confirm')?.invalid">
            Passwords must match
          </mat-error>
        </mat-form-field>
        <div class="registration-errors">
          <mat-list>
            <mat-list-item *ngFor="let registrationError of registrationErrors">
              {{registrationError}}
            </mat-list-item>
          </mat-list>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="accent" [disabled]="!registerForm.valid" (click)="registerUser(registerForm.value)"  >
        <div class="button-content">
          <span>Register</span>
        </div>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
<app-area-loading [criteria]="!validating" title="Validating Invitation..."></app-area-loading>
<app-area-loading [criteria]="!registering" title="Registering..."></app-area-loading>
