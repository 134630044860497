<div class="organization-profile-image-container content-container">
  <div class="image-container">
    <div class="default" *ngIf="!organization_Logo">
      <svg width="100" height="100" [data-jdenticon-value]="getJdenticon()"></svg>
    </div>
    <div class="organization-profile-image" *ngIf="organization_Logo">
      <img [src]="imageService.maybePrefix(organization_Logo)" [alt]="organization_Name" id="Image">
    </div>
    <div class="image-edit-overlay" *ngIf="administrationAccess" (click)="openPhotoUpdateDialog()">
      <mat-icon>photo_camera</mat-icon>
      <h2>Update Banner</h2>
    </div>
  </div>
</div>
