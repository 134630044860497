<div class="profile-management-container" *ngIf="profile !== undefined">
  <div class="profile-details">
    <div class="profile-section-head">
      <h2>Sighting Information</h2>
    </div>
    <div class="profile-section-content">
      <div class="profile-section-entry">
        <div class="profile-section-entry-key">
          <h4>Total Sightings</h4>
        </div>
        <div class="profile-section-entry-value">
          <span *ngIf="profile.encounters !== undefined">{{profile.encounters!.length}}</span>
        </div>
      </div>
      <div class="profile-section-entry">
        <div class="profile-section-entry-key">
          <h4>Most Recent Sighting</h4>
        </div>
        <div class="profile-section-entry-value">
          <span *ngIf="profile.encounters !== undefined">{{getLastEncounterDate()}}</span>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="profile-details">
    <div class="profile-section-head">
      <h2>Sightings By Year</h2>
    </div>
    <div class="profile-section-content">
      <div class="profile-section-entry" *ngIf="currentlySelectedYear !== undefined">
      </div>
      <div *ngFor="let year of years!" class="profile-section-entry-key">
        <h4>{{year}}</h4>
        <div class="profile-section-entry-value" *ngIf="!editingSightingsPerYear">
          <span *ngIf="encountersPerYear.has(year!)">
            {{encountersPerYear.get(year!)}}
          </span>
        </div>
      </div>
      <!--<div class="profile-section-entry-key clickable editable-line" (click)="updateSightingsPerYear()">
          <h4 *ngIf="!editingSightingsPerYear">{{currentlySelectedYear}}</h4>
        <mat-form-field *ngIf="editingSightingsPerYear">
    <mat-select [(ngModel)]="currentlySelectedYear"
                (ngModelChange)="endEdit()"
                (selectionChange)="endEdit()"
                (closed)="endEdit()">
      <mat-option *ngFor="let year of years!" [value]="year">{{year}}</mat-option>
    </mat-select>
  </mat-form-field>
      <div class="profile-section-entry-value" *ngIf="!editingSightingsPerYear">
        <span *ngIf="encountersPerYear.has(currentlySelectedYear!)">{{encountersPerYear.get(currentlySelectedYear!)}}</span>
      </div>
    </div>-->
    </div>
  </div>
  <mat-divider></mat-divider>
</div>

