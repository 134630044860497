import {Component, Inject, OnInit} from '@angular/core';
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {C} from "@angular/cdk/keycodes";
import { UserOrganizationService } from '../../../../../services/user/organization/user-organization.service';
import { OrganizationCreationDto } from '../../../../../models/dto/organization/organizationCreationDto';
import { CreateOrganizationResponseDto } from '../../../../../models/dto/user/organization/createOrganizationDto';
import { HttpErrorResponse } from '@angular/common/http';
import { organizationPreviewDto } from '../../../../../models/dto/user/organization/organizationPreviewDto';
import { Router } from '@angular/router';
import { ILoggingService } from '../../../../../services/logging/logging.service.interface';

export interface OrganizationCreationData {
  name: string;
  allUsers: Observable<Array<UserProfileDto>>;
  users: Array<UserProfileDto>;

}

@Component({
  selector: 'app-organization-creation-dialog',
  templateUrl: './organization-creation-dialog.component.html',
  styleUrls: ['./organization-creation-dialog.component.scss']
})
export class OrganizationCreationDialogComponent implements OnInit {
  public currentUser?: UserProfileDto;

  createNewOrganization = false;
  updating = false;
  allOrganizationsPreviews?: Array<organizationPreviewDto>;
  CloseOrganizations: Array<organizationPreviewDto> = new Array<organizationPreviewDto>();
  dirty = false;


  constructor(
    public dialogRef: MatDialogRef<OrganizationCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrganizationCreationData,
    public workspaceService: WorkspaceService,
    private router: Router,
    private log: ILoggingService,
    public organizationservice: UserOrganizationService
  ) {}

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe(res => {
      if (res && res.settings && res.settings.user) {
        this.currentUser = res.settings.user;
      }
    })
    this.getAllOrganizations();
  }

  onCreateNewOrganization() {
    this.createNewOrganization = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close(this.data);
  }


  removeCurrent(possibleUsers: Array<UserProfileDto>) {
    if (this.currentUser) {
      return possibleUsers.filter(a => a.id !== this.currentUser?.id)
    }
    return possibleUsers;
  }
  getAllOrganizations() {
    this.organizationservice.getAllOrganizationPreviews().subscribe({
      next: (value: Array<organizationPreviewDto>) => {
        this.allOrganizationsPreviews = value;

      },
      error: (err: any) => { }
    });
  }

  updateCloseOrganizations() {
    if (this.data.name.length < 3) {
      return;
    }
    this.dirty = true;
    if (!this.createNewOrganization) {
      this.updating = true;
      this.CloseOrganizations = new Array<organizationPreviewDto>();
      this.allOrganizationsPreviews!.forEach((value, index) => {
      if (this.stringclose(value.name, this.data.name)) {
        this.CloseOrganizations.push(value);
      }
      });
      this.updating = false;
    }
  }

  stringclose(org: string, name: string): boolean {
    org = org.toLowerCase();
    name = name.toLowerCase();
    //Check contain
    if (name != "" && org.includes(name)) {
      return true;
    }

    //Check Levenshtein
    if (this.fastLevenshtein(org, name) < 5) {
      return true;
    }

    return false;
  }

  fastLevenshtein(x: string, y: string): number {
    if (Math.abs(x.length - y.length) > 4) {
      return 5;
    }
    if (x.length==0) {
      return y.length;
    }

    if (y.length == 0) {
      return x.length;
    }

    var substitution = this.fastLevenshtein(x.substring(1), y.substring(1)) + this.costOfSubstitution(x.charAt(0), y.charAt(0));
    var insertion = this.fastLevenshtein(x, y.substring(1)) + 1;
    var deletion = this.fastLevenshtein(x.substring(1), y) + 1;

    return Math.min(substitution, insertion, deletion);
  }

  costOfSubstitution(a: string, b: string): number {
    return a == b ? 0 : 1;
  }
  navigate(Id: string) {
    this.router.navigate(["/organization/profile/" + Id]);
  }

}
