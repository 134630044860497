import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {STATUS} from "../../../../../models/dto/status/statusChangeDto";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {EncounterPageRequestDto} from "../../../../../models/dto/encounter/encounterPageRequestDto";


export class CompleteStatus {
  public static ALL = "All";
  public static COMPLETE = "Complete";
  public static INCOMPLETE = "Incomplete";
  public static UNKNOWN = "Unknown";

  public static invert(value: string) {
    switch (value) {
      case CompleteStatus.ALL:
        return "ALL";
      case CompleteStatus.COMPLETE:
        return "COMPLETE";
      case CompleteStatus.INCOMPLETE:
        return "INCOMPLETE";
      case CompleteStatus.UNKNOWN:
        return "UNKNOWN"
      case "ALL":
        return CompleteStatus.ALL;
      case "COMPLETE":
        return CompleteStatus.COMPLETE;
      case "INCOMPLETE":
        return CompleteStatus.INCOMPLETE;
      case "UNKNOWN":
        return CompleteStatus.UNKNOWN;
      default:
        return "ALL"
    }


  }
}

@Component({
  selector: 'app-encounter-complete-status-selection',
  templateUrl: './encounter-complete-status-selection.component.html',
  styleUrls: ['./encounter-complete-status-selection.component.scss']
})
export class EncounterCompleteStatusSelectionComponent implements  OnInit {
  @Input() workspace: WorkspaceDto | undefined;
  @Input() request: EncounterPageRequestDto | undefined
  @Output() chosenStatus: EventEmitter<string>  = new EventEmitter<string>();


  public statuses = [
    CompleteStatus.ALL,
    CompleteStatus.COMPLETE,
    CompleteStatus.INCOMPLETE,
    CompleteStatus.UNKNOWN
  ]

  public status = CompleteStatus.ALL

  updateStatusSelection() {
    this.chosenStatus.emit(CompleteStatus.invert(this.status).toLowerCase())
  }

  public ngOnInit() {
    if (this.request && this.request.complete) {
      this.status = CompleteStatus.invert(this.request.complete).toLowerCase();
    }
  }

  public reset() {
    this.status = CompleteStatus.ALL;
    this.updateStatusSelection();
  }
}
