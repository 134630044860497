import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ExportRequestDto} from "../../models/dto/export/exportRequestDto";
import { ResponseDto } from '../../models/dto/response/responseDto';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataExportService {

  constructor(private httpClient: HttpClient) { }

  sendExportRequest(dataExportRequestDto: ExportRequestDto){
    const url = `${environment.server.baseUrl}${environment.server.api.export.exportData}`;
    //return this.httpClient.post(url, dataExportRequestDto, {responseType: "text" as any});
    return this.httpClient.post<ResponseDto>(url, dataExportRequestDto);
  }

  sendExportDownload(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.export.exportDownload}/${id}`;
    return this.httpClient.post(url, {responseType: "text" as any});
  }

  getExportReview(id: string): Observable<ExportRequestDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.export.exportGetReview}/${id}`;
    return this.httpClient.get<ExportRequestDto>(url);
  }

  sendExportReview(id: string, approved: boolean) {
    const url = `${environment.server.baseUrl}${environment.server.api.export.exportSendReview}/${id}}`;
    //return this.httpClient.post(url, dataExportRequestDto, {responseType: "text" as any});
    return this.httpClient.post<ResponseDto>(url, approved);
  }
}
