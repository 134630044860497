import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserManagementService} from "../../../../../../services/user/management/user-management.service";
import {ApiKeyGenerationDto} from "../../../../../../models/dto/apiKey/apiKeyGenerationDto";
import {UserProfileDto} from "../../../../../../models/dto/user/userProfileDto";
import {DateService} from "../../../../../../services/utilities/date.service";

@Component({
  selector: 'app-new-key',
  templateUrl: './new-key.component.html',
  styleUrls: ['./new-key.component.scss']
})
export class NewKeyComponent implements OnInit {

  public data?: ApiKeyGenerationDto
  public users?: Array<UserProfileDto>;
  public selectedUser?: UserProfileDto;
  public expirationDate?: Date;
  constructor(
    public dialogRef: MatDialogRef<NewKeyComponent>,
    private userService: UserManagementService,
    private dateService: DateService
  ) {}

   ngOnInit() {
    this.userService.getUserDtos().subscribe(res => {
      this.users = res;
    })
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateData() {
    // @ts-ignore
    const date = this.dateService.isMoment(this.expirationDate) ? this.expirationDate.toDate() : this.expirationDate
    this.data = {
      userId: this.selectedUser?.id,
      expirationDateTime: date
    }
  }
}
