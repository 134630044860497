
<app-navigation [inputSideNav]="sideNav" (notificationsFetched)="notifications=$event" (populationChanged)="switchWorkspace($event)"></app-navigation>
<mat-sidenav-container>
  <mat-sidenav #sideNav (click)="sideNav.toggle()" mode="over" position="end" [style.max-height.vh]="95">

    <div class="notification" *ngIf="notifications.length === 0">
      <div class="content">No Notifications!</div>
    </div>
    <div class="notification" *ngFor="let notification of notifications" [ngClass]="{'new': !notification.read}"  (click)="navigate(notification)"
         (mouseenter)="setNotificationReadStart(notification)" (mouseleave)="setNotificationReadEnd(notification)">
      <div class="date">
        <h5 >{{dateService.formatDate(notification.date, false, true, true, true, true)}}</h5>
        <button mat-icon-button color="accent"  (click)="navigate(notification)"><mat-icon>link</mat-icon></button>
      </div>
      <div class="subject">
        <h4>{{notification.subject}}</h4>
      </div>
      <div class="message">
        <p>{{notification.content}}</p>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet class="router-content"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-area-loading [criteria]="!loading" [title]="'Loading...'"></app-area-loading>









