import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../services/user/authentication.service";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {

  constructor(private router: Router, public authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  signIn() {
    this.router.navigate(["/login"])
  }

  getButtonText() {
    if (this.authService.isUserAuthenticated()) {
      return "Sign In with a Different Account to Continue";
    }
    return "Sign In To Continue";
  }
}
