import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { commentElementData } from '../../../../../../models/dto/comments/commentElement';
import { CommentSectionDto } from '../../../../../../models/dto/comments/CommentSectionDto';
import { NewCommentDto } from '../../../../../../models/dto/comments/NewCommentDto';
import { ResponseDto } from '../../../../../../models/dto/response/responseDto';
import { UserImageDto } from '../../../../../../models/dto/user/userImageDto';
import { CommentService } from '../../../../../../services/comments/comment.service';
import { ErrorHandlerService } from '../../../../../../services/error/error-handler.service';
import { ILoggingService } from '../../../../../../services/logging/logging.service.interface';
import { ImageService } from '../../../../../../services/utilities/image.service';
import { UpdateCommentDto } from '../../../../../../models/dto/comments/UpdateCommentDto';
import { DeleteCommentDto } from '../../../../../../models/dto/comments/DeleteCommentDto';
import { DateService } from '../../../../../../services/utilities/date.service';

@Component({
  selector: 'app-comment-template',
  templateUrl: './comment-template.component.html',
  styleUrls: ['./comment-template.component.scss']
})
export class CommentTemplateComponent {
  @Input() commentElement!: commentElementData;
  @Input() layer!: number;
  @Input() commentSection!: CommentSectionDto;
  @Input() currentUserID!: string;
  @Input() currentUserImage!: string;
  @Output() reload: EventEmitter<any> = new EventEmitter<any>();

    // @ts-ignore
  public Editor = ClassicEditor as { create: any; };
  public editing = false;
  public updating = false;
  public nextlayer: number;
  public isLoggedIn = false;
  public content = "";
  public EditorInit = "";
  public deleted = false;

  public isLoggedUserComment = false;
  constructor(
    private dateService: DateService,
    public imageService: ImageService,
    private _log: ILoggingService,
    private commentservice: CommentService,
    private errorHandler: ErrorHandlerService
  ) {
    this.nextlayer = this.layer! + 1;
  }

  public getJdenticon() {
    return `${this.commentElement.comment?.userID}`;
  }

  formatTime(dateTime: string | Date | undefined) {
    return this.dateService.formatDateFromAny(dateTime, false);
  }

  ngOnInit(): void
  {
    if (this.commentElement === undefined || this.layer === undefined) return;

    if (this.commentElement!.comment!.userID == this.currentUserID) {
      this.isLoggedUserComment = true;
    }
    if (this.commentElement.userName == "Unknown") {
      this.deleted = true;
    }

  }

  log($event: ChangeEvent) {
    this.content = $event.editor.data.get();

  }


  openEditor(): void {
    this.updating = false;
    this.editing = true;
  }

  closeEditor(): void {
    this.editing = false;
  }

  openUpdater(): void {
    this.editing = false;
    this.updating = true;
  }

  closeUpdater(): void {
    this.updating = false;
  }

  updateComment(): void {
    this.editing = false;
    this.updating = false;
    this.commentElement.comment!.content = this.content;

    const commentUpdate: UpdateCommentDto = {
      commentSectionID: this.commentSection.id,
      userID: this.currentUserID,
      content: this.content,
      commentID: this.commentElement.comment?.id,
      postDateTime: new Date()
    }
    this.commentservice.updateComment(commentUpdate).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this._log.error(`Comment could not be added. : ${response.errorMessages.join(', ')}`, true);
        }
        else {
          this._log.info(`Comment updated.`, true);
          this.reload.emit();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Add Comment", err);
      }
    });
  }

  addComment(): void {
    this.editing = false;
    this.updating = false;
    const commentdata: NewCommentDto = {
      commentSectionID: this.commentSection.id,
      userID: this.currentUserID,
      content: this.content,
      responseID: this.commentElement.comment!.id,
      postDateTime: new Date()
    };

    this.commentservice.addComment(commentdata).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this._log.error(`Comment could not be added. : ${response.errorMessages.join(', ')}`, true);
        }
        else {
          this._log.info(`Comment added.`, true)
          this.reload.emit()
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Error Add Comment", err);
      }
    });
  }

  deleteComment(): void {
    const deleteComment: DeleteCommentDto = {
      commentSectionID: this.commentSection.id,
      commentId: this.commentElement.comment!.id
    }
    this.commentservice.deleteComment(deleteComment).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this._log.error(`Comment could not be deleted. : ${response.errorMessages.join(', ')}`, true);
        }
        else {
          this._log.info(`Comment deleted.`, true);
          this.reload.emit()
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Add Comment", err);
      }
    });
  }

  getTimeString(date: Date | string) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.toLocaleTimeString()}`;
  }
}
