import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TimeService {
  public months = [
    {
    full: "January",
    abbrev: "Jan"
    },
    {
      full: "February",
      abbrev: "Feb"
    },
    {
      full: "March",
      abbrev: "Mar"
    },
    {
      full: "April",
      abbrev: "Apr"
    },{
      full: "May",
      abbrev: "May"
    },
    {
      full: "June",
      abbrev: "Jun"
    },
    {
      full: "July",
      abbrev: "Jul"
    },
    {
      full: "August",
      abbrev: "Aug"
    },
    {
      full: "September",
      abbrev: "Sept"
    },
    {
      full: "October",
      abbrev: "Oct"
    },
    {
      full: "November",
      abbrev: "Nov"
    },{
      full: "December",
      abbrev: "Dec"
    },
  ]

  public getMonthAbbreviationByIdx(idx: number) {
    return this.months[idx].abbrev
  }
  public getMonthByIdx(idx: number) {
    return this.months[idx].full
  }
}
