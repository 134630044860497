<div class="component-container" *ngIf="profile !== undefined && !isMobile && !isTablet">
  <div class="component">
    <div class="profile-sidebar" *ngIf="!loading">
      <div class="profile-header">
        <div class="identifier">
          <h1>{{profile.identifier}}</h1>
          <h4 *ngIf="profile.alternateIds !== undefined && profile.alternateIds !== null && profile.alternateIds.length > 0">
            {{profile.alternateIds.join(" / ")}}
          </h4>
        </div>
        <div class="profile-details">
          <app-profile-management [profile]="profile"></app-profile-management>
        </div>
        <div class="profile-details profile-merge">
          <app-profile-merge [profile]="profile"></app-profile-merge>
        </div>

<!--        <div class="profile-next-prev button-row">-->
<!--          <button *ngIf="!loading" class="profile-prev" mat-button (click)="previousProfile()"><mat-icon>chevron_left</mat-icon> <span>Previous Animal </span></button>-->
<!--          <span *ngIf="loading">...loading...</span>-->
<!--          <button *ngIf="!loading" class="profile-next" mat-button (click)="nextProfile()"><span>Next Animal</span> <mat-icon>chevron_right</mat-icon></button>-->
<!--        </div>-->

        <div class="profile-details profile-notes">
          <app-profile-notes-management [profile]="profile"></app-profile-notes-management>
        </div>
        <div class="profile-details profile-sightings">
          <app-profile-sighting-information [profile]="profile" (yearUpdated)="updateContent($event)" (allYears)="years = $event"></app-profile-sighting-information>
        </div>
        <div class="profile-details profile-relationships">
          <app-profile-relationship-information [profile]="profile"></app-profile-relationship-information>
        </div>
      </div>
    </div>
    <div class="profile-content" *ngIf="!loading">
      <div class="sightings-main">
        <div class="profile-image content-section">
          <app-profile-image-management [profile]="profile"></app-profile-image-management>
        </div>
        <div class="locations content-section" *ngIf="profile !== undefined">
          <div class="content-section-header">
            <h1>Locations</h1>
            <mat-form-field *ngIf="years">
              <mat-label>Year Selection</mat-label>
              <mat-select [(ngModel)]="selectedYear" (ngModelChange)="selectYear()">
                <mat-option [value]="'all'">All</mat-option>
                <mat-option [value]="year" *ngFor="let year of years">{{year}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="locations-container" *ngIf="profile!.encounters !== undefined && profile!.encounters.length > 0">
            <events-map [encounters]="profile!.encounters"></events-map>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="timeline content-section" *ngIf="profile && profile!.encounters !== undefined">
        <app-encounter-timeline [animalId]="profile.id"></app-encounter-timeline>
      </div>
    </div>
  </div>
</div>

<mat-drawer-container  class="component-container" *ngIf="profile !== undefined && (isMobile || isTablet)">
  <mat-drawer #drawer class="example-sidenav" mode="over" position="end" [style.min-height.vh]="95">
    <div class="profile-sidebar">
      <div class="profile-header">
        <div class="identifier">
          <h1>{{profile.identifier}}</h1>
        </div>
        <div class="profile-details">
          <app-profile-management [profile]="profile"></app-profile-management>
        </div>
        <div class="profile-details profile-notes">
          <app-profile-notes-management [profile]="profile"></app-profile-notes-management>
        </div>
        <div class="profile-details profile-sightings" >
          <app-profile-sighting-information [profile]="profile" (yearUpdated)="updateContent($event)"></app-profile-sighting-information>
        </div>
        <div class="profile-details profile-relationships">
          <app-profile-relationship-information [profile]="profile"></app-profile-relationship-information>
        </div>
      </div>
    </div>
  </mat-drawer>

  <div class="sidenav-content">
    <div class="mobile-header">
      <h1>{{profile.identifier}}</h1>
      <button type="button" mat-raised-button color="accent" (click)="drawer.toggle()">
        Show {{profile.identifier}} Details
      </button>
    </div>


    <div class="profile-content">
      <div class="sightings-main">
        <div class="profile-image content-section">
          <app-profile-image-management [profile]="profile"></app-profile-image-management>
        </div>
        <mat-divider></mat-divider>
        <div class="timeline content-section" *ngIf="profile.encounters !== undefined">
          <app-encounter-timeline [animalId]="profile.id"></app-encounter-timeline>
        </div>

      </div>


      <div class="locations content-section">
        <h1>Locations</h1>
        <div class="locations-container" *ngIf="locations">
          <animal-track-select-map [selectedLocations]="locations" [allowReselect]="false" [allowDrag]="false"></animal-track-select-map>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>


<app-area-loading [criteria]="profile"></app-area-loading>
<app-error *ngIf="finishedLoading && profile === undefined"></app-error>
