import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() mainMessage = "Error"
  @Input() subMessage = "Something Went Wrong"

  @Input() links = [
    {
      linkText: "Return Home",
      link: ""
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
