import * as ExifReader from "exifreader";
import {Injectable} from "@angular/core";
import {IFileItem} from "../../../../models/items/files/file-item.model";

import {ExpandedTags} from "exifreader";
import {ImageDimension} from "../../../../models/items/files/images/image-dimension.model";
import {IItemStorageService} from "../../../storage/item/item-storage.service.interface";
import {Item} from "../../../../models/items/item.model";
import {IMetaData} from "../../../../models/data/files/meta-data.model";
import {from, Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImageMetaDataService {

  constructor(public itemStorage: IItemStorageService) {}

  getMetaDataForFileItem(item: IFileItem): Observable<IFileItem> {
    const storageKey = `${item.name}-MD`;
    const storedData: IMetaData | null = this.itemStorage.getItem(storageKey) as IMetaData;
    if (storedData) {
      item.metaData = storedData;
      return of(item);
    }
    return this.initializeMetaData(item);
  }

  initializeMetaData(item: IFileItem): Observable<IFileItem> {
   return from(this.getMetaData(item.path).then(
     (value: ExpandedTags) => { return this.assignMetaData(item, value)}
   ));
  }
  async getMetaData(path: string): Promise<ExpandedTags> {
    return await ExifReader.load(path, {expanded: true});
  }

  initializeMetaDataFromBuffer(fileBuffer: ArrayBuffer | SharedArrayBuffer, fileItem: IFileItem) {
    const tags: ExpandedTags = ExifReader.load(fileBuffer, {expanded: true});
    return this.assignMetaData(fileItem, tags);
  }

  async getThumbnail(path: string): Promise<string> {
    return await ExifReader.load(path, {expanded: true}).then( (value: ExpandedTags) => {
      if (value.Thumbnail) {return `data:image/jpg;base64,${value.Thumbnail.base64}`}
      return "";
    })
  }

  async getImageDimensions(path: string): Promise<ImageDimension> {
    return await ExifReader.load(path, {expanded: true}).then( (value: ExpandedTags) => {
      if (value.pngFile) {
        return {
          height: value.pngFile["Image Height"]?.value || 0,
          width: value.pngFile["Image Width"]?.value || 0
        }
      } else {
          return {
            height: 0,
            width: 0
          }
        }
    });
  }



  assignMetaData(item: IFileItem, exifData: ExpandedTags): IFileItem {
    item.metaData.exif = exifData.exif;
    item.metaData.iptc = exifData.iptc;
    this.assignCreationDate(item, exifData);
    this.assignSubLocation(item, exifData);
    this.assignKeywords(item, exifData);
    this.assignEditor(item, exifData);
    this.assignPhotographer(item, exifData);
    this.assignCaption(item, exifData);
    this.assignDimensions(item, exifData);
    this.assignHeadline(item, exifData);

    return item;
  }

  assignCreationDate(item: IFileItem, exifData: ExpandedTags) {
    let dateTimeString = new Date().toString();

    if (exifData.iptc?.["Date Created"]) {
      const dateString = exifData.iptc?.["Date Created"]?.description;
      const timeString = exifData.iptc?.["Time Created"]?.description;
      dateTimeString = `${dateString}T${timeString}`;
    } else if (exifData.exif?.DateTime) {

      const dtString = exifData.exif.DateTime.description.split(" ");
      dtString[0] = dtString[0].replace(":", "-").replace(":", "-")
      dateTimeString = `${dtString[0]}T${dtString[1]}`;
    } else

    //@ts-ignore
    item.metaData.creationDateTime = dateTimeString;
    item.metaData.fileCreationDateTime = dateTimeString;

    return item;
  }

  assignDimensions(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.file) {
      item.metaData.width = exifData.file["Image Width"]?.value;
      item.metaData.height = exifData.file["Image Height"]?.value;
    } else if (exifData.pngFile) {
      item.metaData.width = exifData.pngFile["Image Height"]?.value;
      item.metaData.height = exifData.pngFile["Image Width"]?.value;
    } else if (exifData.exif) {
      item.metaData.width = exifData.exif.PixelXDimension?.value;
      item.metaData.height = exifData.exif.PixelYDimension?.value;
    } else {
      item.metaData.width = -1;
      item.metaData.height = -1;
    }

    return item;
  }


  assignSubLocation(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.iptc?.["Sub-location"]) {
      item.metaData.subLocation = exifData.iptc?.["Sub-location"]?.description
    }
    return item;
  }

  assignKeywords(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.iptc?.["Keywords"]) {
      // @ts-ignore
      item.metaData.keywords = exifData.iptc?.["Keywords"].description;

      if (item.metaData.keywords?.indexOf("right") !== -1) {
        item.metaData.side = "right";
      } else if (item.metaData.keywords?.indexOf("left") !== -1) {
        item.metaData.side = "left"
      }

      if (item.metaData.keywords?.indexOf("eye") !== -1) {
        item.metaData.eyepatch = true;
      }
    }
    return item;
  }

  assignEditor(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.iptc?.["Writer/Editor"]?.description) {
      item.metaData.editorName = exifData.iptc?.["Writer/Editor"]?.description
    }
    return item;
  }

  assignPhotographer(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.iptc?.["By-line"]?.description) {
      item.metaData.photographerName = exifData.iptc?.["By-line"]?.description
    }
    return item;
  }

  assignCaption(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.iptc?.["Caption/Abstract"]?.description) {
      item.metaData.caption = exifData.iptc?.["Caption/Abstract"]?.description
    }
    return item;
  }

  assignThumbnail(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.Thumbnail === undefined) {
      item.metaData.thumbnail = undefined;
    } else {
      let imageType = item.path.toLowerCase().indexOf(".png") !== -1 ? "png" : "jpeg";
      item.metaData.thumbnail = `data:image/${imageType};base64,${exifData.Thumbnail.base64}`;
    }
    return item
  }

  assignHeadline(item: IFileItem, exifData: ExpandedTags) {
    if (exifData.iptc?.["Headline"]?.description !== undefined) {
      item.metaData.headline = exifData.iptc?.["Headline"]?.description
    } else if (exifData.exif?.["Headline"]?.description !== undefined) {
      item.metaData.headline = exifData.exif?.["Headline"]?.description;
    }
    if (item.metaData.headline !== undefined && item.metaData.headline !== null) {
      let i = NaN;
      if (typeof item.metaData.headline == "number") {
        i = item.metaData.headline;
      } else if (typeof item.metaData.headline == "string") {
        i = parseInt(item.metaData.headline, 10);
      }
      if (!isNaN(i)) {
        item.metaData.quality = i;
      }

    }
    return item;
  }
}
