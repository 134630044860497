import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {EncounterDto} from "../../../../models/dto/encounter/encounterDto";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationSettingsDto} from "../../../../models/dto/population/populationSettingsDto";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";
import {PopulationService} from "../../../../services/population/population.service";
import {WorkspaceDto} from "../../../../models/dto/workspace/workspaceDto";
import {Subscription} from "rxjs";
import { CommentSectionDto } from '../../../../models/dto/comments/CommentSectionDto';
import { CommentService } from '../../../../services/comments/comment.service';
import { EncounterPageRequestDto } from '../../../../models/dto/encounter/encounterPageRequestDto';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-encounter-page',
  templateUrl: './encounter-page.component.html',
  styleUrls: ['./encounter-page.component.scss']
})
export class EncounterPageComponent implements OnInit, OnDestroy, OnChanges {
  public encounter: EncounterDto | undefined;
  public populationSettings: PopulationSettingsDto | undefined;
  public selectedImage?: string;
  public commentSection: CommentSectionDto | undefined;
  public request: EncounterPageRequestDto | undefined;
  private navigationInProgress: boolean = false;
  private id: string = "";
  public workspace: WorkspaceDto | undefined;

  private subs = new Array<Subscription>();
  constructor(
    private route: ActivatedRoute,
    private encounterService: EncountersService,
    private log: ILoggingService,
    private router: Router,
    private workspaceService: WorkspaceService,
    private populationService: PopulationService,
    private commentService: CommentService,
    private cookieService: CookieService
  ) {
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     console.log("Navigation ended");
    //   }
    //   if (event instanceof NavigationStart) {
    //     console.log("Navigation started");
    //   }
    //
    //   // Check if it's the first navigation
    //   if (event instanceof NavigationEnd && this.router.getCurrentNavigation()?.id === 1) {
    //     console.log('This is the first navigation.');
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnDestroy();
    // this.ngOnInit();

    }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(query => {
      if (query && query["open"]) {
        this.selectedImage = query["open"];
      }
    });
    if (this.cookieService.get("EncounterRequest")) {
      this.request = JSON.parse(this.cookieService.get("EncounterRequest"))
    }
    // this.request = JSON.parse(this.cookieService.get('EncounterRequest'));

    // var routerparams = this.router.getCurrentNavigation();
    this.subs.push(
      this.workspaceService.workspace.subscribe( res => {
      if (!res || !res.settings || !res.settings.population) {return;}
      this.workspace = res;
      this.route.params.subscribe(res => {
        const populationId = res["populationId"];
        if (populationId && populationId != this.workspace?.settings?.population?.id) {
          this.populationService.getPopulationById(populationId).subscribe(res => {

            this.workspace!.settings!.population = res;
            this.workspaceService.switchWorkspace(this.workspace!)
          })
        }
        this.id = res["encounterId"];
        this.commentService.getCommentSection(this.id).subscribe({
          next: (value: CommentSectionDto) => {
            this.commentSection = value;
          }
        });

        this.loadEncounter();
        this.loadWorkspace();

      });
    }))

  }

  private loadWorkspace() {
    // this.subs.push(
    // this.populationService.populationSettings.subscribe( res => {
    //   this.populationSettings = res;
    // }));
    this.workspaceService.workspace.subscribe( res => {
      this.populationSettings = res.settings?.populationSettings;
    })
  }

  private loadEncounter() {
    if (this.id === undefined) {return;}

      this.subs.push(this.encounterService.getEncounter(this.id).subscribe({
        next: (value: EncounterDto) => {
          this.encounter = value;
          this.log.info(`Encounter loaded.`)
        },
        error: (value: HttpErrorResponse) => {
          if (value.status == 401) {
            this.log.error(`You are unauthorized`)
            this.router.navigate(['/forbidden'])
          }

          if (value.status == 400) {
            this.log.error(`This encounter does not exist`)
          }
        }
      }));
  }


  /**
   * Not Implemented yet
   */
  public isPreviousPageDisabled() {
    return false;
  }
  public isNextPageDisabled() {
    return false;
  }
}
