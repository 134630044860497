import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ISearchService} from "./search.service.interface";
import {environment} from "../../environments/environment";
import {SearchRequest} from "../../models/search/search-request.model";
import {SearchResponse} from "../../models/search/search-response.model";
import {SearchRequestDto} from "../../models/dto/search/searchRequestDto";
import {SearchResponseDto} from "../../models/dto/search/searchResponseDto";

@Injectable({
  providedIn: "root"
})
export class WebSearchService implements ISearchService {
  constructor(public httpClient: HttpClient) {}

  search(request: SearchRequestDto): Observable<SearchResponseDto> | undefined {

    const url = `${environment.server.baseUrl}/api/search`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post<SearchResponseDto>(url, JSON.stringify(request), options);
  }

}
