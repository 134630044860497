import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ApiKeyGenerationDto} from "../../models/dto/apiKey/apiKeyGenerationDto";
import {ApiKeyDto} from "../../models/dto/apiKey/apiKeyDto";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public getKeys(): Observable<Array<ApiKeyDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.access.root}`;
    return this.httpClient.get<Array<ApiKeyDto>>(url);
  }

  public generateKey(request: ApiKeyGenerationDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.access.root}`;
    return this.httpClient.post(url, request);
  }

  public invalidateKey(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.access.root}/invalidate/${id}`;
    return this.httpClient.get(url);
  }

  public deleteKey(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.access.root}/${id}`;
    return this.httpClient.delete(url);
  }
}
