import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";
import {PopulationService} from "../../../../../../services/population/population.service";
import {PopulationDto} from "../../../../../../models/dto/population/populationDto";
import {Subscription} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-single-population-administration',
  templateUrl: './single-population-administration.component.html',
  styleUrls: ['./single-population-administration.component.scss']
})
export class SinglePopulationAdministrationComponent implements OnInit, OnDestroy {

  private id: string | undefined;

  public population: PopulationDto | undefined;
  public subs: Array<Subscription> | undefined;
  public loading = false;
  constructor(private route: ActivatedRoute,
              private populationService: PopulationService,
              private log: ILoggingService,
              private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.subs = new Array<Subscription>();
    this.route.params.subscribe(res => {
      this.id = res["id"];
      this.populationService.isUserPopulationAdministrator(this.id!).subscribe( {
        next: (value: boolean) => {
          if (!value) {
            this.router.navigate(['/forbidden']);
          } else {
            this.loadPopulation();
          }

        }
      })

    });
  }

  ngOnDestroy() {
    for(let sub of this.subs!) {
      sub.unsubscribe();
    }
  }

  private loadPopulation() {
    if (!this.id) {
      this.loading = false;
      return;
    }
    this.subs!.push(this.populationService.getPopulationById(this.id).subscribe({
      next: (value:PopulationDto) => {
        this.population = value;
        this.loading = false;
      },
      error: (value: HttpErrorResponse) => {
        this.log.error(`Could not load population: ${value.message};`)
        this.loading = false;
      }
    }))
  }

  getLink(path: string) {
    if (!this.id) {return ""}
    return `/administration/populations/${this.id}/${path}`;

  }
}
