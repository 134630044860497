<div class="predation-information">
  <app-submission-prey-location
  [population]="population"
  (locationUpdated)="updateLocation($event)"
  style="width: 100%"></app-submission-prey-location>

  <h2>Predation Event?</h2>
  <mat-radio-group [(ngModel)]="predationEvent" (change)="loadPrey($event)">
    <mat-radio-button [value]="true">Yes</mat-radio-button>
    <mat-radio-button [value]="false">No</mat-radio-button>
  </mat-radio-group>
  <div class="predation-detail-container" *ngIf="predationEvent">
    <div class="predation-detail-header">
      <h3>Predated Species</h3>
      <div class="predation-detail-header-buttons">
        <button mat-raised-button (click)="addPredatedSpecies()"><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <div class="predation-targets">
      <div class="predation-target" *ngFor="let target of predationTargets">
        <mat-form-field class="number-input" color="accent">
          <input matInput type="number" min="1" placeholder="Number" [(ngModel)]="target.count" />
        </mat-form-field>
        <mat-form-field *ngIf="populationPrey !== undefined" color="accent">
          <mat-label>Predation Target</mat-label>
          <mat-select [(ngModel)]="target.preyId" (selectionChange)="updatePreyList()">
            <mat-option [value]="prey.id" *ngFor="let prey of availablePrey">{{prey.displayName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button (click)="changePreyPosition(target)"><mat-icon>edit_location</mat-icon></button>
        <button mat-raised-button color="warn" (click)="removePredatedSpecies(target)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>

  </div>
</div>
