import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {AnimalBehaviorDto} from "../../../../../models/dto/behavior/animalBehaviorDto";
import {BehaviorService} from "../../../../../services/behavior/behavior.service";
import {EncounterPageRequestDto} from "../../../../../models/dto/encounter/encounterPageRequestDto";

@Component({
  selector: 'app-behavior-selection',
  templateUrl: './behavior-selection.component.html',
  styleUrls: ['./behavior-selection.component.scss']
})
export class BehaviorSelectionComponent implements OnInit, OnChanges {

  @Input() population?: PopulationDto
  @Input() request: EncounterPageRequestDto | undefined;
  @Output() behaviorSelected: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  public selectedBehaviors: Array<AnimalBehaviorDto> = new Array<AnimalBehaviorDto>();
  constructor(
    private behaviorService: BehaviorService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.request && this.request!.behaviors && this.population && this.population.behaviors) {
      this.selectedBehaviors = this.population?.behaviors?.filter(a => this.request?.behaviors!.indexOf(a.id) !== -1)
    }
  }

  reset() {
    this.selectedBehaviors = new Array<AnimalBehaviorDto>();
    this.alert();
  }

  alert() {

    this.behaviorSelected.emit(this.selectedBehaviors.map(a => a.id));
  }
}
