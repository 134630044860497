import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {WebSearchService} from "../../../services/search/search.service.web";
import {ElectronSearchService} from "../../../services/search/search.service.electron";

export let searchServiceFactory = (httpClient: HttpClient) => {
  if (environment.production && environment.electron) {
    return new ElectronSearchService(httpClient)
  }
  return new WebSearchService(httpClient)
}
