<h3 class="title">Population Contributors <button mat-icon-button (click)="addContributor()" *ngIf="!addingContributor"><mat-icon>add</mat-icon></button></h3>
<div class="contributor-content"  *ngIf="contributorsObs | async as contributors else loading">
  <div class="new-contributor" *ngIf="addingContributor && contributor">
    <app-population-contributor-edit [population]="population" [contributor]="contributor" [contributors]="contributors" (cancelled)="cancel()" [editing]="false"></app-population-contributor-edit>
  </div>
  <div class="contributor-list">
    <mat-card class="contributor" *ngFor="let c of sortContributors(contributors)">
      <mat-card-header>
        <mat-card-title>{{c.name}}</mat-card-title>
        <mat-card-subtitle>{{c.contact}}</mat-card-subtitle>

      </mat-card-header>
      <mat-card-content *ngIf="editingContributorId !== c.id">
        <h4>{{c.name}}</h4>
        <h4>{{c.group}}</h4>
        <h4>{{c.role}}</h4>
        <h4>{{c.location}}</h4>
        <h4>{{c.contact}}</h4>
        <h4>{{c.link}}</h4>
        <div *ngIf="c.icon" class="logo-display">
          <img [src]="c.icon" [alt]="c.name"/>
        </div>

        <div *ngIf="c.footerIcon" class="logo-display">
          <img [src]="c.footerIcon" [alt]="c.name"/>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="editingContributorId === c.id">
        <app-population-contributor-edit [population]="population" [contributor]="c" [contributors]="contributors" [editing]="true" (cancelled)="cancel()"></app-population-contributor-edit>

      </mat-card-content>
      <mat-card-actions *ngIf="editingContributorId !== c.id">
        <button mat-button (click)="editContributor(c.id!)">Edit Contributor Data</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [color]="'accent'"></mat-progress-spinner>
</ng-template>
