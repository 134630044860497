import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { organizationProfileImageDto } from '../../../../../../models/dto/user/organization/organizationProfileImageDto';
import {ImageService} from "../../../../../../services/utilities/image.service";

export interface ProfileImageUpdateDialogData {
  organizationImage?: organizationProfileImageDto
}

@Component({
  selector: 'app-organization-profile-picture-update-dialog',
  templateUrl: './organization-profile-picture-update-dialog.component.html',
  styleUrls: ['./organization-profile-picture-update-dialog.component.scss']
})
export class OrganizationProfilePictureUpdateDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<OrganizationProfilePictureUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProfileImageUpdateDialogData,
    public imageService: ImageService) { }

  addProfileImage(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then(res => {
      this.data.organizationImage!.Logo = res as string;

    });

  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

  }

}
