
export class LifeStatus {
  value!: string;
  displayName!: string

  constructor(v: string, d: string) {
    this.value = v;
    this.displayName = d;

  }

}
export class LifeStatusDto {
  value?: string;
  values?: Array<LifeStatus>;
  constructor() {
    this.values = new Array<LifeStatus>();
    this.values.push( new LifeStatus("alive", "Alive"))
    this.values.push( new LifeStatus("deceased", "Deceased"))
    this.values.push( new LifeStatus("all", "All"))
    this.value = this.values[0].value
  }
}
export class DataSourceDto {
  publicData?: boolean = true;
  organizationalData?: boolean = false;
  personalData?: boolean = false;

}

export class PositionDto {
  left?: boolean = false;
  right?: boolean = false;
}

export class AttributeDto {
  eyepatch?: boolean = false;
}

export class QualityDto {
  noteworthy?: boolean = false;
  good?: boolean = false;
  excellent?: boolean = false;

}

export class AnimalSearchDto {
  status?: string;
  dataSources?: DataSourceDto;
  individuals?: Array<string>;
  attributes?: AttributeDto
  position?: PositionDto;
  quality?: QualityDto;
  startDate?: Date;
  endDate?: Date;
  populationId: string = ""
  startIdx?: number;
  endIdx?: number;
  maximumIdx?: number;
  limit?: number;

  constructor(populationId: string, status?: string, limit?: number) {
    this.dataSources = new DataSourceDto();
    this.individuals = new Array<string>();
    this.attributes = new AttributeDto();
    this.position = new PositionDto();
    this.quality = new QualityDto();
    this.populationId = populationId;
    this.status = status
  }


}
