import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StatusChangeDto} from "../../models/dto/status/statusChangeDto";
import {environment} from "../../environments/environment";
import {NotificationDto} from "../../models/dto/notification/notificationDto";

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private http: HttpClient) { }

  public setStatus(status: StatusChangeDto) {
    const url = `${environment.server.baseUrl}/api/status`;
    return this.http.post(url, status);
  }
}
