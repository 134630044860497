import {Component, Inject, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../../models/dto/encounter/encounterDto";
import {FileItem} from "../../../../../../../models/items/files/file-item.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface AddEncounterImagesDialogData {
  encounter?: EncounterDto
}

@Component({
  selector: 'app-add-images',
  templateUrl: './add-images.component.html',
  styleUrls: ['./add-images.component.scss']
})
export class AddImagesComponent implements OnInit {
  public fileItems: Array<FileItem> | undefined;
  public files: Array<File> | undefined;

  constructor(public dialogRef: MatDialogRef<AddImagesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AddEncounterImagesDialogData) { }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close()
  }



}
