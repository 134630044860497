import {Component, Input, OnInit} from '@angular/core';
import {H, W} from "@angular/cdk/keycodes";
import {StyleManagerService} from "../../../../../services/style-manager/style-manager.service";

@Component({
  selector: 'app-gallery-preview-loading-image',
  templateUrl: './gallery-preview-loading-image.component.html',
  styleUrls: ['./gallery-preview-loading-image.component.scss']
})
export class GalleryPreviewLoadingImageComponent implements OnInit {
  @Input() width: number = 256;
  @Input() height: number = 256;
  public dark: boolean = true;
  protected readonly W = W;
  protected readonly H = H;

  constructor(private style: StyleManagerService) {
  }

  ngOnInit(): void {
    this.style.isDarkObs.subscribe(res => {
      this.dark = res;
    })
  }


}
