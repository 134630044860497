import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-platform-administration',
  templateUrl: './platform-administration.component.html',
  styleUrls: ['./platform-administration.component.scss']
})
export class PlatformAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
