<div class="contributor-edit new-contributor" *ngIf="contributor !== undefined && contributors !== undefined">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="contributor.name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Group</mat-label>
    <input matInput [(ngModel)]="contributor.group" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Role</mat-label>
    <input matInput [(ngModel)]="contributor.role" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Location</mat-label>
    <input matInput [(ngModel)]="contributor.location" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Contact</mat-label>
    <input matInput [(ngModel)]="contributor.contact" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Link</mat-label>
    <input matInput [(ngModel)]="contributor.link" />
  </mat-form-field>


<!--  <mat-form-field>-->
<!--    <mat-label>Order</mat-label>-->
<!--    <input matInput [type]="'number'" min="1" step="1" [max]="contributors.length + 1" [(ngModel)]="contributor.order" value="{{contributors.length + 1}}"/>-->
<!--  </mat-form-field>-->

  <mat-divider></mat-divider>
<!--  <div class="footer-logo-selection">-->
<!--    <h4>Display Contributor Details in Footer?</h4>-->
<!--    <mat-radio-group [(ngModel)]="contributor.footer">-->
<!--      <mat-radio-button [value]="true">Yes</mat-radio-button>-->
<!--      <mat-radio-button [value]="false">No</mat-radio-button>-->
<!--    </mat-radio-group>-->
<!--  </div>-->
  <mat-divider></mat-divider>
  <div class="logo-selection">
    <h4>Contributor Logo</h4>
    <input type="file"  class="file-upload" (change)="addIcon($event)">
    <div *ngIf="contributor.icon" class="logo-display">
      <img [src]="contributor.icon" [alt]="contributor.name"/>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="logo-selection" *ngIf="contributor.footer">
    <h4>Footer Logo</h4>
    <input type="file"  class="file-upload" (change)="addAlternateIcon($event)">
    <div *ngIf="contributor.footerIcon" class="logo-display">
      <img [src]="contributor.footerIcon" [alt]="contributor.name"/>
    </div>
  </div>
  <button mat-stroked-button (click)="confirmContributor()" *ngIf="!editing">Add Contributor</button>
  <button mat-stroked-button (click)="confirmContributor()" *ngIf="editing">Update Contributor</button>
  <button mat-stroked-button color="warn" (click)="cancelAdd()">Cancel</button>
</div>

