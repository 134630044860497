<div class="content-container" *ngIf="encounter && canEdit">
  <div class="content-header">
    <div class="status">
      <h3>Analysis Status</h3>
      <h4 *ngIf="encounter.status && !editing">{{getStatus()}}</h4>
      <h4 *ngIf="!encounter.status && !editing">New</h4>
    </div>
    <button mat-icon-button color="accent" (click)="editing=true" *ngIf="!editing && canEdit"><mat-icon>edit</mat-icon></button>

  </div>
  <div class="content" *ngIf="editing && encounter.status!.status!">
    <mat-form-field color="accent" class="full-width">
      <mat-label>Analysis Status</mat-label>
      <mat-select [(ngModel)]="currentStatus">
        <mat-option *ngFor="let status of statuses" [value]="status">{{status.display}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="button-row">
      <button mat-stroked-button (click)="updateStatus()" color="accent">Save</button>
      <button mat-stroked-button (click)="editing = false" color="accent">Cancel</button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
