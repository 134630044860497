<div class="header-img-container">
  <div class="header-img">
    <img [src]="headerImgSrc"/>
  </div>

</div>
<div class="content-container">
  <div class="content">
    <div class="header-img-content-container">
      <div class="header-img-content-subtitle shade">
        <h1>Streamlining Dorsal Fin Identification</h1>
      </div>

    </div>
    <div class="methodology">
      <div class="method">
        <h3 class="method-title">Upload</h3>
        <mat-divider></mat-divider>
        <p>Sign in and join or create specific populations, then submit identification images from your encounters with them</p>
      </div>
      <div class="method arrow">
        <mat-icon>chevron_right</mat-icon>
      </div>
      <div class="method">
        <h3 class="method-title">Identify</h3>
        <mat-divider></mat-divider>
        <p>The identities of individuals you photographed will be predicted by AI and verified by population experts</p>
      </div>
      <div class="method arrow">
        <mat-icon>chevron_right</mat-icon>
      </div>
      <div class="method">
        <h3 class="method-title">Search</h3>
        <mat-divider></mat-divider>
        <p>Browse for specific images and information about every individual in each population.</p>
      </div>
    </div>

    </div>
  <footer>
    <div class="footer-content">
      <h2>Developed By</h2>
      <div class="developers-box">
        <a class="developer" *ngFor="let developer of developers" [href]="developer.link" target="_blank">
          <div class="logo-container">
            <img *ngIf="isDark" [src]="developer.logos.dark" [alt]="developer.name">
            <img *ngIf="!isDark" [src]="developer.logos.light" [alt]="developer.name">
          </div>
        </a>
      </div>
    </div>

  </footer>
</div>
