import {Injectable, SecurityContext} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {EncounterPageResponseDto} from "../../models/dto/encounter/encounterPageResponseDto";
import {EncounterPageRequestDto} from "../../models/dto/encounter/encounterPageRequestDto";
import {DomSanitizer} from "@angular/platform-browser";
import {AnimalSearchDto, PositionDto, QualityDto} from "../../models/dto/animal/animalSearchDto";

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  public encounterQuery: EncounterPageResponseDto | undefined;
  private _encounterQuery: BehaviorSubject<EncounterPageResponseDto> = new BehaviorSubject<EncounterPageResponseDto>({});

  constructor(private dom: DomSanitizer) { }

  public setEncounterQuery(encounterQuery: EncounterPageResponseDto) {
    this._encounterQuery.next(encounterQuery);
  }

  public invertAnimalQueryParams(p: any): AnimalSearchDto {
    const request = new AnimalSearchDto(p.populationId, p.status, p.limit);

    if (p.hasOwnProperty("data")) {
      let sources = p.data.split(",")
      for (let source of sources) {
        if (source === "public") {
          request.dataSources!.publicData = true;
        }
        if (source == "org") {
          request.dataSources!.organizationalData = true;
        }
        if (source == "private") {
          request.dataSources!.personalData = true;
        }
      }
    }

    if (p.hasOwnProperty("quality")) {
      request.quality = new QualityDto();
      if (p.quality instanceof QualityDto) {
        request.quality = p.quality;
      } else {
        let sources = p.quality.split(",")
        for (let source of sources) {
          if (source === "good") {
            request.quality!.good = true;
          }
          if (source === "noteworthy") {
            request.quality!.noteworthy = true;
          }
          if (source === "excellent") {
            request.quality!.excellent = true;
          }
        }
      }

    }

    if (p.hasOwnProperty("startIdx")) {
      request.startIdx = p.startIdx
    }

    if (p.hasOwnProperty("endIdx")) {
      request.endIdx = p.endIdx
    }

    if (p.hasOwnProperty("maximumIdx")) {
      request.maximumIdx = p.maximumIdx
    }

    if (p.hasOwnProperty("individuals")) {
      if (Array.isArray(p.individuals)) {
        request.individuals = p.individuals
      } else {
        request.individuals = [p.individuals]
      }

    }

    if (p.hasOwnProperty("startDate")) {
      request.startDate = new Date(p.startDate);
    }

    if (p.hasOwnProperty("endDate")) {
      request.endDate = new Date(p.endDate);
    }


    if (p.hasOwnProperty("limit")) {
      request.limit = p.limit;
    }

    if (p.hasOwnProperty("sides")) {

      let dto = new PositionDto();
      if (p.position instanceof PositionDto) {
        dto = p.position;
      } else {

        let sides = p.sides.split(",")
        for (let source of sides) {
          if (source == "left") {
            dto.left = true;
          }
          if (source == "right") {
            dto.right = true;
          }
        }
      }
      request.position = dto;
    }

    return request;
  }
  public invertEncounterQueryParams(p: any): EncounterPageRequestDto {
    const request = new EncounterPageRequestDto(p.populationId, p.view, p.limit);
    if (p.hasOwnProperty("data")) {
      let sources = p.data.split(",")
      for (let source of sources) {
        if (source === "public") {
          request.dataSources!.publicData = true;
        }
        if (source == "org") {
          request.dataSources!.organizationalData = true;
        }
        if (source == "private") {
          request.dataSources!.personalData = true;
        }
      }
    }
    if (p.hasOwnProperty("startIdx")) {
      request.startIdx = p.startIdx
    }

    if (p.hasOwnProperty("endIdx")) {
      request.endIdx = p.endIdx
    }

    if (p.hasOwnProperty("maximumIdx")) {
      request.maximumIdx = p.maximumIdx
    }

    if (p.hasOwnProperty("view")) {
      request.view = p.view
    }

    if (p.hasOwnProperty("status")) {
      request.status = p.status
    }

    if (p.hasOwnProperty("behaviors")) {
      if (Array.isArray(p.behaviors)) {
        request.behaviors = p.behaviors
      } else {
        request.behaviors = [p.behaviors]
      }
    }

    if (p.hasOwnProperty("individuals")) {
      if (Array.isArray(p.individuals)) {
        request.individuals = p.individuals
      } else {
        request.individuals = [p.individuals]
      }

    }

    if (p.hasOwnProperty("locations")) {
      if (Array.isArray(p.locations)) {
        request.locations = p.locations
      } else {
        request.locations = [p.locations]
      }

    }

    if (p.hasOwnProperty("photographers")) {
      if (Array.isArray(p.photographers)) {
        request.photographers = p.photographers
      } else {
        request.photographers = [p.photographers]
      }
    }

    if (p.hasOwnProperty("start")) {
      request.start = new Date(p.start);
    }

    if (p.hasOwnProperty("end")) {
      request.end = new Date(p.end);
    }

    if (p.hasOwnProperty("complete")) {
      request.complete = p.complete
    }

    if (p.hasOwnProperty("limit")) {
      request.encounterLimit = p.limit;
    }

    return request;
  }

  public sanitizeEncounterQuery(encounterQuery: EncounterPageRequestDto) {
    let q = {}
    if (encounterQuery.populationId) {
      // @ts-ignore
      q.populationId = encounterQuery.populationId
    }
    if (encounterQuery.encounterLimit) {
      // @ts-ignore
      q.limit = encounterQuery.encounterLimit;
    }
    if (encounterQuery.dataSources) {
      let sources = [];
      if (encounterQuery.dataSources.organizationalData) {
        sources.push("org")
      }
      if (encounterQuery.dataSources.personalData) {
        sources.push("personal")
      }
      if (encounterQuery.dataSources.publicData) {
        sources.push("public")
      }
      // @ts-ignore
      q.data = sources.join(',')
    }
    if (encounterQuery.encounterLimit) {
      // @ts-ignore
      q.limit = encounterQuery.encounterLimit
    }
    if (encounterQuery.status) {
      // @ts-ignore
      q.status = encounterQuery.status
    }

    if (encounterQuery.view) {
      // @ts-ignore
      q.view = encounterQuery.view
    }
    if (encounterQuery.individuals) {
      // @ts-ignore
      q.individuals = encounterQuery.individuals
    }

    if (encounterQuery.photographers) {
      // @ts-ignore
      q.photographers = encounterQuery.photographers
    }

    if (encounterQuery.locations) {
      // @ts-ignore
      q.locations = encounterQuery.locations
    }

    if (encounterQuery.start) {
      if (typeof encounterQuery.start == "string") {
        // @ts-ignore
        q.start = encounterQuery.start;
      } else {
        // @ts-ignore
        q.start = encounterQuery.start.toLocaleDateString()
      }

    }

    if (encounterQuery.end) {
      if (typeof encounterQuery.end == "string") {
        // @ts-ignore
        q.end = encounterQuery.end;
      } else {
        // @ts-ignore
        q.end = encounterQuery.end.toLocaleDateString()
      }

    }

    if (encounterQuery.startIdx) {
      // @ts-ignore
      q.startIdx = encounterQuery.startIdx
    } else {
      // @ts-ignore
      q.startIdx = 0;
    }

    if (encounterQuery.endIdx) {
      // @ts-ignore
      q.endIdx = encounterQuery.endIdx
    } else {
      // @ts-ignore
      q.endIdx = q.startIdx + encounterQuery.encounterLimit;
    }

    if (encounterQuery.maximumIdx) {
      // @ts-ignore
      q.maximumIdx = encounterQuery.maximumIdx;
    }
    if (encounterQuery.complete) {
      // @ts-ignore
      q.complete = encounterQuery.complete;
    }

    if (encounterQuery.behaviors) {
      // @ts-ignore
      q.behaviors = encounterQuery.behaviors;
    }

    return q;
  }


  sanitizeIndividualQuery(dto: AnimalSearchDto) {
    let q = {}
    if (dto.populationId) {
      // @ts-ignore
      q.populationId = dto.populationId
    }

    if (dto.attributes) {
      if (dto.attributes.eyepatch) {
        //@ts-ignore
        q.eyepatch = dto.attributes.eyepatch;
      }
    }

    if (dto.dataSources) {
      let sources = [];
      if (dto.dataSources.organizationalData) {
        sources.push("org")
      }
      if (dto.dataSources.personalData) {
        sources.push("personal")
      }
      if (dto.dataSources.publicData) {
        sources.push("public")
      }
      // @ts-ignore
      q.data = sources.join(',')
    }

    if (dto.startDate) {
      if (typeof dto.startDate == "string") {
        // @ts-ignore
        q.start = dto.startDate;
      } else {
        // @ts-ignore
        q.start = dto.startDate.toLocaleDateString()
      }

    }

    if (dto.endDate) {
      if (typeof dto.endDate == "string") {
        // @ts-ignore
        q.endDate = dto.endDate;
      } else {
        // @ts-ignore
        q.endDate = dto.endDate.toLocaleDateString()
      }

    }

    if (dto.startIdx) {
      // @ts-ignore
      q.startIdx = dto.startIdx
    } else {
      // @ts-ignore
      q.startIdx = 0;
    }

    if (dto.endIdx) {
      // @ts-ignore
      q.endIdx = dto.endIdx
    } else {
      // @ts-ignore
      q.endIdx = q.startIdx + dto.encounterLimit;
    }

    if (dto.maximumIdx) {
      // @ts-ignore
      q.maximumIdx = dto.maximumIdx;
    }

    if (dto.individuals) {
      // @ts-ignore
      q.individuals = dto.individuals
    }

    if (dto.position) {
      let sides = [];
      if (dto.position.left) {
        sides.push("left")
      }
      if (dto.position.right) {
        sides.push("right")
      }
      // @ts-ignore
      q.sides = sides.join(',')
    }

    if (dto.quality) {
      let qualities = [];
      if (dto.quality.noteworthy) {
        qualities.push("noteworthy")
      }
      if (dto.quality.good) {
        qualities.push("good")
      }

      if (dto.quality.excellent) {
        qualities.push("excellent")
      }
      // @ts-ignore
      q.quality = qualities.join(',')
    }

    if (dto.status) {
      // @ts-ignore
      q.status = dto.status;
    }


    return q;
  }
}
