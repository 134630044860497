import { Location } from "../../location/location.model"
import {Item} from "../../items/item.model";
import {v4 as uuidv4} from "uuid";
import {ExifTags} from "exifreader";

export interface IMetaData extends Item {
  uploadDateTime?: Date | string | undefined;
  creationDateTime?: Date;
  fileCreationDateTime?: string;
  location?: Location | undefined;
  possibleLocations?: Array<Location> | undefined;
  subLocation?: string | undefined;
  keywords?: string | undefined;
  editorName?: string | undefined;
  photographerName?: string | undefined;
  caption?: string | undefined;
  thumbnail?: string | undefined;
  height?: number | undefined;
  width?: number | undefined;
  fileItemId?: string | undefined;
  encounterId?: string | undefined;
  headline?: string | number;
  quality?: number;
  side?: string;
  eyepatch?: boolean;
  notes?: string;
  singleAnimal?: boolean;
  exif?: ExifTags;
  iptc?: ExifTags
}

export class MetaData implements IMetaData {
  id: string;
  caption: string | undefined;
  creationDateTime: Date | undefined;
  editorName: string | undefined;
  keywords: string | undefined;
  location: Location | undefined;
  possibleLocations: Array<Location> | undefined;
  photographerName: string | undefined;
  subLocation: string | undefined;
  thumbnail: string | undefined;
  uploadDateTime: Date | undefined;
  height: number | undefined;
  width: number | undefined;
  fileItemId: string | undefined;
  encounterId?: string | undefined;
  headline?: string | number;
  quality?: number;
  side?: string;
  eyepatch?: boolean;
  notes?: string;
  singleAnimal?: boolean;

  constructor(
    caption?: string,
    creationDateTime?: Date,
    editor?: string,
    keywords?: string,
    location?: Location,
    possibleLocations?: Array<Location>,
    photographer?: string,
    subLocation?: string,
    thumbnail?: string,
    uploadDateTime?: Date,
    height?: number,
    width?: number) {
    this.id = uuidv4().toString();
    this.caption = caption;
    this.creationDateTime = creationDateTime;
    this.editorName = editor;
    this.keywords = keywords;
    this.location = location;
    this.possibleLocations = possibleLocations;
    this.photographerName = photographer;
    this.subLocation = subLocation;
    this.thumbnail = thumbnail;
    this.uploadDateTime = uploadDateTime;
    this.height = height;
    this.width = width;
  }



}
