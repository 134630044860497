<div mat-dialog-title>
  Select How Your Data Can Be Used
</div>
<div mat-dialog-content>
  <h3>Answer these questions to choose how your data can or will be used.</h3>

  <div class="question">
    <div class="question-content" matTooltip="If yes, your data will be protected by a creative commons non commercial
use license. If no, your data will only be visible to users within your
organization." matTooltipPosition="above">
      <div class="question-number">1.</div>
      <div class="question-text">By submitting I understand that my data can be publicly viewed </div>
    </div>
    <div class="question-response">
      <mat-radio-group class="stacked" [(ngModel)]="public" color="accent" (ngModelChange)="updateLicense()">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="question" *ngIf="public">
    <div class="question-content"
         matTooltip="If no, your data will be protected by a creative commons non-commercial use license and may be used to benefit conservation and understanding of this population by scientists, students or educators without contacting or crediting you. If yes, you will be contacted with any requests that include use of your data."
         matTooltipPosition="above">
      <div class="question-number">2.</div>
      <div class="question-text">Please contact me with any requests that include the use of my data. </div>
    </div>
    <div class="question-response">
      <mat-radio-group class="stacked"  [(ngModel)]="attribute" color="accent"  (ngModelChange)="updateLicense()">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="error-messages" *ngIf="!validSelection">
    <mat-error *ngFor="let err of errorMessages">{{err}}</mat-error>
  </div>
  <mat-divider></mat-divider>
  <div class="license-info" *ngIf="license !== undefined && licenseLogo !== undefined && validSelection">
    <h3>Your License:</h3>

    <div class="license-content submission-license" [matTooltip]="licenseTooltip" matTooltipPosition="before">
      <p class="license-name"><b>{{license.name}}</b></p>
      <div class="license-logos">
        <div class="license-logo" *ngFor="let logo of licenseLogo">
          <a [href]="logo.link" target="_blank" >
            <img [src]="logo.img" [matTooltip]="logo.text" [alt]="logo.text"/>
          </a>
        </div>
      </div>
    </div>
    <p>{{license.description}}</p>
  </div>

</div>
<div class="button-row" mat-dialog-actions>
  <button mat-stroked-button color="accent" [mat-dialog-close]="dataLimitation" [disabled]="!validSelection">Confirm License Selection</button>
  <button mat-stroked-button (click)="onNoClick()" >Cancel</button>
</div>
