import {Injectable} from "@angular/core";
import {Prediction} from "../../models/prediction/prediction.model";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FileUploadResponse} from "../../models/response/file/file-upload.response.model";
import {mergeMap, Observable, of} from "rxjs";
import {IFileItem} from "../../models/items/files/file-item.model";
import {environment} from "../../environments/environment";
import {VerificationTaskDto} from "../../models/dto/inference/verificationTaskDto";
import {ImageAnnotation} from "../../models/annotation/image/annotation.model";
import {Encounter} from "../../models/encounters/encounter.model";
import {ResponseDto} from "../../models/dto/response/responseDto";

@Injectable({
  providedIn: 'root'
})
export class PredictionService {

  predictEndpoint = "fin-identify"
  constructor(private httpClient: HttpClient) {}

  public getPrediction(blobContent: string | ArrayBuffer, fileName: string) {
      const formData = new FormData();
      formData.append("file", new Blob([blobContent]), fileName);
      const url = `${environment.server.baseUrl}${this.predictEndpoint}`;
      return this.httpClient.post(url, formData);

  }

  transformTextToPredictionList(text: string): Array<Prediction> {
    const predictions: Array<Prediction> = new Array<Prediction>();
    const lines = text.split("\n");

    lines.forEach((line: string) => {
      const components = line.split(":");
      if (components.length === 2) {
        predictions.push({
          classId: components[0],
          probability: parseFloat(components[1])
        })
      }
    })
    return predictions
  }

  public startPipeline(fileItem: IFileItem) {
    const url = `${environment.server.baseUrl}${environment.server.api.data.submission.autotag}`;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(url, JSON.stringify(fileItem), headers)
  }

  public enqueueJob(fileItem: IFileItem) {
    const url = `${environment.server.baseUrl}${environment.server.api.data.submission.enqueue}`;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(url, JSON.stringify(fileItem), headers)
  }

  public getPredictionsToVerify(): Observable<Array<VerificationTaskDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.inference.verification.getVerifications}`;
    return this.httpClient.get<Array<VerificationTaskDto>>(url);
  }

  public getAnnotationById(annotationId: string): Observable<ImageAnnotation> {
    const url = `${environment.server.baseUrl}${environment.server.api.annotation.annotationById}/${annotationId}`;
    return this.httpClient.get<ImageAnnotation>(url);
  }

  public getEncounterByFileItemId(fileItemId: string): Observable<Encounter> {
    const url = `${environment.server.baseUrl}${environment.server.api.encounter.encounterByFileItemId}/${fileItemId}`;
    return this.httpClient.get<Encounter>(url);
  }

  public confirmDetection(taskDto: VerificationTaskDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.inference.verification.confirmDetection}`;
    return this.httpClient.post<ResponseDto>(url, taskDto);
  }
}
