<div class="timeline-container">
  <div class="timeline-header">
    <h2 *ngIf="!title">Encounters</h2>
    <h2 *ngIf="title">{{title}}</h2>
  </div>
  <div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults" color="accent" diameter="50"></mat-spinner>
      <h3>Fetching Encounters...</h3>
    </div>

    <div class="example-table-container">

      <table mat-table [dataSource]="data" class="example-table"
             matSort matSortActive="date" matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let row"><a [href]="getEncounterLink(row)">{{formatDate(row.dateTime)}}</a></td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
          <td mat-cell *matCellDef="let row"><a [href]="getEncounterLink(row)">{{row.location.name}}</a></td>
        </ng-container>

        <ng-container matColumnDef="stats">
          <th mat-header-cell *matHeaderCellDef>Statistics</th>
          <td mat-cell *matCellDef="let row">
            <h4>{{row.preview.imageCount}} images</h4>
            <h4>{{row.preview.confirmedAnimalCount}} known animals</h4>
            <h4 *ngIf="row.preview.animals">{{getAnimalList(row.preview.animals)}}</h4>
          </td>
        </ng-container>

        <ng-container matColumnDef="organization">
          <th mat-header-cell *matHeaderCellDef>Organization(s)</th>
          <td mat-cell *matCellDef="let row">
            <div class="organizations">
              <h4 *ngFor="let org of row.organizations"><a [routerLink]="getOrgLink(org)">{{org.name}}</a> </h4>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Photographer</th>
          <td mat-cell *matCellDef="let row">
            <a [routerLink]="getUserLink(row.user)">
              <app-encounter-user-profile-image [user]="row.user" [showName]="true"></app-encounter-user-profile-image>
            </a>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="10" aria-label="Select page of encounter search results"></mat-paginator>
  </div>
</div>

