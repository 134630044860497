import {AfterContentInit, Component, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDrawer, MatSidenav} from "@angular/material/sidenav";
import {NavigationEntry} from "../models/navigation/entry";

import * as L from "leaflet";
import {AuthenticationService} from "../services/user/authentication.service";
import {Router} from "@angular/router";
import {PopulationDto} from "../models/dto/population/populationDto";
import {PopulationRoleSet, PopulationService} from "../services/population/population.service";
import {
  NewPopulationDialogComponent
} from "./global/population-select/new-population-dialog/new-population-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../services/logging/logging.service.interface";
import {UserProfileDto} from "../models/dto/user/userProfileDto";
import {UserContentService} from "../services/user/content/user-content.service";
import {Observable, Subscription} from "rxjs";
import {WorkspaceService} from "../services/workspace/workspace.service";
import {WorkspaceDto} from "../models/dto/workspace/workspaceDto";
import {PopulationFeedEntryDto} from "../models/dto/population/populationFeedEntryDto";
import {ContributorDto} from "../models/dto/population/contributorDto";
import {StyleManagerService} from "../services/style-manager/style-manager.service";
import {ResponsiveDesignService} from "../services/design/responsive-design.service";
import {MatDialog} from "@angular/material/dialog";
import {NotificationDto} from "../models/dto/notification/notificationDto";
import {DateService} from "../services/utilities/date.service";
import {NotificationService} from "../services/notifications/notification.service";
import {ErrorHandlerService} from "../services/error/error-handler.service";
import {
  NotificationDropdownComponent
} from "./global/notifications/notification-center/notification-dropdown/notification-dropdown.component";
import {NavigationComponent} from "./global/navigation/navigation.component";
import * as http from "http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public isUserAuthenticated = false;
  public population: PopulationDto | undefined;
  public user: UserProfileDto | undefined;
  public notifications: Array<NotificationDto> = new Array<NotificationDto>();
  isDark = this.styleManager.isDark;

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild(NavigationComponent) navigationComponent!: NavigationComponent;
  public populationRights: PopulationRoleSet | undefined;
  public workspace: WorkspaceDto | undefined;
  public feed: Array<PopulationFeedEntryDto> | undefined;

  public contributorObs: Observable<Array<ContributorDto>> | undefined;

  public unread?: string;

  private activeNotification?: string;
  private activeNotificationStart?: number;

  private readThreshold = 0.5;

  private subs: Array<Subscription> = new Array<Subscription>();
  public haveUnread: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.responsiveService.setStatus(window.innerWidth)
  }

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    private populationService: PopulationService,
    private dialog: MatDialog,
    private log: ILoggingService,
    private userService: UserContentService,
    private workspaceService: WorkspaceService,
    private styleManager: StyleManagerService,
    private responsiveService: ResponsiveDesignService,
    public dateService: DateService,
    private notificationService: NotificationService,
    private errorHandler: ErrorHandlerService
    ) {
  }

  ngOnInit() {
    this.responsiveService.setStatus(window.innerWidth)
    this.authService.authenticationChanged
      .subscribe(res => {
        this.isUserAuthenticated = res;
        this.initializeUserSettings();

      });
    this.initializeUserSettings();
  }



  private initializeUserSettings() {

    if (!this.workspace || !this.user || !this.populationRights || !this.population || !this.contributorObs) {
      if (this.authService.isUserAuthenticated()) {
        this.isUserAuthenticated = true;
        this.workspaceService.getPopulationWorkspace().subscribe({
          next: (value: WorkspaceDto) => {
            this.workspaceService.setWorkspace(value);
            this.log.info("Workspace loaded. Have fun!")
          }, error: (value: HttpErrorResponse) => {
            this.log.error(`Could not load workspace: ${value.message}`)
          }
        })
        // this.workspaceService.workspace.subscribe( res => {
        //   // this.workspaceService.setWorkspace(res);
        //   if (res && res.settings) {
        //     this.workspace = res;
        //     this.user = this.workspace.settings!.user;
        //     this.populationRights = this.workspace.settings!.populationRoles;
        //     this.population = this.workspace.settings!.population;
        //     this.contributorObs = this.populationService.getContributors(this.population?.id!);
        //   }
        // });
      } else {
        this.isUserAuthenticated = false;
        // this.router.navigate(['login'])
      }
    }

  }



  title = "WHALE-E";
  subtitle = "A Bioacoustics and Biovision Platform Powered by Deep Learning"
  public populationAdminObs: Observable<boolean> | undefined;
  public loading: boolean = false;

  close() {
    this.sidenav.close();
  }



  changePopulation(population: PopulationDto) {
    this.populationService.select(population);
  }

  newPopulation(): void {
    const ref = this.dialog.open(NewPopulationDialogComponent, {
      closeOnNavigation: true,
      data: {
        speciesName: undefined,
        displayName: undefined,
        abbreviation: undefined,
        center: {
          latitude: undefined,
          longitude: undefined,
          name: undefined
        }
      }
    });
    ref.afterClosed().subscribe( res => {
      res.center.name = `${res.displayName} center`;
      this.populationService
        .createNewPopulation(res)
        .subscribe({
          next: (value: PopulationDto) => {
            this.log.info(`${res.displayName} created.`, true);
            this.populationService.select(value);
          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`${res.displayName} could not be created: ${value.error}`, true)
          }
        })
    })
  }




  public getJdenticon(user: UserProfileDto) {
    return `${user.firstName} ${user.lastName} ${user.email}`;
  }

  getFooterContributors(contributors: Array<ContributorDto>) {
    return contributors.filter(f => f.footer);
  }

  setNotificationReadStart(notification: NotificationDto) {
    this.activeNotification = notification.id;
    this.activeNotificationStart = Date.now();

  }

  setNotificationReadEnd(notification: NotificationDto) {
    if (notification.id === this.activeNotification) {
      this.activeNotification = notification.id;
      const duration = Date.now() - this.activeNotificationStart!
      if (duration / 1000 >= this.readThreshold) {
        this.navigationComponent.markRead(notification);
      }
    }

  }


  getNotificationCount() {
    const unread = this.notifications.filter(r => !r.read);
    if (unread.length <= 0) {
      this.haveUnread = false;
      return;
    } else {
      this.haveUnread = true;
    }
    if (unread.length < 10) {
      this.unread = `${unread.length}`;
    } else {
      this.unread =  "9+"
    }

  }

  markRead(notification: NotificationDto) {
    notification.read = true;
    this.notificationService.markNotificationAsRead(notification.id).subscribe({
      next: (value: any) => {
        this.getNotificationCount();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError(`Could not mark notification as read`, err)
        notification.read = false;
      }
    })

  }

  navigate(notification: NotificationDto) {
    const queryString = notification.link.split("?")
    if (queryString.length > 1) {
      const queryDict = {}
      const queries = queryString[1].split("&")
      for (let query of queries) {
        const ps = query.split("=")
        // @ts-ignore
        queryDict[ps[0]] = ps[1]
      }
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(
        [queryString[0]],
        { queryParams: queryDict}
      );
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(
        [queryString[0]],
      );
    }

  }

  switchWorkspace($event: PopulationDto) {
    this.loading = true;
    this.workspaceService.switchPopulation($event)
      .subscribe({
        next: (value: WorkspaceDto) => {
          this.workspaceService.setWorkspace(value);
          this.log.info(`Switched to ${$event.displayName}`)
          // this.router.navigate(["individuals"], {onSameUrlNavigation: "reload"});
          this.loading = false;
        }, error: (value: HttpErrorResponse) => {
          this.log.error(`Could not switch to ${$event.displayName}:: ${value.message}`)
          this.loading = false;
        }
      });
  }
}
