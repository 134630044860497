import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {BehaviorService} from "../../../../../services/behavior/behavior.service";
import {AnimalBehaviorDto} from "../../../../../models/dto/behavior/animalBehaviorDto";
import {Subscription} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {B} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-population-behavior',
  templateUrl: './population-behavior.component.html',
  styleUrls: ['./population-behavior.component.scss']
})
export class PopulationBehaviorComponent implements OnInit, OnDestroy {
  @Input() population?: PopulationDto
  public behaviors?: Array<AnimalBehaviorDto>
  public subs: Array<Subscription> = new Array<Subscription>()
  public editingId?: string;
  public adding = false;
  public newBehavior?: AnimalBehaviorDto
  public editingBehavior?: AnimalBehaviorDto;
  public editing = false;
  constructor(
    private behaviorService: BehaviorService,
    private errorHandler: ErrorHandlerService,
  ) {
  }

  ngOnInit() {
    if (!this.population || !this.population.id) return;
    this.subs.push(
      this.behaviorService.getPopulationBehaviors(this.population?.id).subscribe({
        next: (value: any) => {
          this.behaviors = value;
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Get Behaviors", value);
        }
      })
    )
  }

  ngOnDestroy() {
    for(let s of this.subs) {
      s.unsubscribe();
    }
  }

  addBehavior() {
    this.newBehavior = {
      description: "",
      populationId: this.population!.id!,
      id: ""
    };
    this.adding = true;
  }

  createBehavior() {
    this.behaviorService.createBehavior(this.newBehavior!).subscribe({
      next: (value: any) => {
        this.ngOnInit()
        this.adding = false;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Create Behavior", value);
        this.adding = false;
      }
    })
  }

  updateBehavior() {
    this.behaviorService.updateBehavior(this.editingBehavior!).subscribe({
      next: (value: any) => {
        this.ngOnInit()
        this.editing = false;
        this.editingBehavior = undefined;
        this.editingId = undefined;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Edit Behavior", value);
        this.editing = false;
        this.editingBehavior = undefined;
        this.editingId = undefined;
      }
    })
  }

  deleteBehavior(id: string) {
    this.behaviorService.deleteBehavior(id).subscribe({
      next: (value: any) => {
        this.ngOnInit();
        this.editing = false;
        this.editingBehavior = undefined;
        this.editingId = undefined;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Delete Behavior", value);
        this.editing = false;
        this.editingBehavior = undefined;
        this.editingId = undefined;
      }
    })
  }

  startEdit(behavior: AnimalBehaviorDto) {
    this.editingId = behavior.id;
    this.editingBehavior = behavior;
    this.editing = true;

  }

  cancelEdit() {
    this.editing = false;
    this.editingBehavior = undefined;
    this.editingId = undefined;
  }
}
