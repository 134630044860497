import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { AnimalRelationships, Exportable, FileMetaDataByFrame, FileMetaDataByEncounter } from "./tables";
import {DataExportService} from "../../../services/export/data-export.service";
import {ExportRequestDto} from "../../../models/dto/export/exportRequestDto";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import { PopulationService } from "../../../services/population/population.service";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { WorkspaceService } from '../../../services/workspace/workspace.service';
import { UserProfileDto } from '../../../models/dto/user/userProfileDto';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import { ResponseDto } from '../../../models/dto/response/responseDto';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit {
  firstFormGroup = this._formBuilder.group({
    firstCtrl: [undefined, Validators.required],
  });
  isLinear = true;
  public dateSet: boolean = false;
  selectedTable: Exportable | undefined = undefined;
  public dateSelectionRange = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  dateSelectionCriteria = this._formBuilder.group( {
    target: [undefined, Validators.required]
  })
  dateExportCriteria = this._formBuilder.group({
    target: [undefined, Validators.required]
  })
  public requestStarted = false;
  public requestFinished = false;
  public population: PopulationDto | undefined;
  public send: boolean = false;

  public tables: Array<Exportable> = [
    new FileMetaDataByEncounter(),
    new FileMetaDataByFrame(),
    new AnimalRelationships()
  ];
  public needreason = false;
  // @ts-ignore
  public Editor = ClassicEditor as { create: any; };
  public explanaition = "";
  public editing = false;
  public EditorInit = "";

  public user: UserProfileDto | undefined;
  public excludeData = false;


  constructor(
    private _formBuilder: UntypedFormBuilder,
    private dataExportService: DataExportService,
    private workspaceService: WorkspaceService,
    private populationService: PopulationService,
    private errorHandler: ErrorHandlerService
    ) { }

  ngOnInit(): void {
    this.populationService.population.subscribe(res => {
      this.population = res;
    });
    this.workspaceService.workspace
      .subscribe(res => {
        if (res && res.settings) {
          this.population = res.settings!.population;
          this.user = res.settings!.user;
          //this.submissionUser = res.settings!.user;
        }
      })
    this.updateDateSelectionCriteriaForm();
    this.updateDataExportCriteriaForm();
  }

  export(): void {

    if (this.selectedTable === undefined) {
      return;
    }
    const exportRequest: ExportRequestDto = {
      from: this.dateSelectionRange.get("start")?.value.format("YYYY-MM-DDTHH:mm:ss"),
      to: this.dateSelectionRange.get("end")?.value.format("YYYY-MM-DDTHH:mm:ss"),
      tableName: this.selectedTable.target,
      timeSelectionCriteria: this.selectedTable.timeCriteria?.target!,
      exportCriteria: this.selectedTable.exportCriteria?.target!,
      reason: this.explanaition,
      populationId: this.population?.id!,
      userID: this.user?.id,
      excludeAlreadyExported: this.excludeData

    }


    this.requestStarted = true
    this.dataExportService.sendExportRequest(exportRequest).subscribe({
      next: (response: ResponseDto) => {
        this.requestFinished = false;
        this.requestStarted = false;
        this.send = true;
    },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Add Comment", err);
      }
    });
    /*this.dataExportService.sendExportRequest(exportRequest).subscribe( response => {
      this.handleExportResponse(response);
      this.requestFinished = false;
      this.requestStarted = false;
    })*/
  }

  private handleExportResponse(response: any) {
    // @ts-ignore
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    const filename = `${this.selectedTable?.target}_results_${new Date().toUTCString()}.csv`
    if (filename)
      downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode!.removeChild(downloadLink);
  }


  verifySelection(): void {
    this.updateDateSelectionCriteriaForm();
    this.updateDataExportCriteriaForm();
  }



  updateDateSelectionCriteriaForm(): void {
    if (this.selectedTable === undefined) {
      return;
    }
    if (this.selectedTable.timeCriteria !== undefined) {
      this.dateSelectionCriteria.get('target')?.setValue(true);
      return;
    }
    this.dateSelectionCriteria.get('target')?.setValue(undefined);

  }

  updateDataExportCriteriaForm(): void {
    if (this.selectedTable === undefined) {
      return;
    }
    if (this.selectedTable.exportCriteria !== undefined) {
      this.dateExportCriteria.get('target')?.setValue(true);
      if (this.selectedTable.exportCriteria.target == "ExportAggregateData") {
        this.needreason = true;
      }
      else {
        this.needreason = false;
      }
      return;
    }
    this.dateExportCriteria.get('target')?.setValue(undefined);
  }


  openEditor(): void {
    this.editing = true;
  }

  closeEditor(): void {
    this.editing = false;
    this.EditorInit = "";
    this.explanaition = "";
  }

  addExplanaiton(): void {
    this.editing = false;
    this.EditorInit = this.explanaition;
  }

  log($event: ChangeEvent) {
    this.explanaition = $event.editor.data.get();
  }

}
