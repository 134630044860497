import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {log} from "util";
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {ChangeEvent} from "@ckeditor/ckeditor5-angular";

@Component({
  selector: 'app-submission-notes',
  templateUrl: './submission-notes.component.html',
  styleUrls: ['./submission-notes.component.scss']
})
export class SubmissionNotesComponent implements OnInit {
  @Input() notes: string | undefined;
  @Output() notesUpdated: EventEmitter<string> = new EventEmitter<string>();

  public Editor = ClassicEditor;
  public content: string | undefined;

  ngOnInit() {
    this.notes = ""
  }

  log($event: ChangeEvent<ClassicEditor>) {
    this.content = $event.editor.data.get();
    this.notesUpdated.emit(this.content);
  }

  logOriginal() {
    this.notesUpdated.emit(this.notes);
  }
}
