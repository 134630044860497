import {Component, Input} from '@angular/core';
import {EncounterDto} from "../../../../../../models/dto/encounter/encounterDto";
import {WorkspaceDto} from "../../../../../../models/dto/workspace/workspaceDto";

@Component({
  selector: 'app-encounter-completion-status',
  templateUrl: './encounter-completion-status.component.html',
  styleUrls: ['./encounter-completion-status.component.scss']
})
export class EncounterCompletionStatusComponent {
  @Input() encounter: EncounterDto | undefined;
  @Input() workspace: WorkspaceDto | undefined;


}
