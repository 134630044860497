import {Component, Inject, OnInit} from '@angular/core';
import {UserImageDto} from "../../../../../models/dto/user/userImageDto";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageService} from "../../../../../services/utilities/image.service";

export interface ProfileImageUpdateDialogData {
  userImage?: UserImageDto
}
@Component({
  selector: 'app-user-profile-image-update-dialog',
  templateUrl: './user-profile-image-update-dialog.component.html',
  styleUrls: ['./user-profile-image-update-dialog.component.scss']
})
export class UserProfileImageUpdateDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserProfileImageUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProfileImageUpdateDialogData,
    public imageService: ImageService
  ) {}

  addProfileImage(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then( res => {
      this.data.userImage!.image = res as string;

    })
  }


  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
