import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {BehaviorService} from "../../../../services/behavior/behavior.service";
import {UntypedFormControl} from "@angular/forms";
import {AnimalBehaviorDto} from "../../../../models/dto/behavior/animalBehaviorDto";

@Component({
  selector: 'app-submission-behavior',
  templateUrl: './submission-behavior.component.html',
  styleUrls: ['./submission-behavior.component.scss']
})
export class SubmissionBehaviorComponent implements OnInit, OnChanges {

  @Input() population?: PopulationDto
  @Output() behaviorsUpdated: EventEmitter<Array<AnimalBehaviorDto>> = new EventEmitter<Array<AnimalBehaviorDto>>();
  public behaviors?: Array<AnimalBehaviorDto>;
  public selectedBehaviors: Array<AnimalBehaviorDto> = new Array<AnimalBehaviorDto>();
  constructor(
    private behaviorService: BehaviorService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  ngOnInit() {

    if (this.population && this.population.id) {
      this.behaviorService.getPopulationBehaviors(this.population.id).subscribe({
        next: (value: any) => this.behaviors = value
      })
    }
  }

  updateBehaviors() {
    this.behaviorsUpdated.emit(this.selectedBehaviors)
  }
}
