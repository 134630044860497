import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-submission-date',
  templateUrl: './submission-date.component.html',
  styleUrls: ['./submission-date.component.scss']
})
export class SubmissionDateComponent implements OnInit {
  @Input() encounterDateTime!: UntypedFormControl;
  maxDate: Date = new Date();
  constructor() { }

  ngOnInit(): void {}

}
