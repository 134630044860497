import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {ContributorDto} from "../../../../../models/dto/population/contributorDto";
import {PopulationService} from "../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {Observable} from "rxjs";

@Component({
  selector: 'app-population-contributors',
  templateUrl: './population-contributors.component.html',
  styleUrls: ['./population-contributors.component.scss']
})
export class PopulationContributorsComponent implements OnInit, OnChanges {
  @Input() population: PopulationDto | undefined;
  addingContributor = false;
  contributor: ContributorDto | undefined;
  contributorsObs: Observable<Array<ContributorDto>> | undefined;
  editingContributorId: string | undefined;

  constructor(
    private populationService: PopulationService,
    private log: ILoggingService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    if (this.population && this.population.id) {
      this.contributorsObs = this.populationService.getContributors(this.population.id)
    }
  }

  addContributor() {
    this.addingContributor = true;
    this.contributor = {
      populationId: this.population?.id
    }
    this.editingContributorId = undefined;
  }

  editContributor(id: string) {
    this.addingContributor = true;
    this.contributor = undefined;
    this.editingContributorId = id;

  }





  sortContributors(contributors: Array<ContributorDto>) {
    return contributors.sort((a, b) => b.order! - a.order!)
  }

  cancel() {
    this.addingContributor = false;
    this.editingContributorId = undefined;
    this.contributor = undefined;
  }
}
