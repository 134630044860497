import { Injectable } from '@angular/core';
import {StyleManagerService} from "../style-manager/style-manager.service";
import {DataLimitationLicenseDto} from "../../models/dto/encounter/DataLimitationLicenseDto";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(private style: StyleManagerService) { }

  getLogosForLicense(license: DataLimitationLicenseDto, ignoreTheme: boolean = false) {
    let dir = "black";
    if (this.style.isDark || ignoreTheme) {
      dir = "white"
    }
    if (license.limitLevel == 1) {
      return [
        {
          "img": `/assets/logos/cc/${dir}/cc.svg`,
          "link": "https://creativecommons.org/licenses/",
          "text": "Creative Commons 4.0"
        },
        {
          "img": `/assets/logos/cc/${dir}/cc_nc.svg`,
          "link": "https://creativecommons.org/licenses/nc/1.0/",
          "text": "Non-Commercial Usage"
        }
      ]
    } else if (license.limitLevel == 2) {
      return [
        {
          "img": `/assets/logos/cc/${dir}/cc.svg`,
          "link": "https://creativecommons.org/licenses/",
          "text": "Creative Commons 4.0"
        },
        {
          "img": `/assets/logos/cc/${dir}/cc_nc.svg`,
          "link": "https://creativecommons.org/licenses/by-nc/4.0/",
          "text": "Non-Commercial Usage"
        },
        {
          "img": `/assets/logos/cc/${dir}/cc_by.svg`,
          "link": "https://creativecommons.org/licenses/by/4.0/",
          "text": "Attribution Necessary"
        }

      ]
    } else if (license.limitLevel == 3) {
      return [
        {
          "img": `/assets/logos/cc/${dir}/organization.svg`,
          "link": "",
          "text": "Usage within organization"
        },

      ]
    } else if (license.limitLevel == 4) {
      return [
        {
          "img": `/assets/logos/cc/${dir}/private.svg`,
          "link": "",
          "text": "Private usage only"
        }

      ]
    }
    return  []
  }
}
