import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WorkspaceService} from "../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../models/dto/workspace/workspaceDto";
import {ContributorDto} from "../../../models/dto/population/contributorDto";
import {PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {PopulationStatisticsDto} from "../../../models/dto/population/populationStatisticsDto";
import {environment} from "../../../environments/environment";
import {StyleManagerService} from "../../../services/style-manager/style-manager.service";
import {PopulationOverviewDto} from "../../../models/dto/population/populationOverviewDto";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnChanges {

  workspace: WorkspaceDto | undefined;
  population: PopulationDto | undefined;
  statistics: PopulationStatisticsDto | undefined;

  contributors: Array<ContributorDto> | undefined;
  contributorGroups: Map<string, Array<ContributorDto>> | undefined;
  contributorsLoaded: boolean = false;

  contributorGroupNames: Array<string> | undefined;

  headerImgSrc = environment.aboutHeaderImg;
  isDark = true;

  overview: PopulationOverviewDto | undefined;

  constructor(
    private workspaceService: WorkspaceService,
    private populationService: PopulationService,
    private themeService: StyleManagerService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe( res => {
      if (res && res.settings) {
        this.workspace = res;
        this.populationService.getPopulationOverview(res.settings!.population!.id!).subscribe(res => {
          if (res) {
            this.population = res.population;
            this.statistics = res.populationStatistics;
            this.contributors = this.sortContributors(res.contributors!)
          }
        })
        // this.populationObs = this.populationService.getPopulationById(res.settings!.population?.id!);
        // this.statsObs = this.populationService.getStatistics(res.settings!.population!.id!);
        // this.populationService.getContributors(res.settings!.population?.id!).subscribe( res => {
        //   this.contributors = this.sortContributors(res);
        //   this.mapContributors(res);
        // })
      }

    })
    this.themeService.isDarkObs.subscribe(res => {
      this.isDark = res;
    })

  }

  sortContributors(contributors: Array<ContributorDto>) {
    return contributors.sort((a, b) =>  a.name!.localeCompare(b.name!));
  }

  mapContributors(contributors: Array<ContributorDto>) {
    let m: Map<string, Array<ContributorDto>> = new Map<string, Array<ContributorDto>>();
    const names = new Array<string>();
    for(let c of contributors) {
      const g = c.group ?? "";
      if (!m.has(g)) {
        m.set(g, new Array<ContributorDto>());
        names.push(g);
      }
      let v = m.get(g)
      v!.push(c)
      m.set(g, v!);

    }
    this.contributorGroups = m;
    this.contributorGroupNames = names
    this.contributorsLoaded = true;
  }

  getGroup(g: string): Array<ContributorDto> {
    return this.contributorGroups!.get(g)!;
  }
}
