import { Injectable } from '@angular/core';
import {ILoggingService} from "../logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private log: ILoggingService) { }

  public handleRequestError(request: string, err: HttpErrorResponse): void {
    const error = err.error
    this.log.error(`Error - ${request} failed: ${err.error}`, true, err);
  }
}
