import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";
import {DetectionSet} from "../../../models/detection/detection-set.model";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: 'root'
})
export class IDetectionFileService {
  constructor(public log: ILoggingService) {}
  items = new BehaviorSubject<Array<IFileItem>>([]);

  getItems(path: string): void {}

  getDetectionForFile(file: IFileItem): string {return ""}

}
