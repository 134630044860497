<button mat-stroked-button  [matMenuTriggerFor]="popMenu" *ngIf="selectedPopulation !== undefined && auth">
  {{selectedPopulation.displayName}}
</button>

<mat-menu #popMenu="matMenu">
  <a mat-menu-item *ngFor="let population of populations | async" [routerLink]="'/about'" (click)="updatePopulation(population)">{{population.displayName}} ({{population.speciesName}})</a>
  <a mat-menu-item (click)="newPopulation()">Join A Population</a>
</mat-menu>

<button mat-stroked-button (click)="newPopulation()" *ngIf="selectedPopulation === undefined && auth" >Join A Population</button>
