<div *ngIf="encounter !== undefined" class="content">
  <div class="org-content full-width" *ngIf="!inProgress; else saving">
    <!--Population Selector-->
    <app-submission-population [populations]="selectablePopulations"
                                 [selectedPopulation]="selectedPopulation">

    </app-submission-population>
    <div class="confirm" *ngIf="selectedPopulation.value">
      <button mat-stroked-button class="full-width" (click)="updatePopulation()">Change Population</button>
    </div>
  </div>
  <ng-template #saving>
    <mat-progress-spinner [diameter]="50" mode="indeterminate" color="accent"></mat-progress-spinner>
  </ng-template>
</div>
