import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {PopulationDto} from "../../models/dto/population/populationDto";
import {PopulationSettingsDto} from "../../models/dto/population/populationSettingsDto";
import {PopulationRoleSet} from "../population/population.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ResponsiveDesignService {
  private _mobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly mobile: Observable<boolean> = this._mobile.asObservable();
  private _tablet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly tablet: Observable<boolean> = this._tablet.asObservable();

  private _mobileOrTablet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly mobileOrTablet: Observable<boolean> = this._mobileOrTablet.asObservable();
  constructor() { }



  public setStatus(width: number) {
    if (width <= environment.design.mobileMax) {
      this._mobile.next(true);
      this._tablet.next(false);
      this._mobileOrTablet.next(true);
    } else if (width > environment.design.mobileMax && width <= environment.design.tabletMax) {
      this._mobile.next(false);
      this._tablet.next(true);
      this._mobileOrTablet.next(true);
    } else {
      this._mobile.next(false);
      this._tablet.next(false);
      this._mobileOrTablet.next(false);
    }

  }


}
