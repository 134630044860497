<h2 mat-dialog-title>Add New Members</h2>
<div class="content" *ngIf="data.allUsersObs | async as users; else loading">
  <mat-form-field *ngIf="memberIds"  color="accent">
    <mat-label>New Members</mat-label>
    <mat-select [(ngModel)]="memberIds" multiple>
      <mat-option *ngFor="let user of filterUsers(users)" [value]="user.email">{{user.firstName}} {{user.lastName}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="dialog-buttons" >
  <button mat-stroked-button (click)="onNoClick()" color="warn">Cancel</button>
  <button mat-stroked-button (click)="submit()" cdkFocusInitial color="accent">Add Members</button>
</div>

<ng-template #loading>
  <mat-progress-spinner [diameter]="25" [mode]="'indeterminate'"></mat-progress-spinner>
</ng-template>
