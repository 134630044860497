<div class="profile-image-container" *ngIf="profile !== undefined">
  <div class="main-left main-image" *ngIf="profileImages.has('left')">
    <h2>Left Side</h2>
    <div class="image-container" *ngIf="profileImages.get('left')!.extractedImageObs | async as leftImage; else loading"
         [matTooltip]="getTooltipContent('left')"
         [matTooltipPosition]="'after'"
         matTooltipClass="nl-tooltip"
         (click)="viewImage('left')">
      <img *ngIf="leftImage.uri" [src]="leftImage.uri" [alt]="'Left Side'" />
    </div>
  </div>
  <div class="main-left main-image" *ngIf="!profileImages.has('left')">
    <h2>Left Side</h2>
    <button type="button" mat-raised-button color="accent" (click)="viewImage('left')"><span>Select an Image to Display </span></button>
  </div>
  <div class="main-right main-image" *ngIf="profileImages.has('right')">
    <h2>Right Side</h2>
    <div class="image-container" *ngIf="profileImages.get('right')!.extractedImageObs | async as rightImage; else loading"
         [matTooltip]="getTooltipContent('right')"
         [matTooltipPosition]="'after'"
         matTooltipClass="nl-tooltip"
         (click)="viewImage('right')">
      <img *ngIf="rightImage.uri" [src]="rightImage.uri" [alt]="'Right Side'" />
    </div>
  </div>
  <div class="main-left main-image" *ngIf="!profileImages.has('right')">
    <h2>Right Side</h2>
    <button type="button" mat-raised-button color="accent" (click)="viewImage('right')"><span>Select an Image to Display </span></button>
  </div>
</div>

<ng-template #loading>
  <app-gallery-preview-loading-image></app-gallery-preview-loading-image>
</ng-template>
<ng-template #select>
  <div class="spinner-container" style="{width: 100%; display: flex; justify-content: center; align-items: center}">
    <mat-progress-spinner mode="indeterminate" [diameter]="25" *ngIf="profileImage === undefined && profileImageLoadingStarted === true && profileImageLoadingFinished === false"></mat-progress-spinner>

  </div>
</ng-template>
