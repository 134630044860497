<div class="container">
  <div class="container-header">
    <h2>Invitations</h2>
    <button mat-stroked-button (click)="addInvitation()"><mat-icon>add</mat-icon>Create Invitation</button>
  </div>
  <div class="invitations" *ngIf="invitations !== undefined && invitations.length > 0">
    <table mat-table [dataSource]="invitations">
      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
        <td mat-cell *matCellDef="let invitation">{{dateService.formatDateFromAny(invitation.expirationDate, false)}}</td>
      </ng-container>
      <ng-container matColumnDef="limit">
        <th mat-header-cell *matHeaderCellDef>Invitation Limit</th>
        <td mat-cell *matCellDef="let invitation">{{invitation.limit}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="redeemed">
        <th mat-header-cell *matHeaderCellDef>Redeemed</th>
        <td mat-cell *matCellDef="let invitation">{{invitation.redeemed}}</td>
      </ng-container>

      <ng-container matColumnDef="privileges">
        <th mat-header-cell *matHeaderCellDef>Privileges</th>
        <td mat-cell *matCellDef="let invitation" class="privilege-list">
          <span *ngIf="invitation.privileges.length == 0">None</span>
          <span *ngFor="let p of invitation.privileges" class="privilege">{{p.population.displayName}} ({{p.roleName}})</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Remove</th>
        <td mat-cell *matCellDef="let invitation"><button mat-icon-button (click)="delete(invitation)"><mat-icon>delete</mat-icon></button></td>
      </ng-container>

      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>Link</th>
        <td mat-cell *matCellDef="let invitation">{{invitation.link}}</td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">No data</td>
      </tr>

<!--      &lt;!&ndash; Weight Column &ndash;&gt;-->
<!--      <ng-container matColumnDef="weight">-->
<!--        <th mat-header-cell *matHeaderCellDef>Weight</th>-->
<!--        <td mat-cell *matCellDef="let element">{{element.weight}}</td>-->
<!--      </ng-container>-->

<!--      &lt;!&ndash; Symbol Column &ndash;&gt;-->
<!--      <ng-container matColumnDef="symbol">-->
<!--        <th mat-header-cell *matHeaderCellDef>Symbol</th>-->
<!--        <td mat-cell *matCellDef="let element">{{element.symbol}}</td>-->
<!--      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

</div>

<app-area-loading [criteria]="!loading" title="Loading Invitations..."></app-area-loading>
