export interface StatusChangeDto {
  id?: string;
  itemId?: string;
  status?: string;
  updateDate?: Date;
}

export class STATUS {
  static NEW = "NEW";
  static IN_PROGRESS = "IN_PROGRESS";
  static FINISHED = "FINISHED";

  public static invert(status?: string): string {
    switch (status) {
      case STATUS.NEW:
        return "New";
      case STATUS.IN_PROGRESS:
        return "In Progress";
      case STATUS.FINISHED:
        return "Finished"
      case "New":
        return STATUS.NEW;
      case "In Progress":
        return STATUS.IN_PROGRESS;
      case "Finished":
        return STATUS.FINISHED;
      case "All":
        return "All"
      default:
        return "Unknown";
    }
  }
}
