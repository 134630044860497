import {Injectable} from "@angular/core";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: 'root'
})
export class IFileStorageService {

  constructor(public log: ILoggingService) {}
  storeFile(item: IFileItem): void {}
  getFileByName(fileName: string): IFileItem | null {return null; }

  update(item: IFileItem): void {}
  get(item: IFileItem): IFileItem {return item;}
}
