import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { env } from "process";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { NewCommentDto } from "../../models/dto/comments/NewCommentDto";
import { CommentSectionDto } from "../../models/dto/comments/CommentSectionDto";
import { DeleteCommentDto } from "../../models/dto/comments/DeleteCommentDto";
import { ResponseDto } from "../../models/dto/response/responseDto";
import { UpdateCommentDto } from "../../models/dto/comments/UpdateCommentDto";
import { UserImageDto } from "../../models/dto/user/userImageDto";

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  constructor(private httpClient: HttpClient) { }

  getCommentSection(encounterId: string): Observable<CommentSectionDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.comment.getCommentSection}/${encounterId}`;
    return this.httpClient.get<CommentSectionDto>(url);
  }
  //newCommentSection
  addComment(comment: NewCommentDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.comment.addComment}`;
    return this.httpClient.post<ResponseDto>(url, comment);
  }

  updateComment(comment: UpdateCommentDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.comment.updateComment}`;
    return this.httpClient.post<ResponseDto>(url, comment);
  }

  deleteComment(comment: DeleteCommentDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.comment.deleteComment}`;
    return this.httpClient.post<ResponseDto>(url, comment);
  }

  getUser(): Observable<UserImageDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.comment.checkUser}`;
    return this.httpClient.get<UserImageDto>(url);
  }
}
