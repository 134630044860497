export const BoxSettings = {
  lineWidth: 4,
  strokeStyle: "white",
  highlightColor: "red",
  tagSettings: {
    box: {
      xOffset: -2,
      yOffset: 0,
      w: 64,
      h: 20
    },
    tagStyle: "black",
    xOffset: 2,
    yOffset: 14,
    font: '14px sans-serif'
  }
}

export enum DrawStyle {
  STROKE,
  FILL
}
