<div class="image-information-container">
  <div class="image-information" *ngIf="image">
    <h2 class="information-headline">Custom Fields</h2>
    <div class="image-information-item">
      <span class="key editable hil"  [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('single')" *ngIf="!editingMap.get('single')">Single Animal</span>
      <span class="value editable hil"  [ngClass]="{'editable-line': canEdit}"(click)="toggleEdit('single')" *ngIf="!editingMap.get('single')">{{image.metaData.singleAnimal ? 'Yes' : 'No'}}</span>
      <div class="editing-container" *ngIf="editingMap.get('single') == true">
        <label>Does thie image only contain one individual?</label>
        <mat-radio-group
          [(ngModel)]="image.metaData.singleAnimal">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <div class="button-group">
          <button mat-stroked-button (click)="update()">Update</button>
          <button mat-stroked-button (click)="setEditFalse()">Cancel</button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="image-information-item">
      <span class="key editable hil"  [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('eyepatch')" *ngIf="!editingMap.get('eyepatch')">Eyepatch</span>
      <span class="value editable hil"  [ngClass]="{'editable-line': canEdit}"(click)="toggleEdit('eyepatch')" *ngIf="!editingMap.get('eyepatch')">{{image.metaData.eyepatch ? 'Yes' : 'No'}}</span>
      <div class="editing-container" *ngIf="editingMap.get('eyepatch') == true">
        <label id="example-radio-group-label">Is an Eyepatch Present?</label>
        <mat-radio-group
          [(ngModel)]="image.metaData.eyepatch">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <div class="button-group">
          <button mat-stroked-button (click)="update()">Update</button>
          <button mat-stroked-button (click)="setEditFalse()">Cancel</button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="image-information-item" >
      <span class="key editable" [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('quality')" *ngIf="!editingMap.get('quality')">Quality</span>
      <span class="value editable" [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('quality')" *ngIf="!editingMap.get('quality')">{{mapQuality(image.metaData.quality)}}</span>
      <div class="editing-container" *ngIf="editingMap.get('quality')">
        <mat-form-field>
          <mat-label>Quality</mat-label>
          <mat-select  [(ngModel)]="image.metaData.quality">
            <mat-option [value]="0">None</mat-option>
            <mat-option [value]="2">Noteworthy</mat-option>
            <mat-option [value]="3">Good</mat-option>
            <mat-option [value]="4">Excellent</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="button-group">
          <button mat-stroked-button (click)="update()">Update</button>
          <button mat-stroked-button (click)="setEditFalse()">Cancel</button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="image-information-item"  >
      <span class="key editable" [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('side')" *ngIf="!editingMap.get('side')">Side</span>
      <span class="value editable" [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('side')" *ngIf="!editingMap.get('side') && image.metaData.side">{{image.metaData.side!.charAt(0).toUpperCase() + image.metaData.side!.slice(1)! }}</span>
      <div class="editing-container" *ngIf="editingMap.get('side')">
        <mat-form-field>
          <mat-label>Side</mat-label>
          <mat-select  [(ngModel)]="image.metaData.side">
            <mat-option [value]="'left'">Left</mat-option>
            <mat-option [value]="'right'">Right</mat-option>
            <mat-option [value]="'other'">Other (Front, Back, etc.)</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="button-group">
          <button mat-stroked-button (click)="update()">Update</button>
          <button mat-stroked-button (click)="setEditFalse()">Cancel</button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="image-information-item" >
      <span class="key editable" [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('notes')" *ngIf="!editingMap.get('notes')">Notes</span>
      <span class="value editable" [ngClass]="{'editable-line': canEdit}" (click)="toggleEdit('notes')" *ngIf="!editingMap.get('notes')">{{image.metaData.notes}}</span>
      <div class="editing-container" *ngIf="editingMap.get('notes')">
        <mat-form-field>
          <mat-label>Add Some Notes</mat-label>
          <textarea matInput [(ngModel)]="image.metaData.notes">{{image.metaData.notes}}</textarea>
        </mat-form-field>
        <div class="button-group">
          <button mat-stroked-button (click)="update()">Update</button>
          <button mat-stroked-button (click)="setEditFalse()">Cancel</button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="iptc-data">
      <h2 class="information-headline">IPTC Data</h2>
      <div class="image-information-item" *ngIf="image.metaData.creationDateTime !== undefined">
        <span class="key">Creation Date:</span>
        <span class="value">{{getImageDtString(image.metaData)}}</span>
      </div>
      <!--    <mat-divider></mat-divider>-->
      <!--    <div class="image-information-item" *ngIf="image.metaData.caption !== undefined">-->
      <!--      <span class="key">Caption: </span>-->
      <!--      <span class="value">{{image.metaData.caption}}</span>-->
      <!--    </div>-->
      <mat-divider></mat-divider>
      <div class="image-information-item" *ngIf="encounter !== undefined && encounter!.user !== undefined">
        <span class="key">Photographer: </span>
        <span class="value">{{encounter!.user!.firstName}} {{encounter!.user!.lastName}}</span>
      </div>
      <!--    <mat-divider></mat-divider>-->
      <!--    <div class="image-information-item" *ngIf="image.metaData.editorName !== undefined">-->
      <!--      <span class="key">Editor: </span>-->
      <!--      <span class="value">{{image.metaData.editorName}}</span>-->
      <!--    </div>-->
      <!--    <mat-divider></mat-divider>-->
      <!--    <div class="image-information-item" *ngIf="image.metaData.keywords !== undefined">-->
      <!--      <span class="key">Keywords: </span>-->
      <!--      <span class="value">{{image.metaData.keywords}}</span>-->
      <!--    </div>-->
      <mat-divider></mat-divider>
      <div class="image-information-item" *ngIf="encounter !== undefined && encounter!.location !== undefined">
        <span class="key">Location:</span>
        <span class="value">{{encounter!.location!.name}}</span>
      </div>
      <!--    <mat-divider></mat-divider>-->
      <!--    <div class="image-information-item" *ngIf="image.metaData.headline !== undefined">-->
      <!--      <span class="key">Headline:</span>-->
      <!--      <span class="value">{{image.metaData.headline}}</span>-->
      <!--    </div>-->
      <mat-divider></mat-divider>
      <div class="image-information-item" *ngIf="image.annotations">
        <span class="key">IDs</span>
        <span class="value">{{formatIDs()}}</span>
      </div>
    </div>


  </div>
</div>
