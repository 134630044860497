<h2 mat-dialog-title>Select an ID</h2>
<div mat-dialog-content>

  <div *ngIf="data.image !== undefined && data.image.metaData.caption !== undefined && suggestedTags.length > 0">
    <h4>Suggested Tags Based on Contextual Knowledge</h4>
    <mat-action-list>
      <mat-list-item *ngFor="let tag of suggestedTags" (click)="chooseSuggested(tag)">{{tag}}</mat-list-item>
    </mat-action-list>
    <mat-divider></mat-divider>
  </div>
  <h4 *ngIf="!newId">Choose From All Known Animals</h4>
  <mat-form-field class="full-width" *ngIf="!newId">
    <mat-label>Animal Identifier</mat-label>
    <input type="text" matInput [formControl]="idControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setTag($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.identifier}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-divider></mat-divider>
  <h4 *ngIf="!newId">Don't see them in the list?</h4>
  <button mat-stroked-button (click)="newId = true" *ngIf="!newId" class="full-width">Register a New Animal</button>

  <mat-form-field appearance="fill" *ngIf="newId" class="full-width">
    <mat-label>New Identifier</mat-label>
    <input matInput [(ngModel)]="data.orcaTag" cdkFocusInitial  (keydown)="enterSubmit($event)">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data.orcaTag">Confirm</button>
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>
