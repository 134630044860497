import {Component, Inject, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../../models/dto/encounter/encounterDto";
import {UserProfileDto} from "../../../../../../../models/dto/user/userProfileDto";
import {Location} from "../../../../../../../models/location/location.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface UpdateEncounterLocationDialogData {
  encounter: EncounterDto;
  user?: UserProfileDto;
}

@Component({
  selector: 'app-update-location-component',
  templateUrl: './update-location.component.html',
  styleUrls: ['./update-location.component.scss']
})
export class UpdateLocationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UpdateLocationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UpdateEncounterLocationDialogData,) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setLocation($event: Location) {
    this.data.encounter.location = $event;
  }
}
