<div class="content-container" *ngIf="canEdit">
  <div class="header">
    <button mat-stroked-button color="accent" (click)="startMerge()" *ngIf="!merging">Merge Profile</button>
  </div>
  <div class="content" *ngIf="merging && profile">
      <div class="note">
        <h4>Shifting data from {{profile!.identifier}} to <span *ngIf="!mergeTarget">...</span><span *ngIf="mergeTarget">{{mergeTarget.identifier}}</span></h4>
      </div>
      <mat-form-field color="accent" *ngIf="animals">
        <mat-label>Merge Target</mat-label>
        <mat-select [(ngModel)]="mergeTarget">
          <mat-option *ngFor="let animal of animalService.sortFamilies(animals)" [value]="animal">{{animal.identifier}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="button-row">
        <button mat-stroked-button color="accent" (click)="merge()" [disabled]="mergeTarget === undefined">Merge</button>
        <button mat-stroked-button color="accent" (click)="merging = false">Cancel</button>

      </div>
  </div>
  <mat-divider></mat-divider>
</div>


