
<div *ngIf="encounter !== undefined" class="content">
  <div class="org-content full-width" *ngIf="!inProgress; else saving">
    <app-submission-organization
      [organizations]="selectableOrganizations"
      [existingOrganizations]="encounter.organizations"
      [selectedOrganizations]="selectedOrganizations"
    ></app-submission-organization>
    <div class="warning" *ngIf="!selectedOrganizations.pristine">
      <h4>The addition of organizations to an encounter cannot be undone.</h4>
    </div>
    <div class="confirm" *ngIf="selectedOrganizations.value && selectedOrganizations.value.length > 0">
      <button mat-stroked-button class="full-width" (click)="updateOrganizations()">Confirm New Organizations</button>
    </div>
  </div>

  <ng-template #saving>
    <mat-progress-spinner [diameter]="50" mode="indeterminate" color="accent"></mat-progress-spinner>
  </ng-template>
</div>


