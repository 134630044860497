import {environment} from "../../../../environments/environment";
import {ElectronPredictionFileService} from "../../../../services/files/predictions/prediction-file.service.electron";
import {LocalPredictionFileService} from "../../../../services/files/predictions/prediction-file.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";

export let predictionServiceFactory = (log: ILoggingService) => {
  if (environment.production) {
    return new ElectronPredictionFileService(log)
  } else {
    return new LocalPredictionFileService(log)
  }
}
