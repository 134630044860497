import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {IFileItem} from "../../../models/items/files/file-item.model";
import {UserStatisticsResponseDto} from "../../../models/dto/response/user/userStatisticsResponseDto";
import {BehaviorSubject, Observable} from "rxjs";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {ILoggingService} from "../../logging/logging.service.interface";
import {AuthenticationService} from "../authentication.service";

@Injectable({
  providedIn: 'root'
})
export class UserContentService {
  private _user: BehaviorSubject<UserProfileDto> = new BehaviorSubject<UserProfileDto>({});
  public readonly user: Observable<PopulationDto> = this._user.asObservable();
  constructor(
    private http: HttpClient,
    private log: ILoggingService,
    private authService: AuthenticationService
  ) {
    this.updateUser();
  }

  public updateUser = () => {
    if (this.authService.isUserAuthenticated()) {
      this.getUser().subscribe({
        next: (res: UserProfileDto) => {
          this._user.next(res);
        },
        error: (error: any) => {
          this.log.error(`Error fetching populations for user: ${error}`, true)
        }
      });
    }

  }

  public getUser = () => {
    const url = `${environment.server.baseUrl}${environment.server.api.profile.main}`;
    return this.http.get<UserProfileDto>(url)
  }

  public getUserContent() {
    const url = `${environment.server.baseUrl}${environment.server.api.profile.content}`;
    return this.http.get<Array<IFileItem>>(url)
  }

  public getUserStatistics() {
    const url = `${environment.server.baseUrl}${environment.server.api.profile.statistics}`;
    return this.http.get<UserStatisticsResponseDto>(url)
  }
}
