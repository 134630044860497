<mat-progress-bar mode="indeterminate" *ngIf="files === undefined || image === undefined"></mat-progress-bar>
<div class="image-annotation">
  <global-annotate *ngIf="image !== undefined"
                   [image]="image"
                   [user]="user"
                   (annotationRemoved)="removeAnnotation($event)"
                   (annotationConfirmed)="confirmAnnotation($event)"
                   (annotationCreated)="createAnnotation($event)"
                   (annotationUpdated)="updateAnnotation($event)"

                   class="image-area"></global-annotate>
</div>

<app-area-loading [criteria]="!saving" [title]="'Saving...'"></app-area-loading>

