import {Component, Input, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from "@ckeditor/ckeditor5-angular";
import {PopulationService} from "../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-population-description',
  templateUrl: './population-description.component.html',
  styleUrls: ['./population-description.component.scss']
})
export class PopulationDescriptionComponent implements OnInit {

  @Input() population: PopulationDto | undefined;
  // @ts-ignore
  public Editor = ClassicEditor as {create: any;};
  public content: string | undefined;

  public editing = false;
  constructor(
    private populationService: PopulationService,
    private _log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.population && this.population.description == undefined) {
      this.population.description = "";
    }
  }

  log($event: ChangeEvent) {
    this.content = $event.editor.data.get();

  }

  update() {
    // @ts-ignore
    this.population!.description = this.content;
    this.editing = false;
    this.populationService
      .updatePopulationDetails(this.population!.id!, this.population!)
      .subscribe({
        next: (value: PopulationDto) => {
          this._log.info(`Successfully updated population.`)
        },
        error: (value: HttpErrorResponse) => {
          this._log.error(`Could not update population: ${value.error}`)
        }
      })
  }
}
