<div class="profile-container">
  <div class="profile" *ngIf="user !== undefined">
    <div class="information-panel">
      <div class="profile-header panel">
        <div class="profile-picture" *ngIf="user">
          <app-user-profile-image [user]="user" [foreign]="foreign"></app-user-profile-image>
        </div>
        <div class="profile-identifiers">
          <div class="name id-field">{{user.firstName}} {{user.lastName}}</div>
          <div class="email id-field">{{user.email}}</div>
          <button mat-stroked-button color="accent" class="profile-edit-button" *ngIf="!foreign" (click)="toggleProfileEdit()">Edit Profile</button>
          <div class="profile-edit-container" *ngIf="profileEditActive">
            <form [formGroup]="profileUpdateForm">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" [value]="user.email">
              </mat-form-field>
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput placeholder="First Name" formControlName="firstName" [value]="user.firstName">
              </mat-form-field>
              <mat-form-field>
                <mat-label>LastName</mat-label>
                <input matInput placeholder="Last Name" formControlName="lastName" [value]="user.lastName">
              </mat-form-field>
              <button mat-stroked-button color="accent" (click)="updateProfile(profileUpdateForm.value)">Save</button>
            </form>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="profile-summary panel">
        <div class="panel-content" *ngIf="statistics">
          <div class="encounters summary-line">
            <span class="summary-category">Encounters</span><span class="count">{{statistics.encounters}}</span>
          </div>
          <div class="encounters summary-line">
            <span class="summary-category">Images Added</span><span class="count">{{statistics.images}}</span>
          </div>
          <div class="animal-identification  summary-line">
            <span class="summary-category">Known Animals Observed</span><span class="count">{{statistics.animalsObserved}}</span>
          </div>
          <div class="location-history  summary-line">
            <span class="summary-category">Unique Locations</span><span class="count">{{statistics.uniqueLocations}}</span>
          </div>
<!--          <div class="profile-quality  summary-line">-->
<!--            <span class="summary-category">Profile Quality</span><span class="count">{{statistics.rating}}</span>-->
<!--          </div>-->
        </div>
        <div class="panel-content" *ngIf="statistics === undefined">
          <app-area-loading [criteria]="statistics !== undefined"></app-area-loading>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="password-reset panel" *ngIf="!foreign">
        <button mat-stroked-button color="warn" class="password-edit-button" (click)="togglePasswordEdit()">Change Password</button>
        <div class="profile-edit-container" *ngIf="passwordEditActive">
          <form [formGroup]="passwordUpdateForm"  (ngSubmit)="updatePassword(passwordUpdateForm.value)">
            <mat-form-field>
              <mat-label>Current Password</mat-label>
              <input matInput placeholder="Current Password" formControlName="currentPassword" type="password">
            </mat-form-field>
            <mat-form-field>
              <mat-label>New Password</mat-label>
              <input matInput placeholder="New Password" formControlName="newPassword" type="password">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Confirm New Password</mat-label>
              <input matInput placeholder="Repeat New Password" formControlName="newPasswordConfirm" type="password">
            </mat-form-field>
            <button mat-stroked-button color="primary" (click)="updateProfile(passwordUpdateForm.value)" class="button-with-spinner">
              <div class="button-content">
                <span>Update Password</span>
                <span *ngIf="passwordLoading" class="login-spinner-container"><mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2"></mat-progress-spinner></span>
              </div>
            </button>
            <div class="login-errors" *ngIf="showPasswordUpdateError">
              <mat-error>{{passwordUpdateErrorMessage}}</mat-error>
            </div>
            <div class="login-errors" *ngIf="showPasswordUpdateSuccess">
              <h4> Your password has been reset.</h4>
            </div>
          </form>
        </div>
      </div>

    </div>
    <div class="profile-content">
      <app-encounter-timeline *ngIf="user" [userId]="user.id"></app-encounter-timeline>
    </div>
  </div>
  <div class="content" *ngIf="user === undefined">
    <div class="area-loading">
      <mat-progress-spinner [diameter]="50" [mode]="'indeterminate'" [strokeWidth]="2" [color]="'accent'"></mat-progress-spinner>
    </div>
  </div>
</div>
