<div class="content-container">
  <div class="content">
    <div class="content-header">
      <h2>My Organizations</h2>

      <button mat-stroked-button (click)="createOrganization()" color="accent"><mat-icon>add</mat-icon> Create Organization</button>
    </div>
    <mat-divider></mat-divider>
    <div class="population-list" *ngIf="organizations | async as os; else loading">
      <div class="population editable-line" *ngFor="let org of os" [routerLink]="getLink(org)">
        <h3>{{org.name}}</h3>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [color]="'accent'"></mat-progress-spinner>
</ng-template>
