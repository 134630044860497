import {environment} from "../../../../environments/environment";
import {ElectronDetectionFileService} from "../../../../services/files/detections/detection-file.service.electron";
import {LocalDetectionFileService} from "../../../../services/files/detections/detection-file.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";


export let detectionFileServiceFactory = (log: ILoggingService) => {
  if (environment.production) {
    return new ElectronDetectionFileService(log)
  } else {
    return new LocalDetectionFileService(log)
  }
}
