import {Injectable} from "@angular/core";
import {TimeService} from "./time.service";

@Injectable({
  providedIn: "root"
})
export class DateService {

  constructor(public timeService: TimeService) {
  }

  formatDate(date: Date, monthName: boolean = true, year = true, month = true, day = true, time = true): string {
    const components = [];
    if (year) {
      components.push(`${date.getFullYear()}`);
    }
    if (month) {
      if (monthName) {
        components.push(`${this.timeService.getMonthByIdx(date.getMonth())}`);
      } else {
        components.push(`${(date.getMonth() + 1).toString().padStart(2, "0")}`);
      }
    }
    if (day) {
      components.push(`${date.getDate().toString().padStart(2, "0")}`);
    }

    const dateComponents = components.join("-");

    if (!time) {
      return dateComponents;
    }
    else {
      return `${dateComponents} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }

  }

  formatDateFromAny(date: any, monthName: boolean = true, year = true, month = true, day = true, time = false) {
    if (date === undefined || date === null) {return;}
    let dt = date;
    if (typeof date === "string") {
      const newdt = date.slice(0, 10).replace("-", "/").replace("-", "/");
      dt = new Date(newdt)
      const userTimezoneOffset = Math.abs(dt.getTimezoneOffset() * 60000);
      dt = new Date(dt.getTime() + userTimezoneOffset);
    } else if (this.isMoment(dt)) {
      dt = dt.toDate();
    }

    return this.formatDate(dt, monthName, year, month, day, time);
  }

  isMoment(date: any) {
    return Object.getOwnPropertyNames(date).indexOf("_isAMomentObject") !== -1;
  }
}
