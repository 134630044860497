import {Injectable} from "@angular/core";
import {Item} from "../../../models/items/item.model";
import {IItemStorageService} from "./item-storage.service.interface";
import {ILoggingService} from "../../logging/logging.service.interface";

@Injectable({
  providedIn: 'root'
})
export class LocalItemStorageService implements IItemStorageService {
  private readonly storageId = "WHALEEGOODTIME";
  private readonly expirationTimeMinutes = 120;
  constructor(public log: ILoggingService) {}

  remove(STORAGEKEY: string): void {
        localStorage.removeItem(STORAGEKEY);
    }
  storeKeyValue(key: string, value: Item): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any | null{
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  store(key: string, value: any): void {
    let item = this.getItem(this.storageId);

    if (item === null) {
       item = {}
    }

    item[key] = {
      expiresAt: new Date(Date.now() + (this.expirationTimeMinutes * 60 * 1000)),
      obj: value
    };

    localStorage.setItem(this.storageId, JSON.stringify(item));
  }

  fetch(itemId: string): any | undefined {
    let stored = localStorage.getItem(this.storageId);
    if (!stored) {

      return undefined;
    }

    stored = JSON.parse(stored);
    // @ts-ignore
    if (stored[itemId]) {
      // @ts-ignore
      return stored[itemId].obj;
    }
    return undefined;

  }
}
