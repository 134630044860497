<div class="content-container">
  <div class="content" *ngIf="users !== undefined">
    <div class="content-header">
      <div class="title">
        <h2>Users</h2>
      </div>
      <div class="buttons">
        <button mat-stroked-button (click)="createUser()">Create New User</button>
      </div>
    </div>
    <div class="example-action-buttons">
      <button mat-button (click)="accordion.openAll()">Expand All</button>
      <button mat-button (click)="accordion.closeAll()">Collapse All</button>
    </div>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let user of sort(users)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{user.firstName}} {{user.lastName}}
          </mat-panel-title>
          <mat-panel-description>
            {{user.email}}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="user-information">
          <div class="user-profile user-information-section">
            <h2>User Information</h2>
            <div class="id">
              User ID: {{user.id}}
            </div>
            <div class="name">
              Name: {{user.firstName}} {{user.lastName}}
            </div>
            <div class="email">
              Email: {{user.email}}
            </div>
            <div class="email clickable editable-line" [routerLink]="getProfileLink(user)">
              View Profile
            </div>
            <div class="remove-user">
              <button mat-stroked-button color="warn" (click)="deleteUser(user)">Delete User {{user.firstName}} {{user.lastName}}</button>
            </div>
          </div>
          <div class="stats user-information-section" *ngIf="user.userStatistics">
            <h2>User Statistics</h2>
            <h4>{{user.userStatistics.encounters}} Encounters</h4>
            <h4>{{user.userStatistics.images}} Images</h4>
            <h4>{{user.userStatistics.animalsObserved}} Animals Observed</h4>
            <h4>{{user.userStatistics.uniqueLocations}} Locations Reported</h4>
          </div>
          <div class="roles user-information-section">
            <h2>Rights and Usage</h2>
            <div class="roles" *ngIf="user.roles !== undefined">
              <div class="information-section-content">
                <span class="header-key">Role:</span>
                <mat-list class="header-value">
                  <mat-list-item *ngFor="let role of user.roles">{{role}}</mat-list-item>
                </mat-list>
              </div>
              <div class="role-update-options" *ngIf="roleUpdatingActive(user)">
                <mat-divider></mat-divider>
                <label class="role-radio-label">Select new role</label>
                <mat-radio-group
                  class="role-radio-group"
                  [(ngModel)]="user.roleUpdate">
                  <mat-radio-button class="example-radio-button" *ngFor="let role of roles" [value]="role">
                    {{role}}
                  </mat-radio-button>
                </mat-radio-group>
                <div class="update-buttons">
                  <button mat-stroked-button color="accent" (click)="saveRoleUpdate(user)">
                    <div class="load-button">
                      <span class="msg">Save</span>
                      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="20" [strokeWidth]="2" *ngIf="loadButtonActive(user)" color="accent"></mat-progress-spinner>
                    </div>

                  </button>
                  <button mat-stroked-button color="warn" (click)="deactivateRoleUpdate(user)">Cancel</button>
                </div>
              </div>
              <div class="role-update-button full-width" *ngIf="!roleUpdatingActive(user)">
                <button mat-stroked-button color="accent" (click)="activateRoleUpdate(user)">Update Role</button>
              </div>
            </div>
          </div>

        </div>


      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<app-area-loading [criteria]="users !== undefined" [title]="'Fetching Users...'"></app-area-loading>
<app-area-loading [criteria]="!saving" title="Saving..."></app-area-loading>
