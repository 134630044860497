import {Component, Inject, Input, OnInit} from '@angular/core';
import {Location} from "../../../../models/location/location.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface SelectMapDialogData {
  allowDrag: boolean;
  location: Location | undefined;
  singleSelect: boolean;
  allowReselect: boolean;
}

@Component({
  selector: 'app-select-map-dialog',
  templateUrl: './select-map-dialog.component.html',
  styleUrls: ['./select-map-dialog.component.scss']
})
export class SelectMapDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SelectMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectMapDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  updateLocation($event: { lat: number; lng: number }) {
    if (this.data.location !== undefined) {
      this.data.location!.latitude = $event.lat;
      this.data.location!.longitude = $event.lng
    }

  }
}
