<div *ngIf="population && population.behaviors && population.behaviors.length > 0">
  <div class="selection-section-header">
    <h4>Behavior</h4>
    <button mat-icon-button class="reset-button" (click)="reset()"><mat-icon>restart_alt</mat-icon></button>
  </div>
  <mat-form-field color="accent" class="full-width">
    <mat-label>Observed Behaviors</mat-label>
    <mat-select multiple [(ngModel)]="selectedBehaviors" (ngModelChange)="alert()">
      <mat-option *ngFor="let behavior of population.behaviors" [value]="behavior">
        {{behavior.description}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-divider></mat-divider>
</div>
