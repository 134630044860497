<h1 mat-dialog-title>New Invitation</h1>
<div mat-dialog-content class="content finished" *ngIf="data.link !== undefined">
  <h3>Invitation Successfully Created.</h3>
  <p>Send the link below to those whom you would like to join</p>
  <p style="font-family:'Lucida Console', monospace !important">{{data.link}}</p>
</div>
<div mat-dialog-content class="content creating" *ngIf="data.link === undefined" >
  <mat-form-field matTooltip="How many people should be able to accept this invitation?" matTooltipPosition="left" class="full-width">
    <mat-label>Limit</mat-label>
    <input matInput [(ngModel)]="data.limit" type="number" min="1" step="1">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Expiration Date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="data.expirationDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="privilege-assignments" *ngIf="domains">

    <mat-divider></mat-divider>
    <h3>Role Assignments</h3>
    <p>As a population administrator, you have the ability to directly assign a role to anyone receiving this invitation</p>
    <div class="population-list">
      <div class="population" *ngFor="let domain of domains">
        <div class="population-header">
          <h3>{{domain.population.displayName}} <span *ngIf="domain.assignedRole !== undefined"> (Assigned To {{domain.assignedRole!.name}})</span></h3>
          <button mat-stroked-button (click)="addUser(domain.population.id)" *ngIf="(selectedPopulationRoleAssignment === undefined || selectedPopulationRoleAssignment!.populationId !== domain.population.id) && domain.assignedRole === undefined"><mat-icon>add</mat-icon>Add User Assignment</button>
          <button mat-stroked-button (click)="removeUser(domain.population.id)" *ngIf="domain.assignedRole !== undefined"><mat-icon>remove</mat-icon>Remove User Assignment</button>
        </div>
        <div class="population-role-assignment" *ngIf="selectedPopulationRoleAssignment !== undefined && selectedPopulationRoleAssignment!.populationId === domain.population.id">
          <mat-form-field color="accent">
            <mat-label>Assigned Role</mat-label>
            <mat-select [(ngModel)]="selectedPopulationRoleAssignment!.populationRole">
              <mat-option *ngFor="let role of selectedPopulationRoleAssignment!.assignableRoles" [value]="role">{{role.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button (click)="confirmAssignment()">Confirm Role Assignment of {{selectedPopulationRoleAssignment!.populationRole.name}} for {{domain.population.displayName}}</button>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog-buttons" >
  <button mat-stroked-button (click)="onNoClick()" color="warn">Cancel</button>
  <button mat-stroked-button (click)="submit()" cdkFocusInitial color="accent" *ngIf="data.link === undefined" [disabled]="data.expirationDate === undefined || data.limit < 1">Create Invitation</button>
  <button mat-stroked-button (click)="finish()" cdkFocusInitial color="accent" *ngIf="data.link !== undefined">Close</button>
</div>
