import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileItem} from "../../../../models/items/files/file-item.model";

@Component({
  selector: 'app-submission-images-preview',
  templateUrl: './submission-images-preview.component.html',
  styleUrls: ['./submission-images-preview.component.scss']
})
export class SubmissionImagesPreviewComponent implements OnInit {
  @Input() fileItems: Array<FileItem> | undefined;
  @Output() fileRemoved: EventEmitter<Array<FileItem>> = new EventEmitter<Array<FileItem>>();
  constructor() { }

  ngOnInit(): void {
  }

  removeImage(fileItem: FileItem): void {
    if (this.fileItems) {
      const idx = this.fileItems.indexOf(fileItem);
      this.fileItems.splice(idx, 1)
      this.fileRemoved.emit(this.fileItems);
    }

  }

  public reset() {
    this.fileItems = [];
  }

}
