import {Component, Input} from '@angular/core';
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {UserImageDto} from "../../../../../models/dto/user/userImageDto";
import {UserProfileService} from "../../../../../services/user/profile/user-profile.service";
import {ImageService} from "../../../../../services/utilities/image.service";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {MatDialog} from "@angular/material/dialog";
import {UuidService} from "../../../../../services/utilities/uuid.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {
  UserProfileImageUpdateDialogComponent
} from "../../../../user/user-home/user-profile-image/user-profile-image-update-dialog/user-profile-image-update-dialog.component";

@Component({
  selector: 'app-encounter-user-profile-image',
  templateUrl: './encounter-user-profile-image.component.html',
  styleUrls: ['./encounter-user-profile-image.component.scss']
})
export class EncounterUserProfileImageComponent {
  @Input() user: UserProfileDto | undefined;
  @Input() foreign: boolean = true;
  @Input() showName: boolean = false;
  public userImage: UserImageDto | undefined | null;

  constructor(
    private profileService: UserProfileService,
    public imageService: ImageService,
    private errorService: ErrorHandlerService,
    private dialog: MatDialog,
    private uuidService: UuidService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.user === undefined) return;
    // this.profileService.getProfilePicture(this.user).subscribe({
    //   next:(value: UserImageDto | null | undefined) => {
    //     if (value && value.image) {
    //       value.image = this.imageService.maybePrefix(value.image);
    //     }
    //     this.userImage = value;
    //   },
    //   error: (err: HttpErrorResponse) => {
    //     this.errorService.handleRequestError("Fetching User Profile Image", err);
    //   }
    // })
  }

  public getJdenticon(user: UserProfileDto) {
    return `${user.firstName} ${user.lastName} ${user.email}`;
  }

  openPhotoUpdateDialog() {
    const ref = this.dialog.open(UserProfileImageUpdateDialogComponent, {
      data: {
        userImage: {
          id: this.uuidService.generate(),
          userId: this.user?.id,
          image: ""
        }
      }
    })
    ref.afterClosed().subscribe( res => {
      if (res) {
        this.profileService.setProfilePicture(res).subscribe({
          next: () => {
            this.log.info(`Profile image updated`)
            this.ngOnInit();
          },
          error: (err: HttpErrorResponse) => {
            this.errorService.handleRequestError("Updating profile image", err);
          }
        })
      }
    })
  }

  getUserLink(user: UserProfileDto) {
    return `/users/profile/${user.id}`;
  }
}
