<div class="form-content-container">
  <mat-card class="login-card">
    <mat-card-title><h3>Reset Password</h3></mat-card-title>
    <mat-card-content>
      <form class="login-form form" [formGroup]="resetForm" (ngSubmit)="sendPasswordResetEmail(resetForm.value)">
        <mat-form-field  color="accent" class="full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
      </form>
      <div class="login-errors" *ngIf="showError">
        <mat-error>{{errorMessage}}</mat-error>
      </div>
      <div class="login-errors" *ngIf="showSuccess">
        <h4>{{successMessage}}</h4>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="accent" (click)="sendPasswordResetEmail(resetForm.value)" class="button-with-spinner">
        <div class="button-content">
          <h4>Send Reset Token</h4>
          <span *ngIf="loading" class="login-spinner-container"><mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2" color="accent"></mat-progress-spinner>
        </span>
        </div>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
