import {Component, Inject} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {doesNotMatch} from "assert";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {UserManagementService} from "../../../../../services/user/management/user-management.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


export interface NewSubmissionUserDialogData {
  firstName?: string;
  lastName?: string;
  email?: string;
  informUser?: boolean;
  users: Array<UserProfileDto>
}


@Component({
  selector: 'new-submission-user',
  templateUrl: 'new-submission-user-dialog.component.html',
  styleUrls: ['new-submission-user-dialog.component.scss'],
})
export class NewSubmissionUserDialogComponent {
  public newUserForm: UntypedFormGroup = new UntypedFormGroup({
    "firstName": new UntypedFormControl(this.data.firstName, [Validators.required]),
    "lastName": new UntypedFormControl(this.data.lastName, [Validators.required]),
    "email": new UntypedFormControl(this.data.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    "informUser": new UntypedFormControl(this.data.informUser)
  });
  constructor(public dialogRef: MatDialogRef<NewSubmissionUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NewSubmissionUserDialogData,
              private userManagementService: UserManagementService) {}

  onNoClick() {
    this.dialogRef.close();
  }

  isValidEmail() {
    const found = this.data.users.find( v => v.email == this.newUserForm.get('email')?.value)
    const errors = this.newUserForm.controls['email'].errors ?? {}
    if (found !== undefined) {
      errors['taken'] = true;
    } else {
      if (this.newUserForm.controls['email'].hasError('taken')) {
        delete errors['taken']
      }
    }

    if (Object.keys(errors).length == 0) {
      this.newUserForm.controls['email'].setErrors(null);
    }
    else{
      this.newUserForm.controls['email'].setErrors(errors);
    }


  }
}
