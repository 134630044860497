<div class="selection-section-header">
  <h4>Locations</h4>
  <button (click)="reset()" class="reset-button" mat-icon-button><mat-icon>restart_alt</mat-icon></button>
</div>

<mat-form-field color="accent" class="full-width">
  <mat-label>Location</mat-label>
  <mat-chip-grid #locationChipGrid aria-label="Photographer selection" >
    <mat-chip-row *ngFor="let location of selectedLocations" (removed)="removeLocation(location)">
      {{location}}
      <button matChipRemove [attr.aria-label]="'remove ' + location">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="Location" #locationInput [formControl]="locationControl"
         [matChipInputFor]="locationChipGrid" [matAutocomplete]="auto"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         (matChipInputTokenEnd)="addLocation($event)"/>
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selectedLocation($event)">
    <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
      {{location}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
