import {Component, Inject, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../../models/dto/encounter/encounterDto";
import {DateService} from "../../../../../../../services/utilities/date.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface EncounterDeletionDialogData {
  encounter: EncounterDto,
  message?: string;

}

@Component({
  selector: 'app-delete-encounter-dialog',
  templateUrl: './delete-encounter-dialog.component.html',
  styleUrls: ['./delete-encounter-dialog.component.scss']
})
export class DeleteEncounterDialogComponent implements OnInit {

  public deletionVerificationKey?: string;
  public keyMatch: boolean = false;
  public keyInput?: string;
  constructor(
    public dialogRef: MatDialogRef<DeleteEncounterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EncounterDeletionDialogData,
    private dateService:DateService
  ) { }

  ngOnInit(): void {
    const datePart = this.dateService.formatDateFromAny(this.data.encounter.dateTime, false);
    const userPart = `${this.data.encounter.user!.firstName}-${this.data.encounter.user!.lastName}`;
    const locationPart = this.data.encounter.location!.name;
    this.deletionVerificationKey = `${datePart}-${userPart}-${locationPart}`.replace(" ", "");
  }

  public keysMatch() {
    this.keyMatch = this.deletionVerificationKey === this.keyInput;
  }



  onNoClick(): void {
    this.dialogRef.close();
  }

}
