import {environment} from "../../../environments/environment";
import {ElectronLoggingService} from "../../../services/logging/logging.service.electron";
import {WebLoggingService} from "../../../services/logging/logging.service.web";
import {LocalLoggingService} from "../../../services/logging/logging.service.local";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";

export let loggingServiceFactory = (snackBar: MatSnackBar, httpClient: HttpClient) => {
  if (environment.production && environment.electron) {
    return new ElectronLoggingService(snackBar, httpClient)
  } else if ((environment.production && !environment.electron) || environment.server.debugMode) {
    return new WebLoggingService(snackBar, httpClient)
  } else {
    return new LocalLoggingService(snackBar, httpClient)
  }
}
