import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {NotificationDto} from "../../models/dto/notification/notificationDto";
import {UserProfileDto} from "../../models/dto/user/userProfileDto";
import {UuidService} from "../utilities/uuid.service";
import {environment} from "../../environments/environment";
import {PopulationRoleDto} from "../../models/dto/population/populationRoleDto";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private uuid: UuidService
  ) { }

  private generateUser(): UserProfileDto {
    const firstNames = ["John", "Glen", "Sarah", "Prudence"];
    const lastNames = ["Constantine", "Smith", "Price", "Mueller"]
    return {
      id: "2131243124",
      email: "alexander.barnhill@fau.de",
      firstName: firstNames[Math.floor(Math.random()*firstNames.length)],
      lastName: lastNames[Math.floor(Math.random()*lastNames.length)]
    }
  }

  public getUserNotifications(): Observable<Array<NotificationDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.notifications.all}`;
    return this.http.get<Array<NotificationDto>>(url);
  }

  public generateDummyNotification(generatingUser: boolean): NotificationDto {
    const links = ["encounters", "individuals", "submit"];
    const messageFull = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis libero tellus, ac congue dolor sagittis a. Nunc in nisi sit amet erat molestie fermentum et sed lectus. Maecenas porttitor scelerisque arcu"
    const subjectFull = "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    return {
      id: this.uuid.generate(),
      link: `${links[Math.floor(Math.random()*links.length)]}`,
      content: messageFull.slice(0, Math.floor(Math.random()*messageFull.length)),
      subject: subjectFull.slice(0, Math.floor(Math.random()*subjectFull.length)),
      read: Math.random() < 0.5,
      targetUser: this.generateUser(),
      generatingUser: generatingUser ? this.generateUser() : undefined,
      date: new Date()
    }
  }

  public getDummyNotifications(count=10): Observable<Array<NotificationDto>> {
    const notifications = new Array<NotificationDto>();
    for (let i = 0; i < count; i++) {
      notifications.push(this.generateDummyNotification(Math.random() < 0.1))
    }
    return of(notifications)
  }

  markNotificationAsRead(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.notifications.all}/read/${id}`;
    return this.http.get(url);
  }

  notifyEncounterStatusChange(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.notifications.all}/encounters/update/${id}`;
    return this.http.get(url);
  }
}
