<h2>Encounter Notes</h2>
<div class="notes-container" *ngIf="notes !== undefined">
  <ckeditor [editor]="Editor" data=""  [tagName]="'editor'" (change)="log($event)"></ckeditor>

</div>

<!--<mat-form-field class="full-width" color="accent">-->
<!--  <mat-label>Notes...</mat-label>-->
<!--  <textarea [(ngModel)]="notes" matInput placeholder="Anything of note to mention?" (ngModelChange)="logOriginal()"></textarea>-->
<!--</mat-form-field>-->
