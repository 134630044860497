import {Component, OnInit} from '@angular/core';
import {ApiKeyDto} from "../../../../../models/dto/apiKey/apiKeyDto";
import {A} from "@angular/cdk/keycodes";
import {ApiService} from "../../../../../services/api/api.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {InvitationDto} from "../../../../../models/dto/invitations/invitationDto";
import {HttpErrorResponse} from "@angular/common/http";
import {DateService} from "../../../../../services/utilities/date.service";
import {MatDialog} from "@angular/material/dialog";
import {NewKeyComponent} from "./new-key/new-key.component";

@Component({
  selector: 'app-api-key-administration',
  templateUrl: './api-key-administration.component.html',
  styleUrls: ['./api-key-administration.component.scss']
})
export class ApiKeyAdministrationComponent implements OnInit {

  public keys?: Array<ApiKeyDto> = new Array<ApiKeyDto>();

  constructor(
    private apiService: ApiService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService,
    public dateService: DateService,
    public dialog: MatDialog
  ) {

  }

  public loading = false;
  public displayedColumns = ["creationDate", "expirationDate", "key", "user", "email", "uses", "lastUse", "valid", "invalidate", "delete"]

  ngOnInit() {
    this.loading = true;
    this.apiService
      .getKeys()
      .subscribe({
        next: (value: Array<ApiKeyDto>) => {
          this.keys = value;
          this.loading = false;
        },
        error: (value: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Fetching Keys", value);
          this.loading = false;
        }

      })
  }

  addKey() {
    const ref = this.dialog.open(NewKeyComponent, {});
    ref.afterClosed().subscribe(res => {
      if (res) {
        this.apiService.generateKey(res).subscribe({
          next: (value) => {
            this.log.info(`Key generated.`);
            this.ngOnInit();

          },
          error: (value: HttpErrorResponse) => {
            this.errorHandler.handleRequestError("Generating Key" ,value);
          }
        })
      }
    })
  }

  delete(key: ApiKeyDto) {
    this.apiService.deleteKey(key.id!).subscribe({
      next: (value: any) => {
        this.log.info(`Deleted key for ${key.assignedUser?.firstName} ${key.assignedUser?.lastName}`)
        this.ngOnInit();
      },
      error: (value: any) => {
        this.errorHandler.handleRequestError(`Deleting key for ${key.assignedUser?.firstName} ${key.assignedUser?.lastName}`, value)
        this.ngOnInit();
      }
    })
  }

  invalidate(key: ApiKeyDto) {
    this.apiService.invalidateKey(key.id!).subscribe({
      next: (value: any) => {
        this.log.info(`Invalidated key for ${key.assignedUser?.firstName} ${key.assignedUser?.lastName}`)
        this.ngOnInit();
      },
      error: (value: any) => {
        this.errorHandler.handleRequestError(`Invalidating key for ${key.assignedUser?.firstName} ${key.assignedUser?.lastName}`, value)
        this.ngOnInit();
      }
    })
  }
}
