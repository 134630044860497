import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageAnnotation} from "../../../../../models/annotation/image/annotation.model";
import {AnnotatedImage} from "../../../../../models/items/files/images/annotated-images/annotated-image.model";
import {SafeUrl} from "@angular/platform-browser";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {AnimalImageDto} from "../../../../../models/dto/animal/animalImageDto";
import {ExtractedImage} from "../../../../../models/dto/inference/verificationTaskDto";
import {Observable} from "rxjs";
import {ImageService} from "../../../../../services/utilities/image.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";

export interface AnnotationProfileUpdateData {
  image: AnnotatedImage;
  annotation: ImageAnnotation
  side?: string
  animalImage?: AnimalImageDto
}
@Component({
  selector: 'app-annotation-profile',
  templateUrl: './annotation-profile.component.html',
  styleUrls: ['./annotation-profile.component.scss']
})
export class AnnotationProfileComponent implements OnInit, AfterViewInit {

  public options = [
    {
      value: "left",
      display: "Left"
    },
    {
      value: "right",
      display: "Right"
    }
  ]


  public img?: AnimalImageDto;
  public imgObs?: Observable<ExtractedImage>;
  public extractedUri?: string;
  constructor(
    public dialogRef: MatDialogRef<AnnotationProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnnotationProfileUpdateData,
    private animalService: AnimalService,
    private imageService: ImageService,
    private log: ILoggingService
  ) {}

  ngOnInit() {
    this.animalService.getAnimalImageBlobFromAnnotation(this.data.annotation.id, "tmb")
      .subscribe(
        {
          next: (value) => {
            this.extractedUri = value.uri;
            this.data.animalImage = value.animalImage;
          },
          error: (err: HttpErrorResponse) => {
            this.log.error(`Could not get image: ${err.message}`)
          }
        }
      )

  }

  ngAfterViewInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  maybePrefix(s: string) {
    return this.imageService.maybePrefix(s);
  }
}
