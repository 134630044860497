import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {Observable} from "rxjs";
import {UserImageDto} from "../../../models/dto/user/userImageDto";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private client: HttpClient
  ) { }

  public getProfilePicture(user: UserProfileDto | undefined): Observable<UserImageDto> {
    let url = `${environment.server.baseUrl}${environment.server.api.profile.image}`;
    if (user) {
      url = `${url}/${user.id}`;
    }
    return this.client.get<UserImageDto>(url);
  }

  public setProfilePicture(userImage: UserImageDto): Observable<any> {
    let url = `${environment.server.baseUrl}${environment.server.api.profile.image}`;
    return this.client.post<any>(url, userImage);
  }

  public updateProfilePicture(userImage: UserImageDto): Observable<any> {
    let url = `${environment.server.baseUrl}${environment.server.api.profile.image}`;
    return this.client.put<any>(url, userImage);
  }

  public deleteProfilePicture(imageId: string): Observable<any> {
    let url = `${environment.server.baseUrl}${environment.server.api.profile.image}/${imageId}`;
    return this.client.delete<any>(url);
  }


}
