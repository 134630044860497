import {Component, Input, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../models/dto/encounter/encounterDto";
import {WorkspaceDto} from "../../../../../../models/dto/workspace/workspaceDto";
import {EncountersService} from "../../../../../../services/encounters/encounters.service";
import {EncounterCreationRequestDto} from "../../../../../../models/dto/encounter/encounterCreationRequestDto";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../../../services/error/error-handler.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {ChangeEvent} from "@ckeditor/ckeditor5-angular";


@Component({
  selector: 'app-encounter-notes',
  templateUrl: './encounter-notes.component.html',
  styleUrls: ['./encounter-notes.component.scss']
})
export class EncounterNotesComponent implements OnInit {
  @Input() encounter: EncounterDto | undefined;
  @Input() workspace: WorkspaceDto | undefined;
  @Input() title = "Notes";
  @Input() internal: boolean = false;

  public editing = false;
  public canEdit = false;
  public canView = false;

  public Editor = ClassicEditor;
  public content: string | undefined;

  constructor(
    private encounterService: EncountersService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService
    ) { }

  ngOnInit(): void {
    if (this.encounter === undefined || this.workspace?.settings?.populationRoles === undefined) return;
    this.setRights();
    if (this.internal) {
      this.content = this.encounter.internalNotes;
    } else {
      this.content = this.encounter.description;
    }


  }
  private setRights() {
    if (this.workspace!.settings!.user?.id == this.encounter!.user?.id) {
      this.canEdit = true;
      this.canView = true;
    } else if (this.workspace?.settings?.populationRoles?.administrator || this.workspace?.settings?.populationRoles?.professional) {
      this.canView = true;
      this.canEdit = true;
    }
  }
  update($event: ChangeEvent<ClassicEditor>) {
    this.content = $event.editor.data.get();
  }

  saveNotes() {
    let dto: EncounterCreationRequestDto = {
      id: this.encounter?.id
    }
    if (this.internal) {
      dto.internalNotes = this.content;
      dto.analystId = this.workspace?.settings?.user?.id;

    } else {
      dto.description = this.content;
    }

    this.encounterService.updateEncounter(dto).subscribe({
      next:  (value: any) => {
        this.log.info("Updated encounter notes")
        this.editing = false;
        if (this.internal) {
          this.encounter!.internalNotes = this.content;
        } else {
          this.encounter!.description = this.content
        }

      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Updating Notes", value);
        this.editing = false;
      }
    })
  }
}
