import {PopulationDto} from "./populationDto";
import {Location} from "../../location/location.model";
import {UserProfileDto} from "../user/userProfileDto";
import {StatusChangeDto} from "../status/statusChangeDto";

export class PopulationCreationApplicationDto implements PopulationDto {
  location?: Location;
  displayName?: string;
  speciesName?: string;
  authorityA?: UserProfileDto;
  authorityB?: UserProfileDto;
  applicant?: UserProfileDto;
  message?: string;
  comments?: string;

  status?: StatusChangeDto;
  id?: string;
  constructor() {
    this.location = {latitude: undefined, longitude: undefined, name: undefined}
    this.authorityA = {firstName: undefined, lastName: undefined, email: undefined}
    this.authorityB = {firstName: undefined, lastName: undefined, email: undefined}
    this.message =  "";
  }
}

export interface PopulationCreationApplicationUpdate {
  applicationId: string;
  status?: string;
  comments?: string;
}
