import {Component, Inject, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../../models/dto/population/populationDto";
import {ImageService} from "../../../../../../services/utilities/image.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";



@Component({
  selector: 'app-population-banner-update-dialog',
  templateUrl: './population-banner-update-dialog.component.html',
  styleUrls: ['./population-banner-update-dialog.component.scss']
})
export class PopulationBannerUpdateDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopulationBannerUpdateDialogComponent>,
    public imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public data: PopulationDto,

  ) {}

  addProfileImage(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then( res => {
      this.data.banner = res as string;

    })
  }


  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
