<div class="commentElement">
  <div class="user-overlay shader">
    <div class="comment-header" *ngIf="!deleted">
      <div class="user-profile-image default" *ngIf="!commentElement.userImage">
        <svg width="45" height="45" [data-jdenticon-value]="getJdenticon()"></svg>
      </div>
      <div class="user-profile-image" *ngIf="commentElement.userImage">
        <img [src]="imageService.maybePrefix(commentElement.userImage)" [alt]="commentElement.userName">
      </div>
      <h3><span *ngIf="commentElement.userName">{{commentElement.userName}}</span></h3>
    </div>
    <div class="comment-sub-header" *ngIf="!deleted">
      <div class="datetime"><h4><span> {{getTimeString(commentElement!.comment!.postDateTime!)}}</span></h4></div>
      <div class="comment-actions">
        <button mat-button *ngIf="isLoggedUserComment"  color="accent" class="profile-edit-button" (click)="deleteComment()"><mat-icon>delete</mat-icon></button>
        <button mat-button *ngIf="isLoggedUserComment"  color="accent" class="profile-edit-button" (click)="openUpdater()"><mat-icon>edit</mat-icon></button>
        <button mat-button color="accent" class="profile-edit-button" (click)="openEditor()"><mat-icon>reply</mat-icon></button>
      </div>

    </div>

    <div class="user-title">

      <div class="comment-action-line">

        <div class="comment-actions">
          <!--<button mat-stroked-button color="accent" (click)="">Reply</button>-->
          <!-- like dislike comment follow ...-->
          <!--<div [innerHTML]="population.description"></div>-->

        </div>
      </div>
    </div>
    <!-- Time When Posted ?-->
    <div class="comment-content" *ngIf="!updating">
      <div [innerHTML]="commentElement.comment!.content!"></div>
    </div>

    <div class="comment-edit" *ngIf="editing">
      <ckeditor [editor]="Editor" [data]="EditorInit" (change)="log($event)" [tagName]="'editor'"></ckeditor>
      <div class="button-row">
        <button mat-icon-button color="accent" (click)="addComment()"><mat-icon>send</mat-icon></button>
        <button mat-icon-button color="accent" (click)="closeEditor()"><mat-icon>cancel</mat-icon></button>
      </div>

    </div>
    <div class="comment-update" *ngIf="updating">
      <ckeditor [editor]="Editor" [data]="commentElement.comment!.content!" (change)="log($event)" [tagName]="'editor'"></ckeditor>
      <div class="button-row">
        <button mat-icon-button color="accent" (click)="updateComment()"><mat-icon>done</mat-icon></button>
        <button mat-icon-button color="accent" (click)="closeUpdater()"><mat-icon>cancel</mat-icon></button>
      </div>

    </div>

  </div>
  <div class="Responses" *ngFor="let comment of commentElement.responses">
    <app-comment-template [commentElement]="comment" [layer]="nextlayer" [commentSection]="commentSection" [currentUserID]="currentUserID" [currentUserImage]="currentUserImage" (reload)="reload.emit()"></app-comment-template>
  </div>
</div>
<mat-divider></mat-divider>
<!-- Recursive function. -->


