<div class="content-container">
  <h3 [matTooltipPosition]="'before'" matTooltip="An organization lets you submit your data to a specific organization's collection">Choose an organization</h3>
  <mat-form-field *ngIf="organizations; else loading" color="accent"  class="full-width">
    <mat-label>Choose organizations </mat-label>
    <mat-select [formControl]="selectedOrganizations" multiple (valueChange)="announceUpdates()">
      <mat-option *ngFor="let organization of removePublic(organizations)" [value]="organization.id">{{organization.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="organization-creation">
    <h4>Don't see your organization?</h4>
    <button mat-stroked-button (click)="createOrganization()" class="full-width">Join or Create New Organization</button>
  </div>
  <ng-template #loading>
    <h4>No organizations found. <a [routerLink]="'/organization'">Create a new one if you want</a>, otherwise the encounter will be public.</h4>
  </ng-template>

</div>
