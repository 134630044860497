<div mat-dialog-content class="content-container">
  <div class="content" *ngIf="data.encounter">
    <div class="note">
      <h4>Shifting data from {{this.encounterService.getEncounterName(data.encounter!)}} to <span *ngIf="!mergeTarget">...</span><span *ngIf="mergeTarget">{{encounterService.getEncounterName(mergeTarget)}}</span></h4>
    </div>
    <mat-form-field color="accent" *ngIf="mergeTargets" class="full-width">
      <mat-label>Merge Target</mat-label>
      <input type="text" matInput [formControl]="encounterControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setTarget($event)">
        <mat-option *ngFor="let option of filteredEncounters | async" [value]="option">
          {{this.encounterService.getEncounterName(option!)}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </div>
</div>

<div mat-dialog-actions class="button-row">
  <button mat-stroked-button color="accent" (click)="merge()" [disabled]="mergeTarget === undefined">Merge</button>
  <button mat-stroked-button color="accent" (click)="onNoClick()" >Cancel</button>

</div>

<app-area-loading [criteria]="!merging" title="Merging Encounters..."></app-area-loading>
