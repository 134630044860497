import { Component, OnInit } from '@angular/core';
import {OrganizationDto} from "../../../models/dto/user/organization/organizationDto";
import {mergeMap, Observable, of, pipe} from "rxjs";
import {UserOrganizationService} from "../../../services/user/organization/user-organization.service";
import {UserManagementService} from "../../../services/user/management/user-management.service";

import {UserContentService} from "../../../services/user/content/user-content.service";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../services/error/error-handler.service";
import {PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {DateService} from "../../../services/utilities/date.service";
import {AuthenticationService} from "../../../services/user/authentication.service";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  public organizations: Observable<Array<OrganizationDto>> | undefined;
  public populations: Observable<Array<PopulationDto>> | undefined;
  public populationMap: Map<string, boolean> = new Map<string, boolean>();
  public admin = false;

  constructor(
    private organizationService: UserOrganizationService,
    private populationService: PopulationService,
    private userService: UserManagementService,
    private userContentService: UserContentService,
    private logService: ILoggingService,
    private errorHandler: ErrorHandlerService,
    private dateService: DateService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.populations = this.populationService.getUserPopulations();
    this.organizations = this.organizationService.getUserOrganizations();
    this.admin = this.authService.isUserAdmin()
  }



  getDateString(memberSince: Date) {
    return this.dateService.formatDateFromAny(memberSince);
  }


  loadPopulation(population: PopulationDto) {
    this.populationMap.set(population.id!, true);
  }
}
