import {Component, Input, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {Observable} from "rxjs";

@Component({
  selector: 'app-population-administration',
  templateUrl: './population-administration.component.html',
  styleUrls: ['./population-administration.component.scss']
})
export class PopulationAdministrationComponent implements OnInit {

  @Input() populations: Observable<Array<PopulationDto>> | undefined;
  constructor() { }

  ngOnInit(): void {
  }

  getLink(population: PopulationDto) {
    return `/administration/populations/${population.id}`;
  }

  joinPopulation() {

  }
}
