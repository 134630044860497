import {Component, Input, OnInit} from '@angular/core';
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnInit {
  @Input() user: UserProfileDto | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
