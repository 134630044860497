<div class="content-container" *ngIf="organization">
  <div class="content-header">
    <h1 [routerLink]="getOrgLink(organization)">{{organization.name}}</h1>
    <div class="danger-buttons">
      <button mat-stroked-button color="warn" (click)="leaveOrganization()">Resign from {{organization.name}}</button>
      <button mat-stroked-button color="warn" (click)="deleteOrganization()">Delete {{organization.name}}</button>
    </div>
  </div>

  <div class="content">
    <app-organization-members-component [organization]="organization" [membersObs]="members"></app-organization-members-component>
    <app-encounter-timeline [organizationId]="organization.id"></app-encounter-timeline>

  </div>
</div>
