import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {map, Observable, startWith} from "rxjs";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import { AnimalDto } from 'src/models/dto/animal/animalDto';
import {LocationService} from "../../../../../services/location/location.service";
import {PopulationService} from "../../../../../services/population/population.service";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {EncounterPageRequestDto} from "../../../../../models/dto/encounter/encounterPageRequestDto";

@Component({
  selector: 'app-individual-selection',
  templateUrl: './individual-selection.component.html',
  styleUrls: ['./individual-selection.component.scss']
})
export class IndividualSelectionComponent implements OnInit, OnChanges {
  @Input() workspace: WorkspaceDto | undefined;
  @Input() population: PopulationDto | undefined;
  @Input() animals: Array<AnimalDto> | undefined;
  @Input() request: EncounterPageRequestDto | undefined;

  @Output() chosenAnimals: EventEmitter<Array<AnimalDto>> = new EventEmitter<Array<AnimalDto>>();

  @ViewChild('individualInput') individualInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  individualControl = new FormControl();
  public filteredAnimals: Observable<string[]> | undefined;
  public selectedAnimals: string[] = new Array<string>();

  constructor(
    private locationService: LocationService,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public reset() {
    this.individualControl.reset();
    this.selectedAnimals = new Array<string>();
    this.chosenAnimals.emit(this.getIndividuals());
  }

  ngOnInit() {

    if (this.animals) {
      this.filteredAnimals = this.individualControl.valueChanges.pipe(
        startWith(null),
        map((animal: string | null) => (animal ? this._filterAnimals(animal) : this.animals!.map(a => a.identifier).slice())),
      );
    }

    if (this.request && this.request.individuals && this.animals) {

      const animals = this.animals.filter(a => this.request?.individuals?.includes(a.id));
      for (let a of animals) {
        if (this.selectedAnimals.indexOf(a.identifier!) == -1) {
          this.selectedAnimals.push(a.identifier!);
        }
      }
      this.chosenAnimals.emit(this.getIndividuals());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.request && this.request.individuals && this.animals) {

      const animals = this.animals.filter(a => this.request?.individuals?.includes(a.id));
      for (let a of animals) {
        if (this.selectedAnimals.indexOf(a.identifier!) == -1) {
          this.selectedAnimals.push(a.identifier!);
        }
      }
      this.chosenAnimals.emit(this.getIndividuals());
    }
    if (changes["animals"] && changes["animals"]["currentValue"]) {
      this.animals = changes["animals"]["currentValue"]
      this.filteredAnimals = this.individualControl.valueChanges.pipe(
        startWith(null),
        map((animal: string | null) => (animal ? this._filterAnimals(animal) : this.animals!.map(a => a.identifier).slice())),
      );
    }
  }

  private _filterAnimals(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.animals!.map(a => a.identifier).filter(animal => animal.toLowerCase().includes(filterValue));
  }

  addIndividual(event: MatChipInputEvent): void {
    const value = event.value;
    if (value) {
      this.selectedAnimals.push(value);
    }
    event.chipInput!.clear();
    this.individualControl.setValue(null);
    this.chosenAnimals.emit(this.getIndividuals());
  }





  removeIndividual(animal: string): void {
    const index = this.selectedAnimals.indexOf(animal);

    if (index >= 0) {
      this.selectedAnimals.splice(index, 1);
    }
    this.chosenAnimals.emit(this.getIndividuals());
  }

  private getIndividuals() {
    return this.animals!.filter(a => this.selectedAnimals.indexOf(a.identifier) !== -1);
  }



  selectedIndividual(event: MatAutocompleteSelectedEvent): void {
    this.selectedAnimals.push(event.option.value);
    this.individualInput.nativeElement.value = '';
    this.individualControl.setValue(null);
    this.chosenAnimals.emit(this.getIndividuals());
  }

}
