import {Component, Input, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../models/dto/encounter/encounterDto";
import {WorkspaceDto} from "../../../../../../models/dto/workspace/workspaceDto";

@Component({
  selector: 'app-encounter-predation',
  templateUrl: './encounter-predation.component.html',
  styleUrls: ['./encounter-predation.component.scss']
})
export class EncounterPredationComponent implements OnInit {
  @Input() encounter?: EncounterDto
  @Input() workspace?: WorkspaceDto

  ngOnInit(): void {
  }
}
