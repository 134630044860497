import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Location} from "../../../../models/location/location.model";
import {SubmissionLocationType} from "../submit-data.component";
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {LocationService} from "../../../../services/location/location.service";
import {UntypedFormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-submission-location',
  templateUrl: './submission-location.component.html',
  styleUrls: ['./submission-location.component.scss']
})
export class SubmissionLocationComponent implements OnInit, OnChanges {
  @Input() population: PopulationDto | undefined;
  @Input() location: Location | undefined;
  @Output() locationUpdated: EventEmitter<Location> = new EventEmitter<Location>();
  public locationName = new UntypedFormControl("", [Validators.required]);
  public locations: Array<Location> | undefined;
  public selectedLocation: Location | undefined;
  public selectedLocationSubmissionType: SubmissionLocationType | undefined;
  public useKnownLocation = false;
  public initialLocation: Location | undefined;
  pinSelection: boolean = false;
  knownSelection: boolean = false;
  coordinatesSelection = false;

  public locationFetched = false;

  public initialZoom = 7;
  public minZoom = 4;
  public maxZoom = 16;

  selectionMethod = 'pin';

  public defaultLatitude: number | undefined;
  public defaultLongitude: number | undefined;
  public useLocation: boolean = false;

  constructor(
    private locationService: LocationService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["population"] !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    if (this.population !== undefined && this.population.id !== undefined && this.location === undefined) {
      this.initializeLocationForPopulation()
    } else if (this.location !== undefined && this.location.name !== undefined && this.location.latitude !== undefined && this.location.longitude !== undefined) {
      this.selectedLocation = this.location;
      this.locationName.setValue(this.location.name);
      this.locationFetched = true;
      this.initialLocation = this.location;

    } else if (this.location !== undefined && this.location.name === undefined && this.location.latitude !== undefined && this.location.longitude !== undefined) {
      // @ts-ignore
      this.selectedLocation = location;
    }
    this.locationName.valueChanges.subscribe(res => {
      this.selectedLocation!.name = res;
    })
    this.locationService.getLocationsFromServer()
      .subscribe( (locationResult: Array<Location>) => {
        this.locations = locationResult;
      })
  }

  sortLocations(locations: Array<Location>) {
    return locations.sort((a, b) => a.name!.localeCompare(b.name!))
  }

  toggleLocationSelectionCriteria(name: string) {
    this.pinSelection = false;
    this.knownSelection = false;
    this.coordinatesSelection = false;
    if (name === "pin") {
      this.pinSelection = true;
    } else if (name === "known") {
      this.knownSelection = true;
    } else {
      this.coordinatesSelection = true;
    }

  }

  setLocationSubmissionType($event: any) {
    this.selectedLocationSubmissionType = $event.value;
  }

  dec2deg(dec: number) {
    const components = (dec + "").split(".");
    const degree = parseInt(components[0])
    const minutes = parseFloat("0." + components[1]) * 60;
    return [degree, minutes];
  }

  deg2dec(deg: number, min: number) {
    const decimal = min / 60;
    const d = (deg + "") + "." + (decimal + "").split(".")[1]
    return parseFloat(d);

  }

  initializeLocationForPopulation() {
    if (this.population !== undefined) {
      if (this.population.center == undefined) {
        this.locationService
          .getLocationById(this.population.locationId!)
          .subscribe( res => {
            this.population!.center = res;
            this.defaultLongitude = this.population!.center.longitude;
            this.defaultLatitude = this.population!.center.latitude;
            this.locationFetched = true;

            this.selectedLocation = {
              latitude: this.defaultLatitude,
              longitude: this.defaultLongitude,
              lonMin: this.dec2deg(this.defaultLongitude!)[1],
              lonDeg: this.dec2deg(this.defaultLongitude!)[0],
              latMin: this.dec2deg(this.defaultLatitude!)[1],
              latDeg: this.dec2deg(this.defaultLatitude!)[0],
            }
            // this.selectedLocation = this.population?.center;
            // this.initialLocation = this.selectedLocation;
          })
      } else {
        this.locationFetched = true;
        this.defaultLongitude = this.population.center.longitude;
        this.defaultLatitude = this.population.center.latitude;
        this.selectedLocation = {
          latitude: this.defaultLatitude,
          longitude: this.defaultLongitude,
          lonMin: this.dec2deg(this.defaultLongitude!)[1],
          lonDeg: this.dec2deg(this.defaultLongitude!)[0],
          latMin: this.dec2deg(this.defaultLatitude!)[1],
          latDeg: this.dec2deg(this.defaultLatitude!)[0],
        }
        // this.selectedLocation = this.population?.center;
        // this.initialLocation = this.selectedLocation;
      }
    }
  }

  selectKnownLocation($event: any) {
    this.selectedLocation = $event.value;
    this.locationName.setValue($event.value.name);
    this.report();
  }

  addLocation(latlng: {lat: number, lng: number}) {
    this.useKnownLocation = false;
    this.locationName.setValue(this.selectedLocation?.name);
    this.selectedLocation = {
      longitude: latlng.lng,
      latitude: latlng.lat,
      name: this.selectedLocation?.name,
      lonMin: this.dec2deg(latlng.lng)[1],
      lonDeg: this.dec2deg(latlng.lng)[0],
      latMin: this.dec2deg(latlng.lat)[1],
      latDeg: this.dec2deg(latlng.lat)[0],
    }
    this.report();
  }

  removeLocation(latlng: {lat: number, lng: number}) {
    this.selectedLocation = undefined;
    this.locationName.setValue("");
    // this.report();
  }

  updateLocation() {
    const lat = this.selectedLocation?.latitude;
    const lng = this.selectedLocation?.longitude;
    const name = this.locationName.value;
    this.selectedLocation = {
      latitude: lat,
      longitude: lng,
      name: name,
      lonMin: this.selectedLocation?.lonMin,
      lonDeg: this.selectedLocation?.lonDeg,
      latMin: this.selectedLocation?.latMin,
      latDeg: this.selectedLocation?.latDeg
    }

    this.report()
    this.useLocation = true;
  }

  report() {
    if (this.selectedLocation === undefined) return;
    this.selectedLocation = {
      latitude: this.selectedLocation?.latitude,
      longitude: this.selectedLocation?.longitude,
      name: this.selectedLocation!.name ? this.selectedLocation!.name : this.locationName.value,
      lonDeg: this.selectedLocation.lonDeg,
      lonMin: this.selectedLocation.lonMin,
      latDeg: this.selectedLocation.latDeg,
      latMin: this.selectedLocation.latMin
    }
    this.locationUpdated.emit(this.selectedLocation)
  }

  public reset() {
    this.selectedLocation = this.initialLocation;
    this.report();
  }

  updateLocationDegMin() {
    this.selectedLocation!.latitude = this.deg2dec(this.selectedLocation?.latDeg!, this.selectedLocation?.latMin!)
    this.selectedLocation!.longitude = this.deg2dec(this.selectedLocation?.lonDeg!, this.selectedLocation?.lonMin!);
    this.updateLocation();
  }
}
