<div class="profile-management-container" *ngIf="profile !== undefined">
  <div class="profile-details">
    <div class="profile-section-head">
      <h2>Details</h2>
    </div>
    <div class="profile-section-content">
      <!--      <div class="profile-section-entry" (click)="toggleEdit('nickname')">-->
      <!--        <div class="profile-section-entry-key">-->
      <!--          <h4 [ngClass]="{'editable-line': canUserChange}">Nickname</h4>-->
      <!--        </div>-->
      <!--        <div class="profile-section-entry-value">-->
      <!--          <span *ngIf="profile!.nickname">{{profile!.nickname}}</span>-->
      <!--          <span *ngIf="!profile.nickname">Unknown</span>-->
      <!--        </div>-->
      <!--        <div class="break"></div>-->
      <!--        <mat-form-field class="profile-section-entry-edit" *ngIf="profileDetailEdit['nickname']">-->
      <!--          <input matInput placeholder="Sparky..." [(ngModel)]="profile.nickname" (focusout)="leaveEdit()">-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
      <div class="profile-section-entry">
        <div class="profile-section-entry-key">
          <span [ngClass]="{'editable-line': canUserChange}">Sex</span>
        </div>
        <div class="profile-section-entry-value">
          <span *ngIf="profile!.sex">{{profile!.sex}}</span>
          <span *ngIf="!profile.sex">Unknown</span>
        </div>
        <div class="break"></div>

        <mat-radio-group aria-label="Select an option" *ngIf="profileDetailEdit['sex']" [(ngModel)]="profile.sex">
          <mat-radio-button value="Male">Male</mat-radio-button>
          <mat-radio-button value="Female">Female</mat-radio-button>
        </mat-radio-group>

      </div>
      <div class="profile-section-entry">
        <div class="profile-section-entry-key">
          <span [ngClass]="{'editable-line': canUserChange}">Birth Year</span>
        </div>
        <div class="profile-section-entry-value">
          <span *ngIf="profile!.dateOfBirth">{{ displayBirthday() }}</span>
          <span *ngIf="!profile.dateOfBirth">Unknown</span>
        </div>
        <div class="break"></div>
        <app-year-selection *ngIf="profileDetailEdit['dob']" [title]="'Birth Year'" [style.width.pc]="100" (yearSelected)=updateDob($event) [startDate]="profile!.dateOfBirth!"></app-year-selection>

      </div>
      <div class="profile-section-entry">
        <div class="profile-section-entry-key" *ngIf="profile!.dateOfDeath || updatingDetails">
          <span [ngClass]="{'editable-line': canUserChange}">Max Year of Death</span>
        </div>
        <div class="profile-section-entry-value">
          <span *ngIf="profile!.dateOfDeath">{{ displayDeathday() }}</span>
        </div>
        <div class="break"></div>
        <app-year-selection *ngIf="profileDetailEdit['dod']" [title]="'Year of Death'" [style.width.pc]="100" (yearSelected)=updateDod($event) [startDate]="profile!.dateOfDeath!"></app-year-selection>

      </div>
      <div class="profile-section-entry" *ngIf="!updatingDetails">
        <div class="profile-section-entry-key not-editable">
          <span>Age</span>
        </div>
        <div class="profile-section-entry-value">
          <span *ngIf="profile!.dateOfBirth">{{getAge()}}</span>
          <span *ngIf="!profile.dateOfBirth">Unknown</span>
        </div>
        <div class="break"></div>
      </div>
    </div>
  </div>
  <div class="profile-update-population" *ngIf="updatingDetails">
    <app-submission-population [populations]="selectablePopulations"
                               [selectedPopulation]="selectedPopulation">
    </app-submission-population>
    <div class="confirm" *ngIf="selectedPopulation.value">
      <button mat-stroked-button class="full-width" (click)="updatePopulation()">Change Population</button>
    </div>
  </div>


  <div class="buttons" *ngIf="canUserChange">
    <div class="button-row" *ngIf="!updatingDetails">
      <button mat-stroked-button color="accent" class="full-width" (click)="startProfileUpdate()">Update Profile Details</button>
    </div>
    <div class="button-row-stacked" *ngIf="updatingDetails">
      <button mat-stroked-button color="accent" class="full-width" (click)="updateProfile()">Save Changes</button>
      <button mat-stroked-button color="accent" class="full-width" (click)="cancelProfileUpdate()">Cancel</button>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>

