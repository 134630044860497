import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {AnimalService} from "../../../../../../services/animal/animal.service";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";
import {AnimalProfileDto} from "../../../../../../models/dto/animal/animalProfileDto";
import {Observable} from "rxjs";
import {ExtractedImage} from "../../../../../../models/dto/inference/verificationTaskDto";
import {ImageService} from "../../../../../../services/utilities/image.service";
import {AnimalProfileImageDto} from "../../../../../../models/dto/animal/animalProfileImageDto";
import {HttpErrorResponse} from "@angular/common/http";
import {AnimalImageDto} from "../../../../../../models/dto/animal/animalImageDto";
import {DateService} from "../../../../../../services/utilities/date.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-image-select-dialog',
  templateUrl: './image-select-dialog.component.html',
  styleUrls: ['./image-select-dialog.component.scss']
})
export class ImageSelectDialogComponent implements OnInit {
  @Output() profileImageRemoved: EventEmitter<ExtractedImage> = new EventEmitter<ExtractedImage>();
  public imagesObvs: Observable<Array<ExtractedImage>> | undefined;
  public profileImages: Array<AnimalProfileImageDto> | undefined;
  public newProfileImage: AnimalProfileImageDto | undefined;
  public images: Array<AnimalImageDto> | undefined;
  public isMainSet = false;
  public hasSelected = false;
  public selectedImage: AnimalImageDto | undefined;
  constructor(public dialogRef: MatDialogRef<ImageSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { profile: AnimalProfileDto, side: string },
              private animalService: AnimalService,
              private logService: ILoggingService,
              public imageService: ImageService,
              public dateService: DateService) { }

  ngOnInit(): void {
    this.animalService
      .getSidedImages(this.data.profile.id!, this.data.side, 3)
      .subscribe({
        next: (value: Array<AnimalImageDto>) => {
          for (let v of value) {
            v.extractedImageObs = this.animalService.getExtractedImageById(v.extractedImageId);
          }
          this.images = value;
          this.dialogRef.updateSize(`${ (this.images.length * 400 ) + 80}px` )
        },
        error: (value: HttpErrorResponse) => {
          this.logService.error(`Could not get profile pictures: ${value.message}`);
        }
      })
    this.imagesObvs = this.animalService.getExtractedAnimalImages(this.data.profile.id!);

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onUpdate(): void {
    this.animalService.setSidedImage(this.selectedImage!.animalId!, this.selectedImage!.side!, this.selectedImage!.id!).subscribe(res => {
      this.logService.info(`Updated profile image for ${this.selectedImage!.animalId!} (${this.selectedImage!.side} side)`)
    })
    this.dialogRef.close();
    location.reload();
  }

  getTooltipContent(image: AnimalImageDto) {
    return `Date: ${this.dateService.formatDateFromAny(image.dateTime, false)}
    Location: ${image.location}
    Photographer: ${image.photographer}`;
  }

  select(image: AnimalImageDto) {
    if (!this.images) { return;}
    for (let i of this.images) {
      i.active = false;
    }
    image.active = true;
    this.selectedImage = image;
    this.hasSelected = true;

  }
}
