export enum SortCriteria {
  creationDate,
  uploadDate,
  photographer,
  editor,
  encounterDate
}

export enum SortDirection {
  ascending,
  descending
}
