import {Injectable} from "@angular/core";
import {LogLevel} from "../../models/logging/log-levels.enum";
import {ILoggingService} from "./logging.service.interface";
import {formatDate} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class LocalLoggingService implements ILoggingService{
  level = LogLevel.INFO;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(public snackBar: MatSnackBar, public httpClient: HttpClient) {}

  setLevel(logLevel: LogLevel) {this.level = logLevel;}

  log(msg: string, level: string, snackbar = false, content?: any): void {
    const message: string = `${formatDate(Date.now(), 'yyyy-MM-ddTHH:mm:ss', 'en-GB')} | ${level} | ${msg}`;
    if (content) {
      console.log(message, content);
    } else {
      console.log(message);
    }
    if (snackbar) {
      this.logSnackbar(message);
    }
  }

  logSnackbar(msg: string): void {
    this.snackBar.open(msg, "Got it!", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 5000
    });
  }

  info(msg: string, snackbar= false, content?: any): void {
    this.log(msg, "INFO", snackbar, content);
  }

  debug(msg: string, snackbar= false, content?: any): void {
    this.log(msg, "DEBUG", snackbar, content);
  }

  warn(msg: string, snackbar= false, content?: any): void {
    this.log(msg, "WARN", snackbar, content);
  }

  error(msg: string, snackbar= false, content?: any): void {
    this.log(msg, "ERROR", snackbar, content);
  }


}
