import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PredationTarget } from '../../../../../models/dto/animal/predationTarget';
import { ResponsiveDesignService } from '../../../../../services/design/responsive-design.service';
import { LocationService } from '../../../../../services/location/location.service';
import { SubmissionLocationType } from '../../submit-data.component';
import { Location } from "../../../../../models/location/location.model"
import { PopulationDto } from '../../../../../models/dto/population/populationDto';
import { SelectMapDialogMapComponent } from '../../../select-map/select-map-dialog/select-map-dialog-map/select-map-dialog-map.component';
export interface IPreyLocationData {
  predationTarget: PredationTarget;
  population: PopulationDto
  location: Location;
  locationUpdated: EventEmitter<Location>;
}

@Component({
  selector: 'app-submission-prey-location',
  templateUrl: './submission-prey-location.component.html',
  styleUrls: ['./submission-prey-location.component.scss']
})
export class SubmissionPreyLocationComponent implements OnInit {
  @Input() population: PopulationDto | undefined;
  @Output() locationUpdated: EventEmitter<Location> = new EventEmitter<Location>();

  public dialogRef: MatDialogRef<any> | undefined;
  public tablet = false;
  public mobile = false;

  public idx: number = 0;



  constructor(
    public dialog: MatDialog,
    private responsiveService: ResponsiveDesignService
  ) {}
  ngOnInit() {
    this.responsiveService.tablet.subscribe(res => {
      this.tablet = res;
    });
    this.responsiveService.mobile.subscribe(res => {
      this.mobile = res;
    })
  }
  public getWidth() {
    if (this.tablet || this.mobile) {
      return "100vw"
    } else {
      return "80vw";
    }
  }

  openDialog(predationTarget: PredationTarget, idx: number) {
    this.idx = idx;
    this.dialogRef = this.dialog.open(PreyLocationData, {
      data: {
        predationTarget: predationTarget,
        population: this.population,
        location: predationTarget.location,
        locationUpdated: this.locationUpdated
      },
      width: this.getWidth(),
      maxWidth: this.getWidth()
    })
  }
}

@Component({
  selector: 'app-submission-prey-location-data',
  templateUrl: './submission-prey-location-data.component.html',
  styleUrls: ['./submission-prey-location-data.component.scss']
})
export class PreyLocationData implements OnInit {
  public population: PopulationDto | undefined;
  public location: Location | undefined;
  public locationUpdated: EventEmitter<Location> = new EventEmitter<Location>();

  public tablet = false;
  public mobile = false;

  public idx: number = 0;

  public locationName = new UntypedFormControl("", [Validators.required]);
  public placeholderLocationName = "Location Name"
  public locations: Array<Location> | undefined;
  public selectedLocation: Location | undefined;
  public selectedLocationSubmissionType: SubmissionLocationType | undefined;
  public useKnownLocation = false;
  public initialLocation: Location | undefined;
  pinSelection: boolean = false;
  knownSelection: boolean = false;
  coordinatesSelection = false;

  public locationFetched = false;

  public initialZoom = 7;
  public minZoom = 4;
  public maxZoom = 16;

  selectionMethod = 'pin';

  public defaultLatitude: number | undefined;
  public defaultLongitude: number | undefined;
  public useLocation: boolean = false;

  public predationTarget: PredationTarget | undefined;

  constructor(
    public dialogRef: MatDialogRef<PreyLocationData>,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: IPreyLocationData,

  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.population = this.data.population;
    this.location = this.data.location;
    this.predationTarget = this.data.predationTarget;
    this.locationUpdated = this.data.locationUpdated;

    if (this.population !== undefined && this.population.id !== undefined && this.location === undefined) {
      this.initializeLocationForPopulation();
    }
    else {
      this.initDefaultPopulationLocation()
      this.selectedLocation = this.data.location;
      if (this.data.location.name) {
        this.placeholderLocationName = this.data.location.name;
      }
    }
  }
  onNoClick(): void {
    this.locationUpdated.emit(this.selectedLocation)
    this.dialogRef.close();
    console.log("Debug_Close")
  }


  
  addLocation(latlng: { lat: number, lng: number }) {
    console.log("DebugAddLocation")
    this.useKnownLocation = false;
    this.locationName.setValue(this.selectedLocation?.name);
    this.selectedLocation = {
      longitude: latlng.lng,
      latitude: latlng.lat,
      name: this.selectedLocation?.name,
      lonMin: this.dec2deg(latlng.lng)[1],
      lonDeg: this.dec2deg(latlng.lng)[0],
      latMin: this.dec2deg(latlng.lat)[1],
      latDeg: this.dec2deg(latlng.lat)[0],
    }
    this.report();
  }

  removeLocation(latlng: { lat: number, lng: number }) {
    this.selectedLocation = undefined;
    this.locationName.setValue("");
    // this.report();
  }
  dec2deg(dec: number) {
    const components = (dec + "").split(".");
    const degree = parseInt(components[0])
    const minutes = parseFloat("0." + components[1]) * 60;
    return [degree, minutes];
  }

  deg2dec(deg: number, min: number) {
    const decimal = min / 60;
    const d = (deg + "") + "." + (decimal + "").split(".")[1]
    return parseFloat(d);

  }
  changeLocationName1() {
    this.selectedLocation!.name = this.locationName.value;
    this.placeholderLocationName = this.locationName.value;
    this.report()
  }
  changeLocationName2() {
    this.selectedLocation!.name = this.locationName.value;
    this.placeholderLocationName = this.locationName.value;
    this.updateLocation()
  }

  report() {
    if (this.selectedLocation === undefined) return;
    this.selectedLocation = {
      latitude: this.selectedLocation?.latitude,
      longitude: this.selectedLocation?.longitude,
      name: this.selectedLocation!.name ? this.selectedLocation!.name : this.locationName.value,
      lonDeg: this.selectedLocation.lonDeg,
      lonMin: this.selectedLocation.lonMin,
      latDeg: this.selectedLocation.latDeg,
      latMin: this.selectedLocation.latMin
    }
    //his.locationUpdated.emit(this.selectedLocation)
  }

  initDefaultPopulationLocation() {
    if (this.population !== undefined) {
      if (this.population.center == undefined) {
        this.locationService
          .getLocationById(this.population.locationId!)
          .subscribe(res => {
            this.population!.center = res;
            this.defaultLongitude = this.population!.center.longitude;
            this.defaultLatitude = this.population!.center.latitude;
            this.locationFetched = true;
          })
      }
      else {
        this.locationFetched = true;
        this.defaultLongitude = this.population.center.longitude;
        this.defaultLatitude = this.population.center.latitude;
      }
    }
  }

  initializeLocationForPopulation() {
    if (this.population !== undefined) {
      if (this.population.center == undefined) {
        this.locationService
          .getLocationById(this.population.locationId!)
          .subscribe(res => {
            this.population!.center = res;
            this.defaultLongitude = this.population!.center.longitude;
            this.defaultLatitude = this.population!.center.latitude;
            this.locationFetched = true;

            this.selectedLocation = {
              latitude: this.defaultLatitude,
              longitude: this.defaultLongitude,
              lonMin: this.dec2deg(this.defaultLongitude!)[1],
              lonDeg: this.dec2deg(this.defaultLongitude!)[0],
              latMin: this.dec2deg(this.defaultLatitude!)[1],
              latDeg: this.dec2deg(this.defaultLatitude!)[0],
            }
            // this.selectedLocation = this.population?.center;
            // this.initialLocation = this.selectedLocation;
          })
      } else {
        this.locationFetched = true;
        this.defaultLongitude = this.population.center.longitude;
        this.defaultLatitude = this.population.center.latitude;
        this.selectedLocation = {
          latitude: this.defaultLatitude,
          longitude: this.defaultLongitude,
          lonMin: this.dec2deg(this.defaultLongitude!)[1],
          lonDeg: this.dec2deg(this.defaultLongitude!)[0],
          latMin: this.dec2deg(this.defaultLatitude!)[1],
          latDeg: this.dec2deg(this.defaultLatitude!)[0],
        }
        // this.selectedLocation = this.population?.center;
        // this.initialLocation = this.selectedLocation;
      }
    }
  }

  updateLocation() {
    const lat = this.selectedLocation?.latitude;
    const lng = this.selectedLocation?.longitude;
    const name = this.locationName.value;
    this.selectedLocation = {
      latitude: lat,
      longitude: lng,
      name: name,
      lonMin: this.selectedLocation?.lonMin,
      lonDeg: this.selectedLocation?.lonDeg,
      latMin: this.selectedLocation?.latMin,
      latDeg: this.selectedLocation?.latDeg
    }

    this.report()
    this.useLocation = true;
  }
  updateLocationDegMin() {
    this.selectedLocation!.latitude = this.deg2dec(this.selectedLocation?.latDeg!, this.selectedLocation?.latMin!)
    this.selectedLocation!.longitude = this.deg2dec(this.selectedLocation?.lonDeg!, this.selectedLocation?.lonMin!);
    this.updateLocation();
  }

}
