import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {IItemStorageService} from "../storage/item/item-storage.service.interface";
import {Observable} from "rxjs";
import {GeocoderResponse} from "../../models/response/geocoding/geocoder.response.model";
import {environment} from "../../environments/environment";
import {Location} from "../../models/location/location.model";
import GeocoderGeometry = google.maps.GeocoderGeometry;
import GeocoderResult = google.maps.GeocoderResult;

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private baseUrl = "https://maps.google.com/maps/api/geocode/json";
  constructor(private httpClient: HttpClient, private storageService: IItemStorageService) {}

  getLocation(location: string): Observable<GeocoderResponse> {
    const encodedLocation = encodeURIComponent(location);
    const url = `${this.baseUrl}?address=${encodedLocation}&sensor=false&key=${environment.maps.googleApiKey}`;
    return this.httpClient.get<GeocoderResponse>(url);
  }

  getLocationNameFromLatLng(latitude: number, longitude: number) {
    const url = `${this.baseUrl}?latlng=${latitude},${longitude}&language=en&key=${environment.maps.googleApiKey}`;
    return this.httpClient.get<GeocoderResponse>(url);
  }

  mapGeoCoderResponseFromReverseToName(geocoderResult: GeocoderResponse): string {
    if (geocoderResult.results.length === 0) {
      return "Unknown"
    }
    let name = "Unknown";
    for (let res of geocoderResult.results) {
      if (res.types[0].indexOf("administrative") !== -1) {
        return res.formatted_address;
      }
    }

    return name;

  }

  mapGeoCoderResponseToLocation(geocoderResult: GeocoderResponse): Array<Location> {
    const locations: Array<Location> = [];
    if (geocoderResult.results.length === 0) {
      return [];
    }

    geocoderResult.results.forEach( (value: google.maps.GeocoderResult) => {
      const geometryLocation: GeocoderGeometry | undefined = value?.geometry
      if (geometryLocation) {
        // @ts-ignore
        const lat = geometryLocation.location.lat as number;
        // @ts-ignore
        const lng = geometryLocation.location.lng as number;

        locations.push({
          name: value.address_components[0].long_name,
          latitude: lat,
          longitude: lng
        })
      }
    });

    return locations;
  }
}
