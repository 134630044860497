import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {FileItem} from "../../../../models/items/files/file-item.model";
import {ImageScalingService, ResizeResult} from "../../../../services/files/images/scale/image-scaling.service";
import {timer} from "rxjs";
import {IImageService} from "../../../../services/files/images/image.service.interface";

@Component({
  selector: 'app-submission-images',
  templateUrl: './submission-images.component.html',
  styleUrls: ['./submission-images.component.scss']
})
export class SubmissionImagesComponent implements OnInit, OnChanges {
  @ViewChild("fileDropRef", {static: false}) fileDropEl!: ElementRef;
  @ViewChild("canvasElement", {static: false}) canvasEl!: ElementRef;

  @Input() fileItems: Array<FileItem> = new Array<FileItem>();
  @Output() fileItemsDropped: EventEmitter<Array<FileItem>> = new EventEmitter<Array<FileItem>>();
  @Output() filesDropped: EventEmitter<Array<File>> = new EventEmitter<Array<File>>();
  files: any[] = [];

  @Output()

  public preparing = false;
  public currentStepText = "Preparing files for upload...";

  constructor(
    private scalingService: ImageScalingService,
    private imageService: IImageService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileItems !== undefined) {
      if (changes.fileItems.currentValue.length === 0) {
        this.files = [];
      }
    }
  }

  ngOnInit(): void {
  }

  async onFileDropped($event: any) {
    await this.prepareFilesList($event);
  }

  async fileBrowseHandler($event: any) {
    await this.prepareFilesList($event.target.files);
  }

  sleep(timeMs: number): Promise<any> {
    return timer(timeMs).toPromise().then(res => {});
  }

  isImageFile(str: string) {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|tif|TIF|tiff|TIFF)$/);
    if (str == null) {
      return false;
    }
    return regex.test(str);
  }

  async prepareFilesList(files: Array<any>) {
    files = [...files].filter( f => this.isImageFile(f.name));
    this.preparing = true;
    for (const file of files) {
      file.progress = 0;
      this.files.push(file);
      const fileItem = new FileItem(`/${file.name}`)
      fileItem.file = file;
      this.fileItems.push(fileItem);
    }
    while (this.fileItems.length != files.length) {
      await this.sleep(1000);
    }
    this.preparing = false;
    this.fileItemsDropped.emit(this.fileItems);
    this.filesDropped.emit(files);
    this.fileDropEl.nativeElement.value = "";
  }

  public reset() {
    this.files = [];
    this.fileItems = [];

  }
}
