import { Component, Input, OnInit } from '@angular/core';
import { organizationPopulationStatisticsDto } from '../../../../../models/dto/user/organization/organizationPopulationStatisticsDto';

@Component({
  selector: 'app-organization-profile-population-preview',
  templateUrl: './organization-profile-population-preview.component.html',
  styleUrls: ['./organization-profile-population-preview.component.scss']
})
export class OrganizationProfilePopulationPreviewComponent implements OnInit {

  @Input() population!: organizationPopulationStatisticsDto;
  constructor() { }

  ngOnInit(): void {
    if (this.population === undefined) return;

  }

  public getJdenticon(population: organizationPopulationStatisticsDto) {
    return `${population.organization_Population!.displayName} ${population.organization_Population!.speciesName} ${population.organization_Population!.abbreviation}`;
  }

  getRouterLink(population: organizationPopulationStatisticsDto) {
    return `/about`;
  }


}
