<div class="content-container">
  <div class="content" *ngIf="loading == false">
    <div class="request-review" *ngIf="exportRequest!.approved == 'False' && accepted == false">
      <h2>Please review this Export Request.</h2>
      <mat-label>Table name: {{exportRequest!.tableName!}}</mat-label>
      <mat-label>Time selection Criteria: {{exportRequest!.timeSelectionCriteria!}}</mat-label>
      <mat-label>Export Criteria: {{exportRequest!.exportCriteria!}}</mat-label>
      <mat-label>From: {{exportRequest!.from!}}</mat-label>
      <mat-label>To: {{exportRequest!.to!}}</mat-label>
      <mat-label>Reason: {{exportRequest!.reason!}}</mat-label>
      <div>
        <button mat-button (click)="handleReview(false)" color="accent">Decline</button>
        <button mat-button (click)="handleReview(true)" color="accent">Accept</button>
      </div>
    </div>
    <div *ngIf="exportRequest!.approved != undefined && exportRequest!.approved == 'True' || accepted == true">
      <h2>The Export has already been Accepted.</h2>
    </div>
    <div *ngIf="declined == true && accepted == false">
        <h2>The Export has already been Declined. Accept anyway?</h2>
      </div>
  </div>
</div>
